import FAIcon from '@Components/ui/fa-icons';
import { addComma, ordinalSuffixFormatter } from '@Utils/number';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Treemap, Tooltip } from 'recharts';

const colors = ['#FFB700FF', '#C0C0C0', '#8B4513'];
const crownColors = ['#A86200FF', '#868686FF', '#D66C20FF'];

const processData = data => {
  return [
    {
      name: '',
      children: data
        .sort((a, b) => b.value - a.value)
        .map((item, index) => ({
          name: item.label,
          size: item.value,
          rank: index,
          value: item.value,
        })),
    },
  ];
};

const CustomizedContent = props => {
  const { depth, x, y, width, height, rank, name, value, suffix, prefix } = props;

  if (depth <= 1) {
    return null;
  }

  const grayScale = `rgba(128, 128, 128, ${0 + (rank - 6) * 0.1})`;

  const fill = rank < 3 ? colors[rank] : grayScale;

  const fontSize = Math.max(10, Math.min(32, (width + height) / 12));

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + '...';
    }
    return text;
  };
  const truncatedName = truncateText(name, Math.floor((width * 1.45) / fontSize));

  return (
    <g>
      <rect x={x} y={y} width={width} height={height} style={{ fill, stroke: '#fff' }} />
      {width > 40 && height > 20 && (
        <>
          {rank < 3 && (
            <foreignObject
              x={x + width / 2 - 8}
              y={y + height / 2 - fontSize * 1.7}
              width={56}
              height={56}
            >
              {/* @ts-ignore */}
              <div xmlns="http://www.w3.org/1999/xhtml">
                <FAIcon name="fasCrown" color={crownColors[rank]} />
              </div>
            </foreignObject>
          )}
          <text
            x={x + width / 2}
            y={y + height / 2}
            textAnchor="middle"
            fill="white"
            fontSize={fontSize}
          >
            {capitalize(truncatedName)}
          </text>
          <text
            x={x + width / 2}
            y={y + height / 2 + fontSize * 0.8}
            textAnchor="middle"
            fill="white"
            fontSize={Math.max(10, (fontSize - 2) * 0.5)}
          >
            {prefix}
            {addComma(value)}
            {suffix}
          </text>
        </>
      )}
    </g>
  );
};

// Treemap Chart Component
const TreeMapChart = ({
  data,
  suffix,
  tooltipSuffix,
  prefix,
  tooltipPrefix,
}: {
  data: {
    label: string;
    value: number;
  }[];
  suffix?: string;
  tooltipSuffix?: string;
  prefix?: string;
  tooltipPrefix?: string;
}) => {
  const processedData = processData(data);
  const size = window.innerWidth > 1680 ? window.innerWidth - 380 : window.innerWidth - 180;
  const [width, setWidth] = useState(size);

  // TODO: not working
  useEffect(() => {
    const updateWidth = () => {
      setWidth(size);
    };
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-black rounded-lg px-2 py-1">
          <p className="label text-white font-bold text-lg">
            {payload[0].payload.rank < 3 ? (
              <FAIcon
                className="pr-1"
                name="fasCrown"
                color={crownColors[payload[0].payload.rank]}
              />
            ) : null}
            {ordinalSuffixFormatter(payload[0].payload.rank + 1)}
          </p>
          <p className="label text-white font-bold text-3xl">
            {capitalize(payload[0].payload.name)}
          </p>
          <p className="label text-white ">
            {tooltipPrefix} {addComma(payload[0].value)} {tooltipSuffix}
          </p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={`${data?.length === 0 ? 'bg-transparent' : 'bg-black w-fit'}`}>
      <Treemap
        width={width}
        height={600}
        data={processedData}
        dataKey="size"
        aspectRatio={1}
        stroke="#FFFFFF1C"
        isAnimationActive={false}
        content={<CustomizedContent suffix={suffix} prefix={prefix} />}
      >
        <Tooltip content={CustomTooltip} />
      </Treemap>
    </div>
  );
};

export default TreeMapChart;
