import React, { useCallback, useContext, useMemo } from 'react';
import { useStates } from '@Hooks';
import { createContext } from 'react';
import { ArtistListResponseDataItem, useGetArtistList } from '@Services/artist';
import { showInfoMessage } from '@Utils';

export const MODE = ['ADD', 'REMOVE'] as const;
type Mode = (typeof MODE)[number];

type States = {
  mode: Mode;
  tags: number[];
  filters: {
    tags: number[];
    code2s: string[];
  };
};

type ContextTypes = States & {
  onChangeMode: (mode: Mode) => void;
  onChangeTags: (tags: number[] | ((old: number[]) => number[])) => void;
  onChangeFilter: (key: keyof States['filters']) => (value: string[] | number[]) => void;
  numberOfArtists: number;
  isLoading: boolean;
  artists: ArtistListResponseDataItem[];
  onReset: () => void;
};

const INITIAL_STATE: States = {
  mode: 'ADD',
  tags: [],
  filters: {
    tags: [],
    code2s: [],
  },
};

const EditArtistGenreBatchContext = createContext<ContextTypes>({
  ...INITIAL_STATE,
  onChangeMode: () => {},
  onChangeTags: () => {},
  onChangeFilter: () => () => {},
  numberOfArtists: 0,
  isLoading: false,
  artists: [],
  onReset: () => {},
});

const EditArtistGenreBatchProvider = ({ children }: { children: React.ReactNode }) => {
  const { states, setState, onDispatchState } = useStates<States>(INITIAL_STATE);

  const onChangeFilter = useCallback(
    (key: keyof States['filters']) => (value: string[] | number[]) => {
      if (key === 'tags' && states.mode === 'REMOVE') {
        const newTag = (value as number[]).filter(tag => !states.tags.includes(Number(tag)));
        const removedTag = states.tags.filter(tag => !(value as number[]).includes(Number(tag)));

        onDispatchState('tags', (_, old) => ({
          ...old,
          tags: (old.tags as number[]).filter(tag => !removedTag.includes(tag)).concat(newTag),
        }))([] as number[]);
      }
      setState(`filters.${key}`)(value);
    },
    [states.mode, states.tags, setState, onDispatchState]
  );

  const { data: rawData, isLoading } = useGetArtistList(
    {
      data: {
        tags: states.filters.tags,
        code2s: states.filters.code2s,
        limit: 100,
        offset: 0,
      },
    },
    {
      disable: !states.filters.tags?.length && !states.filters.code2s?.length,
    }
  );

  const artists = useMemo(() => rawData?.data ?? [], [rawData]);
  const numberOfArtists = useMemo(() => rawData?.total ?? 0, [rawData]);
  const onReset = useCallback(() => {
    setState('filters.tags')(undefined);
    setState('filters.code2s')(undefined);
    setState('tags')([]);
    showInfoMessage(
      'Data will be reflected in production according to the data scheduling. Updated tags can be checked immediately in the admin tool.'
    );
  }, [setState]);

  return (
    <EditArtistGenreBatchContext.Provider
      value={{
        ...states,
        onChangeMode: onDispatchState('mode', (newMode, old) => ({
          ...INITIAL_STATE,
          filters: {
            tags: old.filters.tags,
            code2s: old.filters.code2s,
          },
          mode: newMode,
        })),
        onChangeTags: setState('tags'),
        onChangeFilter,
        numberOfArtists,
        isLoading,
        artists,
        onReset,
      }}
    >
      {children}
    </EditArtistGenreBatchContext.Provider>
  );
};

export const useEditArtistGenreBatch = () => {
  return useContext(EditArtistGenreBatchContext);
};

export default EditArtistGenreBatchProvider;
