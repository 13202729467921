import { PLATFORMS } from './index';

type Platform = typeof PLATFORMS;

export const PLATFORM_COLORS: Record<Platform[number], string> = {
  acr: '#515151',
  siriusxm: '#515151',
  radio: '#515151',
  netease: '#E60000',
  qq: '#EB1923',
  tencent: '#5784F9',
  tunefind: '#515151',
  amazon: '#FF9900',
  applemusic: '#515151',
  itunes: '#d875d0',
  boomplay: '#0066FF',
  bandsintown: '#00B4B3',
  beatport: '#A86011',
  chartmetric: '#08B0C3',
  deezer: '#9545F6',
  facebook: '#1778F2',
  genius: '#fbc531',
  instagram: '#DD2A7B',
  line: '#5BC463',
  melon: '#00d344',
  pandora: '#3668FF',
  pinterest: '#c8232c',
  shazam: '#4D62B1',
  songkick: '#F90447',
  soundcloud: '#FC7811',
  spotify: '#1DB954',
  tiktok: '#515151',
  twitch: '#6441A5',
  twitter: '#1DA1F3',
  wikipedia: '#636466',
  youtube: '#FF0400',
  youtubeforartist: '#FF0400',
  reddit: '#FF4500',
  anghami: '#A8A8A8',
};

export const PLATFORM_DARK_COLORS: Record<Platform[number], string> = {
  acr: '#A8A8A8',
  siriusxm: '#A8A8A8',
  radio: '#A8A8A8',
  netease: '#E60000',
  qq: '#EB1923',
  tencent: '#5784F9',
  tunefind: '#A8A8A8',
  amazon: '#FF9900',
  applemusic: '#B4B4B4',
  itunes: '#d875d0',
  boomplay: '#227BFF',
  bandsintown: '#00B4B3',
  beatport: '#A86011',
  chartmetric: '#08B0C3',
  deezer: '#9545F6',
  facebook: '#1778F2',
  genius: '#fbc531',
  instagram: '#DD2A7B',
  line: '#0ad269',
  melon: '#00d344',
  pandora: '#4E7AFD',
  pinterest: '#c8232c',
  shazam: '#6078CE',
  songkick: '#F90447',
  soundcloud: '#FC7811',
  spotify: '#1DB954',
  tiktok: '#A8A8A8',
  twitch: '#6441A5',
  twitter: '#1DA1F3',
  wikipedia: '#7D7E81',
  youtube: '#FF0400',
  youtubeforartist: '#FF0400',
  reddit: '#FF4500',
  anghami: '#A8A8A8',
};
