import { cn } from '@/lib/utils';
import { Badge, Flex, Input, Muted } from '@/ui';
import { Panel } from '@Components/ui/panel';
import { useGetPageHistoryStatistics } from '@Services/log';
import { AntdTable } from '@Shared';
import DataTableSearch from '@Shared/Table/SearchInput';
import { generateCmUrl } from '@Utils';
import { capitalize } from 'lodash';
import { useState } from 'react';

const COLUMNS = [
  {
    Header: 'Type',
    accessor: 'state',
    width: 125,
    Cell: props => (
      <Badge
        className={cn({
          'bg-orange-500 text-white': props.value.split('.')[0].toLowerCase() === 'mainapp',
          'bg-blue-500 text-white': props.value.split('.')[0].toLowerCase() === 'customdashboard',
          'bg-green-500 text-white':
            props.value.split('.')[0].toLowerCase() !== 'customdashboard' &&
            props.value.split('.')[0].toLowerCase() !== 'mainapp',
        })}>
        {capitalize(props.value.split('.')[0])}
      </Badge>
    ),
  },
  {
    Header: 'Page',
    accessor: 'state', // String-based value accessors!
    width: 250,
    Cell: props => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={generateCmUrl(props.value.split('.').slice(1).join('.').replace('.', '/'), {
          id: props.original.page_id ?? undefined,
        })}>
        {props.value.replace('mainApp.', '').replace('.', '/')}
      </a>
    ),
  },
  {
    Header: 'Page ID',
    accessor: 'page_id',
  },
  {
    Header: 'Count',
    accessor: 'count',
  },
];
const PageHistoryStatistics = () => {
  const [fromDaysAgo, setFromDaysAgo] = useState(30);
  const { data, isLoading } = useGetPageHistoryStatistics({ data: { daysAgo: fromDaysAgo } });
  const [filteredData, setFilteredData] = useState(data ?? []);
  return (
    <Panel
      header="Page History Statistics"
      aside={
        <Flex align="center" gap={1}>
          <Muted>From Days Ago</Muted>
          <Input value={fromDaysAgo} onChange={e => setFromDaysAgo(Number(e.target.value))} type="number" />
        </Flex>
      }>
      <DataTableSearch originalData={data ?? []} onChange={setFilteredData} />
      <AntdTable columns={COLUMNS} data={filteredData} loading={isLoading} />
    </Panel>
  );
};

export default PageHistoryStatistics;
