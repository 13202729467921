import React, { useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { Button, Checkbox, Flex, Input, Select, Typography } from 'antd';
import { useDeleteCacheKey } from '@Services/system';
import { Panel, Icon, AntdIcon } from '../../../shared/Style';
import { useGetSignUps, useUpdateSourceUpdatedForUser } from '../../../services/user';
import {
  generateCmUrl,
  generateInternalLink,
  getFormattedDateString,
  getStripeCustomerUrl,
  showInfoMessage,
} from '../../../utilities';
import DataTableSearch from '../../../shared/Table/SearchInput';
import useDateSelect from '../../../hooks/useDateSelect';
import DateSelector from '../../../shared/Table/DateSelector';
import FAIcon from '@Components/ui/fa-icons';
import { AntdTable } from '@Shared';

const CACHE_KEY = (since, until) =>
  `Admin: getUserSignUps: ["${since}T00:00:00.000Z","${until}T00:00:00.000Z","0.5"]`;

const CSV_FORMAT = [
  { label: 'Email', key: 'email' },
  { label: 'Artists', key: 'artists' },
  { label: 'Customer ID', key: 'customer_id' },
  { label: 'Plan', key: 'plan' },
  { label: 'Register Date', key: 'register_date' },
  { label: 'Source Updated', key: 'source_update' },
  { label: 'Source Updated Changed', key: 'source_update_changed' },
  { label: 'Unsubscribed', key: 'unsubscribed' },
];

const FILTER_OPTIONS = [
  {
    label: 'All Sources',
    value: 'all',
  },
  {
    label: 'Source Updated',
    value: 'updated',
  },
  {
    label: 'Source Pending',
    value: 'pending',
  },
];

const SEARCH_FILTERS = [
  { value: 'email', label: 'Email' },
  { value: 'artists', label: 'Artists' },
  { value: 'plan', label: 'Plan' },
  { value: 'register_date', label: 'Register Date' },
  { value: 'unsubscribed', label: 'Unsubscribed' },
];

function SourceUpdatedCheckBox({ value, userId, original }) {
  const [checked, setChecked] = useState(value);
  const { execute: updateSourceUpdated } = useUpdateSourceUpdatedForUser();

  const handleChangeCheckBox = userId => () => {
    setChecked((checked: boolean) => !checked);
    updateSourceUpdated({
      data: { userId, updated: !checked },
    });
    original.source_update = !checked;
  };

  return <Checkbox checked={checked} onChange={handleChangeCheckBox(userId)} />;
}

function SignUpHistory() {
  const { handleDateChange, handleSelectPeriod, period, since, until } = useDateSelect();

  const { data, refetch, isLoading } = useGetSignUps({
    data: {
      since,
      until: moment(until).add(1, 'day').format('YYYY-MM-DD'),
      subscriptionLevel: 0.5,
    },
  });
  const [filteredData, setFilteredData] = useState(data);
  const [sourceUpdatedFilter, setSourceUpdatedFilter] = useState<'all' | 'updated' | 'pending'>(
    'pending'
  );

  //TODO: delete cache key before back-end logic resolved.
  const { execute: deleteCacheKey } = useDeleteCacheKey();

  useEffect(() => {
    refetch().then(() => {
      //TODO: delete cache key before back-end logic resolved.
      deleteCacheKey({
        query: {
          key: CACHE_KEY(since, moment(until).add(1, 'day').format('YYYY-MM-DD')),
        },
      });
    });
  }, [since, until]);

  useEffect(() => {
    //TODO: delete cache key before back-end logic resolved.
    deleteCacheKey({
      query: {
        key: CACHE_KEY(since, until),
      },
    });
  }, [data]);

  const getDataForCSV = useMemo(() => {
    if (data) {
      return data.map(item => {
        const { email, artists, customer_id, plan, register_date, source_update, unsubscribed } =
          item;
        return {
          ...item,
          source_update: source_update === 'updated' ? 'O' : 'X',
          register_date: getFormattedDateString(register_date),
          artists: artists.map(({ name }) => name).join(', '),
        };
      });
    }
    return [];
  }, [data]);

  const handleCheckSourceUpdatedFilter = value => {
    setSourceUpdatedFilter(value);
  };

  const filteredDataWithSourceUpdated = useMemo(() => {
    if (!data) return [];
    if (sourceUpdatedFilter === 'all') return data;
    if (sourceUpdatedFilter === 'updated') {
      return data.filter(({ source_update }) => Boolean(source_update));
    }
    if (sourceUpdatedFilter === 'pending') {
      return data.filter(({ source_update }) => !source_update);
    }
    return [];
  }, [data, sourceUpdatedFilter]);

  const COLUMNS = [
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ value }) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={generateInternalLink('user/user-profile', { qs: value })}
        >
          {value}
        </a>
      ),
    },
    {
      Header: 'Artists',
      accessor: 'artists',
      Cell: ({ value }) =>
        value.map(({ id, name }, i) => (
          <>
            <Typography.Link
              target="_blank"
              rel="noopener noreferrer"
              href={generateCmUrl('artist', { id })}
            >
              {name}
            </Typography.Link>
            {i !== value.length - 1 && (
              <>
                {', '}
                <br />
              </>
            )}
          </>
        )),
    },
    {
      Header: 'Plan',
      accessor: 'plan',
    },
    {
      Header: 'Unsubscribed',
      accessor: 'unsubscribed',
      Cell: ({ value }) => value && <FAIcon name="fasCheckCircle" color="red" />,
      width: 100,
    },
    {
      Header: 'Registered At',
      accessor: 'register_date',
      Cell: ({ value }) => getFormattedDateString(value),
      width: 110,
    },
    {
      Header: 'Stripe ID',
      accessor: 'customer_id',
      Cell: ({ value }) => (
        <a href={getStripeCustomerUrl(value)} target="_blank" rel="noopener noreferrer">
          {value}
        </a>
      ),
    },
    {
      Header: 'Source Updated',
      accessor: 'source_update',
      Cell: ({ value, original }) => (
        <SourceUpdatedCheckBox value={value} userId={original.id} original={original} />
      ),
      width: 125,
    },
    {
      Header: 'Source Updated Changed',
      accessor: 'source_update_changed',
      Cell: ({ value }) => (value ? getFormattedDateString(value) : null),
      width: 125,
    },
  ];

  return (
    <Panel
      icon="farHistory"
      title="Sign Up History For Artist Plan Users"
      extra={
        <DataTableSearch onChange={setFilteredData} originalData={data} filters={SEARCH_FILTERS} />
      }
    >
      <br />
      <Flex justify="space-between">
        <Flex gap="small">
          <DateSelector
            handleDateChange={handleDateChange}
            handleSelectPeriod={handleSelectPeriod}
            period={period}
            since={since}
            until={until}
          />
          <Select
            options={FILTER_OPTIONS}
            value={sourceUpdatedFilter}
            onChange={handleCheckSourceUpdatedFilter}
          />
        </Flex>
        <CSVLink
          data={getDataForCSV}
          headers={CSV_FORMAT}
          filename={`Sign Up History For Artist Plan Users (${since} ~ ${until}).csv`}
        >
          <Button className="green" type="primary" icon={<AntdIcon name="csv" />}>
            Download CSV
          </Button>
        </CSVLink>
      </Flex>
      <br />
      <AntdTable loading={isLoading} data={filteredDataWithSourceUpdated} columns={COLUMNS} />
    </Panel>
  );
}

export default SignUpHistory;
