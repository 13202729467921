import { mutateToUrl } from './helpers';

const ENDPOINT = {
  DELETE: '/admin/playlist/delete',
  UPDATE: '/admin/playlist',
};

export const useDeletePlaylist = mutateToUrl<{
  data: { id: number; streamingType: string };
}>('DELETE', ENDPOINT.DELETE);

export const useUpdatePlaylist = mutateToUrl<{
  data: {
    id: number;
    streamingType: string;
    storefront: string;
    metadata: { code2: string; personalized: boolean };
  };
}>('PUT', ENDPOINT.UPDATE);
