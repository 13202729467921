export function capitalizeEveryWord(words: string) {
  if (!words) {
    return '';
  }
  return words.toLowerCase().replace(/(\b[a-z](?!\s))/g, letter => letter.toUpperCase());
}

export function addSpaceBeforeCapitalLetter(text: string) {
  return capitalize(text.replace(/([A-Z])/g, ' $1').trim());
}

export function capitalize(str: string) {
  if (!str || typeof str !== 'string') {
    return str;
  }

  return str[0].toUpperCase() + str.slice(1);
}

export function findMatchingString(source: string, targets: string[]): string | undefined {
  for (let target of targets) {
    if (source.includes(target)) {
      return target;
    }
  }
  return undefined;
}

export const VALID_EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
