// Define a type for RGB color
type RGBColor = {
  r: number;
  g: number;
  b: number;
  a?: number;
};

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  let isWhite = true;
  while (isWhite) {
    color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 15)];
    }
    if (!/^#FFFFFF/i.test(color)) {
      isWhite = false;
    }
  }
  return color;
}

// Function to convert HEX to RGB
export function hexToRgb(hex: string): RGBColor {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

// Function to convert RGB to HEX
export function rgbaToHex({ r, g, b, a }: RGBColor): string {
  const toHex = (value: number) => {
    const hex = value.toString(16);
    return hex.length === 1 ? '0' + hex : hex;
  };

  return a
    ? `#${toHex(r)}${toHex(g)}${toHex(b)}${toHex(Math.round(a * 255))}`
    : `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

// Function to calculate the average color
export function averageColor(hexColors: string[]): string {
  const rgbColors = hexColors.map(hex => hexToRgb(hex));
  const totalColors = rgbColors.length;

  // Sum up all the RGB values
  const sumColor = rgbColors.reduce(
    (acc, color) => {
      acc.r += color.r;
      acc.g += color.g;
      acc.b += color.b;
      return acc;
    },
    { r: 0, g: 0, b: 0 }
  );

  // Calculate the average RGB values
  const avgColor = {
    r: Math.round(sumColor.r / totalColors),
    g: Math.round(sumColor.g / totalColors),
    b: Math.round(sumColor.b / totalColors),
  };

  return rgbaToHex(avgColor);
}
export const whitenColor = (color: string, percent = 40): string => {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  let R = (num >> 16) + amt;
  let G = ((num >> 8) & 0x00ff) + amt;
  let B = (num & 0x0000ff) + amt;

  // Make sure the values are within the valid range (0-255)
  R = R < 0 ? 0 : R > 255 ? 255 : R;
  G = G < 0 ? 0 : G > 255 ? 255 : G;
  B = B < 0 ? 0 : B > 255 ? 255 : B;

  const newColor =
    '#' + (0x1000000 + R * 0x10000 + G * 0x100 + B).toString(16).slice(1).toUpperCase();
  return newColor;
};

export function isTooWhite(color: string) {
  if (color === 'transparent' || !color) {
    return false;
  }

  if (color.length === 4) {
    color = '#' + [color[1], color[1], color[2], color[2], color[3], color[3]].join('');
  }

  color = color.substring(1); // remove #
  const rgb = parseInt(color, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  // Using the standard luminance calculation
  const luma = 0.299 * r + 0.587 * g + 0.114 * b;

  return luma > 190; // higher threshold for identifying "too white"
}

export const isDarker = (color: string) => {
  if (color === 'transparent' || !color) {
    return false;
  }

  if (color.startsWith('rgba')) {
    const [r, g, b, a] = color.replace('rgba(', '').replace(')', '').split(',').map(Number);

    color = rgbaToHex({ r, g, b, a });
  }

  if (color.length === 4) {
    color = '#' + [color[1], color[1], color[2], color[2], color[3], color[3]].join('');
  }

  color = color.substring(1); // remove #
  const rgb = parseInt(color, 16); // convert rrggbb to decimal
  const r = (rgb >> 16) & 0xff; // extract red
  const g = (rgb >> 8) & 0xff; // extract green
  const b = (rgb >> 0) & 0xff; // extract blue

  // Adjusted weights to increase sensitivity
  const luma = 0.299 * r + 0.587 * g + 0.114 * b; // using adjusted coefficients

  return luma < 100; // lower threshold for higher sensitivity
};

export const darkenColor = (color: string, percent = 40) => {
  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  let R = (num >> 16) - amt;
  let G = ((num >> 8) & 0x00ff) - amt;
  let B = (num & 0x0000ff) - amt;

  // Make sure the values are within the valid range (0-255)
  R = R < 0 ? 0 : R > 255 ? 255 : R;
  G = G < 0 ? 0 : G > 255 ? 255 : G;
  B = B < 0 ? 0 : B > 255 ? 255 : B;

  const newColor =
    '#' + (0x1000000 + R * 0x10000 + G * 0x100 + B).toString(16).slice(1).toUpperCase();
  return newColor;
};

// Function to brighten a color
export function brightenColor(hex: string, percent: number): string {
  const rgb = hexToRgb(hex);

  const brighten = (value: number) => {
    return Math.min(255, Math.round(value + (255 - value) * (percent / 100)));
  };

  const brightenedColor = {
    r: brighten(rgb.r),
    g: brighten(rgb.g),
    b: brighten(rgb.b),
  };

  return rgbaToHex(brightenedColor);
}

export const opacityColor = (hex: string, opacity: number) => {
  return hex + Math.round(opacity * 255).toString(16);
};

export const invertColor = (color: string) => {
  const hex = color.replace('#', '');
  const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16).padStart(2, '0');
  const g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16).padStart(2, '0');
  const b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16).padStart(2, '0');
  return `#${r}${g}${b}`;
};
