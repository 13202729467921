import moment from 'moment';
import { useState } from 'react';

type Props = { defaultPeriod: number } | undefined;

const useDateSelect = (props?: Props) => {
  const defaultPeriod = props?.defaultPeriod || 7;
  const [since, setSince] = useState(moment().subtract(defaultPeriod, 'days').format('YYYY-MM-DD'));
  const [until, setUntil] = useState(moment().format('YYYY-MM-DD'));
  const [selectedPeriodOption, setPeriodOption] = useState(defaultPeriod);

  const handleSelectPeriod = (days: number) => {
    setPeriodOption(days);

    if (days === 9999) {
      setSince(moment('2016-01-01').format('YYYY-MM-DD'));
    } else {
      setSince(moment().subtract(days, 'days').format('YYYY-MM-DD'));
    }
    setUntil(moment().format('YYYY-MM-DD'));
  };

  const handleDateChange = ({ since, until }) => {
    setSince(since);
    setUntil(until);
  };

  return {
    since,
    until,
    period: selectedPeriodOption,
    handleDateChange,
    handleSelectPeriod,
  };
};

export default useDateSelect;
