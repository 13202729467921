import { SearchInput, WhatsThis } from '@Shared';
import { AntdIcon, Panel } from '@Shared/Style';
import ModalButton from '@Shared/Style/ModalButton';
import { Button, Checkbox, Divider, Flex, Modal, Segmented, Select, Typography } from 'antd';
import React, { createContext, useState } from 'react';
import GenreSelection from './Components/GenreSelection';
import { useModal, useStates } from '@Hooks';
import EditArtistGenreBatchProvider, { MODE, useEditArtistGenreBatch } from './Context';
import ArtistSelection from './Components/ArtistSelection';
import { capitalize, isEmpty } from 'lodash';
import CountryFilter from './Components/Selector/CountryFilter';
import {
  convertInternalTagTypeToLinkType,
  useDeleteArtistTagBatch,
  useInsertArtistTagBatch,
  useTagList,
} from '@Services/tag';
import TagFilter from './Components/Selector/TagFilter';
import FAIcon from '@Components/ui/fa-icons';
import { ModalClose } from '@/ui';

function EditArtistGenreBatch() {
  const { mode, onChangeMode, filters, tags, numberOfArtists, onReset, onChangeFilter } =
    useEditArtistGenreBatch();
  const { execute: insertRequest, isLoading: isInserting } = useInsertArtistTagBatch();
  const { execute: deleteRequest, isLoading: isDeleting } = useDeleteArtistTagBatch();
  const { getTagById, getLinkType } = useTagList();

  const execute = async () => {
    if (mode === 'ADD') {
      await insertRequest({
        data: {
          code2s: filters.code2s,
          tagFilters: filters.tags,
          tags: tags.map(id => {
            const tag = getTagById(id)!;
            return {
              id: tag.id,
              linkType: getLinkType(tag.id),
            };
          }),
        },
      });
    } else {
      await deleteRequest({
        data: {
          code2s: filters.code2s,
          tagFilters: filters.tags,
          tags: tags.map(id => {
            const tag = getTagById(id)!;
            return {
              id: tag.id,
              linkType: getLinkType(tag.id),
            };
          }),
        },
      });
    }
    onReset();
  };

  return (
    <Panel
      title="Adding artists to a genre en-masse"
      extra={
        <Flex align="center">
          {mode === 'REMOVE' && (
            <Checkbox checked={true} disabled>
              Soft delete{' '}
              <WhatsThis title="Soft Delete">
                Soft delete will hide the tag from the artist but keep the data. If you want to
                remove the tag completely, please contact the data team.
              </WhatsThis>
            </Checkbox>
          )}
          <ModalButton
            title="Check your request"
            onOk={execute}
            okButtonProps={{
              loading: isInserting || isDeleting,
            }}
            footer={
              <ModalClose asChild>
                <Button
                  size="large"
                  loading={isInserting || isDeleting}
                  disabled={!numberOfArtists || isEmpty(tags)}
                  icon={<AntdIcon name="checkCircle" />}
                  className="green"
                >
                  Submit
                </Button>
              </ModalClose>
            }
          >
            Are you sure you want to add <b>{tags.map(id => getTagById(id)?.name).join(', ')}</b> to{' '}
            <b>{numberOfArtists} artists</b>?
          </ModalButton>
        </Flex>
      }
    >
      <Flex align="center" justify="space-between">
        <Segmented
          options={MODE.map(m => ({
            value: m,
            label: capitalize(m),
          }))}
          value={mode}
          onChange={onChangeMode}
        />
        <Flex vertical>
          <Typography.Text type="secondary">Filter by</Typography.Text>
          <Flex gap="small">
            <TagFilter onChange={onChangeFilter('tags')} tagType="mainV2" multiple />
            <CountryFilter />
          </Flex>
        </Flex>
      </Flex>
      <Divider />
      <Flex gap="small" style={{ height: '100%' }}>
        <Flex flex={3}>
          <GenreSelection />
        </Flex>
        <Divider type="vertical" />
        <Flex flex={4} style={{ minHeight: '100%' }}>
          <ArtistSelection />
        </Flex>
      </Flex>
    </Panel>
  );
}

export default () => {
  return (
    <EditArtistGenreBatchProvider>
      <EditArtistGenreBatch />
    </EditArtistGenreBatchProvider>
  );
};
