import { PixelCrop } from 'react-image-crop';
import round from 'lodash/round';
import isEmpty from 'lodash/isEmpty';
import imageCompression, { Options } from 'browser-image-compression';
import { FILE_SIZE_LIMIT } from '@Utils/constants';

const TO_RADIANS = Math.PI / 180;

type Dimensions = { width: number; height: number };

// Source: https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/canvasPreview.ts:0-1702
export async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0,
  requiredMinDimensions: Dimensions = {} as Dimensions
) {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = round(image.naturalWidth / image.width, 1);
  const scaleY = round(image.naturalHeight / image.height, 1);
  const maxScale = Math.max(scaleX, scaleY);

  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  const cropWidthRounded = round(crop.width);
  const cropHeightRounded = round(crop.height);

  const canvasWidthInit = Math.floor(cropWidthRounded * maxScale);
  const canvasHeightInit = Math.floor(cropHeightRounded * maxScale);

  if (
    !isEmpty(requiredMinDimensions) &&
    (canvasWidthInit < requiredMinDimensions.width ||
      canvasHeightInit < requiredMinDimensions.height)
  ) {
    throw new Error(
      `Min. image dimensions ${requiredMinDimensions.width}x${requiredMinDimensions.height}px. Received: ${canvasWidthInit}x${canvasHeightInit}px`
    );
  }

  const canvasWidth = Math.floor(cropWidthRounded * maxScale * pixelRatio);
  const canvasHeight = Math.floor(cropHeightRounded * maxScale * pixelRatio);

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * maxScale;
  const cropY = crop.y * maxScale;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export function clearCanvas(canvas: HTMLCanvasElement) {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  ctx.clearRect(0, 0, canvas.width, canvas.height);
}

// Source: https://codesandbox.io/s/react-image-crop-demo-with-react-hooks-y831o?file=/src/imgPreview.ts
function toBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
  return new Promise(resolve => {
    canvas.toBlob(resolve);
  });
}

// Returns an image source you should set to state and pass
// `{previewSrc && <img alt="Crop preview" src={previewSrc} />}`
export async function imgPreview(
  t,
  image: HTMLImageElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  let previewUrl = '';

  const canvas = document.createElement('canvas');
  await canvasPreview(image, canvas, crop, scale, rotate);

  const blob = await toBlob(canvas);

  if (!blob) {
    console.error('Failed to create blob');
    return '';
  }

  if (previewUrl) {
    URL.revokeObjectURL(previewUrl);
  }

  previewUrl = URL.createObjectURL(blob);

  return previewUrl;
}

export async function getCroppedImage(imgRef, previewCanvasRef, completedCrop, blobUrlRef) {
  const image = imgRef.current;
  const previewCanvas = previewCanvasRef.current;
  if (!image || !previewCanvas || !completedCrop) {
    throw new Error('Crop canvas does not exist');
  }

  // This will size relative to the uploaded image
  // size. If you want to size according to what they
  // are looking at on screen, remove scaleX + scaleY
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const maxScale = Math.max(scaleX, scaleY);
  image.crossOrigin = 'anonymous';

  const cropWidthRounded = round(completedCrop.width);
  const cropHeightRounded = round(completedCrop.height);

  const offscreen = new OffscreenCanvas(cropWidthRounded * maxScale, cropHeightRounded * maxScale);
  const ctx = offscreen.getContext('2d') as OffscreenCanvasRenderingContext2D;
  if (!ctx) {
    throw new Error('No 2d context');
  }

  ctx.drawImage(
    previewCanvas,
    0,
    0,
    previewCanvas.width,
    previewCanvas.height,
    0,
    0,
    offscreen.width,
    offscreen.height
  );

  // Workaround to convert OffscreenCanvas to a Blob
  const tempCanvas = document.createElement('canvas');
  tempCanvas.width = offscreen.width;
  tempCanvas.height = offscreen.height;
  const tempCtx = tempCanvas.getContext('2d');
  if (!tempCtx) {
    throw new Error('No 2d context on temp canvas');
  }
  const imageBitmap = await offscreen.transferToImageBitmap();
  tempCtx.drawImage(imageBitmap, 0, 0);

  const blob = await toBlob(tempCanvas);

  if (blobUrlRef.current) {
    URL.revokeObjectURL(blobUrlRef.current);
  }
  if (blob) blobUrlRef.current = URL.createObjectURL(blob);

  return { imageSrc: blobUrlRef.current, imageBlob: blob };
}

export const compressImage = async (file: File) => {
  if (file.type.slice(0, 5) !== 'image') {
    return file;
  }

  const options: Options = {
    maxSizeMB: FILE_SIZE_LIMIT,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const compressedFile = await imageCompression(file, options);
  return new File([compressedFile], 'image.png', { type: 'image/png' });
};
