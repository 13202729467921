import TagGroups from './componetns/GernePanel';
import GenreOrder from './componetns/GenreOrderPanel';
import { GenrePanelProps, GenrePanelProvider } from './context/GenrePanelContext';

function EditGenrePanel(props: GenrePanelProps) {
  return (
    <>
      <GenrePanelProvider {...props}>
        <GenreOrder />
        <TagGroups />
      </GenrePanelProvider>
    </>
  );
}
export default EditGenrePanel;
