import { DatePicker, Flex, Segmented } from 'antd';
import dayjs from 'dayjs';

const dateFormat = 'YYYY-MM-DD';

function DateSelector({
  handleSelectPeriod,
  handleDateChange,
  period,
  since,
  until,
  options = [7, 30, 9999],
}) {
  return (
    <Flex justify="space-between" wrap="wrap" gap="small">
      <DatePicker.RangePicker
        allowEmpty={[false, false]}
        allowClear={false}
        format={dateFormat}
        onChange={(dates, dateStrings) => {
          handleDateChange({ since: dateStrings[0], until: dateStrings[1] });
        }}
        value={[dayjs(since, dateFormat), dayjs(until, dateFormat)]}
      />
      <Segmented
        options={options.map(option => ({
          label: option === 9999 ? 'All Time' : `Recent ${option} days`,
          value: option,
        }))}
        value={period}
        onChange={handleSelectPeriod}
      />
    </Flex>
  );
}

export default DateSelector;

