import Dragger from 'antd/es/upload/Dragger';
import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Divider, UploadFile } from 'antd';
import { parseExcel } from '@Utils/excel';
import Typography from 'antd/es/typography/Typography';
import { showErrorMessage } from '@Utils';

interface Props {
  setEmails: React.Dispatch<React.SetStateAction<string[]>>;
  emails: string[];
}

const AddBulkMember = ({ emails, setEmails }: Props) => {
  const [file, setFile] = React.useState<UploadFile<any> | null>(null);

  return (
    <>
      <Typography className="mb-1">
        <b>{`CSV or XLXS (Excel)`}</b> supported. Insert emails into the <code>email</code> column.
        If credits are insufficient, randomly adding members. and the result will be displayed in a
        table.
      </Typography>
      <Dragger
        name="file"
        multiple={false}
        listType="picture"
        accept=".xlsx,.csv"
        beforeUpload={file => {
          setFile(file);
          parseExcel(file, ['email']).then(data => {
            const emails = data.map((d: any) => d.email).filter(Boolean);
            console.log(data);
            if (!emails.length) return showErrorMessage('No emails found in the file');
            setEmails(data.map((d: any) => d.email).filter(Boolean));
          });
          return file;
        }}
        fileList={file ? [file] : []}
        onRemove={file => {
          setFile(null);
          setEmails([]);
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </Dragger>
      {emails.length > 0 && (
        <>
          <Divider />
          <Typography>
            {emails.length} email{emails.length > 1 ? 's' : ''} found in the file
          </Typography>
        </>
      )}
    </>
  );
};

export default AddBulkMember;
