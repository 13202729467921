// eslint-disable-next-line max-classes-per-file
import { showErrorMessage } from '@Utils';
import { AWS_ACCESS_CONFIG, AWS_CONFIG, AWS_ENV_KEY } from 'aws-exports';
import AWS, { S3 } from 'aws-sdk';
import { DocumentClient } from 'aws-sdk/clients/dynamodb';

AWS.config.update({
  region: AWS_CONFIG.aws_project_region,
  accessKeyId: AWS_ACCESS_CONFIG.ACCESS_KEY_ID,
  secretAccessKey: AWS_ACCESS_CONFIG.SECRET_ACCESS_KEY,
});

interface PutObjectParams {
  Bucket: string;
  Key: string;
  Body: string | Buffer;
  File: Blob | null;
}

class S3Builder {
  private params: PutObjectParams = {
    Bucket: AWS_ACCESS_CONFIG.S3_BUCKET,
    Key: 'public',
    Body: '',
    File: null,
  };

  private s3: S3;

  constructor() {
    this.s3 = new AWS.S3();
  }

  body(file: Blob, fileName: string) {
    this.params.Key = `${this.params.Key}/${fileName}`;
    this.params.File = file;
    return this;
  }

  async execute(): Promise<{ key: string; url: string } | null> {
    try {
      const arrayBuffer = await new Response(this.params.File).arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);

      await this.s3
        .putObject({
          Bucket: this.params.Bucket,
          Key: this.params.Key,
          Body: uint8Array,
        })
        .promise();

      return {
        key: this.params.Key,
        url: `https://${AWS_ACCESS_CONFIG.S3_BUCKET}.s3.${AWS_CONFIG.aws_project_region}.amazonaws.com/${this.params.Key}`,
      };
    } catch (error) {
      console.log(error);
      showErrorMessage('Failed to upload the file');
      return null;
    }
  }
}

/**
 * Get data from DynamoDB.
 */
class QueryBuilder<T> {
  params: DocumentClient.QueryInput = {
    TableName: '',
  };

  constructor(tableName: string) {
    this.params.TableName = `${tableName}-${AWS_ENV_KEY.beta}`;
  }

  /**
   * Sets the key conditions for the query.
   * @param key - The key attribute name.
   * @param comparisonOperator - The comparison operator for the key condition.
   * @param attributeValueList - The list of attribute values for the key condition.
   * @returns The QueryBuilder instance.
   */
  keyCondition(
    key: keyof T,
    comparisonOperator: DocumentClient.Condition['ComparisonOperator'],
    attributeValueList?:
      | DocumentClient.Condition['AttributeValueList']
      | AWS.DynamoDB.DocumentClient.AttributeValueList
  ) {
    this.params.KeyConditions = {
      [key]: {
        ComparisonOperator: comparisonOperator,
        AttributeValueList: attributeValueList,
      },
    };
    return this;
  }

  /**
   * Sets the limit for the query.
   * @param limit - The maximum number of items to return.
   * @returns The QueryBuilder instance.
   */
  limit(limit: number) {
    this.params.Limit = limit;
    return this;
  }

  /**
   * Picks the attributes to retrieve.
   * @param select - The attributes to retrieve.
   * @returns The QueryBuilder instance.
   */
  select(select: DocumentClient.QueryInput['Select']) {
    this.params.Select = select;
    return this;
  }

  /**
   * Determines the read consistency model.
   * @param consistentRead - The consistent read option.
   * @returns The QueryBuilder instance.
   */
  consistentRead(consistentRead: DocumentClient.QueryInput['ConsistentRead']) {
    this.params.ConsistentRead = consistentRead;
    return this;
  }

  /**
   * Gets the result in ascending or descending order.
   * @param scanIndexForward - if true, the results are returned in descending order.
   * @returns The QueryBuilder instance.
   */
  scanIndexForward(scanIndexForward: DocumentClient.QueryInput['ScanIndexForward']) {
    this.params.ScanIndexForward = scanIndexForward;
    return this;
  }

  /**
   * Sets the return consumed capacity option for the query.
   * @param returnConsumedCapacity - The return consumed capacity option.
   * @returns The QueryBuilder instance.
   */

  returnConsumedCapacity(
    returnConsumedCapacity: DocumentClient.QueryInput['ReturnConsumedCapacity']
  ) {
    this.params.ReturnConsumedCapacity = returnConsumedCapacity;
    return this;
  }

  /**
   * Sets the index table key.
   * @param indexName - The index name.
   * @returns The QueryBuilder instance.
   */
  indexName(indexName: DocumentClient.QueryInput['IndexName']) {
    this.params.IndexName = indexName;
    return this;
  }

  /**
   * After scanning the table of data base, filters the results.
   * @param filterExpression - The filter expression.
   * @returns The QueryBuilder instance.
   */
  filterExpression(filterExpression: DocumentClient.QueryInput['FilterExpression']) {
    this.params.FilterExpression = filterExpression;
    return this;
  }

  /**
   * Sets the expression attribute values for the query.
   * @param expressionAttributeValues - The expression attribute values.
   * @returns The QueryBuilder instance.
   */
  expressionAttributeValues(
    expressionAttributeValues: DocumentClient.QueryInput['ExpressionAttributeValues']
  ) {
    this.params.ExpressionAttributeValues = expressionAttributeValues;
    return this;
  }

  /**
   * Sets the expression attribute names for the query.
   * @param expressionAttributeNames - The expression attribute names.
   * @returns The QueryBuilder instance.
   */
  expressionAttributeNames(
    expressionAttributeNames: DocumentClient.QueryInput['ExpressionAttributeNames']
  ) {
    this.params.ExpressionAttributeNames = expressionAttributeNames;
    return this;
  }

  /**
   * Sets the exclusive start key for the query.
   * @param exclusiveStartKey - The exclusive start key.
   * @returns The QueryBuilder instance.
   */
  exclusiveStartKey(exclusiveStartKey: DocumentClient.QueryInput['ExclusiveStartKey']) {
    this.params.ExclusiveStartKey = exclusiveStartKey;
    return this;
  }

  projectionExpression(projectionExpression: DocumentClient.QueryInput['ProjectionExpression']) {
    if (projectionExpression?.includes('name')) {
      projectionExpression = projectionExpression.replace(/name/g, '#nme');
      this.params.ExpressionAttributeNames = {
        ...this.params.ExpressionAttributeNames,
        '#nme': 'name',
      };
    }
    if (projectionExpression?.includes('owner')) {
      projectionExpression = projectionExpression.replace(/owner/g, '#own');
      this.params.ExpressionAttributeNames = {
        ...this.params.ExpressionAttributeNames,
        '#own': 'owner',
      };
    }
    this.params.ProjectionExpression = projectionExpression;
    return this;
  }

  /**
   * Executes the query and returns the result.
   */
  async execute() {
    const docClient = new AWS.DynamoDB.DocumentClient();
    try {
      const res = await (await docClient.query(this.params).promise()).Items;
      return (res ?? []) as T[];
    } catch (err) {
      showErrorMessage(JSON.stringify(err, null, 2));
      return [];
    }
  }

  async executeOne() {
    this.params.Limit = 1;
    const res = await this.execute();
    return res?.[0] as T;
  }

  async scan() {
    const docClient = new AWS.DynamoDB.DocumentClient();
    try {
      const res = await (await docClient.scan(this.params).promise()).Items;
      return (res ?? []) as T[];
    } catch (err) {
      showErrorMessage(JSON.stringify(err, null, 2));
      return [];
    }
  }
}

const docClient = new AWS.DynamoDB.DocumentClient();

class PutBuilder<T> {
  params: DocumentClient.PutItemInput = {
    TableName: '',
    Item: {},
  };

  constructor(tableName: string) {
    this.params.TableName = `${tableName}-${AWS_ENV_KEY.beta}`;
  }

  /**
   * Sets the item to be put.
   * @param item - The item to be put.
   * @returns The PutBuilder instance.
   */
  item(item: Partial<Record<keyof T, string | number | boolean>>) {
    this.params.Item = item;
    return this;
  }

  /**
   * Sets the conditional operator for the put operation.
   * @param conditionalOperator - The conditional operator.
   * @returns The PutBuilder instance.
   */
  conditionalOperator(conditionalOperator: DocumentClient.PutItemInput['ConditionalOperator']) {
    this.params.ConditionalOperator = conditionalOperator;
    return this;
  }

  /**
   * Sets the condition expression for the put operation.
   * @param conditionExpression - The condition expression.
   * @returns The PutBuilder instance.
   */
  conditionExpression(conditionExpression: DocumentClient.PutItemInput['ConditionExpression']) {
    this.params.ConditionExpression = conditionExpression;
    return this;
  }

  /**
   * Sets the expected attribute values for the put operation.
   * @param expected - The expected attribute values.
   * @returns The PutBuilder instance.
   */
  expected(expected: DocumentClient.PutItemInput['Expected']) {
    this.params.Expected = expected;
    return this;
  }

  /**
   * Sets the expression attribute names for the put operation.
   * @param expressionAttributeNames - The expression attribute names.
   * @returns The PutBuilder instance.
   */
  expressionAttributeNames(
    expressionAttributeNames: DocumentClient.PutItemInput['ExpressionAttributeNames']
  ) {
    this.params.ExpressionAttributeNames = expressionAttributeNames;
    return this;
  }

  /**
   * Sets the expression attribute values for the put operation.
   * @param expressionAttributeValues - The expression attribute values.
   * @returns The PutBuilder instance.
   */
  expressionAttributeValues(
    expressionAttributeValues: DocumentClient.PutItemInput['ExpressionAttributeValues']
  ) {
    this.params.ExpressionAttributeValues = expressionAttributeValues;
    return this;
  }

  /**
   * Sets the return values option for the put operation.
   * @param returnValues - The return values option.
   * @returns The PutBuilder instance.
   */
  returnValues(returnValues: DocumentClient.PutItemInput['ReturnValues']) {
    this.params.ReturnValues = returnValues;
    return this;
  }

  /**
   * Sets the return consumed capacity option for the put operation.
   * @param returnConsumedCapacity - The return consumed capacity option.
   * @returns The PutBuilder instance.
   */
  returnConsumedCapacity(
    returnConsumedCapacity: DocumentClient.PutItemInput['ReturnConsumedCapacity']
  ) {
    this.params.ReturnConsumedCapacity = returnConsumedCapacity;
    return this;
  }

  /**
   * Sets the return item collection metrics option for the put operation.
   * @param returnItemCollectionMetrics - The return item collection metrics option.
   * @returns The PutBuilder instance.
   */
  returnItemCollectionMetrics(
    returnItemCollectionMetrics: DocumentClient.PutItemInput['ReturnItemCollectionMetrics']
  ) {
    this.params.ReturnItemCollectionMetrics = returnItemCollectionMetrics;
    return this;
  }

  /**
   * Sets the return values on condition check failure option for the put operation.
   * @param returnValuesOnConditionCheckFailure - The return values on condition check failure option.
   * @returns The PutBuilder instance.
   */
  returnValuesOnConditionCheckFailure(
    returnValuesOnConditionCheckFailure: DocumentClient.PutItemInput['ReturnValuesOnConditionCheckFailure']
  ) {
    this.params.ReturnValuesOnConditionCheckFailure = returnValuesOnConditionCheckFailure;
    return this;
  }

  /**
   * Executes the put operation.
   */
  async execute() {
    const docClient = new AWS.DynamoDB.DocumentClient();
    try {
      const res = await docClient.put(this.params).promise();
      return res;
    } catch (err) {
      showErrorMessage(JSON.stringify(err, null, 2));
      throw err;
    }
  }
}

class UpdateBuilder<T> {
  params: DocumentClient.UpdateItemInput = {
    TableName: '',
    Key: {},
  };

  constructor(tableName: string, key: Partial<Record<keyof T, string | number | boolean>>) {
    this.params.TableName = `${tableName}-${AWS_ENV_KEY.beta}`;
    this.params.Key = key;
  }

  /**
   * Recommends to set attribute updates for the update operation.
   */
  updateItem(attributions: Partial<Record<keyof T, string | number | boolean | null>>) {
    const AttributeUpdates: DocumentClient.UpdateItemInput['AttributeUpdates'] = {};
    // eslint-disable-next-line guard-for-in
    for (const key in attributions) {
      AttributeUpdates[key] = {
        Action: 'PUT',
        Value: attributions[key],
      };
    }
    this.params.AttributeUpdates = AttributeUpdates;
    return this;
  }

  /**
   * Sets the update expression for the update operation.
   * @param updateExpression - The update expression.
   * @returns The UpdateBuilder instance.
   */
  updateExpression(updateExpression: DocumentClient.UpdateItemInput['UpdateExpression']) {
    this.params.UpdateExpression = updateExpression;
    return this;
  }

  /**
   * Sets the expression attribute names for the update operation.
   * @param expressionAttributeNames - The expression attribute names.
   * @returns The UpdateBuilder instance.
   */
  expressionAttributeNames(
    expressionAttributeNames: DocumentClient.UpdateItemInput['ExpressionAttributeNames']
  ) {
    this.params.ExpressionAttributeNames = expressionAttributeNames;
    return this;
  }

  /**
   * Sets the expression attribute values for the update operation.
   * @param expressionAttributeValues - The expression attribute values.
   * @returns The UpdateBuilder instance.
   */
  expressionAttributeValues(
    expressionAttributeValues: DocumentClient.UpdateItemInput['ExpressionAttributeValues']
  ) {
    this.params.ExpressionAttributeValues = expressionAttributeValues;
    return this;
  }

  /**
   * Sets the return values option for the update operation.
   * @param returnValues - The return values option.
   * @returns The UpdateBuilder instance.
   */
  returnValues(returnValues: DocumentClient.UpdateItemInput['ReturnValues']) {
    this.params.ReturnValues = returnValues;
    return this;
  }

  /**
   * Sets the attribute updates for the update operation.
   * @param attributeUpdates - The attribute updates.
   * @returns The UpdateBuilder instance.
   */
  attributeUpdates(attributeUpdates: DocumentClient.UpdateItemInput['AttributeUpdates']) {
    this.params.AttributeUpdates = attributeUpdates;
    return this;
  }

  /**
   * Sets the conditional operator for the update operation.
   * @param conditionalOperator - The conditional operator.
   * @returns The UpdateBuilder instance.
   */
  conditionalOperator(conditionalOperator: DocumentClient.UpdateItemInput['ConditionalOperator']) {
    this.params.ConditionalOperator = conditionalOperator;
    return this;
  }

  /**
   * Sets the condition expression for the update operation.
   * @param conditionExpression - The condition expression.
   * @returns The UpdateBuilder instance.
   */
  conditionExpression(conditionExpression: DocumentClient.UpdateItemInput['ConditionExpression']) {
    this.params.ConditionExpression = conditionExpression;
    return this;
  }

  /**
   * Sets the expected attribute values for the update operation.
   * @param expected - The expected attribute values.
   * @returns The UpdateBuilder instance.
   */
  expected(expected: DocumentClient.UpdateItemInput['Expected']) {
    this.params.Expected = expected;
    return this;
  }

  /**
   * Sets the return consumed capacity option for the update operation.
   * @param returnConsumedCapacity - The return consumed capacity option.
   * @returns The UpdateBuilder instance.
   */
  returnConsumedCapacity(
    returnConsumedCapacity: DocumentClient.UpdateItemInput['ReturnConsumedCapacity']
  ) {
    this.params.ReturnConsumedCapacity = returnConsumedCapacity;
    return this;
  }

  /**
   * Sets the return item collection metrics option for the update operation.
   * @param returnItemCollectionMetrics - The return item collection metrics option.
   * @returns The UpdateBuilder instance.
   */
  returnItemCollectionMetrics(
    returnItemCollectionMetrics: DocumentClient.UpdateItemInput['ReturnItemCollectionMetrics']
  ) {
    this.params.ReturnItemCollectionMetrics = returnItemCollectionMetrics;
    return this;
  }

  /**
   * Sets the return values on condition check failure option for the update operation.
   * @param returnValuesOnConditionCheckFailure - The return values on condition check failure option.
   * @returns The UpdateBuilder instance.
   */
  returnValuesOnConditionCheckFailure(
    returnValuesOnConditionCheckFailure: DocumentClient.UpdateItemInput['ReturnValuesOnConditionCheckFailure']
  ) {
    this.params.ReturnValuesOnConditionCheckFailure = returnValuesOnConditionCheckFailure;
    return this;
  }

  /**
   * Executes the update operation.
   */
  async execute() {
    const docClient = new AWS.DynamoDB.DocumentClient();
    try {
      return docClient.update(this.params).promise();
    } catch (error) {
      showErrorMessage(error);
      return null;
    }
  }
}

class DeleteBuilder<T> {
  params: DocumentClient.DeleteItemInput = {
    TableName: '',
    Key: {},
  };

  constructor(tableName: string, key: Partial<Record<keyof T, string | number | boolean>>) {
    this.params.TableName = `${tableName}-${AWS_ENV_KEY.beta}`;
    this.params.Key = key;
  }

  /**
   * Sets the conditional operator for the delete operation.
   * @param conditionalOperator - The conditional operator.
   * @returns The DeleteBuilder instance.
   */
  conditionalOperator(conditionalOperator: DocumentClient.DeleteItemInput['ConditionalOperator']) {
    this.params.ConditionalOperator = conditionalOperator;
    return this;
  }

  /**
   * Sets the condition expression for the delete operation.
   * @param conditionExpression - The condition expression.
   * @returns The DeleteBuilder instance.
   */
  conditionExpression(conditionExpression: DocumentClient.DeleteItemInput['ConditionExpression']) {
    this.params.ConditionExpression = conditionExpression;
    return this;
  }

  /**
   * Sets the expected attribute values for the delete operation.
   * @param expected - The expected attribute values.
   * @returns The DeleteBuilder instance.
   */
  expected(expected: DocumentClient.DeleteItemInput['Expected']) {
    this.params.Expected = expected;
    return this;
  }

  /**
   * Sets the expression attribute names for the delete operation.
   * @param expressionAttributeNames - The expression attribute names.
   * @returns The DeleteBuilder instance.
   */
  expressionAttributeNames(
    expressionAttributeNames: DocumentClient.DeleteItemInput['ExpressionAttributeNames']
  ) {
    this.params.ExpressionAttributeNames = expressionAttributeNames;
    return this;
  }

  /**
   * Sets the expression attribute values for the delete operation.
   * @param expressionAttributeValues - The expression attribute values.
   * @returns The DeleteBuilder instance.
   */
  expressionAttributeValues(
    expressionAttributeValues: DocumentClient.DeleteItemInput['ExpressionAttributeValues']
  ) {
    this.params.ExpressionAttributeValues = expressionAttributeValues;
    return this;
  }

  /**
   * Sets the return values option for the delete operation.
   * @param returnValues - The return values option.
   * @returns The DeleteBuilder instance.
   */
  returnValues(returnValues: DocumentClient.DeleteItemInput['ReturnValues']) {
    this.params.ReturnValues = returnValues;
    return this;
  }

  /**
   * Sets the return consumed capacity option for the delete operation.
   * @param returnConsumedCapacity - The return consumed capacity option.
   * @returns The DeleteBuilder instance.
   */
  returnConsumedCapacity(
    returnConsumedCapacity: DocumentClient.DeleteItemInput['ReturnConsumedCapacity']
  ) {
    this.params.ReturnConsumedCapacity = returnConsumedCapacity;
    return this;
  }

  /**
   * Sets the return item collection metrics option for the delete operation.
   * @param returnItemCollectionMetrics - The return item collection metrics option.
   * @returns The DeleteBuilder instance.
   */
  returnItemCollectionMetrics(
    returnItemCollectionMetrics: DocumentClient.DeleteItemInput['ReturnItemCollectionMetrics']
  ) {
    this.params.ReturnItemCollectionMetrics = returnItemCollectionMetrics;
    return this;
  }

  /**
   * Sets the return values on condition check failure option for the delete operation.
   * @param returnValuesOnConditionCheckFailure - The return values on condition check failure option.
   * @returns The DeleteBuilder instance.
   */
  returnValuesOnConditionCheckFailure(
    returnValuesOnConditionCheckFailure: DocumentClient.DeleteItemInput['ReturnValuesOnConditionCheckFailure']
  ) {
    this.params.ReturnValuesOnConditionCheckFailure = returnValuesOnConditionCheckFailure;
    return this;
  }

  /**
   * Executes the delete operation.
   */
  async execute() {
    try {
      const result = await docClient.delete(this.params).promise();
      return result;
    } catch (error) {
      showErrorMessage(error);
      throw error;
    }
  }
}

export { QueryBuilder, PutBuilder, UpdateBuilder, DeleteBuilder, S3Builder };
