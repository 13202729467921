import {
  QueryBuilder,
  UpdateBuilder,
  DeleteBuilder,
  PutBuilder,
  S3Builder,
} from '@Services/Onesheet/helpers';
import {
  CognitoBasicUser,
  CognitoUser,
  refineCognitoUser,
  useUserCognito,
} from '@Services/Onesheet/user';
import { showErrorMessage, showInfoMessage } from '@Utils';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import uuid4 from 'uuid4';
import { ImageData } from '@Shared/ImageCropper/ImageCropper';
import { checkIsUUID4 } from './utils';
// =========================================
// Schema
interface AccountTable {
  email: string;
  teamId: string;
  createdAt: string;
  name: string;
  sheetOrderId: string;
  updatedAt: string;
}

export interface TeamTable {
  id: string;
  name: string;
  owner: string;
  sheetLimit: number;
  customLogoKey?: string;
  customLogoUrl?: string;
}

export interface TeamMemberInvitationTable {
  id: string;
  accountCreated: boolean;
  email: string;
  invitedBy: string;
  permissions?: string;
  teamId: string;
  teamName: string;
  updatedAt: string;
}

interface TeamMembersTable {
  id: string;
  email: string;
  isAdmin: boolean;
  teamId: string;
  userId: string;
  userName: string;
}

export interface TeamPermissionsTable {
  id: string;
  readFile: boolean;
  teamId: string;
  type: 'Folder';
  typeId: string;
  userId: string;
  writeFile: boolean;
}

interface FolderTable {
  id: string;
  cognitoId: string;
  createdAt: string;
  name: string;
  ownerId: string;
  sheetOrderId: string;
  teamId: string;
  updatedAt: string;
}
// ===========================================
interface User extends Omit<TeamMembersTable, 'id'> {
  id?: string;
}

export interface Invitation extends TeamMemberInvitationTable {
  team?: Team;
  permissionNames?: string[];
}

export interface Team extends TeamTable {
  ownerName: string;
  members: User[];
  invitations: Invitation[];
}

export interface Info extends Omit<User, 'userId' | 'userName'> {
  userId?: string;
  userName?: string;
  invitations?: Invitation[];
}

export interface UserPermission extends Omit<TeamPermissionsTable, 'id'> {
  permissionId: string;
  folderId: string;
  folderName: string;
}

interface TeamSuggestion {
  id: string;
  name: string;
}

export const useGetTeam = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<Team | undefined>(undefined);
  const [searchData, setSearchData] = useState<TeamSuggestion[] | undefined>(undefined);

  const search = async (q: string) => {
    if (isEmpty(q)) return;
    setLoading(true);

    const suggestion = checkIsUUID4(q)
      ? await new QueryBuilder<TeamTable>('Team').keyCondition('id', 'EQ', [q.trim()]).executeOne()
      : await new QueryBuilder<TeamTable>('Team')
          .indexName('byName')
          .keyCondition('name', 'EQ', [q.trim()])
          .projectionExpression('id, name')
          .executeOne();

    if (!suggestion) {
      showErrorMessage('No team found. All strings are accurate.');
    }

    setLoading(false);
    return setSearchData(suggestion ? [suggestion] : []);
  };

  const getTeam = async (teamId: string) => {
    if (isEmpty(teamId)) return;
    setLoading(true);
    const teamInfo = await new QueryBuilder<TeamTable>('Team')
      .keyCondition('id', 'EQ', [teamId])
      .executeOne();

    if (!teamInfo) {
      showErrorMessage('No team found');
      return;
    }

    const members = await getTeamMembers(teamId);
    const invitations = await getInvitationsByTeam(teamId);

    setLoading(false);
    setData({
      ...teamInfo,
      id: teamId,
      ownerName: members.find(({ userId }) => userId === teamInfo.owner)?.userName ?? '',
      invitations,
      members,
    });
  };

  const refetch = async () => {
    if (isEmpty(data) || !data) return;

    const members = await getTeamMembers(data.id);
    const invitations = await getInvitationsByTeam(data.id);

    setData(old => ({
      ...old!,
      ownerName: members.find(({ userId }) => userId === old?.owner)?.userName ?? '',
      invitations,
      members,
    }));
  };

  const getInvitationsByTeam = async (teamId: string) => {
    const invitations = await new QueryBuilder<TeamMemberInvitationTable>('TeamMemberInvitation')
      .indexName('teamMemberInvitationByTeamId')
      .keyCondition('teamId', 'EQ', [teamId])
      .execute();

    if (!invitations) {
      showInfoMessage('No invitations found');
      return [];
    }

    const folderIds = invitations
      .map(inv => inv.permissions?.split(','))
      .flat()
      .filter(v => !isEmpty(v));

    const folders = folderIds.length
      ? await Promise.all(
          folderIds.map(async id =>
            new QueryBuilder<FolderTable>('Folder')
              .keyCondition('id', 'EQ', [id])
              .executeOne()
              .catch(e => null)
          )
        )
      : [];

    const res: Invitation[] = invitations.map(inv => ({
      ...inv,
      permissionNames: folders
        .filter(folder => inv.permissions?.split(',').includes(folder?.id ?? ''))
        ?.map(folder => folder?.name ?? ''),
    }));

    return res;
  };

  const getTeamMembers = async (teamId: string) => {
    const teamMembers = await new QueryBuilder<TeamMembersTable>('TeamMembers')
      .indexName('byTeam')
      .keyCondition('teamId', 'EQ', [teamId])
      .execute();

    if (!teamMembers) {
      showErrorMessage('No team members found');
      return [];
    }
    return teamMembers;
  };

  return {
    refetch,
    fetch: getTeam,
    loading,
    data,
    search,
    searchData,
  };
};

export const useGetUserTeam = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setInfo] = useState<Info | null>(null);

  // NOTE: this is forbidden to define a hook inside another hook
  const { fetch } = useUserCognito();

  const getUserWithTeam = async (q: string): Promise<void> => {
    setLoading(true);

    try {
      const cognitoUser = await fetch(q.trim())
        .then(user => user)
        .catch(e => null);
      const userId = cognitoUser?.Attributes?.find(({ Name }) => Name === 'sub')?.Value;

      const userTeamInfo = userId
        ? await new QueryBuilder<TeamMembersTable>('TeamMembers')
            .indexName('byAccount')
            .keyCondition('userId', 'EQ', [
              cognitoUser.Attributes?.find(({ Name }) => Name === 'sub')?.Value,
            ])
            .executeOne()
        : null;

      if (userTeamInfo) {
        setLoading(false);
        return setInfo(userTeamInfo);
      }

      const invitations = await getUserInvitations(q);

      if (!invitations?.length) {
        showErrorMessage('User is not in a team or has no invitations');
        setLoading(false);
        setInfo(null);
        return;
      }

      setInfo({
        email: invitations[0].email,
        isAdmin: false,
        teamId: '',
        userId,
        userName: cognitoUser?.Username ?? '',
        invitations,
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const refetch = async () => {
    if (isEmpty(data) || !data) return;

    const userInfo = data?.userId
      ? await new QueryBuilder<TeamMembersTable>('TeamMembers')
          .indexName('byAccount')
          .keyCondition('userId', 'EQ', [data.userId])
          .executeOne()
      : undefined;

    const invitations = await getUserInvitations(data.email);

    setInfo(old => ({
      ...old!,
      teamId: userInfo?.teamId ?? '',
      invitations,
    }));
  };

  const getUserInvitations = async (email: string): Promise<Invitation[]> => {
    const invitations = await new QueryBuilder<TeamMemberInvitationTable>('TeamMemberInvitation')
      .indexName('teamMemberInvitationByEmail')
      .keyCondition('email', 'EQ', [email])
      .execute();

    if (!invitations) {
      showInfoMessage('No invitations found');
      return [];
    }
    return invitations;
  };

  return {
    fetch: getUserWithTeam,
    refetch,
    loading,
    data,
  };
};

export const useUpdateTeam = () => {
  const [fetching, setFetching] = useState<boolean>(false);

  // NOTE: this is forbidden to define a hook inside another hook
  const { fetch } = useUserCognito();

  /**
   * owner ID should be the email
   */
  const createTeam = async (
    team: Omit<TeamTable, 'id' | 'customLogoKey' | 'customLogoUrl'>,
    users: CognitoBasicUser[]
  ) => {
    setFetching(true);
    try {
      const existingTeam = await new QueryBuilder<TeamTable>('Team')
        .indexName('byName')
        .keyCondition('name', 'EQ', [team.name])
        .executeOne();

      if (existingTeam) {
        setFetching(false);
        return showErrorMessage('Team name already exists');
      }

      const teamId = uuid4();

      const ownerUser = await fetch(team.owner.trim()).catch(e => null);
      const refinedOwner = ownerUser ? refineCognitoUser(ownerUser) : null;

      if (!refinedOwner) {
        setFetching(false);
        return showErrorMessage('Owner not found');
      }

      await new PutBuilder<TeamTable>('Team')
        .item({
          ...team,
          owner: ownerUser?.Attributes?.find(({ Name }) => Name === 'sub')?.Value,
          id: teamId,
        })
        .execute();

      await Promise.all([
        [refinedOwner, ...users].map((user, index) =>
          addMember(user.email, teamId, { isAdmin: !index })
        ),
      ]);

      setFetching(false);
      showInfoMessage('Team created');
      return {
        ...team,
        id: teamId,
      } as TeamTable;
    } catch (e) {
      setFetching(false);
      showErrorMessage('Something wrong, can not create the team');
      return null;
    }
  };

  const updateLogo = async (teamId: string, file: ImageData) => {
    setFetching(true);
    try {
      if (!file.imageBlob) return;
      const S3 = await new S3Builder()
        .body(file.imageBlob, `${uuid4()}.${file.imageBlob.type.split('/')[1]}`)
        .execute();

      if (!S3) {
        setFetching(false);
        return showErrorMessage('Something wrong, can not upload the logo');
      }

      await new UpdateBuilder<TeamTable>('Team', {
        id: teamId,
      })
        .updateItem({
          customLogoKey: S3.key,
          customLogoUrl: S3.url,
        })
        .execute();

      showInfoMessage('Logo updated');

      setFetching(false);
    } catch (e) {
      setFetching(false);
      showErrorMessage('Something wrong, can not update the logo');
      throw e;
    }
  };

  const updateSheetLimit = async (teamId: string, limit: number) => {
    setFetching(true);
    try {
      const res = await new UpdateBuilder<TeamTable>('Team', {
        id: teamId,
      })
        .updateItem({
          sheetLimit: limit,
        })
        .execute();

      setFetching(false);
      showInfoMessage('Sheet limit updated');
      return res?.$response.data;
    } catch (e) {
      setFetching(false);
      return null;
    }
  };

  const addMember = async (
    email: string,
    teamId: string,
    { isAdmin = false, permissions = '' }: { isAdmin?: boolean; permissions?: string }
  ) => {
    setFetching(true);
    const cognitoUser = await fetch(email.trim()).catch(e => null);
    if (!cognitoUser) {
      setFetching(false);
      return showErrorMessage('User not found');
    }

    const userId = cognitoUser?.Attributes?.find(({ Name }) => Name === 'sub')?.Value;
    const userName = cognitoUser?.Username ?? '';

    const existingMember = await new QueryBuilder<TeamMembersTable>('TeamMembers')
      .indexName('byAccount')
      .keyCondition('userId', 'EQ', [userId])
      .executeOne();

    if (existingMember) {
      setFetching(false);
      throw showErrorMessage(`This user already in the team ${existingMember.teamId}`);
    }

    await new PutBuilder<TeamMembersTable>('TeamMembers')
      .item({
        id: uuid4(),
        email,
        isAdmin,
        teamId,
        userId,
        userName,
      })
      .execute();

    await new UpdateBuilder<AccountTable>('Account', { email })
      .updateItem({
        teamId,
      })
      .execute();

    permissions.split(',')?.map(async permission => {
      if (isEmpty(permission)) return;

      const folder = await new QueryBuilder<FolderTable>('Folder')
        .keyCondition('id', 'EQ', [permission])
        .executeOne();

      if (!folder || folder.teamId !== teamId) {
        const message = folder
          ? `Folder (${permission}) not in the team.`
          : `Folder (${permission}) not found.`;
        showErrorMessage(`${message} Please check the folder ID. Permission not added.`);
      }

      return new PutBuilder<TeamPermissionsTable>('TeamPermissions')
        .item({
          id: uuid4(),
          readFile: true,
          teamId,
          type: 'Folder',
          typeId: permission,
          userId,
          writeFile: false,
        })
        .execute();
    });

    const invitation = await new QueryBuilder<TeamMemberInvitationTable>('TeamMemberInvitation')
      .indexName('teamMemberInvitationByEmail')
      .keyCondition('email', 'EQ', [email])
      .execute();

    if (invitation?.length) {
      invitation.map(async inv => {
        await new DeleteBuilder<TeamMemberInvitationTable>('TeamMemberInvitation', {
          id: inv.id,
        }).execute();
      });
    }

    setFetching(false);
  };

  const deleteMember = async (id: string, userId: string) => {
    setFetching(true);
    try {
      const teamMembers = await new QueryBuilder<TeamMembersTable>('TeamMembers')
        .keyCondition('id', 'EQ', [id])
        .executeOne();

      const email = teamMembers?.email;

      await new UpdateBuilder<AccountTable>('Account', { email })
        .updateItem({
          teamId: null,
        })
        .execute();

      await new DeleteBuilder<TeamMembersTable>('TeamMembers', {
        id,
      }).execute();

      const permissions = await new QueryBuilder<TeamPermissionsTable>('TeamPermissions')
        .indexName('byAccount')
        .keyCondition('userId', 'EQ', [userId])
        .execute();

      permissions?.map(async permission => {
        await new DeleteBuilder<TeamPermissionsTable>('TeamPermissions', {
          id: permission.id,
        }).execute();
      });

      setFetching(false);
    } catch (e) {
      setFetching(false);
      showErrorMessage('Something wrong, can not delete the member');
      return null;
    }
  };

  const toggleAdmin = async (id: string, isAdmin: boolean) => {
    setFetching(true);
    try {
      const res = await new UpdateBuilder<TeamMembersTable>('TeamMembers', {
        id,
      })
        .updateItem({
          isAdmin,
        })
        .execute();
      setFetching(false);
      return res;
    } catch (e) {
      setFetching(false);
      showErrorMessage('Something wrong, can not update the member');
      return null;
    }
  };

  return {
    updateSheetLimit,
    addMember,
    deleteMember,
    toggleAdmin,
    fetching,
    createTeam,
    updateLogo,
  };
};

export const useUpdateTeamInvitation = () => {
  const [fetching, setFetching] = useState<boolean>(false);

  // NOTE: this is forbidden to define a hook inside another hook
  const { fetch } = useUserCognito();

  const acceptInvitation = async (invitation: Invitation) => {
    setFetching(true);

    const cognitoUser = await fetch(invitation.email.trim()).catch(e => null);
    if (!cognitoUser) {
      setFetching(false);

      return showErrorMessage('User not found');
    }

    const userId = cognitoUser?.Attributes?.find(({ Name }) => Name === 'sub')?.Value;
    const userName = cognitoUser?.Username ?? '';
    await deleteInvitation(invitation.id);

    Promise.all([
      new PutBuilder<TeamMembersTable>('TeamMembers')
        .item({
          id: uuid4(),
          email: invitation.email,
          isAdmin: false,
          teamId: invitation.teamId,
          userId,
          userName,
        })
        .execute(),
      invitation.permissions?.split(',')?.map(async permission => {
        if (isEmpty(permission)) return;
        return new PutBuilder<TeamPermissionsTable>('TeamPermissions')
          .item({
            id: uuid4(),
            readFile: true,
            teamId: invitation.teamId,
            type: 'Folder',
            typeId: permission,
            writeFile: false,
            userId,
          })
          .execute();
      }),
      new UpdateBuilder<AccountTable>('Account', { email: invitation.email }).updateItem({
        teamId: invitation.teamId,
      }),
    ]);
    setFetching(false);
  };

  const deleteInvitation = async (id: string) => {
    setFetching(true);
    try {
      const res = await new DeleteBuilder<TeamMemberInvitationTable>('TeamMemberInvitation', {
        id,
      }).execute();
      setFetching(false);
      return res;
    } catch (e) {
      setFetching(false);
      showErrorMessage('Something wrong, can not delete the invitation');
      return null;
    }
  };

  /**
   * Deprecated, use 'addMember' from useUpdateTeam instead
   */
  const createInvitation = async (invitation: TeamMemberInvitationTable) => {
    setFetching(true);
    const accountCreated = await fetch(invitation.email.trim()).catch(e => null);
    try {
      const cognitoUser = await fetch(invitation.email.trim()).catch(e => null);
      const existingMember = await new QueryBuilder<TeamMembersTable>('TeamMembers')
        .indexName('byAccount')
        .keyCondition('userId', 'EQ', [
          cognitoUser?.Attributes?.find(({ Name }) => Name === 'sub')?.Value,
        ])
        .executeOne();

      if (existingMember) {
        setFetching(false);
        return showErrorMessage(`This user already in the team ${existingMember.teamId}`);
      }

      const existingInvitation = await new QueryBuilder<TeamMemberInvitationTable>(
        'TeamMemberInvitation'
      )
        .indexName('teamMemberInvitationByEmail')
        .keyCondition('email', 'EQ', [invitation.email])
        .executeOne();

      if (existingInvitation) {
        setFetching(false);
        return showErrorMessage(`This user already has invitation ${existingInvitation.teamName}`);
      }

      const res = await new PutBuilder<TeamMemberInvitationTable>('TeamMemberInvitation')
        .item({
          ...invitation,
          id: uuid4(),
          accountCreated: !!accountCreated,
        })
        .execute();
      setFetching(false);
      showInfoMessage('Invitation created');
      return res;
    } catch (e) {
      setFetching(false);
      showErrorMessage('Something wrong, can not create the invitation');
      return null;
    }
  };

  return {
    acceptInvitation,
    deleteInvitation,
    createInvitation,
    fetching,
  };
};

export const useUserPermission = (userId?: string) => {
  const [data, setData] = useState<UserPermission[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  const getPermission = async () => {
    setLoading(true);
    const permissions = await new QueryBuilder<TeamPermissionsTable>('TeamPermissions')
      .indexName('byAccount')
      .keyCondition('userId', 'EQ', [userId])
      .execute();

    const res = await Promise.all(
      permissions.map(async permission => {
        const folder = await new QueryBuilder<FolderTable>('Folder')
          .keyCondition('id', 'EQ', [permission.typeId])
          .projectionExpression('id, name')
          .executeOne();
        return {
          ...permission,
          permissionId: permission.id,
          folderId: folder?.id ?? '',
          folderName: folder?.name ?? '',
          id: undefined,
        };
      })
    );

    setData(res);

    setLoading(false);
  };

  const deletePermission = async (id: string) => {
    setFetching(true);
    try {
      await new DeleteBuilder<TeamPermissionsTable>('TeamPermissions', {
        id,
      }).execute();
    } catch (e) {
      showErrorMessage('Something wrong, can not delete the permission');
    }
    setFetching(false);
  };

  const addPermission = async (permission: Omit<TeamPermissionsTable, 'id'>) => {
    setFetching(true);
    const folder = await new QueryBuilder<FolderTable>('Folder')
      .keyCondition('id', 'EQ', [permission.typeId])
      .executeOne()
      .catch(e => null);

    if (!folder || folder.teamId !== permission.teamId) {
      const message = folder ? 'Folder not in the team.' : 'Folder not found.';
      showErrorMessage(`${message} Please check the folder ID and try again.`);
      setFetching(false);
      throw new Error('Folder not found');
    }

    try {
      await new PutBuilder<TeamPermissionsTable>('TeamPermissions')
        .item({
          ...permission,
          id: uuid4(),
        })
        .execute();
    } catch (e) {
      showErrorMessage('Something wrong, can not add the permission');
    }

    setFetching(false);
  };

  useEffect(() => {
    if (userId) {
      getPermission();
    }
  }, [userId]);

  return {
    data,
    fetching,
    deletePermission,
    addPermission,
    loading,
    refetch: getPermission,
  };
};

export const useGetTeams = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<TeamTable[] | undefined>(undefined);

  const getTeams = async () => {
    setLoading(true);
    const res = await new QueryBuilder<TeamTable>('Team')
      .projectionExpression('id, name, owner')
      .scan();
    setData(res);
    setLoading(false);
  };

  useEffect(() => {
    getTeams();
  }, []);

  return { data, loading };
};
