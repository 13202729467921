import React from 'react';
import { Layout } from '@Components/ui';
import { generateRoute as generateRoutePath, ROUTE_PATH } from '@Routes/path';
import { useUser } from '@Services/auth';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MENUS } from './routes/menus';
import Login from './screens/Login';
import './styles/antd.scss';
import './styles/globals.css';
import './styles/index.scss';

function App() {
  const { isAuthed } = useUser();
  const loginRoute = `${ROUTE_PATH.LOGIN}?redirect=${window.location.pathname}${window.location.search}${window.location.hash}`;

  return (
    <Routes>
      {MENUS.map(menu => {
        if (menu.items.length === 0) {
          return (
            <Route
              key={menu.name}
              path={menu.route}
              element={
                isAuthed ? <Layout>{menu.component}</Layout> : <Navigate to={loginRoute} replace />
              }
            />
          );
        }

        return menu.items.map(item => (
          <Route
            key={`${menu.name}-${item.name}`}
            path={generateRoutePath(menu.route, item.name)}
            element={
              isAuthed ? <Layout>{item.component}</Layout> : <Navigate to={loginRoute} replace />
            }
          />
        ));
      })}

      <Route path={ROUTE_PATH.LOGIN} element={<Login />} />
    </Routes>
  );
}

export default App;
