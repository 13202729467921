'use client';

import * as React from 'react';
import { CalendarIcon } from '@radix-ui/react-icons';
import { format } from 'date-fns';

import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import { Calendar, CalendarProps } from '@/components/ui/calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';

type Props = CalendarProps & {
  value?: Date;
  onChange?: (date: Date) => void;
};

export function DatePicker({ value: date, onChange, ...rest }: Props) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn('w-[240px] justify-between font-normal', !date && 'text-muted-foreground')}
        >
          {/* @ts-ignore */}
          {date && date instanceof Date && !isNaN(date) ? (
            format(date, 'PPP')
          ) : (
            <span>Pick a date</span>
          )}
          <CalendarIcon />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          {...rest}
          mode="single"
          //  @ts-ignore
          selected={date && date instanceof Date && !isNaN(date) ? date : new Date()}
          onSelect={onChange}
        />
      </PopoverContent>
    </Popover>
  );
}
