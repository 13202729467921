'use client';

import axios, { AxiosProgressEvent, CancelTokenSource } from 'axios';
import {
  AudioWaveform,
  File,
  FileImage,
  FolderArchive,
  Loader2,
  UploadCloud,
  Video,
  X,
} from 'lucide-react';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Input } from './input';
import { Progress } from './progress';
import { ScrollArea } from './scroll-area';
import { cn } from '@/lib/utils';
interface FileProgress {
  loading: boolean;
  File: File;
  source: CancelTokenSource | null;
  error: string | null;
}

enum FileTypes {
  Image = 'image',
  Pdf = 'pdf',
  Audio = 'audio',
  Video = 'video',
  Other = 'other',
}

const ImageColor = {
  bgColor: 'bg-purple-600',
  fillColor: 'fill-purple-600',
};

const PdfColor = {
  bgColor: 'bg-blue-400',
  fillColor: 'fill-blue-400',
};

const AudioColor = {
  bgColor: 'bg-yellow-400',
  fillColor: 'fill-yellow-400',
};

const VideoColor = {
  bgColor: 'bg-green-400',
  fillColor: 'fill-green-400',
};

const OtherColor = {
  bgColor: 'bg-gray-400',
  fillColor: 'fill-gray-400',
};

interface FileUploadProps {
  onChange: (status: 'uploading' | 'done' | 'error', file: File, response: any) => void;
  uploadUrl: string;
  type: 'single' | 'multiple';
  onRemove?: (file: File) => void;
  accept?: string;
}

function FileUpload({ onChange, uploadUrl, type, onRemove, accept }: FileUploadProps) {
  const [files, setFiles] = useState<FileProgress[]>([]);

  const getFileIconAndColor = (file: File) => {
    if (file.type.includes(FileTypes.Image)) {
      return {
        icon: <FileImage size={40} className={ImageColor.fillColor} />,
        color: ImageColor.bgColor,
      };
    }

    if (file.type.includes(FileTypes.Pdf)) {
      return {
        icon: <File size={40} className={PdfColor.fillColor} />,
        color: PdfColor.bgColor,
      };
    }

    if (file.type.includes(FileTypes.Audio)) {
      return {
        icon: <AudioWaveform size={40} className={AudioColor.fillColor} />,
        color: AudioColor.bgColor,
      };
    }

    if (file.type.includes(FileTypes.Video)) {
      return {
        icon: <Video size={40} className={VideoColor.fillColor} />,
        color: VideoColor.bgColor,
      };
    }

    return {
      icon: <FolderArchive size={40} className={OtherColor.fillColor} />,
      color: OtherColor.bgColor,
    };
  };

  // feel free to mode all these functions to separate utils
  // here is just for simplicity
  // const onUploadProgress = (
  //   progressEvent: AxiosProgressEvent,
  //   file: File,
  //   cancelSource: CancelTokenSource
  // ) => {
  //   const progress = Math.round((progressEvent.loaded / (progressEvent.total ?? 0)) * 100);
  //   if (progress === 100) {
  //     setUploadedFiles(prevUploadedFiles => {
  //       return [...prevUploadedFiles, file];
  //     });

  //     setFilesToUpload(prevUploadProgress => {
  //       return prevUploadProgress.filter(item => item.File !== file);
  //     });

  //     return;
  //   }

  //   setFilesToUpload(prevUploadProgress => {
  //     return prevUploadProgress.map(item => {
  //       if (item.File.name === file.name) {
  //         return {
  //           ...item,
  //           progress,
  //           source: cancelSource,
  //         };
  //       } else {
  //         return item;
  //       }
  //     });
  //   });
  // };

  const uploadImageToCloudinary = async (
    formData: FormData,
    // onUploadProgress: (progressEvent: AxiosProgressEvent) => void,
    cancelSource: CancelTokenSource
  ) => {
    onChange('uploading', formData.get('file') as File, cancelSource);
    setFiles(prevUploadProgress => {
      return prevUploadProgress.map(item => {
        if (item.File.name === (formData.get('file') as File).name) {
          return { ...item, loading: true };
        }
        return item;
      });
    });
    return axios.post(uploadUrl, formData, {
      withCredentials: true,
      // onUploadProgress,
      cancelToken: cancelSource.token,
    });
  };

  const removeFile = (file: File) => {
    setFiles(prevUploadProgress => {
      return prevUploadProgress.filter(item => item.File !== file);
    });

    onRemove?.(file);
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setFiles(prevUploadProgress => {
        return [
          ...prevUploadProgress,
          ...acceptedFiles.map(file => {
            return {
              error: null,
              loading: false,
              File: file,
              source: null,
            };
          }),
        ];
      });

      if (uploadUrl) {
        // cloudinary upload
        const fileUploadBatch = acceptedFiles.map(file => {
          const formData = new FormData();
          formData.append('file', file);

          const cancelSource = axios.CancelToken.source();
          return uploadImageToCloudinary(
            formData,
            // progressEvent => onUploadProgress(progressEvent, file, cancelSource),
            cancelSource
          ).catch(error => {
            setFiles(prevUploadProgress => {
              return prevUploadProgress.map(item => {
                if (item.File.name === file.name) {
                  return { ...item, loading: false, error: error.toString() };
                }
                return item;
              });
            });
            throw error;
          });
        });

        try {
          await Promise.all(fileUploadBatch).then(responses => {
            responses.forEach((response, index) => {
              onChange('done', acceptedFiles[index], response.data);
              setFiles(prevUploadProgress => {
                return prevUploadProgress.map(item => {
                  if (item.File.name === acceptedFiles[index].name) {
                    return { ...item, loading: false };
                  }
                  return item;
                });
              });
            });
          });
        } catch (error) {
          console.error('Error uploading files: ', error);
        }
      }
    },
    [uploadUrl, onChange]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <div>
        <label
          {...getRootProps()}
          className="relative flex flex-col items-center justify-center w-full py-6 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100 "
        >
          <div className=" text-center">
            <div className=" border p-2 rounded-md max-w-min mx-auto">
              <UploadCloud size={20} />
            </div>

            <p className="mt-2 text-base text-gray-600">
              <span className="font-semibold">Drag files</span>
            </p>
            <p className="text-sm text-gray-500">
              Click to upload files &#40;files should be under 10 MB &#41;
            </p>
          </div>
        </label>

        <Input
          {...getInputProps()}
          id="dropzone-file"
          accept={accept}
          type="file"
          className="hidden"
        />
      </div>

      {files.length > 0 && (
        <div>
          <p className="font-medium my-2 mt-6 text-muted-foreground text-sm">Files</p>
          <div className="space-y-2 pr-3">
            {files.map(file => {
              const failedFile = file.error;
              return (
                <div
                  key={file.File.lastModified}
                  className={cn(
                    'flex justify-between gap-2 rounded-lg overflow-hidden border border-slate-100 group hover:pr-0 pr-2 hover:border-slate-300 transition-all',
                    failedFile && 'border-red-500 border'
                  )}
                >
                  <div className="flex items-center flex-1 p-2">
                    <div className="text-white">{getFileIconAndColor(file.File).icon}</div>
                    <div className="w-full ml-2 space-y-1">
                      <div className="text-sm flex justify-between">
                        <p className="text-muted-foreground ">{file.File.name.slice(0, 25)}</p>
                      </div>
                      {failedFile && (
                        <p className="text-xs text-red-500">{failedFile.toString()}</p>
                      )}
                    </div>
                    <span className="text-xs">
                      {file.loading && <Loader2 size={16} className="animate-spin" />}
                    </span>
                  </div>

                  <button
                    onClick={() => removeFile(file.File)}
                    className="bg-red-500 text-white transition-all items-center justify-center px-2 hidden group-hover:flex"
                  >
                    <X size={20} />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}

export { FileUpload };
