import React from 'react';

import { ActionInput, AntdIcon, ModalButton, Panel } from '@Shared/Style';
import { useAddTeamManager, useDeleteTeamManager, useGetTeamManagers } from '@Services/team';
import { generateInternalLink, getFormattedLocalDate } from '@Utils';
import { Link } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { GlobalSearch } from '@Components';
import { AntdTable, SearchInput } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import FAIcon from '@Components/ui/fa-icons';

const ActionCell = ({ value }) => {
  const { execute, isLoading } = useDeleteTeamManager();
  const { refetch } = useGetTeamManagers();

  return (
    <Popconfirm
      title="Warning"
      onConfirm={() => execute({ query: { q: value } }).then(() => refetch())}
      description={`Are you sure you want to delete ${(<b>{value}</b>)} from team manager?`}
    >
      <Button loading={isLoading} icon={<AntdIcon name="delete" />} className="red" />
    </Popconfirm>
  );
};

const columns: Column<any>[] = [
  {
    Header: 'ID',
    accessor: 'id', // String-based value accessors!
  },
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: ({ value }) => (
      <Link to={generateInternalLink('user/user-profile', { email: value })}>{value}</Link>
    ),
  },
  {
    Header: 'Company',
    accessor: 'company',
    filter: true,
  },
  {
    Header: 'Time Zone',
    accessor: 'tzone',
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    formatter: value => getFormattedLocalDate(value),
    sorter: true,
  },
  {
    Header: 'Action',
    accessor: 'email',
    Cell: ActionCell,
  },
];
const TeamManagers = () => {
  const { data, isLoading, refetch } = useGetTeamManagers();
  const [filteredData, setFilteredData] = React.useState(data);
  const { execute, isLoading: isLoadingAdd } = useAddTeamManager();
  const [q, setQ] = React.useState('');
  return (
    <Panel
      title="Team Managers"
      extra={
        <ModalButton
          title="Add Team Manager"
          onOk={() => execute({ data: { q } }).then(() => refetch())}
          okButtonProps={{
            className: 'green',
            loading: isLoadingAdd,
            disabled: !q,
          }}
          okText="Add"
          Button={
            <Button className="green" icon={<FAIcon name="farPlus" />} type="primary">
              Add Manager
            </Button>
          }
        >
          <GlobalSearch
            types={['user']}
            loading={isLoadingAdd}
            icon="farUser"
            size="middle"
            onChange={value => setQ(value as string)}
          />
        </ModalButton>
      }
    >
      <SearchInput originalData={data} onChange={setFilteredData} />
      <br />
      <AntdTable data={filteredData} columns={columns} loading={isLoading} pageSize={15} />
    </Panel>
  );
};

export default TeamManagers;
