import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  BLOCKED_DOMAINS: '/admin/blocked-domains',
  STRICT_DOMAINS: '/admin/strict-domains',
  API_LIMIT_OVERRIDE: '/admin/api-limit',
};

export const useGetBlockedDomains = persistGetToUrl(ENDPOINT.BLOCKED_DOMAINS);
export const useAddBlockedDomain = mutateToUrl<{
  data: { domain: string };
}>('POST', ENDPOINT.BLOCKED_DOMAINS);
export const useDeleteBlockedDomain = mutateToUrl<{
  query: { domain: string };
}>('DELETE', ENDPOINT.BLOCKED_DOMAINS);

export const useGetStrictDomains = persistGetToUrl(ENDPOINT.STRICT_DOMAINS);
export const useAddStrictDomain = mutateToUrl<{
  data: { domain: string };
}>('POST', ENDPOINT.STRICT_DOMAINS);
export const useDeleteStrictDomain = mutateToUrl<{
  query: { domain: string };
}>('DELETE', ENDPOINT.STRICT_DOMAINS);

export const useGetApiLimitOverride = persistGetToUrl(ENDPOINT.API_LIMIT_OVERRIDE);
export const useSetApiLimitOverride = mutateToUrl<{
  query: { max: number; duration: string; email: string };
}>('PUT', ENDPOINT.API_LIMIT_OVERRIDE);
export const useDeleteApiLimitOverride = mutateToUrl<{
  query: { email: string };
}>('DELETE', ENDPOINT.API_LIMIT_OVERRIDE);
