export const TABS = ['artist', 'track'] as const;
export const PLATFORM_FILTERS = {
  spotify: 'hasSpotifyURL',
  pandora: 'hasPandoraURL',
  instagram: 'hasInstagramURL',
  tiktok: 'hasTiktokURL',
  youtube: 'hasYoutubeURL',
  shazam: 'hasShazamURL',
};
export const SUPPORT_PLATFORMS = {
  artist: ['spotify', 'instagram', 'youtube', 'pandora', 'tiktok', 'shazam'],
  track: ['spotify', 'instagram', 'youtube', 'pandora', 'tiktok', 'shazam'],
};
export const VIEWS = ['table', 'card'] as const;