import { capitalizeEveryWord } from '@Utils';
import { COUNTRY_LIST } from '@Utils/constants';
import { Flex, Tooltip } from 'antd';
import { capitalize } from 'lodash';
import React from 'react';

function Country({ value, showName = true }: { value: string; showName?: boolean }) {
  if (value)
    return (
      <Flex align="center" gap="small" justify="center">
        <Tooltip title={capitalize(COUNTRY_LIST[value])}>
          <img
            alt={value}
            style={{
              width: 20,
              height: 20,
              borderRadius: 8,
            }}
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value?.toUpperCase()}.svg`}
          />
        </Tooltip>
        {showName && <span>{capitalize(COUNTRY_LIST[value])}</span>}
      </Flex>
    );
  return null;
}

export default Country;
