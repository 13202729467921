import React, { useEffect, useState } from 'react';
import { capitalize, isEmpty } from 'lodash';
import { useGetClaimedEntities } from '@Services/meta';
import { generateCmUrl } from '../../../../utilities';
import useDebounceValue from '../../../../hooks/useDebounceValue';
import { TARGET_MAPPER } from '../../../../utilities/constants';
import usePagination from '../../../../hooks/usePagination';
import GlobalSearch from '../../../../components/global-search/GlobalSearch';
import ClaimedEntityUsersModal from './ClaimedEntityUsersModal';
import { AntdTable } from '@Shared';
import Country from '@Components/Country';
import { Panel } from '@/ui';

const COLUMNS = [
  {
    Header: 'Type',
    accessor: 'target',
    Cell: ({ value }) => <span>{capitalize(TARGET_MAPPER[value])}</span>,
  },
  {
    Header: 'Name',
    accessor: 'name',
    Cell: ({ original, value }) => (
      <a
        href={generateCmUrl(TARGET_MAPPER[original.target], {
          id: original.id,
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {value}
      </a>
    ),
  },
  {
    Header: 'Country',
    accessor: 'code2',
    Cell: Country,
    filter: true,
  },
  {
    Header: 'Count',
    accessor: 'count',
    sorter: true,
  },
  {
    Header: 'Users',
    Cell: ClaimedEntityUsersModal,
  },
];

function ClaimList() {
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
  const search = useDebounceValue(searchInput, 500);
  const { limit, offset, handleChangePage, handleChangePageSize, reset } = usePagination({
    limit: 10,
  });
  const { data, isLoading } = useGetClaimedEntities({
    data: {
      limit,
      offset,
      target: 'cm_artist',
      search,
    },
  });

  useEffect(() => {
    if (!isEmpty(searchInput)) reset();
  }, [search]);

  const handleSearch = (value: string) => {
    if (isEmpty(value)) return setSearchInput(undefined);
    if (value.startsWith('http')) {
      const id = value.split('com/')[1];
      const targetId = id.split('/')[1];
      setSearchInput(targetId);
      return;
    }
    if (value.includes('@')) {
      setSearchInput(value);
    }
  };

  return (
    <Panel
      header="Claim List"
      icon="faListUl"
      aside={<GlobalSearch size="middle" types={['artist', 'user']} onChange={handleSearch} />}
    >
      <AntdTable
        columns={COLUMNS}
        data={data?.data ?? []}
        loading={isLoading}
        lazyPaginationOptions={{
          total: data?.total,
          limit,
          offset,
          handleChangePage,
          handleChangePageSize,
        }}
      />
    </Panel>
  );
}

export default ClaimList;
