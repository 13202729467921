import React, { useEffect, useRef, useState } from 'react';
import type { GetProp, InputRef, TableColumnType, TableProps } from 'antd';
import { Button, Card, Input, Space, Table } from 'antd';
import { get, isEmpty } from 'lodash';
import { ColumnGroupType, FilterDropdownProps, SortOrder } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
type ColumnsType<T extends object> = GetProp<TableProps<T>, 'columns'>;
type ExpandableConfig<T extends object> = TableProps<T>['expandable'];

type DataType = any;

export interface Column<T, E = any> {
  Header: string | React.JSX.Element;
  accessor?: keyof T;
  width?: number;
  Cell?: React.FC<{ value: any; original: T; extraData: E }> | string;
  align?: 'left' | 'right' | 'center';
  sorter?: ((a, b) => number) | boolean;
  rowScope?: 'row';
  search?: boolean;
  sortOrder?: SortOrder;
  sortDirections?: SortOrder[];
  filter?: boolean;
  formatter?: (value: any, original: T) => string | number | boolean | undefined | null;
  /**
   * It's necessary to use custom sort
   */
  key?: string;
}

const getAntdColumns = <T, E extends Record<string, any>>(
  columns: Column<T, E>[],
  extraData: E = {} as E,
  data: T[] | undefined
): ColumnsType<any> =>
  columns.map(column => {
    const { Header, accessor, width, Cell, align, sorter, formatter, ...rest } = column;
    return {
      ...rest,
      title: Header,
      dataIndex: accessor,
      width: width ?? 100,
      align: align ?? 'center',
      render: (value, record) =>
        typeof Cell === 'string' ? (
          Cell
        ) : Cell ? (
          <Cell value={get(record, accessor ?? '')} original={record} extraData={extraData ?? {}} />
        ) : formatter ? (
          formatter(get(record, accessor ?? '') ?? '', record)
        ) : (
          get(record, accessor ?? '')
        ),
      sorter:
        sorter && accessor
          ? (a, b) => {
              const valueA = get(a, accessor);
              const valueB = get(b, accessor);
              //if date string
              if (
                new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/).test(valueA) &&
                new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/).test(valueB)
              ) {
                return new Date(valueA).getTime() - new Date(valueB).getTime();
              }

              if (typeof valueA === 'string' && typeof valueB === 'string') {
                return valueA.localeCompare(valueB);
              }

              return valueA - valueB;
            }
          : false,
      filters: rest.filter
        ? Array.from(
            new Set(
              data?.map(item =>
                formatter ? formatter(item[accessor as string], item) : item[accessor as string]
              )
            )
          )
            .filter(e => !isEmpty(e))
            .map((item: any) => ({
              text: item,
              value: item,
            }))
        : undefined,
      onFilter: (value, record) => {
        const recordValue = formatter
          ? formatter(get(record, accessor as string), record)
          : get(record, accessor as string);

        return recordValue.indexOf(formatter ? formatter(value, record) : (value as string)) === 0;
      },
    };
  });

interface Props<T extends Record<string, any>> {
  title?: string;
  loading?: TableProps<DataType>['loading'];
  footer?: TableProps<DataType>['footer'];
  expandable?: ExpandableConfig<DataType>;
  data: T[] | undefined;
  columns: Column<T, any>[];
  lazyPaginationOptions?: {
    total: number;
    limit: number;
    offset: number;
    handleChangePage: (page: number) => void;
    handleChangePageSize: (pageSize: number) => void;
  };
  extraData?: Record<string, any>;
  showSizeChanger?: boolean;
  pageSize?: number;
  onChange?: TableProps<DataType>['onChange'];
}

function AntdTable<T extends Record<string, any>>({
  lazyPaginationOptions,
  title,
  footer,
  expandable,
  data,
  columns,
  loading,
  extraData,
  showSizeChanger = true,
  pageSize,
  onChange,
}: Props<T>) {
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = ({ dataIndex, title }: any): TableColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          ref={searchInput}
          // placeholder={`Search ${typeof title === 'string' ? title : ''}`}
          value={selectedKeys[0]}
          onChange={e => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            handleSearch(selectedKeys as string[], confirm, dataIndex);
          }}
          onPressEnter={() => {
            confirm();
          }}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({ closeDropdown: true });
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      JSON.stringify(record[dataIndex])
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    // render: text =>
    //   searchedColumn === dataIndex ? (
    //     <Highlighter
    //       highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
    //       searchWords={[searchText]}
    //       autoEscape
    //       textToHighlight={text ? text.toString() : ''}
    //     />
    //   ) : (
    //     text
    //   ),
  });

  const tableColumns = getAntdColumns(columns, extraData, data).map(item => ({
    ...item,
    // @ts-ignore
    ...(item.search && getColumnSearchProps(item)),
    ellipsis: false,
  }));

  const handleSearch = (
    selectedKeys: string[],
    confirm: FilterDropdownProps['confirm'],
    dataIndex: string
  ) => {
    confirm({ closeDropdown: false });
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const prevData = useRef(data);

  useEffect(() => {
    if (loading) return;
    prevData.current = data;
  }, [data]);

  const tableProps: TableProps<DataType> = {
    loading,
    expandable,
    title: () => title,
    footer,
  };

  return (
    <Table
      {...tableProps}
      pagination={{
        pageSize,
        ...(lazyPaginationOptions && {
          current: lazyPaginationOptions.offset / lazyPaginationOptions.limit + 1,
          pageSize: lazyPaginationOptions.limit,
          total: lazyPaginationOptions.total,
        }),
        position: ['bottomRight'],
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        showSizeChanger: showSizeChanger,
      }}
      onChange={(...props) => {
        const [pagination] = props;
        lazyPaginationOptions?.handleChangePage(pagination.current! - 1);
        lazyPaginationOptions?.handleChangePageSize(pagination.pageSize!);
        onChange?.(...props);
      }}
      columns={tableColumns}
      dataSource={(loading ? prevData?.current : data) ?? []}
      scroll={{
        x: '100%',
      }}
    />
  );
}

export default AntdTable;
