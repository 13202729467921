import React from 'react';
import { H4, Muted } from '@/ui';
import useURLParams from '@Hooks/useURLParams';
import { useGetEntity } from '@Services/search';
import { GlobalSearch } from '@Components';
import { capitalize, isEmpty } from 'lodash';
import EditAlbum from './EditAlbum';
import EditArtist from './EditArtist';
import EditCurator from './EditCurator';
import EditTrack from './EditTrack';
import EditPlaylist from './Playlist';
import Title from '@Shared/Style/Title/Title';

export type EditEntityProps = {
  q: string;
};

const EditCMData = () => {
  const {
    setParams,
    params: { q },
  } = useURLParams();

  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <>
      <Title
        title={data ? `Edit ${capitalize(data.type)} "${data.name}" Metadata` : 'Edit Metadata'}
        description="Search for an artist, track, playlist, album, or curator and edit their metadata, genres, tracks, albums and links."
      />

      <GlobalSearch
        defaultValue={q}
        types={['artist', 'playlist', 'track', 'album', 'curator']}
        className="top-search-box"
        icon="farEdit"
        loading={isFetching}
        onSubmit={q => setParams({ q: q as string })}
      />
      <br />
      {data?.type === 'artist' && <EditArtist q={q} />}
      {data?.type === 'track' && <EditTrack q={q} />}
      {data?.type === 'playlist' && (
        <EditPlaylist q={q} id={data.id} {...data} fetchData={refetch} />
      )}
      {data?.type === 'album' && <EditAlbum q={q} id={data.id} {...data} fetchData={refetch} />}
      {data?.type === 'curator' && <EditCurator q={q} />}
    </>
  );
};

export default EditCMData;
