import {
  useUpdateTeam,
  useUpdateTeamInvitation,
  useGetTeam,
  Invitation,
} from '@Services/Onesheet/team';
import { ActionInput, AntdIcon, Panel } from '@Shared/Style';
import { ANTD_ICON_TYPES } from '@Shared/Style/AntdIcon';
import ResponsiveSection from '@Components/ResponsiveSection';
import { Flex, Checkbox, Popconfirm, Button, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { AntdTable, ImageCropper } from '@Shared';
import AddMemberModal from './AddMemberModal';
import Permission from './Permission';
import { Table } from '@Components';

function Team({
  title,
  teamId,
  refetchUser,
}: {
  title: string;
  teamId: string;
  refetchUser?: () => void;
}) {
  const { updateSheetLimit, fetching, deleteMember, toggleAdmin, updateLogo } = useUpdateTeam();
  const {
    acceptInvitation,
    deleteInvitation,
    fetching: fetchingInvitation,
  } = useUpdateTeamInvitation();
  const { data, refetch, fetch } = useGetTeam();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetch(teamId);
  }, [teamId]);

  const handleClickInvitationAction = async (action: string, invitation: Invitation) => {
    if (action === 'approve') {
      await acceptInvitation(invitation);
    } else if (action === 'decline') {
      await deleteInvitation(invitation.id);
    }

    await refetch();
    refetchUser?.();
  };

  const handleToggleAdmin = async (id: string, isAdmin: boolean) => {
    await toggleAdmin(id, isAdmin);
    refetch();
  };

  const handleDeleteMember = async (id: string, userId: string) => {
    await deleteMember(id, userId);
    await refetch();
    refetchUser?.();
  };

  if (!data) return null;

  return (
    <>
      <Panel title={title}>
        <Flex gap="middle" vertical>
          <ResponsiveSection>
            <Table
              title="Basic Info"
              options={[
                {
                  label: 'Logo',
                  component: (
                    <ImageCropper
                      onCropComplete={async imageData => updateLogo(data!.id, imageData)}
                      defaultImageUrl={data.customLogoUrl}
                    />
                  ),
                },
                {
                  label: 'Team Name',
                  component: data.name,
                },
                {
                  label: 'Owner',
                  component: data.ownerName,
                },
                {
                  label: 'Sheet Limit',
                  description: 'This is not updating their Stripe account',
                  component: (
                    <ActionInput
                      type="number"
                      defaultValue={data.sheetLimit.toString()}
                      action={{
                        icon: 'farEdit',
                        onClick: e => {
                          updateSheetLimit(data!.id, Number(e.target.value));
                        },
                        content: 'Edit',
                        disabledEmpty: true,
                        loading: fetching,
                      }}
                    />
                  ),
                },
              ]}
            />
            <Panel
              innerStyle
              title="Members"
              extra={
                <Button
                  className="green"
                  icon={<AntdIcon name="plus" />}
                  onClick={() => setOpen(true)}
                >
                  Add Member
                </Button>
              }
            >
              <AntdTable
                data={data.members ?? []}
                columns={[
                  {
                    accessor: 'email',
                    Header: 'Email',
                  },
                  {
                    accessor: 'userName',
                    Header: 'User Name',
                  },
                  {
                    accessor: 'isAdmin',
                    Header: 'Is Admin',
                    Cell: ({ original, value }) => (
                      <Checkbox
                        checked={value}
                        onChange={e => handleToggleAdmin(original.id!, e.target.checked)}
                      />
                    ),
                    width: 70,
                  },
                  {
                    accessor: 'id',
                    Header: 'Delete',
                    Cell: ({ original, value }) => (
                      <Popconfirm
                        title="Be careful this action"
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => handleDeleteMember(value, original.userId)}
                        description="Are you sure you want to delete this member?"
                      >
                        <Button
                          disabled={data.owner === value}
                          loading={fetching}
                          className="red"
                          icon={<AntdIcon name="delete" />}
                        />
                      </Popconfirm>
                    ),
                    width: 70,
                  },
                  {
                    accessor: 'userId',
                    Header: 'Permissions',
                    Cell: ({ value }) => {
                      const [open, setOpen] = useState(false);

                      return (
                        <>
                          <Button
                            className="green"
                            icon={<AntdIcon name="list" />}
                            onClick={() => setOpen(true)}
                          />
                          <Modal
                            title="Folder Permissions"
                            open={open}
                            cancelText="Close"
                            onCancel={() => setOpen(false)}
                            footer={null}
                          >
                            <Permission userId={value} teamId={data!.id} withoutHeader />
                          </Modal>
                        </>
                      );
                    },
                    width: 70,
                  },
                ]}
              />
            </Panel>
          </ResponsiveSection>
          <Panel innerStyle title="Invitations">
            <AntdTable
              data={data.invitations ?? []}
              columns={[
                {
                  accessor: 'email',
                  Header: 'Email',
                },
                {
                  accessor: 'invitedBy',
                  Header: 'Invited By',
                },
                {
                  accessor: 'permissionNames',
                  Header: 'Folder IDs',
                  Cell: ({ value }) => value.join(', '),
                },
                {
                  accessor: 'updatedAt',
                  Header: 'Updated At',
                },
                {
                  accessor: 'accountCreated',
                  Header: 'Account Created',
                  Cell: ({ value }) =>
                    value ? <Icon color="blue" name="check" /> : <Icon color="red" name="x" />,
                },
                {
                  accessor: 'id',
                  Header: 'Team Name',
                  Cell: ({ original, value }) => (
                    <Flex gap="small" justify="center">
                      {[
                        { action: 'approve', icon: 'success', color: 'green' },
                        { action: 'decline', icon: 'delete', color: 'red' },
                      ].map(({ action, icon, color }) => (
                        <Popconfirm
                          title="Be careful this action"
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() =>
                            handleClickInvitationAction(action, original as Invitation)
                          }
                          description={`Are you sure you want to ${action} this invitation?`}
                        >
                          <Button
                            loading={fetchingInvitation}
                            className={color}
                            icon={<AntdIcon name={icon as ANTD_ICON_TYPES} />}
                            disabled={action === 'approve' && !original.accountCreated}
                          />
                        </Popconfirm>
                      ))}
                    </Flex>
                  ),
                },
              ]}
            />
          </Panel>
        </Flex>
      </Panel>
      {data && (
        <AddMemberModal
          open={open}
          setOpen={setOpen}
          teamId={data.id}
          teamName={data.name}
          callback={async () => {
            await refetch();
            refetchUser?.();
          }}
        />
      )}
    </>
  );
}

export default Team;
