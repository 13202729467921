'use client';

import React from 'react';
import { Navbar } from './navbar';
import { useUser } from '@Services/auth';
import FAIcon from '@Components/ui/fa-icons';
import { MenuType } from '@Routes/menus';

interface ContentLayoutProps {
  children: React.ReactNode;
}

export function ContentLayout({ children }: ContentLayoutProps) {
  return (
    <div>
      <Navbar />
      <div className="py-4 px-4 sm:px-8">{children}</div>
    </div>
  );
}
