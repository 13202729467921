import React from 'react';
import { H4, Muted } from '@/ui';
import { useGetEntity } from '@Services/search';
import { GlobalSearch } from '@Components';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import DeletePlaylist from './DeletePlaylist';

const DeleteCMdata = () => {
  const [q, setQ] = useState('');
  const { data, isLoading } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <>
      <H4>Delete CM Data (only support playlist)</H4>
      <GlobalSearch types={['playlist']} onSubmit={e => setQ(e as string)} loading={isLoading} />
      <br />
      {data && data.type !== 'playlist' && <Muted>Only support playlist</Muted>}
      {data?.type === 'playlist' && <DeletePlaylist setQ={setQ} q={q} />}
    </>
  );
};

export default DeleteCMdata;
