import React from 'react';
import { createRoot } from 'react-dom/client';
// CSS
import { ToastProvider } from '@/lib/Toast';
import { ConfigProvider } from 'antd';
import { Amplify } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ANTD_THEME } from 'styles/antd';
import App from './application';
import { AWS_CONFIG } from './aws-exports';

Amplify.configure(AWS_CONFIG);
const client = new QueryClient();

createRoot(document.getElementById('root')).render(
  <ConfigProvider theme={ANTD_THEME}>
    <QueryClientProvider client={client}>
      <BrowserRouter>
        <ToastProvider>
          <App />
        </ToastProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </ConfigProvider>
);
