import { COUNTRY_LIST } from './constants';

export const COUNTRY_OPTIONS = Object.entries(COUNTRY_LIST).map(([code2, name]) => ({
  value: code2.toLowerCase(),
  label: Array.isArray(name) ? name.join(' / ') : name,
}));

export const GENDER_OPTIONS = [
  {
    value: 0,
    label: 'Female',
  },
  {
    value: 1,
    label: 'Male',
  },
  {
    value: 2,
    label: 'Mixed',
  },
  {
    value: 3,
    label: 'Unspecified',
  },
];

export const PRONOUN_OPTIONS = [
  {
    value: 'he/him',
    label: 'He/Him',
  },
  {
    value: 'she/her',
    label: 'She/Her',
  },
  {
    value: 'they/them',
    label: 'They/Them',
  },
];
