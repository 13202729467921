import { MOOD_COLORS_CLASSES, MOOD_EXPLANATIONS, MOOD_LIST } from '@Constants/platform/moods';
import { useDeleteTrackMood, useInsertTrackMood } from '@Services/mood';
import { AntdIcon, ModalButton, Panel, WhatsThis } from '@Shared/Style';
import FAIcon from '@Components/ui/fa-icons';
import { capitalizeEveryWord } from '@Utils/string';
import { Badge, Button, Flex, Select, Tag, Tooltip, Typography } from 'antd';
import React, { useState } from 'react';
import { Muted } from '@/ui';
const convertToPastel = (colorClass: string): string => {
  const pastelColors: { [key: string]: string } = {
    Blue: '#6699cc',
    Grey: '#9B9B9B',
    Red: '#cc6666',
    Purple: '#9966cc',
    Orange: '#ff9933',
    Green: '#66cc99',
    Yellow: '#E3B52D',
    Pink: '#ff6699',
  };
  return pastelColors[colorClass] || '#B8B8B8'; // Default to Grey if colorClass not found
};
interface Mood {
  name: string;
  number_of_playlists: number;
  pct: number;
  tag_type: TagType;
}

interface Props {
  id: number;
  moods: Mood[];
  callback: () => void;
}

const TAG_TYPE = ['MOOD', 'ACTIVITY / THEME'] as const;
type TagType = (typeof TAG_TYPE)[number];

const MoodPanel = ({ id, moods, callback }: Props) => {
  const groupedMoods: Record<TagType, [string, Mood[]]> = moods.reduce<{
    [key: string]: [string, Mood[]];
  }>((acc, mood) => {
    if (!acc[mood.tag_type]) {
      acc[mood.tag_type] = ['', []];
    }
    acc[mood.tag_type][1].push(mood);
    acc[mood.tag_type][1] = acc[mood.tag_type][1].sort((a, b) => b.pct - a.pct);

    return acc;
  }, {}) as Record<TagType, [string, Mood[]]>;

  return (
    <Panel
      title="Moods"
      description={
        <>
          it will reflect to production by data side scheduling. Sort by <code>PCT</code>
        </>
      }
    >
      <Panel.ResponsiveBody>
        {TAG_TYPE.map(type => {
          const [_, moods] = groupedMoods[type] ?? [];

          return (
            <Panel
              innerStyle
              key={type}
              title={capitalizeEveryWord(type)}
              extra={<AddMoodModal entityId={id} type={type} callback={callback} />}
            >
              <Flex wrap="wrap" gap="small">
                {moods?.map(mood => (
                  <Label
                    pct={mood.pct}
                    name={mood.name}
                    type={type as TagType}
                    entityId={id}
                    callback={callback}
                  />
                )) ?? <Muted>No data found</Muted>}
              </Flex>
            </Panel>
          );
        })}
      </Panel.ResponsiveBody>
    </Panel>
  );
};

const AddMoodModal = ({
  entityId,
  type,
  callback,
}: {
  entityId: number;
  type: TagType;
  callback: () => void;
}) => {
  const { execute, isLoading } = useInsertTrackMood();
  const [selectedMood, setSelectedMood] = useState<string | undefined>();

  return (
    <ModalButton
      okButtonProps={{
        loading: isLoading,
      }}
      onOk={async () =>
        execute({
          path: { id: entityId },
          query: { name: selectedMood as string, type },
        }).then(callback)
      }
      title={`Add Mood as ${capitalizeEveryWord(type)} type`}
      Button={<Button loading={isLoading} className="green" icon={<FAIcon name="farPlus" />} />}
    >
      <Flex vertical gap="small">
        <Select
          allowClear
          showSearch
          style={{ width: '100%' }}
          onChange={setSelectedMood}
          value={selectedMood}
          placeholder={`Select ${capitalizeEveryWord(type)}`}
          options={MOOD_LIST.filter(e => e.type.toLowerCase() === type.toLowerCase()).map(
            ({ name }) => ({
              label: capitalizeEveryWord(name),
              value: name,
            })
          )}
        />
        {selectedMood && MOOD_EXPLANATIONS[selectedMood.toLowerCase()] && (
          <Typography.Text>
            <Tag
              color={convertToPastel(
                MOOD_COLORS_CLASSES[selectedMood.replace(/\s+/g, '_').toUpperCase()]
              )}
            >
              {capitalizeEveryWord(selectedMood)}
            </Tag>
            {MOOD_EXPLANATIONS[selectedMood.toLowerCase()].split(': ')[1]}
          </Typography.Text>
        )}
      </Flex>
    </ModalButton>
  );
};

const Label = ({
  entityId,
  name,
  type,
  callback,
  pct,
}: {
  entityId: number;
  name: string;
  type: TagType;
  callback: () => void;
  pct: number;
}) => {
    // It's soft delete (override)
    const { execute, isLoading } = useDeleteTrackMood();

    return (
      <Tooltip
        title={
          type === 'MOOD' && (MOOD_EXPLANATIONS[name.toLowerCase()] || 'No description available.')
        }
      >
        <Tag color={convertToPastel(MOOD_COLORS_CLASSES[name])}>
          <Flex align="center" gap={6}>
            {capitalizeEveryWord(name)} ({(pct * 100).toFixed(2)})
            {type === 'MOOD' && <AntdIcon name="questionCircle" color="white" />}{' '}
            <FAIcon
              name="farTrash"
              loading={isLoading}
              onClick={() =>
                execute({
                  path: { id: entityId },
                  query: { name, type },
                }).then(callback)
              }
            />
          </Flex>
        </Tag>
      </Tooltip>
    );
  };

export default MoodPanel;
