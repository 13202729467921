import { AntdIcon } from '@Shared/Style';
import { Flex, Button, Typography, Tag, Popconfirm } from 'antd';
import React, { useState } from 'react';
import styles from './Hierarchy.module.scss';
import { capitalize, isEmpty } from 'lodash';
import FAIcon, { FAIcons } from '@Components/ui/fa-icons';

type Item = {
  id: number;
  name: string;
};

type Position = 'parent' | 'child';

type Action = {
  onClick: (id: number, position: Position) => void;
  popconfirmMessage: string;
  icon: FAIcons;
};
interface Actions {
  onClickTag?: (id: number, position: Position) => void;
  action: Action;
}

interface Props extends Actions {
  items: {
    parents: Item[];
    children: Item[];
  };
  name: string;
  id: number;
}

const Tree = ({ items: item, name, id, ...rest }: Props) => {
  const [showId, setShowId] = useState(false);

  if (!item) return null;

  return (
    <Flex vertical justify="space-between" className={styles.wrapper} wrap gap="small">
      <Button
        className={styles.showId}
        icon={<AntdIcon name={showId ? 'eyeDisable' : 'eyeEnable'} />}
        onClick={() => setShowId(prev => !prev)}
      >
        {showId ? 'Hide ID' : 'Show ID'}
      </Button>
      <Section {...rest} data={item?.parents} showId={showId} title="Parents" type="parent" />
      <br />
      <Flex align="center" style={{ minHeight: 50 }} vertical justify="center">
        <Tag className={styles.main}>
          <Typography.Text>
            <Flex align="center" vertical>
              {capitalize(name)}
              {showId && <Typography.Text style={{ fontSize: 12 }}>{id}</Typography.Text>}
            </Flex>
          </Typography.Text>
        </Tag>
      </Flex>
      <br />
      <Section {...rest} data={item?.children} showId={showId} title="Children" type="child" />
    </Flex>
  );
};

const Section = ({
  data,
  showId,
  title,
  type,
  ...rest
}: {
  data: Item[];
  showId: boolean;
  title: string;
  type: 'parent' | 'child';
} & Actions) => {
  return (
    <Flex vertical align="center" style={{ minHeight: 50 }}>
      <Typography.Text
        type="secondary"
        style={{
          marginBottom: 10,
        }}
      >
        {title}
      </Typography.Text>
      <Flex wrap gap="middle" justify="center">
        {data?.map(item => (
          <Label {...rest} item={item} position={type} showId={showId} />
        ))}
      </Flex>
    </Flex>
  );
};

const Label = ({
  item,
  position,
  showId,
  onClickTag,
  action,
}: {
  item: Item;
  position: Position;
  showId: boolean;
} & Actions) => {
  return (
    <div style={{ position: 'relative' }}>
      {action && (
        <Popconfirm
          title={action.popconfirmMessage}
          onConfirm={e => {
            e?.stopPropagation();
            e?.preventDefault();
            action.onClick(item.id, position);
          }}
        >
          <FAIcon name={action.icon} className={styles.action} />
        </Popconfirm>
      )}
      <Tag
        key={item.id}
        className={styles[position]}
        onClick={() => onClickTag?.(item.id, position)}
      >
        <Flex vertical align="center">
          <Typography.Text>{capitalize(item.name)}</Typography.Text>
          {showId && <Typography.Text style={{ fontSize: 12 }}>{item.id}</Typography.Text>}
        </Flex>
      </Tag>
    </div>
  );
};

export default Tree;
