import { isEmpty } from 'lodash';

export const getDomainStr = (rawDomain: string) => {
  return rawDomain
    ?.replace('{', '')
    ?.replace('}', '')
    ?.split(',')
    .filter(e => !isEmpty(e));
};

export const truncatedAddress = (arr: string[]) => {
  return arr?.[0] ?? '' + (arr.length > 1 ? ` + ${arr.length - 1} more` : '');
};
