import { capitalize } from 'lodash';
import React, { useState } from 'react';
import { Button, Modal, Tag } from 'antd';
import { generateCmUrl } from '../../../../utilities';
import { AntdIcon } from '../../../../shared/Style';
import AntdTable from '../../../../shared/Table/AntdTable';
import { TARGET_MAPPER } from '../../../../utilities/constants';

const COLUMNS = [
  {
    Header: 'Claim Id',
    accessor: 'id', // String-based value accessors!
  },
  {
    Header: 'Type',
    accessor: 'target',
    Cell: ({ value }) => <span>{capitalize(TARGET_MAPPER[value])}</span>,
  },
  {
    Header: 'Name',
    Cell: ({ original }) => (
      <a
        href={generateCmUrl(TARGET_MAPPER[original.target], { id: original.target_id })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {original.target_name}
      </a>
    ),
  },
  {
    Header: 'Reason',
    accessor: 'reason',
  },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <Tag color={value === 'accepted' ? 'green' : value === 'denied' ? 'red' : 'grey'}>
        {capitalize(value)}
      </Tag>
    ),
  },
  {
    Header: 'Reviewer',
    accessor: 'reviewer_email',
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({ value }) => <span>{value.split('T')[0]}</span>,
  },
  {
    Header: 'Modified',
    accessor: 'modified_at',
    Cell: ({ value }) => <span>{value.split('T')[0]}</span>,
  },
];

function UserClaimsModal({ id, data }) {
  const [open, setOpen] = useState(false);

  return data?.length ? (
    <>
      <Button className="green" onClick={() => setOpen(true)} icon={<AntdIcon name="list" />} />
      <Modal
        style={{
          minWidth: '98vw',
        }}
        open={open}
        title={`Claim History for ${id}`}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        okText="Close"
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <AntdTable columns={COLUMNS} data={data} />
      </Modal>
    </>
  ) : null;
}

export default UserClaimsModal;
