'use client';

import { cn } from '@/lib/utils';
import FAIcon, { FAIcons } from '@Components/ui/fa-icons';
import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';

const cardWrapperVariants = cva('rounded-3xl bg-card text-card-foreground py-7', {
  variants: {
    variant: {
      default: 'border border-transparent shadow-xs border-[2px] border-white dark:border-black',
      bordered: 'border border-card-border',
      card: 'p-0 border border-card-border',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const cardHeaderVariants = cva('flex flex-col px-7 pb-6', {
  variants: {
    variant: {
      default: '',
      bordered: '',
      card: 'bg-decorative p-4 rounded-t-3xl',
    },
  },
});

const PanelWrapper = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardWrapperVariants>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn(cardWrapperVariants(props), className)} {...props} />
));
PanelWrapper.displayName = 'Card';

const PanelHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof cardHeaderVariants>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn(cardHeaderVariants(props), className)} {...props} />
));
PanelHeader.displayName = 'CardHeader';

const PanelTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => (
    <h4
      ref={ref}
      className={cn('font-semibold leading-none tracking-tight text-lg text-card-title', className)}
      {...props}
    />
  )
);
PanelTitle.displayName = 'CardTitle';

const PanelDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={cn('text-sm text-muted-foreground pt-2', className)} {...props} />
));
PanelDescription.displayName = 'CardDescription';

const PanelContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('px-7 flex flex-col gap-8', className)} {...props} />
  )
);
PanelContent.displayName = 'CardContent';

const PanelFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('flex items-center px-6 pt-4', className)} {...props} />
  ),
);
PanelFooter.displayName = 'CardFooter';

interface PanelProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'className'>,
    VariantProps<typeof cardWrapperVariants> {
  children: React.ReactNode;
  header?: React.ReactNode;
  aside?: React.ReactNode;
  footer?: React.ReactNode;
  description?: React.ReactNode;
  icon?: FAIcons;
  grid?: boolean;
  classNames?: {
    wrapper?: string;
    header?: string;
    title?: string;
    description?: string;
    content?: string;
    footer?: string;
  };
}

const Panel = React.forwardRef<HTMLDivElement, PanelProps>(
  (
    { children, header, aside, description, footer, classNames, icon, grid, variant, ...props },
    ref
  ) => (
    <PanelWrapper ref={ref} {...props} className={classNames?.wrapper} variant={variant}>
      {header && (
        <PanelHeader className={classNames?.header} variant={variant}>
          <div className="gap-2 items-center justify-between flex flex-col w-full md:flex-row">
            <div className="flex flex-row gap-2 items-center col-span-2">
              {icon && <FAIcon name={icon} className="text-lg text-card-title" />}
              <PanelTitle className={classNames?.title}>{header}</PanelTitle>
            </div>
            <div className="w-full flex justify-end md:w-[50%]">{aside}</div>
          </div>
          {description && (
            <PanelDescription className={classNames?.description}>{description}</PanelDescription>
          )}
        </PanelHeader>
      )}
      <PanelContent
        className={cn(
          classNames?.content,
          grid &&
            `grid xxl:grid-cols-3 xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 gap-4`
        )}
      >
        {children}
      </PanelContent>
      {footer && <PanelFooter className={classNames?.footer}>{footer}</PanelFooter>}
    </PanelWrapper>
  )
);

export { Panel };
