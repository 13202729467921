import React, { useState } from 'react';
import { AntdTable, RequestSubmitButton } from '@Shared';
import { Segments } from '../../../shared/Style';
import {
  useGetNewsLinkDomains,
  useGetNewsLinkRequest,
  useUpdateNewsLinkRequest,
} from '../../../services/request';
import {
  generateCmUrl,
  generateInternalLink,
  getFormattedDateString,
  getFormattedLocalDate,
  getDateFormatFromEpoch,
} from '../../../utilities';
import useRequestTable from '../../../hooks/useRequstTable';
import DataTableSearch from '../../../shared/Table/SearchInput';
import Pagination from '../../../shared/Table/Paignation';
import { Column } from '@Shared/Table/AntdTable';
import { Link } from 'react-router-dom';
import { Panel, Badge, Checkbox, Flex, Radio } from '@/ui';

function NewsLinkRequest() {
  const { data: domains, isLoading: isDomainLoading } = useGetNewsLinkDomains();
  const { execute, isLoading: isFetching } = useUpdateNewsLinkRequest();

  const {
    data,
    isLoading,
    state,
    onClickApprove,
    onClickDecline,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    refetch,
    onSearch,
    selected,
    clear,
  } = useRequestTable(useGetNewsLinkRequest, null);

  const COLUMNS: Column<any>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 50,
    },
    {
      Header: 'Artist',
      accessor: 'artist_name',
      Cell: (props: any) => (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={generateCmUrl('artist', { id: props.original.cm_artist })}
        >
          {props.value}
        </a>
      ),
      width: 300,
    },
    {
      Header: 'URL',
      accessor: 'url',
      Cell: ({ value }) => (
        <a target="_blank" rel="noreferrer noopener" href={value}>
          {value}
        </a>
      ),
    },
    {
      Header: 'Requested By',
      accessor: 'user_email',
      Cell: props => (
        <Link
          to={generateInternalLink('user/user-profile', {
            qs: props.value,
          })}
        >
          {props.value}
        </Link>
      ),
    },
    {
      Header: 'Created',
      accessor: 'created_at',
      formatter: value => getFormattedLocalDate(value),
      width: 200,
      sorter: true,
    },
    {
      Header: (
        <Flex justify="center" align="center" gap={2}>
          Approve
          <Checkbox
            checked={state.approves.length === data?.length && data.length > 0}
            onClick={onClickSelectApproveAll}
          />
        </Flex>
      ),
      Cell: (props: any) => (
        <Flex justify="center">
          <Radio
            id={`approve-${props.original.id}`}
            checked={state.approves.includes(props.original.id)}
            onClick={() => onClickApprove(props.original.id)}
          />
        </Flex>
      ),
      width: 100,
    },
    {
      Header: (
        <Flex justify="center" align="center" gap={2}>
          Decline
          <Checkbox
            checked={state.declines.length === data?.length && data.length > 0}
            onClick={onClickSelectDeclineAll}
          />
        </Flex>
      ),
      Cell: (props: any) => (
        <Flex justify="center">
          <Radio
            id={`decline-${props.original.id}`}
            checked={state.declines.includes(props.original.id)}
            onClick={() => onClickDecline(props.original.id)}
          />
        </Flex>
      ),
      width: 100,
      align: 'center',
    },
  ];

  const COLUMNS_DOMAIN: Column<any>[] = [
    {
      Header: 'Domain',
      accessor: 'domain',
      Cell: (props: any) => (
        <a target="_blank" rel="noreferrer noopener" href={`https://${props.value}`}>
          {props.value}
        </a>
      ),
      search: true,
    },
    {
      Header: 'Count',
      accessor: 'count',
      sorter: true,
    },
  ];

  const SUBMIT_MODAL_COLUMNS = [
    {
      Header: 'Artist',
      accessor: 'artist_name',
      Cell: (props: any) => (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={generateCmUrl('artist', { id: props.original.cm_artist })}
        >
          {props.value}
        </a>
      ),
    },
    {
      Header: 'URL',
      accessor: 'url',
      Cell: ({ value }) => (
        <a target="_blank" rel="noreferrer noopener" href={value}>
          {value}
        </a>
      ),
    },
    {
      Header: 'Requested By',
      accessor: 'user_email',
    },
    {
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ value }) => getFormattedLocalDate(value) ?? '',
    },
  ];

  const handleSubmit = () =>
    execute({
      data: {
        requests: state.approves
          .map(id => ({
            id,
            process: true,
            cm_artist: data.find((item: any) => item.id === id).cm_artist,
            url: data.find((item: any) => item.id === id).url,
          }))
          .concat(
            state.declines.map(id => ({
              id,
              process: false,
              cm_artist: data.find((item: any) => item.id === id).cm_artist,
              url: data.find((item: any) => item.id === id).url,
            }))
          ),
      },
    }).then(() => {
      refetch();
      clear();
    });

  return (
    <Segments
      options={[
        {
          title: 'News Link Request',
          icon: 'farNewspaper',
          content: (
            <Panel
              icon="farNewspaper"
              aside={<DataTableSearch onChange={onSearch} originalData={data} />}
              header={
                <Flex align="center" gap={2}>
                  News Link Request
                  <Badge variant="default">{data?.length ?? '0'}</Badge>
                </Flex>
              }
              classNames={{
                footer: 'justify-center',
              }}
              footer={
                <RequestSubmitButton
                  columns={SUBMIT_MODAL_COLUMNS}
                  onClickSubmit={handleSubmit}
                  approveRequest={state.approves}
                  declineRequest={state.declines}
                  data={data ?? []}
                  loading={isFetching}
                  selected={selected}
                />
              }
            >
              <AntdTable loading={isLoading} columns={COLUMNS} data={state.filteredData} />
            </Panel>
          ),
        },
        {
          title: 'Frequently Requested Domains',
          icon: 'farLinkHorizontal',
          content: (
            <Panel icon="farLinkHorizontal" header="Frequently Requested Domains">
              <AntdTable loading={isDomainLoading} columns={COLUMNS_DOMAIN} data={domains} />
            </Panel>
          ),
        },
      ]}
    />
  );
}

export default React.memo(NewsLinkRequest);
