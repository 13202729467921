import React, { useState } from 'react';
import { capitalize } from 'lodash';
import { getFormattedLocalDate, getDateFormatFromEpoch, getStripeCustomerUrl } from '../utilities';
import { useSyncUserStripe } from '../services/system';
import { Table } from '@Components';
import { Button, Card, CardContent, Flex, Panel } from '@/ui';
import { useUserProfileContext } from '@/pages/user-manager/user-profile/useUserProfileContext';

export const StripeBasicInfo = ({ customerInfo, email, userId }) => {
  const [clickedSyncStripe, setClickedSyncStripe] = useState(null);
  const { execute: syncUserStripe, isLoading: isLoadingSyncUserStripe } = useSyncUserStripe();
  const { refetch } = useUserProfileContext();

  const handleOnClickSyncStripe = () => {
    syncUserStripe({ data: { id: userId } });
    setClickedSyncStripe(email);
    refetch();
  };

  return (
    <Table
      options={[
        {
          label: 'Stripe Customer ID',
          component: (
            <Flex style={{ width: '100%' }} gap={4} align="center">
              {customerInfo ? (
                <a
                  href={getStripeCustomerUrl(customerInfo.id)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {customerInfo.id}
                </a>
              ) : (
                'N/A'
              )}
              <Flex vertical>
                <Button
                  variant="outline"
                  disabled={clickedSyncStripe === email}
                  onClick={handleOnClickSyncStripe}
                  loading={isLoadingSyncUserStripe}
                >
                  {clickedSyncStripe === email ? 'Updated' : 'Sync Stripe'}
                </Button>
                if there is a discrpancy between the Stripe and Chartmetric plans / plan counts for
                team, Please try to run it.
              </Flex>
            </Flex>
          ),
        },
      ]}
    />
  );
};

export const SubscriptionChurnInfo = ({ churnInfo }) =>
  churnInfo && (
    <Table
      title="Churned User Information"
      options={[
        {
          label: 'Status',
          component: capitalize(churnInfo?.status?.replace('_', ' ')),
        },
        {
          label: 'Churned on',
          component: getFormattedLocalDate(churnInfo?.churned_on),
        },
        {
          label: 'Activated on',
          component: getFormattedLocalDate(churnInfo?.activated_on),
        },
        {
          label: 'Latest Plan',
          component: churnInfo?.plan,
        },
        {
          label: 'MRR',
          component: churnInfo?.mrr,
        },
      ]}
    />
  );

export const SubscriptionPlan = ({ customerInfo }) => {
  const prodPlan = customerInfo?.subscription;
  const apiPlan = customerInfo?.addOns?.sort((a, b) => b.created - a.created)?.[0];

  return (
    <Flex vertical gap={8}>
      {[prodPlan, apiPlan].map((plan, i) => {
        const isTrial =
          plan?.trial_start &&
          plan.trial_start <= new Date().getTime() / 1000 &&
          new Date().getTime() / 1000 < plan.trial_end;
        return plan?.plan?.nickname ? (
          <Table
            title={
              (i === 0 ? 'Production Plan' : `API Plan`) + (plan.trial_start ? ' (Trial)' : '')
            }
            options={[
              {
                label: 'Subscription ',
                component: plan?.plan?.nickname ?? 'N/A',
              },
              {
                label: 'Plan',
                component: plan?.plan?.product ?? 'N/A',
              },
              {
                label: 'Price',
                component:
                  plan?.plan?.amount === 0
                    ? 'Free'
                    : plan?.plan?.amount
                      ? `${(plan?.plan?.amount ?? 0) / 100} (${
                          plan?.currency?.toUpperCase() ?? ''
                        }) / ${plan?.plan?.interval}`
                      : 'N/A',
              },
              ...(isTrial
                ? [
                    {
                      label: 'Trial Days',
                      component: `${
                        Math.ceil((plan?.trial_end ?? 0) - (plan?.trial_start ?? 0)) / 86400
                      } day(s)`,
                    },
                    {
                      label: 'Trial Start Date',
                      component: plan?.trial_start
                        ? getDateFormatFromEpoch(plan?.trial_start)
                        : 'N/A',
                    },
                    {
                      label: 'Trial End Date',
                      component: plan?.trial_end ? getDateFormatFromEpoch(plan?.trial_end) : 'N/A',
                    },
                  ]
                : [
                    {
                      label: 'First Date',
                      component: plan?.created ? getDateFormatFromEpoch(plan?.created) : 'N/A',
                    },
                    {
                      label: 'Renewed Date',
                      component: plan?.current_period_start
                        ? getDateFormatFromEpoch(plan?.current_period_start)
                        : 'N/A',
                    },
                    {
                      label: 'End Date',
                      component: plan?.cancel_at ? getDateFormatFromEpoch(plan?.cancel_at) : 'N/A',
                    },
                  ]),
              {
                label: 'Metadata',
                component: JSON.stringify(plan?.plan?.metadata),
              },
            ]}
          />
        ) : (
          <Card>
            <CardContent className="py-4">No {i === 0 ? 'Production' : 'API'} Plan</CardContent>
          </Card>
        );
      })}
    </Flex>
  );
};

function Subscription({ churnInfo, customerInfo, email, userId }) {
  return customerInfo ? (
    <Panel header="Subscription">
      <StripeBasicInfo customerInfo={customerInfo} email={email} userId={userId} />
      <SubscriptionChurnInfo churnInfo={churnInfo} />
      <SubscriptionPlan customerInfo={customerInfo} />
    </Panel>
  ) : null;
}

export default Subscription;
