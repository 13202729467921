import React from 'react';
import { Empty } from 'antd';
import { BrandIcon } from '../../../../shared/Style';
import { getFormattedLocalDate } from '@Utils';
import { capitalize } from 'lodash';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import FAIcon from '@Components/ui/fa-icons';
import { Panel } from '@/ui';

function ArtistLinksDetails({ artist_links_details }) {
  const columns: Column<any>[] = [
    {
      Header: 'Social Type',
      accessor: 'type',
      Cell: ({ value }) => (
        <BrandIcon
          platform={value}
          style={{
            width: '20px',
            height: '20px',
          }}
        />
      ),
    },
    {
      Header: 'Account ID',
      accessor: 'account_id',
    },
    {
      Header: 'URL',
      accessor: 'url',
    },
    {
      Header: 'User ID',
      accessor: 'user_id',
    },
    {
      Header: 'User Email',
      accessor: 'email',
    },
    {
      Header: 'Confidence',
      accessor: 'confidence',
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      formatter: value => getFormattedLocalDate(value),
    },
    {
      Header: 'Modified At',
      accessor: 'modified_at',
      formatter: value => getFormattedLocalDate(value),
    },
    {
      Header: 'Locked',
      accessor: 'locked',
      Cell: ({ value }) => (value === 'true' ? <FAIcon name="farCheck" color="green" /> : null),
    },
  ];
  return (
    <Panel header="Artist Links Details">
      {!artist_links_details || !artist_links_details.length ? (
        <Empty />
      ) : (
        <AntdTable data={artist_links_details} columns={columns} />
      )}
    </Panel>
  );
}

export default ArtistLinksDetails;
