import React, { useEffect } from 'react';
import { useGetLinksByUserDetails } from '../../../services/user';

import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import {
  generateCmUrl,
  getDateFromTimestamp,
  getFormattedDateString,
  getFormattedLocalDate,
} from '@Utils';
import FAIcon from '@Components/ui/fa-icons';
import { capitalize } from 'lodash';
import { Typography } from 'antd';
import { Panel } from '@/ui';

const LINKS_BY_USER_DEFAULT_DAYS_AGO = {
  FROM: 9999,
  TO: 0,
};

export function LinksByUserDetails({ q }) {
  const { data, isLoading, refetch } = useGetLinksByUserDetails({
    data: {
      q,
      fromDaysAgo: LINKS_BY_USER_DEFAULT_DAYS_AGO.FROM,
      toDaysAgo: LINKS_BY_USER_DEFAULT_DAYS_AGO.TO,
    },
  });

  useEffect(() => {
    refetch();
  }, [q]);

  const COLUMNS: Column<any>[] = [
    {
      Header: 'Social Type',
      accessor: 'type',
      formatter: capitalize,
    },
    {
      Header: 'Artist',
      accessor: 'artist',
      Cell: ({ original, value }) => (
        <Typography.Link
          target="_blank"
          href={generateCmUrl('artist', {
            id: original.artist_id,
          })}
        >
          {value}
        </Typography.Link>
      ),
    },
    {
      Header: 'URL',
      accessor: 'url',
      Cell: ({ value }) => <Typography.Link href={value}>{value}</Typography.Link>,
    },
    {
      Header: 'Date Added',
      accessor: 'date_added',
      formatter: getFormattedLocalDate,
    },
    {
      Header: 'Date Modified',
      accessor: 'date_modified',
      formatter: getFormattedLocalDate,
    },
    {
      Header: 'Locked',
      accessor: 'locked',
      Cell: ({ value }) => value && <FAIcon name={value && 'fasCheck'} />,
    },
  ];

  return (
    <Panel header="All Links Activated By User">
      <AntdTable data={data} columns={COLUMNS} loading={isLoading} />
    </Panel>
  );
}
