import { useCallback, useMemo } from 'react';
import { mutateToUrl, persistGetToUrl } from './helpers';
import { capitalize } from 'lodash';
import { ARTIST_TAG_MAPPER } from '@Utils/constants';

const ENDPOINT = {
  UPDATE: '/admin/track/:id/mood',
};

interface UpdateTrackMoodOptions {
  path: {
    id: number;
  };
  query: {
    name: string;
    type: string;
  };
}

export const useDeleteTrackMood = mutateToUrl<UpdateTrackMoodOptions>('DELETE', ENDPOINT.UPDATE);
export const useInsertTrackMood = mutateToUrl<UpdateTrackMoodOptions>('POST', ENDPOINT.UPDATE);
