export const PLATFORMS = [
  'chartmetric',
  'spotify',
  'applemusic',
  'itunes',
  'amazon',
  'deezer',
  'youtube',
  'tiktok',
  'soundcloud',
  'pandora',
  'genius',
  'shazam',
  'line',
  'melon',
  'anghami',
  'instagram',
  'facebook',
  'twitch',
  'youtubeforartist',
  'twitter',
  'wikipedia',
  'beatport',
  'acr',
  'radio',
  'qq',
  'siriusxm',
  'bandsintown',
  'boomplay',
  'tencent',
  'netease',
  'reddit',
  'pinterest',
  'songkick',
  'tunefind',
] as const;

export const PLAYLIST_PLATFORMS = ['spotify', 'amazon', 'deezer', 'youtube', 'applemusic'] as const;
export const Platforms = {
  Chartmetric: 'Chartmetric',
  Spotify: 'Spotify',
  AppleMusic: 'AppleMusic',
  Itunes: 'iTunes',
  Amazon: 'Amazon',
  Deezer: 'Deezer',
  YouTube: 'YouTube',
  TikTok: 'TikTok',
  SoundCloud: 'SoundCloud',
  Pandora: 'Pandora',
  Genius: 'Genius',
  Shazam: 'Shazam',
  Line: 'Line',
  Melon: 'Melon',
  Anghami: 'Anghami',
  Instagram: 'Instagram',
  Facebook: 'Facebook',
  Twitch: 'Twitch',
  YouTubeForArtist: 'YouTubeForArtist',
  Twitter: 'Twitter',
  Wikipedia: 'Wikipedia',
  Beatport: 'Beatport',
  ACR: 'AirPlay',
  Radio: 'Radio',
  QQ: 'QQ',
  SiriusXM: 'SiriusXM',
  Bandsintown: 'Bandsintown',
  Boomplay: 'Boomplay',
  Tencent: 'Tencent',
  Netease: 'Netease',
  Reddit: 'Reddit',
  Pinterest: 'Pinterest',
  Songkick: 'Songkick',
  Tunefind: 'Tunefind',
};
