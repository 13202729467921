import React, { useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import {
  useDeleteCompanyUnivUser,
  useGetCompanyUnivPools,
  useUpdateCompanyUnivUser,
} from '@Services/userPool';
import DataTableSearch from '../../../shared/Table/SearchInput';
import { getStripeCustomerUrl } from '../../../utilities';
import MembersModal from './MembersModal';
import CreateUserPoolModal from './CreateUserPoolModalButton';
import EditAddressButton from './EditAddressModal';
import FAIcon from '@Components/ui/fa-icons';
import { getDomainStr, truncatedAddress } from './utils';
import { useNavigate } from 'react-router-dom';
import { AntdTable } from '@Shared';
import { Button, Flex, Input, ModalButton, ModalClose, Panel, Popconfirm } from '@/ui';
import { Table } from '@Components';
import { AntdIcon } from '@Shared/Style';

function ActionCell({ original, refetch }) {
  const { execute: deleteOrgUser, isLoading: isLoadingDeleteOrgUser } = useDeleteCompanyUnivUser();
  const { execute: updateOrgUser, isLoading: isLoadingUpdateOrgUser } = useUpdateCompanyUnivUser();
  const { id } = original;
  const navigate = useNavigate();
  const [name, setName] = useState(original.name);
  const [domains, setDomains] = useState(
    original.domains.replace('{', '').replace('}', '').split(',').join(', ')
  );
  const [stripeId, setStripeId] = useState(original.stripe_customer_id);

  const handleDeleteOrgUser = id => deleteOrgUser({ path: { id } }).then(() => refetch());
  const handleEditOrgUser = (id, name) => {
    updateOrgUser({ data: { name }, path: { id } }).then(() => refetch());
  };

  return (
    <Flex gap={2} justify="center">
      <MembersModal id={id} />
      <ModalButton
        trigger={
          <Button
            loading={isLoadingUpdateOrgUser}
            icon={<FAIcon name="farEdit" />}
            variant="outline"
            disabled={isEmpty(name)}
          />
        }
        header="Edit Organization User"
        footer={
          <ModalClose asChild onClick={() => handleEditOrgUser(id, name)}>
            <Button
              loading={isLoadingUpdateOrgUser}
              variant="submit"
              icon={<FAIcon name="farEdit" />}
            >
              Edit
            </Button>
          </ModalClose>
        }
      >
        <Table
          borderless
          options={[
            {
              label: 'ID',
              component: (
                <Input prefix={<AntdIcon name="tag" />} placeholder="ID" value={id} disabled />
              ),
            },
            {
              label: 'Name',
              component: (
                <Input
                  prefix={<AntdIcon name="user" />}
                  placeholder="Organization Name"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              ),
            },
            {
              label: 'Domains',
              component: (
                <Input
                  placeholder="Email domains (comma separated)"
                  value={domains}
                  onChange={e => setDomains(e.target.value)}
                  disabled
                />
              ),
            },
            {
              label: 'Stripe Customer ID',
              component: (
                <Input
                  placeholder="Stripe Customer ID"
                  value={stripeId}
                  onChange={e => setStripeId(e.target.value)}
                  disabled
                />
              ),
            },
          ]}
        />
      </ModalButton>
      <Popconfirm
        header="Warning"
        message="Are you sure you want to delete this organization user?"
        onConfirm={() => handleDeleteOrgUser(id)}
      >
        <Button
          variant="destructive"
          loading={isLoadingDeleteOrgUser}
          icon={<FAIcon name="farTrash" />}
        />
      </Popconfirm>
    </Flex>
  );
}

function CompanyUnivAccessTable() {
  const { data, isLoading, refetch } = useGetCompanyUnivPools();
  const [filteredData, setFilteredData] = useState(data);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const POOL_COLUMNS = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'id',
        width: 50,
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Domain',
        accessor: 'domains',
        Cell: ({ original, value }) => {
          const domains = getDomainStr(value);
          return (
            <Flex justify="end" align="center" gap={1}>
              {truncatedAddress(domains)}
              <EditAddressButton type="domain" id={original.id} addresses={domains} />
            </Flex>
          );
        },
      },
      {
        Header: 'IP Range',
        accessor: 'ip_ranges',
        Cell: ({ value, original }) => (
          <Flex justify="end" align="center" gap={1}>
            {truncatedAddress(value)}
            <EditAddressButton type="ip" id={original.id} addresses={value} />
          </Flex>
        ),
      },
      {
        Header: 'Stripe Customer ID',
        accessor: 'stripe_customer_id',
        Cell: ({ value, original }) => (
          <a href={getStripeCustomerUrl(value)} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        ),
      },
      {
        Header: 'Actions',
        Cell: ({ original }) => <ActionCell original={original} refetch={refetch} />,
        width: 200,
      },
    ],
    [refetch, data]
  );

  return (
    <>
      <Panel
        icon="farBuildingUser"
        header="Company-wide/University Access Organizations"
        aside={
          <Flex align="center" gap={1}>
            <CreateUserPoolModal />
          </Flex>
        }
      >
        <DataTableSearch onChange={setFilteredData} originalData={data ?? []} />
        <AntdTable loading={isLoading} data={filteredData ?? []} columns={POOL_COLUMNS} />
      </Panel>
    </>
  );
}

export default CompanyUnivAccessTable;
