import useURLParams from '@Hooks/useURLParams';
import { useGetEntity } from '@Services/search';
import { GlobalSearch } from '@Components';
import { AntdIcon, Panel, Table } from '@Shared/Style';
import Country from '@Components/Country';
import { CAREER_STAGE_MAPPER } from '@Utils/constants';
import { Card, Divider, Image, Typography } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import MoodPanel from '@Shared/MoodPanel/MoodPanel';
import { generateCmUrl, generateInternalLink } from '@Utils';
import { Link } from 'react-router-dom';
import FAIcon from '@Components/ui/fa-icons';
import { Button, Flex, H4, Muted } from '@/ui';
import EntityCell from '@Shared/Table/EntityCell';
import { EditGenrePanel } from '@Components';

const EditTagAndMood = () => {
  const {
    setParams,
    params: { q },
  } = useURLParams();

  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <Flex vertical gap={4}>
      <Flex gap={2} vertical>
        <div>
          <H4 className="flex flex-row items-center gap-2">
            Edit
            {data && ` ${data.name}`} Genre & Mood
          </H4>
          <Muted>Search for an artist or track to edit tags and moods</Muted>
        </div>
        <GlobalSearch
          types={['artist', 'track']}
          className="top-search-box"
          icon="farEdit"
          loading={isFetching}
          onSubmit={q => setParams({ q: q as string })}
          defaultValue={q}
        />
      </Flex>

      {data?.type === 'artist' && (
        <EditGenrePanel entity="artist" id={data.id} tags={data.tags} callback={refetch} />
      )}
      {data?.type === 'track' && (
        <>
          <EditGenrePanel
            entity="track"
            id={data.id}
            tags={data.tags.map(({ id, name }) => ({
              name,
              id,
              type: 'main',
              hidden: false,
            }))}
            callback={refetch}
          />
          <MoodPanel id={data.id} moods={data.moods} callback={refetch} />
        </>
      )}
    </Flex>
  );
};

export default EditTagAndMood;
