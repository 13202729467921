import { SearchInput } from '@Shared';
import { Flex, Select, Checkbox, Radio, Empty, Avatar, Typography, Spin } from 'antd';
import React, { useRef, useState } from 'react';
import {
  AutoSizer,
  List as VirtualList,
  CellMeasurer,
  CellMeasurerCache,
  ListRowProps,
} from 'react-virtualized';
import { useTagList } from '@Services/tag';
import { capitalize } from 'lodash';
import FAIcon from '@Components/ui/fa-icons';

interface Props {
  onChange: (value: number, checked: boolean) => void;
  values: number[];
}

export default function GenreList({ onChange, values }: Props) {
  const { tagMap, tagOptions, isLoading } = useTagList();

  const [genreType, setGenreType] = useState<'main' | 'sub' | 'mainV2'>('mainV2');

  const originalData = tagMap[genreType]?.map(e => ({ ...e, type: genreType })) ?? [];

  const [filteredData, setFilteredData] = useState(originalData);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 100,
    })
  );

  const rowRenderer = ({ index, key, style, parent }: ListRowProps) => {
    const item = filteredData[index];
    if (!item) return null;

    return (
      <CellMeasurer
        cache={cache.current}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => (
          <div style={style} onLoad={measure}>
            <Flex
              className="hoverable"
              style={{ padding: '10px 0px', cursor: 'pointer' }}
              gap="small"
              onClick={() => onChange(item.id, !Boolean(values.find(value => value === item.id)))}
            >
              <Checkbox
                checked={Boolean(values.find(value => value === item.id))}
                value={item.id}
              />
              {capitalize(item.name)}
            </Flex>
          </div>
        )}
      </CellMeasurer>
    );
  };

  if (isLoading)
    return (
      <Flex justify="center" align="center" style={{ height: '100%' }}>
        <Spin tip="Loading" size="large" />
      </Flex>
    );

  return (
    <>
      <Flex gap="small" style={{ width: '100%' }}>
        <Select
          style={{ width: 200 }}
          options={tagOptions}
          onChange={setGenreType}
          value={genreType}
          size="large"
          suffixIcon={<FAIcon name="farBarsFilter" />}
        />
        <SearchInput
          originalData={originalData}
          onChange={value => setFilteredData(value!)}
          size="large"
        />
      </Flex>
      <div style={{ minHeight: '100%' }}>
        {filteredData?.length > 0 ? (
          <AutoSizer>
            {({ width, height }) => (
              <VirtualList
                style={{ padding: 8 }}
                height={height}
                deferredMeasurementCache={cache.current}
                rowHeight={cache.current.rowHeight}
                rowCount={filteredData.length}
                width={width}
                rowRenderer={rowRenderer}
                overscanRowCount={5}
              />
            )}
          </AutoSizer>
        ) : (
          <Empty description="No Genre Found" style={{ marginTop: '24px' }} />
        )}
      </div>
    </>
  );
}
