'use client';

import * as React from 'react';
import { CheckIcon } from '@radix-ui/react-icons';
import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';

import { cn } from '@/lib/utils';
import { Label } from './label';

const RadioGroupRoot = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return <RadioGroupPrimitive.Root className={cn('grid gap-2', className)} {...props} ref={ref} />;
});
RadioGroupRoot.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    // @ts-ignore
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        'aspect-square h-4 w-4 rounded-full border border-primary text-primary shadow focus:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <CheckIcon className="h-3.5 w-3.5 fill-primary" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

// =========================================================================================================
interface RadioGroupOption<T> {
  value: T;
  label: React.ReactNode;
}

interface RadioGroupProps<T> {
  options: RadioGroupOption<T>[];
  value: T;
  onValueChange: (value: T) => void;
}

const Item = ({ value, label }: RadioGroupOption<any>) => {
  const id = React.useId();

  return (
    <div className="flex items-center gap-2">
      <RadioGroupItem value={value} id={id} />
      <Label htmlFor={id}>{label}</Label>
    </div>
  );
};

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  Omit<React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>, 'value' | 'onValueChange'> &
    RadioGroupProps<any>
>(({ options, onValueChange, ...props }, ref) => {
  return (
    <RadioGroupRoot {...props} ref={ref} onValueChange={onValueChange}>
      {options.map(option => (
        <Item key={option.value} {...option} />
      ))}
    </RadioGroupRoot>
  );
});

// =========================================================================================================

interface RadioProps {
  checked: boolean;

  children?: React.ReactNode;
}

const Radio = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  Omit<
    React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>,
    'checked' | 'onValueChange'
  > &
    RadioProps
>(({ checked, children, ...props }, ref) => {
  const id = React.useId();

  return (
    <RadioGroupRoot {...props} ref={ref} value={checked ? id : ''}>
      <Item value={id} label={children} />
    </RadioGroupRoot>
  );
});

export { RadioGroupRoot, RadioGroupItem, RadioGroup, Radio };
