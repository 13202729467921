'use client';

import React from 'react';
import { Check, ChevronsUpDown, MenuIcon, PanelsTopLeft } from 'lucide-react';
import { Menu } from './menu';
import { Button } from '../button';
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from '../sheet';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu';
import { useStore } from 'zustand';
import { useSidebarToggle } from '@Hooks/layout/useSidebar';
import GroupDropdown from './group-dropdown';

export function SheetMenu() {
  return (
    <Sheet>
      <SheetTrigger className="lg:hidden" asChild>
        <Button className="h-8" variant="outline" size="icon">
          <MenuIcon size={20} />
        </Button>
      </SheetTrigger>
      <SheetContent className="sm:w-72 px-3 h-full flex flex-col pt-10" side="left">
        <SheetHeader>
          <GroupDropdown />
          <Button
            className="flex justify-center items-center pb-2 pt-1"
            variant="link"
            asChild
          ></Button>
        </SheetHeader>
        <Menu isOpen />
      </SheetContent>
    </Sheet>
  );
}
