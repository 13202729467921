import React, { useState } from 'react';
import { capitalize } from 'lodash';
import { capitalizeEveryWord } from '../../../utilities';
import { InternalTagType, Tag, useUpdateTag } from '@Services/tag';
import FAIcon from '@Components/ui/fa-icons';
import { Button, ModalButton, ModalClose, Select } from '@/ui';

function AddTagModal({
  entity,
  internalTagType,
  entityId,
  callback,
  list,
}: {
  entity: 'artist' | 'track';
  list: Tag[];
  internalTagType: InternalTagType;
  entityId: number;
  callback: () => void;
}) {
  const { getLinkType, handleAddArtistGenreTag, isAdding } = useUpdateTag(
    entity,
    entityId,
    callback
  );
  const [newTag, setTag] = useState<number>();

  if (internalTagType === 'others' || (internalTagType === 'main' && entity === 'artist'))
    return null;

  return (
    <ModalButton
      header={`Add ${capitalizeEveryWord(internalTagType.replace('_', ' '))} Tag`}
      trigger={<Button variant="outline" icon={<FAIcon name="fasPlus" />} />}
      footer={
        <ModalClose asChild>
          <Button
            disabled={!newTag}
            onClick={() => handleAddArtistGenreTag(newTag as number)}
            loading={isAdding}
            icon={<FAIcon name="fasPlus" />}
            variant="submit"
          >
            Add
          </Button>
        </ModalClose>
      }
    >
      <Select
        options={list.map(tag => ({
          label: capitalizeEveryWord(tag.name),
          value: tag.id,
        }))}
        onChange={(value: number) => setTag(value)}
        placeholder={`Select`}
      />
      {newTag && (
        <p className="mt-2 text-sm text-muted-foreground">Link type: {getLinkType(newTag)}</p>
      )}
    </ModalButton>
  );
}

export default AddTagModal;
