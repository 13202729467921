import {
  Badge,
  Button,
  Card,
  CardContent,
  Flex,
  Muted,
  Panel,
  Select,
  Skeleton,
  Textarea,
} from '@/ui';
import GlobalSearch from '@Components/global-search/GlobalSearch';
import FAIcon from '@Components/ui/fa-icons';
import {
  useAddUserFollowedArtist,
  useAddUserViewableArtist,
  useBatchFollow,
  useDeleteUserFollowedArtist,
  useDeleteUserViewableArtist,
  useGetUserFollowedArtist,
  useGetUserViewableArtist,
} from '@Services/user';
import React, { useEffect } from 'react';
import uuid4 from 'uuid4';
import { useUserProfileContext } from './useUserProfileContext';

function UserArtists({
  userId: id,
  useGet,
  useAdd,
  useDelete,
  title,
}: {
  userId: number;
  useGet: any;
  useAdd: any;
  useDelete: any;
  title: string;
}) {
  const { data, refetch, isLoading } = useGet({ path: { id } });
  const { execute: addArtist, isLoading: isFetchingAdd } = useAdd();
  const { execute: deleteArtist, isLoading: isFetchingDelete } = useDelete();

  useEffect(() => {
    refetch();
  }, [id]);

  const addNewArtist = url => {
    addArtist({
      data: { cm_artist: url.split('/artist/')[1].split('/about')[0] },
      path: { id },
    }).then(() => refetch());
  };
  const removeArtist = artist => {
    deleteArtist({
      path: { id },
      data: { cm_artist: artist.cm_artist },
    }).then(() => refetch());
  };

  return (
    <Panel header={title}>
      <GlobalSearch
        loading={isFetchingAdd}
        icon="farEdit"
        types={['artist']}
        onSubmit={addNewArtist}
        name="artist-search"
        size="middle"
        buttonIcon="farPlus"
        buttonLabel="Add Artist"
      />
      <Flex wrap gap={4}>
        {isLoading ? (
          <Flex gap={2} wrap>
            {Array.from({ length: 5 }).map((_, i) => (
              <Skeleton key={uuid4()} className="w-20 h-6" />
            ))}
          </Flex>
        ) : data?.length ? (
          data?.map((artist: any) => (
            <a target="_blank" href={`https://app.chartmetric.com/artist?id=${artist.cm_artist}`}>
              <Card key={artist.cm_artist} className="gap-2 flex flex-row items-center px-4 py-2">
                <div>
                  {`${artist.name}`}
                  <Muted>{`(${artist.cm_artist})`}</Muted>
                </div>
                <Button
                  size="icon"
                  variant="ghost"
                  className="ml-4"
                  loading={isFetchingDelete}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    removeArtist(artist);
                  }}
                  icon={<FAIcon name="farTrash" />}
                />
              </Card>
            </a>
          ))
        ) : (
          <Flex justify="center" align="center" style={{ width: '100%' }}>
            <Muted>No Data Found</Muted>
          </Flex>
        )}
      </Flex>
    </Panel>
  );
}

const FollowEntityBatch = ({ userId }: { userId: number }) => {
  const { execute: batchFollow, isLoading: isFetchingBatchFollow } = useBatchFollow();
  const { refetch } = useUserProfileContext();

  const handleClick = () => {
    if (type === '') {
      window.alert('Please select a type to follow');
    } else {
      batchFollow({
        query: { id: userId, ids, type },
      }).then(() => refetch());
    }
  };
  const [ids, setIds] = React.useState('');
  const [type, setType] = React.useState('artists');
  const cmTypes = [
    { label: 'Artists', value: 'artists' },
    { label: 'Tracks', value: 'tracks' },
    { label: 'Albums', value: 'albums' },
  ];
  return (
    <Panel
      header="Follow Entity Batch"
      description="Artists / Tracks / Albums to follow (Chartmetric IDs seperated by comma)"
    >
      <Textarea
        rows={10}
        cols={100}
        name="follow"
        value={ids}
        onChange={e => setIds(e.target.value)}
      />
      <Flex justify="center" gap={2}>
        <Select
          className="w-auto"
          options={cmTypes}
          onChange={e => {
            setType(e);
          }}
          value={type}
        />
        <Button
          icon={<FAIcon name="farPlus" />}
          onClick={() => handleClick()}
          loading={isFetchingBatchFollow}
        >
          Batch Follow
        </Button>
      </Flex>
    </Panel>
  );
};

export function FollowedArtist({ userId: id }: { userId: number }) {
  return (
    <>
      <UserArtists
        title="Followed Artists"
        userId={id}
        useGet={useGetUserFollowedArtist}
        useAdd={useAddUserFollowedArtist}
        useDelete={useDeleteUserFollowedArtist}
      />
      <FollowEntityBatch userId={id} />
    </>
  );
}

export function ViewableArtist({ userId: id }: { userId: number }) {
  return (
    <UserArtists
      title="Viewable Artists (Artist Plan)"
      userId={id}
      useGet={useGetUserViewableArtist}
      useAdd={useAddUserViewableArtist}
      useDelete={useDeleteUserViewableArtist}
    />
  );
}
