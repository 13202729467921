import React from 'react';

import FAIcon, { FAIcons } from './ui/fa-icons';
import { TabsContent, TabsList, TabsRoot, TabsTrigger } from '@Components/ui/tabs';

interface Props {
  options: {
    title: string;
    content: React.ReactNode;
    icon?: FAIcons;
  }[];
  className?: string;
}

function Tabs({ options, className }: Props) {
  const [activeTab, setActiveTab] = React.useState(options[0].title);

  const handleChange = (key: string) => {
    window.history.pushState({}, '', `?${window.location.search}`);
    window.location.hash = key.toLowerCase().replace('/', '-').replaceAll(' ', '-');
    setActiveTab(key);
  };

  React.useEffect(() => {
    const { hash } = window.location;
    const tabHash = hash.replace('#', '').replaceAll('-', ' ').replace('/', '-').split('?')[0];
    const tabOriginalName = options.find(tab => tab.title.toLowerCase() === tabHash);
    setActiveTab(tabOriginalName?.title || options[0].title);
  }, [window.location.hash]);

  return (
    <TabsRoot value={activeTab}>
      <TabsList className={className}>
        {options.map((item, i) => (
          <TabsTrigger key={i} value={item.title} onClick={() => handleChange(item.title)}>
            {item.icon && <FAIcon name={item.icon} className="text-lg mr-2" />}
            {item.title}
          </TabsTrigger>
        ))}
      </TabsList>
      {options.map((item, i) => (
        <TabsContent key={i} value={item.title}>
          {item.content}
        </TabsContent>
      ))}
    </TabsRoot>
  );
}

export default Tabs;
