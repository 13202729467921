import React, { useEffect, useState } from 'react';
import { Upload } from 'antd';
import type { GetProp, UploadFile, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadListType } from 'antd/es/upload/interface';

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const ImageCropper = ({
  defaultImageUrl,
  onChangeFile,
  listType = 'picture-circle',
}: {
  defaultImageUrl?: string;
  onChangeFile?: (file?: FileType) => void;
  listType?: UploadListType;
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>();

  useEffect(() => {
    if (defaultImageUrl)
      setFileList([
        {
          uid: '-1',
          name: 'image.png',
          status: 'done',
          url: defaultImageUrl,
        },
      ]);
  }, [defaultImageUrl]);

  const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as FileType);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <ImgCrop rotationSlider aspectSlider>
      <Upload
        multiple={false}
        listType={listType}
        fileList={fileList?.map(e => ({ ...e, status: 'done' }))}
        onChange={onChange}
        onPreview={onPreview}
        customRequest={async ({ file, onSuccess }) => {
          onChangeFile?.(file as FileType);
          Promise.resolve();
        }}
        onRemove={() => {
          setFileList(undefined);
          onChangeFile?.(undefined);
        }}
      >
        {!fileList?.length && '+ Upload'}
      </Upload>
    </ImgCrop>
  );
};

export { ImageCropper };
