import React, { useState, useEffect } from 'react';

import { useUserPermission, TeamPermissionsTable, UserPermission } from '@Services/Onesheet/team';
import { AntdIcon, Panel } from '@Shared/Style';
import { Form, Popconfirm, Button, Modal, Input, Checkbox, Flex, Divider, Typography } from 'antd';
import { Icon } from 'semantic-ui-react';
import { AntdTable } from '@Shared';

function Permission({
  userId,
  teamId,
  withoutHeader,
}: {
  userId?: string;
  teamId?: string;
  withoutHeader?: boolean;
}) {
  const { fetching, loading, data, refetch, deletePermission, addPermission } =
    useUserPermission(userId);
  const [form] = Form.useForm<Omit<TeamPermissionsTable, 'id'>>();
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setDisabled(false))
      .catch(() => setDisabled(true));
  }, [form, values]);

  const onSubmitAdd = () =>
    addPermission({
      ...form.getFieldsValue(),
      userId: userId!,
      teamId: teamId!,
    }).then(() => {
      form.resetFields();
      setOpen(false);
      refetch();
    });

  const render = () => (
    <>
      <AntdTable
        data={data}
        loading={loading}
        columns={[
          //   {
          //     accessor: 'type',
          //     Header: 'Type',
          // },
          {
            accessor: 'typeId',
            Header: 'Folder ID',
          },
          {
            accessor: 'folderName',
            Header: 'Name',
          },
          {
            accessor: 'readFile',
            Header: 'Read File',
            Cell: ({ value }) =>
              value ? <Icon color="blue" name="check" /> : <Icon color="red" name="x" />,
            width: 70,
          },
          {
            accessor: 'writeFile',
            Header: 'Write File',
            Cell: ({ value }) =>
              value ? <Icon color="blue" name="check" /> : <Icon color="red" name="x" />,
            width: 70,
          },
          {
            accessor: 'permissionId',
            Header: 'Delete',
            Cell: ({ value }) => (
              <Popconfirm
                title="Be careful this action"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  deletePermission(value).then(() => refetch());
                }}
                description="Are you sure you want to delete this permission?"
              >
                <Button loading={fetching} className="red" icon={<AntdIcon name="delete" />} />
              </Popconfirm>
            ),
            width: 70,
          },
        ]}
      />
      <Modal
        title="Add Permission"
        open={open}
        cancelText="Close"
        okText="Add"
        okButtonProps={{
          disabled,
          loading: fetching,
        }}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={onSubmitAdd}
      >
        {renderAddForm()}
      </Modal>
    </>
  );

  const renderAddForm = () => (
    <Form
      form={form}
      name="basic"
      initialValues={
        {
          readFile: true,
          writeFile: false,
          typeId: '',
          type: 'Folder',
        } as Omit<TeamPermissionsTable, 'id'>
      }
      onFinish={() => {}}
      autoComplete="off"
    >
      {/* <Form.Item
          label="Type"
          name="type"
          labelCol={{ span: 5 }}
          rules={[{ type: 'string', required: true, message: 'Please input type' }]}
        >
          <Input name="type" variant="filled" placeholder="Enter type" type="string" />
        </Form.Item> */}
      <Form.Item
        // label={values?.type === 'Folder' ? 'Folder ID' : 'Type ID'}
        label="Folder ID"
        name="typeId"
        labelCol={{ span: 5 }}
        rules={[{ type: 'string', required: true, message: 'Please input type ID' }]}
      >
        <Input name="typeId" variant="filled" placeholder="Enter type ID" type="string" />
      </Form.Item>
      <Form.Item
        label="Read File"
        name="readFile"
        valuePropName="checked"
        labelCol={{ span: 5 }}
        rules={[{ type: 'boolean', required: true, message: 'Please input read file' }]}
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        label="Write File"
        name="writeFile"
        valuePropName="checked"
        labelCol={{ span: 5 }}
        rules={[{ type: 'boolean', required: true, message: 'Please input type' }]}
      >
        <Checkbox />
      </Form.Item>
    </Form>
  );

  return withoutHeader ? (
    <Flex vertical gap="small">
      {render()}
      <Divider style={{ marginBottom: 4 }} />
      <Typography.Title style={{ marginTop: 0 }} level={5}>
        Add Folder Permission
      </Typography.Title>
      {renderAddForm()}
      <Button className="green" disabled={disabled} loading={fetching} onClick={onSubmitAdd}>
        Add Folder Permission
      </Button>
    </Flex>
  ) : (
    <Panel
      innerStyle
      title="Permissions"
      extra={
        userId &&
        teamId && (
          <Button className="green" icon={<AntdIcon name="plus" />} onClick={() => setOpen(true)}>
            Add
          </Button>
        )
      }
    >
      {render()}
    </Panel>
  );
}

export default Permission;
