import React, { useState } from 'react';
import { capitalize, isEmpty } from 'lodash';
import { Table } from '../../../../shared/Style';
import { generateCmUrl } from '../../../../utilities';
import { useGetEntity } from '@Services/search';
import { EditEntityProps } from '..';
import { Avatar, Button, Flex, Input, Typography } from 'antd';
import { useUpdateAlbum } from '@Services/meta';

function Info({ q }: EditEntityProps) {
  const { data, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );
  const { execute, isLoading } = useUpdateAlbum();
  const [label, setLabel] = useState(data.label);

  return (
    <Table
      layout="vertical"
      panelStyle
      title="Basic Information"
      options={[
        {
          label: 'Name',
          component: (
            <Flex gap="small" align="center">
              <Avatar src={data.image_url} size="large" shape="square" style={{ marginRight: 6 }} />
              <Typography.Link
                target="_blank"
                rel="noopener noreferrer"
                href={generateCmUrl('album', { id: data.id })}
              >
                {data.name}
              </Typography.Link>{' '}
            </Flex>
          ),
          labelIcon: 'tag',
        },
        {
          label: 'UPC',
          value: data.upc,
          labelIcon: 'tag',
        },
        {
          labelIcon: 'farCompactDisc',
          label: 'Number of Tracks',
          value: data.num_track,
        },
        {
          labelIcon: 'fadMicrophoneStand',
          label: 'Artists',
          component: data.artist_name?.map((name, index) => (
            <a
              key={data.cm_artist[index]}
              target="_blank"
              rel="noopener noreferrer"
              href={generateCmUrl('artist', { id: data.cm_artist[index] })}
              style={{ marginRight: 6 }}
            >
              {capitalize(name)}
              {index !== (data.artist_name?.length ?? 0) - 1 && ', '}
            </a>
          )),
        },
        {
          label: 'Label',
          labelIcon: 'album',
          component: (
            <Flex gap="small" justify="space-between">
              <Input
                style={{ maxWidth: '300px' }}
                value={data.label}
                onChange={e => {
                  setLabel(e.target.value);
                }}
              />
              <Button
                loading={isLoading}
                className="green"
                onClick={() => {
                  execute({ query: { id: data.id }, data: { label } }).then(() => refetch());
                }}
              >
                Update
              </Button>
            </Flex>
          ),
        },
      ]}
    />
  );
}

export default Info;
