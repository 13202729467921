import { BrandIcon } from '@Shared/Style';
import { type } from 'os';
import React from 'react';
import { LinkCompletenessOptions } from '@Services/linkCompleteness';
import { PLATFORM_FILTERS, SUPPORT_PLATFORMS } from '../../constants';
import { Flex, MultiSelect, Muted, Select } from '@/ui';

interface Props {
  type: 'artist' | 'track';
  onChange: (key: string, value: any) => void;
  state: LinkCompletenessOptions;
}

const Filters = ({ type, onChange, state }: Props) => {
  const renderPlatformLabel = (platform: string, has?: boolean) => {
    return (
      <Flex
        gap={2}
        style={{ color: has === undefined ? 'black' : has ? 'blue' : 'red' }}
        align="center"
      >
        {has === undefined ? 'ALL' : has ? 'HAS' : 'NO'} <BrandIcon platform={platform as any} />
      </Flex>
    );
  };

  const renderLabel = (label: string, positive: boolean) => {
    return (
      <Flex gap={2} style={{ color: positive ? 'blue' : 'red' }}>
        {label}
      </Flex>
    );
  };
  return (
    <Flex vertical align="end">
      <Muted>Filters</Muted>
      <Flex gap={2} wrap justify="end">
        <Select
          placeholder="Filter By Reviewed"
          className="w-[160px]"
          onChange={value => {
            onChange('completed', value);
          }}
          value={state.completed}
          options={[
            {
              label: 'All Status',
              value: 'all',
            },
            {
              label: renderLabel('Linked', true),
              value: true,
            },
            {
              label: renderLabel('Not Linked', false),
              value: false,
            },
          ]}
        />
        {Object.keys(PLATFORM_FILTERS)
          .filter(p => SUPPORT_PLATFORMS[type].includes(p))
          .map(key => (
            <Select
              placeholder={
                <Flex gap={2}>
                  Filter by <BrandIcon platform={key as any} />
                </Flex>
              }
              className="w-[160px]"
              onChange={value => {
                onChange(PLATFORM_FILTERS[key], value);
              }}
              value={state[PLATFORM_FILTERS[key]]}
              options={[
                {
                  label: renderPlatformLabel(key, undefined),
                  value: 'all',
                },
                {
                  label: renderPlatformLabel(key, true),
                  value: true,
                },
                {
                  label: renderPlatformLabel(key, false),
                  value: false,
                },
              ]}
            />
          ))}
        <MultiSelect
          placeholder="Filter By Tier"
          className="w-[160px]"
          // @ts-expect-error
          value={state.tier}
          onValueChange={value => {
            if (value === null) {
              onChange('tier', undefined);
              return;
            }
            onChange('tier', value);
          }}
          // @ts-expect-error
          options={[1, 2, 3, 4, 5].map(tier => ({
            label: `Tier ${tier}`,
            value: tier,
          }))}
        />
      </Flex>
    </Flex>
  );
};

export default Filters;
