import { mutateToUrl, persistGetToUrl } from './helpers';
import { capitalize } from 'lodash';

const ENDPOINT = {
  KEYWORDS: '/admin/keywords',
  TRENDING_KEYWORDS: '/admin/keywords/trending',
  SUGGESTION: '/admin/keywords/suggestion',
  DETAILS: (entity: string) => `/admin/keywords/${entity}/details`,
  COUNTS: '/admin/keywords/counts',
};

export interface TrendingKeywordsItem {
  TOKEN: string;
  TOKEN_PLAYLISTS_TODAY: number;
  TOKEN_PLAYLISTS_LAST_WEEK: number;
  TOKEN_PLAYLISTS_DIFF: number;
  TOKEN_PLAYLISTS_PERCENT_GROWTH: number;
  TOKEN_FOLLOWERS_TODAY: number;
  TOKEN_FOLLOWERS_LAST_WEEK: number;
  TOKEN_FOLLOWERS_DIFF: number;
  TOKEN_FOLLOWERS_PERCENT_GROWTH: number;
  TOP_PLAYLISTS_BY_FOLLOWERS_DIFF_RANK: {
    description: string;
    followers_diff: number;
    followers_perent_diff: number;
    name: string;
    playlist_id: string;
    spotify_playlist: number;
    image_url: string;
  }[];
  TOP_PLAYLISTS_BY_FOLLOWERS_PERCENT_DIFF_RANK: {
    description: string;
    followers_diff: number;
    followers_perent_diff: number;
    name: string;
    playlist_id: string;
    spotify_playlist: number;
    image_url: string;
  }[];
  TOP_TRACKS: {
    adds: number;
    artist_name: string;
    cm_artist: number;
    cm_track: number;
    track_name: string;
    image_url: string;
  }[];
  TOP_ARTISTS: {
    adds: number;
    artist_name: string;
    cm_artist: number;
    image_url: string;
  }[];
}

export type TrendingKeywordsSortBy =
  | 'followers'
  | 'playlists'
  | 'followers_diff'
  | 'playlists_diff'
  | 'followers_diff_percent'
  | 'playlists_diff_percent';

export const useGetTrendingKeywords = persistGetToUrl<
  {
    data: {
      limit: number;
      offset: number;
      sortBy: TrendingKeywordsSortBy;
      sortOrderDesc: boolean;
    };
  },
  TrendingKeywordsItem[]
>(ENDPOINT.TRENDING_KEYWORDS, {});

export const useGetKeywordSuggestions = persistGetToUrl<
  {
    data: {
      q: string;
      limit: number;
      offset: number;
    };
  },
  string[]
>(ENDPOINT.SUGGESTION, {});

export interface KeywordDetailArtistItem {
  adds: number;
  id: number;
  name: string;
  code2: string;
  image_url: string;
}

export const useGetKeywordArtistDetails = persistGetToUrl<
  {
    data: {
      q: string;
    };
  },
  KeywordDetailArtistItem[]
>(ENDPOINT.DETAILS('artist'), {});

export interface KeywordDetailTrackItem {
  adds: number;
  id: number;
  artist_id: number;
  name: string;
  artist_name: string;
  image_url: string;
}

export const useGetKeywordTrackDetails = persistGetToUrl<
  {
    data: {
      q: string;
    };
  },
  KeywordDetailTrackItem[]
>(ENDPOINT.DETAILS('track'), {});

export interface KeywordDetailPlaylistItem {
  id: number;
  spotify_id: string;
  name: string;
  description: string;
  followers_today: number;
  followers_last_week: number;
  followers_diff: number;
  followers_percent_diff: number;
  image_url: string;
}

export const useGetKeywordPlaylistDetails = persistGetToUrl<
  {
    data: {
      q: string;
      sortBy: 'followers' | 'followers_diff' | 'followers_percent';
    };
  },
  KeywordDetailPlaylistItem[]
>(ENDPOINT.DETAILS('playlist'), {});

export const useGetKeywordCounts = persistGetToUrl<
  {
    data: {
      q: string;
    };
  },
  {
    playlists_diff: number;
  }
>(ENDPOINT.COUNTS, {});