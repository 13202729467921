import { Select } from 'antd';
import React from 'react';
import { useEditArtistGenreBatch } from '../../Context';
import { COUNTRY_LIST } from '@Utils/constants';

const CountryFilter = () => {
  const {
    onChangeFilter,
    filters: { code2s },
  } = useEditArtistGenreBatch();

  const countryOptions = Object.entries(COUNTRY_LIST).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  return (
    <Select
      placeholder="Country"
      style={{ width: 200 }}
      options={countryOptions}
      value={code2s}
      onChange={onChangeFilter('code2s')}
      mode="multiple"
      filterOption={(input, option) =>
        JSON.stringify(option).toLowerCase().includes(input.toLowerCase())
      }
      showSearch
    />
  );
};

export default CountryFilter;
