import React, { useCallback, useEffect, useState } from 'react';
import { Input, Modal, Flex, Typography } from 'antd';
import { AntdIcon } from '../../../../shared/Style';
import useModal from '../../../../hooks/useModal';
import DataTableSearch from '../../../../shared/Table/SearchInput';
import {
  useAddCampaignContent,
  useDeleteCampaignContent,
  useGetCampaignContent,
  useEditCampaignContent,
} from '../../../../services/campaign';
import { useStates } from '@Hooks';
import { capitalize } from 'lodash';
import { Content } from '../types';
import { Button, Card, CardContent, CardHeader, H3, H4 } from '@/ui';
import FAIcon from '@Components/ui/fa-icons';
import ContentCard from './ContentCard';
import { AntdTable } from '@Shared';

interface Props {
  onSelect: (content?: Content) => void;
  value?: Content;
}

const SelectContent = ({ onSelect, closeModal }) => {
  const { data, refetch: getContent, isLoading } = useGetCampaignContent();
  const { isLoading: isLoadingDeleteContent, execute: deleteContent } = useDeleteCampaignContent();
  const {
    showModal,
    closeModal: closeEditModal,
    modalProps,
  } = useModal<{
    content: Content;
    id: number;
    callback: () => void;
  }>();

  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    getContent();
  }, []);

  const handleDelete = useCallback(id => {
    deleteContent({ path: { id } }).then(() => {
      getContent();
    });
  }, []);

  const handleSelect = useCallback(content => {
    onSelect(content);
    closeModal();
  }, []);

  const handleSearch = useCallback(filteredData => {
    setFilteredData(filteredData);
  }, []);

  const COLUMNS = [
    {
      Header: 'Id',
      accessor: 'id',
      width: 50,
    },
    {
      Header: 'Title',
      accessor: 'title',
      Cell: ({ value }) => <Input.TextArea value={value} />,
    },
    {
      Header: 'Sentence',
      accessor: 'sentence',
      Cell: ({ value }) => <Input.TextArea value={value} />,
    },
    {
      Header: 'Image',
      accessor: 'image_url',
      Cell: ({ value }) => value && <img width={100} src={value} alt="cover" />,
      width: 125,
    },
    {
      Header: 'Button Label',
      accessor: 'button_label',
      Cell: ({ value }) => <Input value={value} />,
      width: 100,
    },
    {
      Header: 'Action',
      Cell: ({ original }) => (
        <Flex gap={8}>
          <Button
            className="green"
            onClick={() => handleSelect(original)}
            icon={<AntdIcon name="check" />}
          />
          {/* TODO: update when BE reflecting */}
          {/* <Button
            className="blue"
            onClick={() =>
              showModal({
                content: {
                  title: original.title,
                  sentence: original.sentence,
                  imageUrl: original.image_url,
                  buttonLabel: original.button_label,
                },
                id: value,
                callback: getContent,
              })
            }
            icon={<AntdIcon name="edit" />}
          /> */}
          <Button
            className="red"
            onClick={() => handleDelete(original.id)}
            icon={<AntdIcon name="delete" />}
          />
        </Flex>
      ),
      width: 130,
    },
  ];

  return (
    <>
      <DataTableSearch onChange={handleSearch} originalData={data} />
      <br />
      <AntdTable
        data={filteredData}
        loading={isLoading || isLoadingDeleteContent}
        columns={COLUMNS}
      />
      <Modal
        style={{
          minWidth: '98vw',
        }}
        title={'Edit Content'}
        open={modalProps.show}
        onCancel={closeEditModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <UpdateContent onChangeModal={closeEditModal} mode="edit" {...modalProps} />
      </Modal>
    </>
  );
};

type UpdateModalProps = {
  onChangeModal: (type: 'add' | 'select') => void;
} & (
  | {
      mode: 'edit';
      content: Content;
      id: number;
      callback: () => void;
    }
  | { mode: 'add' }
);

const UpdateContent = ({ onChangeModal, mode, ...rest }: UpdateModalProps) => {
  const { isLoading: isLoadingAddContent, execute: addContent } = useAddCampaignContent();
  const { isLoading: isLoadingEditContent, execute: editContent } = useEditCampaignContent();

  const { states, setAllStates } = useStates<Content>({
    sentence: '',
    title: '',
    imageUrl: '',
    buttonLabel: '',
  });

  const handleUpdateContent = async () => {
    if (mode === 'add')
      await addContent({
        data: {
          ...states,
          // TODO: update when BE reflecting
          // @ts-ignore
          buttonLabel: undefined,
        },
      });
    if (mode === 'edit')
      await editContent({
        path: { id: rest['id'] },
        data: states,
      });
    setAllStates({
      sentence: '',
      title: '',
      imageUrl: '',
      buttonLabel: '',
    });
    // @ts-ignore
    rest?.callback?.();
    onChangeModal('select');
  };

  const handleChangeInput = useCallback(e => {
    setAllStates({ [e.target.id]: e.target.value });
  }, []);

  useEffect(() => {
    if (mode) {
      setAllStates(rest['content'] as Content);
    }
  }, [rest['id']]);

  return (
    <>
      <Flex gap="small" vertical style={{ marginBottom: 16 }}>
        <Flex vertical>
          <Typography.Text type="secondary">Title</Typography.Text>
          <Input.TextArea
            id="title"
            value={states.title}
            onChange={handleChangeInput}
            placeholder="Replace title text"
          />
          <p>:name will be replaced to user name</p>
        </Flex>
        <Flex vertical>
          <Typography.Text type="secondary">Sentence</Typography.Text>
          <Input.TextArea
            value={states.sentence}
            id="sentence"
            onChange={handleChangeInput}
            placeholder="Replace main text"
          />
        </Flex>
        <Flex vertical>
          <Typography.Text type="secondary">Image URL</Typography.Text>
          <Input
            value={states.imageUrl}
            id="imageUrl"
            onChange={handleChangeInput}
            placeholder="Replace image"
          />
        </Flex>
        <Flex vertical>
          <Typography.Text type="secondary">Button Label</Typography.Text>
          <Input
            value={states.buttonLabel}
            id="buttonLabel"
            onChange={handleChangeInput}
            placeholder="Button Label"
          />
        </Flex>
      </Flex>
      <Flex justify="flex-end">
        <Button
          loading={isLoadingAddContent || isLoadingEditContent}
          variant="submit"
          onClick={() => {
            handleUpdateContent();
          }}
        >
          {capitalize(mode)}
        </Button>
      </Flex>
    </>
  );
};

function CampaignContentModal({ onSelect, value: content }: Props) {
  const { showModal, closeModal, modalProps } = useModal();

  const [modalType, setModalType] = useState<'add' | 'select'>('select');

  const changeModalType = type => {
    setModalType(type);
  };

  return (
    <>
      {content && <ContentCard content={content} />}
      <Flex gap={4}>
        <Button
          icon={<AntdIcon name="check" />}
          onClick={() => {
            showModal();
            setModalType('select');
          }}
        >
          Select
        </Button>{' '}
        {content && (
          <Button icon={<FAIcon name="farRotateLeft" />} onClick={() => onSelect(undefined)}>
            Clear
          </Button>
        )}
        <Button
          icon={<AntdIcon name="plus" />}
          variant="submit"
          onClick={() => {
            showModal();
            setModalType('add');
          }}
        >
          Add
        </Button>
      </Flex>
      <Modal
        style={{
          minWidth: '98vw',
        }}
        title={modalType === 'add' ? 'Add Content' : 'Select Content'}
        open={modalProps.show}
        onCancel={closeModal}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        {modalType === 'add' ? (
          <UpdateContent onChangeModal={changeModalType} mode="add" />
        ) : (
          <SelectContent onSelect={onSelect} closeModal={closeModal} />
        )}
      </Modal>
    </>
  );
}

export default CampaignContentModal;
