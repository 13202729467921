import { SearchInput } from '@Shared';
import FAIcon from '@Components/ui/fa-icons';
import AntdTable, { Column } from '@Shared/Table/AntdTable';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react';
import EntityOverlapCell from '../../../shared/Table/EntityOverlapCell';
import { addComma, ordinalSuffixFormatter } from '@Utils/number';
import {
  TrendingKeywordsItem,
  TrendingKeywordsSortBy,
  useGetTrendingKeywords,
} from '@Services/keywords';
import { capitalize } from 'lodash';
import { SorterResult } from 'antd/es/table/interface';
import DiffCell from '@Shared/Table/DiffCell';
import Title from '@Shared/Style/Title/Title';
import { CSVLink } from 'react-csv';
import TreeMapChart from './TreeMap';
import { Link } from 'react-router-dom';
import { generateInternalLink } from '@Utils';
import { Button, Flex, Muted, Panel, Popover, Skeleton, ToggleGroup, Tooltip } from '@/ui';
import { usePagination } from '@Hooks';

interface ExtraData {
  mode: Mode;
}

type Mode = 'diff' | 'percent' | 'value';
type Type = 'playlists' | 'followers';

const TOTAL = 500;
const LIMIT = 10;

const CSV_FORMAT = [
  { label: 'Position', key: 'position' },
  { label: 'Keyword', key: 'token' },
  { label: 'Playlists', key: 'playlists' },
  { label: 'Followers', key: 'followers' },
  { label: 'Top Artists', key: 'top_artists' },
  { label: 'Top Tracks', key: 'top_tracks' },
  { label: 'Top Playlists by diff', key: 'top_playlists_by_diff' },
  { label: 'Top Playlists by percent diff', key: 'top_playlists_by_percent_diff' },
];

const COLUMN = ({
  sortMode,
  sortedInfo,
}: {
  sortMode: Mode;
  sortedInfo: SorterResult<any>;
}): Column<TrendingKeywordsItem & { position: number }, ExtraData>[] => [
  {
    Header: 'Position',
    accessor: 'position',
    align: 'left',
    sorter: true,
    Cell: ({ value }) => (
      <>
        {value <= 3 && (
          <FAIcon
            name="fasCrown"
            color={value === 1 ? 'orange' : value === 2 ? 'silver' : 'brown'}
          />
        )}
        {ordinalSuffixFormatter(value)}
      </>
    ),
    width: 25,
  },
  {
    Header: (
      <Flex align="center" gap={1}>
        <FAIcon name="farSquareK" />
        <b>Trending Keywords</b>
      </Flex>
    ),
    Cell: ({ value }) => (
      <Flex align="center" gap={4}>
        <p className="text-lg font-bold">{capitalize(value)}</p>

        <Link
          target="_blank"
          to={generateInternalLink(`keyword/keyword-search`, {
            q: value,
          })}
        >
          <Tooltip content={`Search ${capitalize(value)}`}>
            <Button
              variant="ghost"
              icon={<FAIcon name="farSearch" color="gray" className="w-[12px] h-[12px]" />}
            />
          </Tooltip>
        </Link>
      </Flex>
    ),
    align: 'left',
    accessor: 'TOKEN',
    width: 200,
    search: true,
  },
  {
    key: 'playlists',
    Header: 'Playlists',
    sorter: true,
    accessor: {
      value: 'TOKEN_PLAYLISTS_TODAY',
      percent: 'TOKEN_PLAYLISTS_PERCENT_GROWTH',
      diff: 'TOKEN_PLAYLISTS_DIFF',
    }[sortMode] as keyof TrendingKeywordsItem,
    Cell: ({ original, extraData }) => (
      <DiffCell
        value={original.TOKEN_PLAYLISTS_TODAY}
        diff={original.TOKEN_PLAYLISTS_DIFF}
        percent={original.TOKEN_PLAYLISTS_PERCENT_GROWTH}
        mode={extraData.mode}
      />
    ),
    sortOrder: sortedInfo.columnKey === 'playlists' ? sortedInfo.order : undefined,
    width: 260,
  },
  {
    key: 'followers',
    Header: 'Followers',
    accessor: {
      value: 'TOKEN_FOLLOWERS_TODAY',
      percent: 'TOKEN_FOLLOWERS_PERCENT_GROWTH',
      diff: 'TOKEN_FOLLOWERS_DIFF',
    }[sortMode] as keyof TrendingKeywordsItem,
    Cell: ({ original, extraData }) => (
      <DiffCell
        value={original.TOKEN_FOLLOWERS_TODAY}
        diff={original.TOKEN_FOLLOWERS_DIFF}
        percent={original.TOKEN_FOLLOWERS_PERCENT_GROWTH}
        mode={extraData.mode}
      />
    ),
    sorter: true,
    sortOrder: sortedInfo.columnKey === 'followers' ? sortedInfo.order : undefined,
    width: 260,
  },
  {
    Header: 'Top Artists',
    accessor: 'TOP_ARTISTS',
    Cell: ({ value }) => (
      <EntityOverlapCell
        value={value.map(e => ({
          id: e.cm_artist,
          name: e.artist_name,
          description: `Added to ${addComma(e.adds)} Playlists`,
          image_url: e.image_url,
        }))}
        entity="artist"
      />
    ),
    width: 350,
    search: true,
  },
  {
    Header: 'Top Tracks',
    accessor: 'TOP_TRACKS',
    Cell: ({ value }) => (
      <EntityOverlapCell
        value={value.map(e => ({
          id: e.cm_track,
          name: e.track_name,
          description: `Added to ${addComma(e.adds)} Playlists`,
          image_url: e.image_url,
        }))}
        entity="track"
      />
    ),
    search: true,
    width: 300,
  },
  {
    Header: 'Top Playlists by diff',
    accessor: 'TOP_PLAYLISTS_BY_FOLLOWERS_DIFF_RANK',
    Cell: ({ value }) => (
      <EntityOverlapCell
        value={value.map(e => ({
          id: e.spotify_playlist,
          name: e.name,
          description: `Followers Diff: ${addComma(e.followers_diff)}`,
          image_url: e.image_url,
          platform: 'spotify',
        }))}
        entity="playlist"
      />
    ),
    width: 300,
    search: true,
  },
  {
    Header: 'Top Playlists by percent diff',
    accessor: 'TOP_PLAYLISTS_BY_FOLLOWERS_PERCENT_DIFF_RANK',
    Cell: ({ value }) => (
      <EntityOverlapCell
        value={value.map(e => ({
          id: e.spotify_playlist,
          name: e.name,
          description: `Followers Diff: ${addComma(e.followers_diff)}`,
          image_url: e.image_url,
          platform: 'spotify',
        }))}
        entity="playlist"
      />
    ),
    search: true,
    width: 300,
  },
];

const TrendingKeywords = () => {
  const [mode, setMode] = useState<Mode>('value');
  const [type, setType] = useState<Type>('playlists');
  const lastMonday = dayjs().startOf('week').format('MMM Do');
  const today = dayjs().format('MMM Do');

  const [sortedInfo, setSortedInfo] = useState<SorterResult<any>>({});
  const [sortMode, setSortMode] = useState<Mode>('value');

  const { limit, offset, handleChangePage, handleChangePageSize } = usePagination({
    limit: LIMIT,
  });

  const { data, isLoading, isFetched } = useGetTrendingKeywords({
    data: {
      limit,
      offset,
      sortBy: mode === 'value' ? type : (`${type}_${mode}` as TrendingKeywordsSortBy),
      sortOrderDesc: true,
    },
  });

  const { data: dataForChart, isLoading: isLoadingForChart } = useGetTrendingKeywords({
    data: {
      limit: 100,
      offset: 0,
      sortBy: mode === 'value' ? type : (`${type}_${mode}` as TrendingKeywordsSortBy),
      sortOrderDesc: true,
    },
  });

  useEffect(() => {
    if (isFetched) {
      setSortedInfo({
        columnKey: type,
        order: 'descend',
      });
      setSortMode(mode);
    }
  }, [isFetched, mode, type]);

  const columns = useMemo(() => COLUMN({ sortMode, sortedInfo }), [sortMode, sortedInfo]);

  const getCSVData = () => {
    if (!data) return [];
    return data.map((e, index) => ({
      position: index + 1,
      token: e.TOKEN,
      playlists: `${e.TOKEN_PLAYLISTS_TODAY} (${e.TOKEN_PLAYLISTS_DIFF})`,
      followers: `${e.TOKEN_FOLLOWERS_TODAY} (${e.TOKEN_FOLLOWERS_DIFF})`,
      top_artists: e.TOP_ARTISTS.map(e => `${e.artist_name} (${e.cm_artist})`).join(', '),
      top_tracks: e.TOP_TRACKS.map(e => `${e.track_name} (${e.cm_track})`).join(', '),
      top_playlists_by_diff: e.TOP_PLAYLISTS_BY_FOLLOWERS_DIFF_RANK.map(
        e => `${e.name} (${e.playlist_id})`
      ).join(', '),
      top_playlists_by_percent_diff: e.TOP_PLAYLISTS_BY_FOLLOWERS_PERCENT_DIFF_RANK.map(
        e => `${e.name} (${e.playlist_id})`
      ).join(', '),
    }));
  };

  return (
    <>
      <Title
        title={
          type === 'followers'
            ? 'Top keywords by change in playlist follower total over past week'
            : 'Top keywords by change in playlist count over past week'
        }
        description="Display by absolute change, percentage change, or total count"
      />
      {isLoadingForChart ? (
        <Skeleton className="w-full h-[600px]" />
      ) : (
        <TreeMapChart
          data={
            dataForChart?.map((e, index) => ({
              label: e.TOKEN,
              value: {
                followers: {
                  value: e.TOKEN_FOLLOWERS_TODAY,
                  diff: e.TOKEN_FOLLOWERS_DIFF,
                  percent: e.TOKEN_FOLLOWERS_PERCENT_GROWTH,
                },
                playlists: {
                  value: e.TOKEN_PLAYLISTS_TODAY,
                  diff: e.TOKEN_PLAYLISTS_DIFF,
                  percent: e.TOKEN_PLAYLISTS_PERCENT_GROWTH * 100,
                },
              }[type][mode],
            })) ?? []
          }
          prefix={mode === 'diff' ? '+' : ''}
          tooltipPrefix={mode === 'diff' ? '+' : ''}
          suffix={
            {
              diff: '',
              percent: '%',
              value: '',
            }[mode]
          }
          tooltipSuffix={
            {
              diff: `${capitalize(type)} Changed`,
              percent: `% ${capitalize(type)} Changed`,
              value: capitalize(type),
            }[mode]
          }
        />
      )}
      <br />
      <Panel
        header={
          <Flex vertical>
            <Muted>
              Weekly Data: {lastMonday} - {today}
            </Muted>
            <br />
            <Flex align="center" gap={4}>
              <Flex align="center" gap={1}>
                <Muted>By Change In</Muted>
                <ToggleGroup
                  type="single"
                  variant="primary"
                  options={[
                    {
                      label: 'Playlists',
                      value: 'playlists',
                    },
                    {
                      label: 'Followers',
                      value: 'followers',
                    },
                  ]}
                  value={type}
                  onValueChange={value => value && setType(value as Type)}
                />
              </Flex>

              <ToggleGroup
                type="single"
                value={mode}
                onValueChange={value => value && setMode(value as Mode)}
                options={[
                  {
                    prefix: (
                      <img
                        src="https://storage.googleapis.com/cm-app-assets/icons/plusMinus.png"
                        className="fill-neutral-200 "
                        width={13}
                        height={13}
                      />
                    ),
                    value: 'diff',
                    label: '',
                  },
                  {
                    prefix: <p>%</p>,
                    value: 'percent',
                    label: '',
                  },
                  {
                    prefix: <p>123</p>,
                    value: 'value',
                    label: '',
                  },
                ]}
              />
            </Flex>
          </Flex>
        }
        aside={
          <CSVLink
            data={getCSVData()}
            headers={CSV_FORMAT}
            filename={`Trending Keywords - ${type} - ${mode}.csv`}
          >
            <Button icon={<FAIcon name="farFileCsv" />}>Download CSV</Button>
          </CSVLink>
        }
      >
        <AntdTable<TrendingKeywordsItem & { position: number }>
          lazyPaginationOptions={{
            total: TOTAL,
            limit,
            offset,
            handleChangePage,
            handleChangePageSize,
          }}
          columns={columns}
          data={data?.map((e, index) => ({
            ...e,
            position: index + 1 + offset,
          }))}
          loading={isLoading}
          extraData={{ mode }}
          onChange={(pagination, filters, sorter) => {
            if (sorter) {
              setSortedInfo(sorter as SorterResult<any>);
            }
          }}
        />
      </Panel>
    </>
  );
};

export default TrendingKeywords;
