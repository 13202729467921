import React, { useEffect, useMemo } from 'react';
import { createContext } from 'react';
import { EntityTag } from './types';
import { InternalTagType, Tag, useGetGenreOrder, useTagList } from '@Services/tag';
import { isEmpty } from 'lodash';
import { TAG_TYPES } from './constants';

interface GenrePanelProps {
  entity: 'artist' | 'track';
  tags: EntityTag[];
  callback: () => void;
  id: number;
}

interface GenrePanelContextProps extends GenrePanelProps {
  children: React.ReactNode;
}

interface GenrePanelContextType extends GenrePanelProps {
  groupedTags: Record<InternalTagType, Record<string, EntityTag[]>>;
  entireTagMap: Partial<Record<InternalTagType, Tag[]>>;
  orders: number[];
  onEditOrderGenre: (id: number) => void;
  onChangeOrder: (ids: number[]) => void;
  isLoading: boolean;
}

const GenrePanelContext = createContext<GenrePanelContextType | undefined>(undefined);

const GenrePanelProvider = ({ children, ...props }: GenrePanelContextProps) => {
  const { entity, tags: entityTags, callback, id } = props;
  const [orderState, setOrderState] = React.useState<number[]>([]);

  const { data, isLoading: isLoadingOrder } = useGetGenreOrder(
    {
      path: {
        // @ts-ignore
        entity,
        id,
      },
    },
    {
      // Only support artist
      disable: entity !== 'artist',
    }
  );

  useEffect(() => {
    // @ts-ignore //FIXME: BE is wrong
    if (data) setOrderState(typeof data?.[0] === 'number' ? data : data.map(e => e.id));
  }, [data]);

  const { tagMap: entireTagMap, isLoading: isLoadingTags } = useTagList();

  const groupedTags = useMemo(
    () =>
      entityTags.reduce<Record<InternalTagType, Record<string, EntityTag[]>>>(
        (all, one) => {
          let findGroup = false;
          TAG_TYPES[entity].map(type => {
            if (entireTagMap[type].find(e => e.id === one.id)) {
              all[type][one.link_type] = [
                ...(all[type][one.link_type] ?? []),
                { ...one, internalTagType: type },
              ];
              findGroup = true;
            }
          });
          if (!findGroup) {
            all.others[one.link_type] = [
              ...(all.others[one.link_type] ?? []),
              { ...one, internalTagType: 'others' },
            ];
          }
          return all;
        },
        {
          mainV2: {},
          main: {},
          sub: {},
          artist: {},
          artistPriority: {},
          others: {},
        }
      ),
    [entityTags, entireTagMap]
  );

  const onEditOrderGenre = (id: number) => {
    setOrderState(prev => {
      if (prev.includes(id)) {
        return prev.filter(e => e !== id);
      }
      return [...prev, id];
    });
  };

  return (
    <GenrePanelContext.Provider
      value={{
        ...props,
        groupedTags,
        entireTagMap,
        onEditOrderGenre,
        onChangeOrder: setOrderState,
        orders: orderState,
        isLoading: isLoadingOrder || isLoadingTags,
      }}
    >
      {children}
    </GenrePanelContext.Provider>
  );
};

const useGenrePanel = () => {
  const context = React.useContext(GenrePanelContext);
  if (context === undefined) {
    throw new Error('useTagPanel must be used within a TagPanelProvider');
  }
  return context;
};

export { GenrePanelProps, GenrePanelProvider, useGenrePanel, GenrePanelContextProps };
