export const MOOD_LIST = [
  { name: 'CHILL', type: 'Activity / Theme' },
  { name: 'SPA', type: 'Activity / Theme' },
  { name: 'SUN', type: 'Activity / Theme' },
  { name: 'RAIN', type: 'Activity / Theme' },
  { name: 'NOISE', type: 'Activity / Theme' },
  { name: 'AMBIENT', type: 'Activity / Theme' },
  { name: 'STUDY', type: 'Activity / Theme' },
  { name: 'SOFT', type: 'Activity / Theme' },
  { name: 'PIANO', type: 'Activity / Theme' },
  { name: 'LOUNGE', type: 'Activity / Theme' },
  { name: 'MELODIC', type: 'Activity / Theme' },
  { name: 'GUITAR', type: 'Activity / Theme' },
  { name: 'MORNING', type: 'Activity / Theme' },
  { name: 'SLEEP', type: 'Activity / Theme' },
  { name: 'LOFI', type: 'Activity / Theme' },
  { name: 'BACKGROUND', type: 'Activity / Theme' },
  { name: 'SMOOTH', type: 'Activity / Theme' },
  { name: 'WIND', type: 'Activity / Theme' },
  { name: 'DRIVING', type: 'Activity / Theme' },
  { name: 'COFFEE', type: 'Activity / Theme' },
  { name: 'ACOUSTIC', type: 'Activity / Theme' },
  { name: 'MEDITATION', type: 'Activity / Theme' },
  { name: 'SLEEPING', type: 'Activity / Theme' },
  { name: 'NATURE', type: 'Activity / Theme' },
  { name: 'RELAXATION', type: 'Activity / Theme' },
  { name: 'YOGA', type: 'Activity / Theme' },
  { name: 'GARDEN', type: 'Activity / Theme' },
  { name: 'VIOLIN', type: 'Activity / Theme' },
  { name: 'STRINGS', type: 'Activity / Theme' },
  { name: 'DRINKING', type: 'Activity / Theme' },
  { name: 'BIRDS', type: 'Activity / Theme' },
  { name: 'EXERCISE', type: 'Activity / Theme' },
  { name: 'WHITE NOISE', type: 'Activity / Theme' },
  { name: 'PERCUSSION', type: 'Activity / Theme' },
  { name: 'COMMUTE', type: 'Activity / Theme' },
  { name: 'NIGHTTIME', type: 'Activity / Theme' },
  { name: 'NIGHT OUT', type: 'Activity / Theme' },
  { name: 'NAPTIME', type: 'Activity / Theme' },
  { name: 'SOLFEGGIOS FREQUENCIES', type: 'Activity / Theme' },
  { name: 'BLUE', type: 'Mood' },
  { name: 'DARK', type: 'Mood' },
  { name: 'ROMANTIC', type: 'Mood' },
  { name: 'PSYCHEDELIC', type: 'Mood' },
  { name: 'WARM', type: 'Mood' },
  { name: 'SEXY', type: 'Mood' },
  { name: 'SERIOUS', type: 'Mood' },
  { name: 'ATMOSPHERIC', type: 'Mood' },
  { name: 'PEACEFUL', type: 'Mood' },
  { name: 'RELAXED', type: 'Mood' },
  { name: 'LONELY', type: 'Mood' },
  { name: 'INTENSE', type: 'Mood' },
  { name: 'DEPRESSED', type: 'Mood' },
  { name: 'MELANCHOLIC', type: 'Mood' },
  { name: 'HYPNOTIC', type: 'Mood' },
  { name: 'VIOLENT', type: 'Mood' },
  { name: 'BITTER', type: 'Mood' },
  { name: 'REFINED', type: 'Mood' },
  { name: 'CREEPY', type: 'Mood' },
  { name: 'HEARTBROKEN', type: 'Mood' },
  { name: 'JOYFUL', type: 'Mood' },
  { name: 'SPACEY', type: 'Mood' },
  { name: 'SOPHISTICATED', type: 'Mood' },
  { name: 'WANDERING', type: 'Mood' },
  { name: 'GLOOMY', type: 'Mood' },
  { name: 'GRIEF', type: 'Mood' },
  { name: 'FAITHFUL', type: 'Mood' },
  { name: 'WHIMSICAL', type: 'Mood' },
  { name: 'REFLECTIVE', type: 'Mood' },
  { name: 'PAINFUL', type: 'Mood' },
  { name: 'JEALOUS', type: 'Mood' },
  { name: 'SEDUCTIVE', type: 'Mood' },
  { name: 'REBELLIOUS', type: 'Mood' },
  { name: 'OPTIMISTIC', type: 'Mood' },
  { name: 'VICTORIOUS', type: 'Mood' },
  { name: 'CYNICAL', type: 'Mood' },
  { name: 'AMBITIOUS', type: 'Mood' },
  { name: 'ARROGANT', type: 'Mood' },
  { name: 'SUSPENSEFUL', type: 'Mood' },
  { name: 'EMPOWERED', type: 'Mood' },
  { name: 'PROVOCATIVE', type: 'Mood' },
  { name: 'ELECTRIFIED', type: 'Mood' },
  { name: 'INTIMIDATING', type: 'Mood' },
  { name: 'CELEBRATORY', type: 'Mood' },
  { name: 'REGRETFUL', type: 'Mood' },
  { name: 'ENRAGED', type: 'Mood' },
  { name: 'BELLIGERENT', type: 'Mood' },
  { name: 'GLEEFUL', type: 'Mood' },
  { name: 'MISCHIEVOUS', type: 'Mood' },
  { name: 'HORRIFIED', type: 'Mood' },
  { name: 'REASSURED', type: 'Mood' },
];

export const MOOD_COLORS_CLASSES: { [key: string]: string } = {
  BLUE: 'Blue',
  DARK: 'Grey',
  ROMANTIC: 'Red',
  PSYCHEDELIC: 'Purple',
  WARM: 'Orange',
  SEXY: 'Red',
  SERIOUS: 'Blue',
  ATMOSPHERIC: 'Grey',
  PEACEFUL: 'Green',
  RELAXED: 'Green',
  LONELY: 'Blue',
  INTENSE: 'Orange',
  DEPRESSED: 'Blue',
  MELANCHOLIC: 'Blue',
  HYPNOTIC: 'Purple',
  VIOLENT: 'Red',
  BITTER: 'Blue',
  REFINED: 'Pink',
  CREEPY: 'Pink',
  HEARTBROKEN: 'Blue',
  JOYFUL: 'Yellow',
  SPACEY: 'Purple',
  SOPHISTICATED: 'Pink',
  WANDERING: 'Green',
  GLOOMY: 'Blue',
  GRIEF: 'Blue',
  FAITHFUL: 'Orange',
  WHIMSICAL: 'Pink',
  REFLECTIVE: 'Green',
  PAINFUL: 'Blue',
  JEALOUS: 'Red',
  SEDUCTIVE: 'Red',
  REBELLIOUS: 'Red',
  OPTIMISTIC: 'Yellow',
  VICTORIOUS: 'Orange',
  CYNICAL: 'Purple',
  AMBITIOUS: 'Red',
  ARROGANT: 'Red',
  SUSPENSEFUL: 'Purple',
  EMPOWERED: 'Red',
  PROVOCATIVE: 'Orange',
  ELECTRIFIED: 'Red',
  INTIMIDATING: 'Red',
  CELEBRATORY: 'Yellow',
  REGRETFUL: 'Blue',
  ENRAGED: 'Red',
  BELLIGERENT: 'Red',
  GLEEFUL: 'Yellow',
  MISCHIEVOUS: 'Pink',
  HORRIFIED: 'Purple',
  REASSURED: 'Green',
  CHILL: 'Blue',
  //Activity / Theme
  SPA: 'Blue',
  SUN: 'Yellow',
  RAIN: 'Blue',
  NOISE: 'Grey',
  AMBIENT: 'Grey',
  STUDY: 'Purple',
  SOFT: 'Green',
  PIANO: 'Blue',
  LOUNGE: 'Blue',
  MELODIC: 'Orange',
  GUITAR: 'Orange',
  MORNING: 'Yellow',
  SLEEP: 'Blue',
  LOFI: 'Pink',
  BACKGROUND: 'Grey',
  SMOOTH: 'Blue',
  WIND: 'Green',
  DRIVING: 'Red',
  COFFEE: 'Yellow',
  ACOUSTIC: 'Orange',
  MEDITATION: 'Blue',
  SLEEPING: 'Grey',
  NATURE: 'Green',
  RELAXATION: 'Purple',
  YOGA: 'Purple',
  GARDEN: 'Green',
  VIOLIN: 'Red',
  STRINGS: 'Red',
  DRINKING: 'Orange',
  BIRDS: 'Green',
  EXERCISE: 'Red',
  WHITE_NOISE: 'Grey',
  PERCUSSION: 'Purple',
  COMMUTE: 'Yellow',
  NIGHTTIME: 'Purple',
  NIGHT_OUT: 'Orange',
  NAPTIME: 'Grey',
  SOLFEGGIOS_FREQUENCIES: 'Green',
};

export const MOOD_EXPLANATIONS = {
  blue: 'Blue: Conveying a sense of sadness or melancholy.',
  dark: 'Dark: Evoking feelings of mystery or foreboding.',
  romantic: 'Romantic: Inspiring feelings of love and affection.',
  psychedelic: 'Psychedelic: Creating a surreal or mind-bending experience.',
  sexy: 'Sexy: Exuding sensuality and allure.',
  warm: 'Warm: Emanating comfort and coziness.',
  serious: 'Serious: Presenting a solemn or thoughtful atmosphere.',
  atmospheric: 'Atmospheric: Immersing you in a rich and enveloping soundscape.',
  peaceful: 'Peaceful: Promoting calmness and tranquility.',
  relaxed: 'Relaxed: Inducing a state of ease and unwinding.',
  lonely: 'Lonely: Reflecting solitude and isolation.',
  intense: 'Intense: Bringing strong, powerful emotions to the forefront.',
  depressed: 'Depressed: Capturing a deep sense of sadness.',
  melancholic: 'Melancholic: Channeling a wistful or reflective sadness.',
  hypnotic: 'Hypnotic: Mesmerizing with a repetitive, trance-like quality.',
  violent: 'Violent: Expressing aggression and forcefulness.',
  bitter: 'Bitter: Highlighting feelings of resentment or harshness.',
  refined: 'Refined: Showcasing elegance and sophistication.',
  creepy: 'Creepy: Creating an eerie and unsettling vibe.',
  heartbroken: 'Heartbroken: Expressing the pain of lost love.',
  joyful: 'Joyful: Overflowing with happiness and delight.',
  spacey: 'Spacey: Evoking a cosmic, otherworldly atmosphere.',
  sophisticated: 'Sophisticated: Displaying a polished and cultured essence.',
  wandering: 'Wandering: Conjuring feelings of exploration and aimlessness.',
  gloomy: 'Gloomy: Enveloping in a dark and dreary mood.',
  grief: 'Grief: Expressing deep sorrow and loss.',
  faithful: 'Faithful: Radiating loyalty and trust.',
  whimsical: 'Whimsical: Filled with playful and fanciful elements.',
  reflective: 'Reflective: Encouraging introspection and thoughtfulness.',
  painful: 'Painful: Bringing out feelings of hurt and suffering.',
  jealous: 'Jealous: Highlighting envy and possessiveness.',
  seductive: 'Seductive: Tempting and alluring with a hint of mystery.',
  rebellious: 'Rebellious: Challenging norms with a defiant attitude.',
  optimistic: 'Optimistic: Filled with hope and positivity.',
  victorious: 'Victorious: Celebrating triumph and success.',
  cynical: 'Cynical: Displaying distrust and skepticism.',
  ambitious: 'Ambitious: Driven by strong goals and determination.',
  arrogant: 'Arrogant: Showing overconfidence and superiority.',
  suspenseful: 'Suspenseful: Building tension and anticipation.',
  empowered: 'Empowered: Encouraging strength and confidence.',
  provocative: 'Provocative: Challenging and stimulating with boldness.',
  electrified: 'Electrified: Charged with energy and excitement.',
  intimidating: 'Intimidating: Evoking fear or apprehension.',
  celebratory: 'Celebratory: Filled with festive joy and merriment.',
  regretful: 'Regretful: Conveying remorse and sorrow.',
  enraged: 'Enraged: Full of intense anger and fury.',
  belligerent: 'Belligerent: Aggressively hostile and combative.',
  gleeful: 'Gleeful: Bursting with happiness and joy.',
  mischievous: 'Mischievous: Playfully naughty and teasing.',
  horrified: 'Horrified: Overwhelmed with shock and fear.',
  reassured: 'Reassured: Providing comfort and confidence.',
};