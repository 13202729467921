import { usePagination } from '@Hooks';
import React, { useEffect, useMemo } from 'react';
import { TABS } from '../../constants';
import { AntdTable, Pagination } from '@Shared';
import { Checkbox, Spin, Tag } from 'antd';
import { getLinkCompletenessResponse } from '@Services/linkCompleteness';
import { BrandIcon } from '@Shared/Style';
import COLUMNS from './columns';
import { generateCmUrl } from '@Utils';
import { addComma } from '@Utils/number';
import NoteCell from '../Statistics/NoteCell';
import { LinkingPanel } from '../Linking';
import styles from '../../LinkCompleteness.module.scss';
import { useComplete } from '../../hooks';
import { CardTitle, Flex, Button } from '@/ui';
import FAIcon from '@Components/ui/fa-icons';
import { Table } from '@Components';

interface Props extends ReturnType<typeof usePagination> {
  data: getLinkCompletenessResponse;
  isLoading: boolean;
  entity: (typeof TABS)[number];
  cacheKey: any;
}

const CardView = ({
  data,
  handleChangePage: rootHandleChangePage,
  offset: rootOffset,
  limit: rootLimit,
  isLoading,
  entity,
  cacheKey,
}: Props) => {
  const { limit, offset, handleChangePage, handleChangePageSize } = usePagination({
    limit: 1,
  });
  const item = data?.data[offset - rootOffset];
  const { handleCheck, status } = useComplete(item?.id ?? 0, entity, cacheKey);
  const onChangePage = (page: number) => {
    rootHandleChangePage(Math.floor(page / rootLimit));
    handleChangePage(page);
  };

  useEffect(() => {
    handleChangePage(rootOffset);
  }, []);

  if (isLoading)
    return (
      <Flex vertical align="center" justify="center" style={{ width: '100%', height: '700px' }}>
        <Spin />
      </Flex>
    );

  if (!item) return null;

  return (
    <Flex vertical>
      <div style={{ position: 'relative' }}>
        <Button
          onClick={() => {
            onChangePage(offset - 1);
          }}
          disabled={offset === 0}
          className="blue rounded-full"
          icon={<FAIcon name="farArrowLeft" />}
          style={{ position: 'absolute', top: '50%', left: -40, transform: 'translateY(-50%)' }}
        />
        <Flex vertical gap={1}>
          <Tag
            color={status ? 'blue' : 'red'}
            style={{
              position: 'absolute',
              top: 0,
              right: -40,
              transform: 'translateY(-50%)',
            }}
            onClick={handleCheck}
          >
            <Flex gap={1} align="center">
              <Checkbox checked={status} onClick={handleCheck} />
              {status ? 'Linked' : 'Not Linked'}
            </Flex>
          </Tag>
          <Table
            className={status ? styles.glowBorder : ''}
            options={[
              {
                label: 'Name',
                component: (
                  <Flex gap={1} align="center">
                    <img
                      src={item.image_url}
                      style={{
                        borderRadius: entity === 'artist' ? '50%' : '0%',
                        width: 36,
                        height: 36,
                      }}
                    />
                    <a
                      href={generateCmUrl('artist', { id: item.id })}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.name}
                    </a>
                  </Flex>
                ),
              },
              {
                label: (
                  <Flex align="center" gap={1} justify="center">
                    Rank
                  </Flex>
                ),
                component: <div>{addComma(item.cm_artist_rank)}</div>,
              },
              {
                label: 'Tier',
                component: <div>{item.tier.toString()}</div>,
              },
              {
                label: 'Notes',
                component: <NoteCell original={item} value={item.notes} extraData={{ cacheKey }} />,
              },
            ]}
          />
        </Flex>
        <Button
          onClick={() => {
            onChangePage(offset + 1);
          }}
          disabled={offset >= data.total}
          className="blue rounded-full"
          icon={<FAIcon name="farArrowRight" />}
          style={{ position: 'absolute', top: '50%', right: -40, transform: 'translateY(-50%)' }}
        />
      </div>
      <br />
      <CardTitle>DSP & Social Links</CardTitle>
      <LinkingPanel entity={entity} item={item} cacheKey={cacheKey} />
      <Flex justify="end">
        <Pagination
          page={offset}
          pageSize={1}
          onPageChange={onChangePage}
          total={data?.total}
          excludeSizeChanger
        />
      </Flex>
    </Flex>
  );
};

export default React.memo(CardView);
