import { InternalTagType } from '@Services/tag';
import { PLATFORMS_COLOR } from '@Utils/constants';

const ARTIST_TAG_TYPES = ['main', 'mainV2', 'sub', 'artist', 'artistPriority'] as const;
const TRACK_TAG_TYPES = ['main'] as const;

const TAG_TYPES = {
  artist: ARTIST_TAG_TYPES,
  track: TRACK_TAG_TYPES,
};

const getTagColor = (type: InternalTagType, hidden: boolean, source: string) => {
  return type === 'others' || type === 'artistPriority' || type === 'artist'
    ? 'orange'
    : hidden
    ? 'gray'
    : source
    ? PLATFORMS_COLOR[source.toLowerCase()]
    : 'blue';
};


export { ARTIST_TAG_TYPES, TRACK_TAG_TYPES, TAG_TYPES, getTagColor };
