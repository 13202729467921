import React, { useEffect, useState } from 'react';
import { Button, Flex } from 'antd';
import { Panel, ActionInput, Table } from '../../../shared/Style';
import { useEditLabelGroup, useGetLabelGroup } from '../../../services/label';
import { showInfoMessage } from '../../../utilities';
import { ENTITY_ICON } from '@Constants/entity';

function LabelGroup() {
  const [parentLabel, setParentLabel] = useState<string>();
  const [label, setLabel] = useState<string>();

  const {
    data: labelGroup,
    refetch: getLabelGroup,
    isLoading,
  } = useGetLabelGroup({
    data: { label },
  });
  const { execute: editLabelGroup, isLoading: isLoadingEdit } = useEditLabelGroup();

  const handleSubmitButtonClick = value => {
    editLabelGroup({ query: { parentLabel: value, subLabel: label } }).then(() => {
      showInfoMessage('Label Group Updated Successfully');
    });
  };

  const handleOnClickRecommendation = e => {
    setParentLabel(e.target.innerText);
  };

  useEffect(() => {
    setParentLabel(labelGroup?.parent);
  }, [labelGroup?.parent]);

  return (
    <>
      <Panel title="Change Label Group (Parents label)" icon="farLayerGroup">
        <ActionInput
          placeholder="Label Name"
          action={{
            icon: 'farSearch',
            content: 'Search',
            onClick: () => {
              getLabelGroup();
            },
            loading: isLoading,
            variant: 'submit',
          }}
          icon={ENTITY_ICON.LABEL}
          value={label}
          onChange={e => setLabel(e.target.value)}
        />
      </Panel>
      {labelGroup && (
        <Table
          title="Label Group"
          panelStyle
          options={[
            {
              label: 'Label Name',
              value: labelGroup?.child,
            },
            {
              label: 'Parent Label',
              component: (
                <>
                  <ActionInput
                    placeholder="Parent Label Name"
                    value={parentLabel}
                    onChange={e => setParentLabel(e.target.value)}
                    id="parent-label"
                    action={{
                      icon: 'farEdit',
                      content: 'Edit',
                      onClick: ({ target: { value } }) => {
                        handleSubmitButtonClick(value);
                      },
                      loading: isLoadingEdit,
                    }}
                  />
                  <br />
                  <Flex wrap="wrap" gap="small">
                    <Button type="dashed" onClick={handleOnClickRecommendation}>
                      Sony Music Entertainment
                    </Button>
                    <Button type="dashed" onClick={handleOnClickRecommendation}>
                      Universal Music Group
                    </Button>
                    <Button type="dashed" onClick={handleOnClickRecommendation}>
                      Warner Music Group
                    </Button>
                  </Flex>
                </>
              ),
            },
          ]}
        />
      )}
    </>
  );
}

export default LabelGroup;
