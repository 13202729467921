import React from 'react';
import { Button as AntdButton } from 'antd';
import classNames from 'classnames';
import AntdIcon, { ANTD_ICON_TYPES } from './AntdIcon';
import { ButtonColor, Size } from './types';

interface Props {
  children?: React.ReactNode;
  loading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  className?: string;
  color?: ButtonColor;
  icon?: ANTD_ICON_TYPES | string;
  disabled?: boolean;
  id?: string;
  size?: Size;
}

/**
 * @deprecated
 */
function Button({
  id,
  loading,
  color = 'blue',
  children,
  className,
  disabled,
  icon,
  onClick,
  size,
}: Props) {
  return (
    <AntdButton
      id={id}
      disabled={disabled}
      loading={loading}
      className={classNames(color, className)}
      icon={icon && <AntdIcon name={icon as ANTD_ICON_TYPES} />}
      type={color === 'blue' ? 'primary' : 'default'}
      onClick={onClick}
      size={size}
    >
      {children}
    </AntdButton>
  );
}

export default Button;
