import { Button, Card, CardContent, CardHeader, Flex, H4 } from '@/ui';
import type { Content } from '../types';
import React from 'react';

const ContentCard = ({ content }: { content: Content }) => {
  return (
    <>
      <Card>
        <CardHeader />
        <CardContent>
          <Flex align="center" vertical>
            <H4>{content['title']}</H4>
            {content['image_url'] && (
              <img style={{ width: '100%', maxWidth: '300px' }} src={content['image_url']} />
            )}
            <div>{content['sentence']}</div>
            <br />
            <Button className="cm">{content['button_label'] ?? 'Subscribe Now!'}</Button>
            <br />
          </Flex>
        </CardContent>
      </Card>
      <br />
    </>
  );
};

export default ContentCard;
