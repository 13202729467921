/* eslint-disable react/no-unused-prop-types */
import { Button, Checkbox, DatePicker, Flex, Input, Muted, Panel, Select, Textarea, Tooltip } from '@/ui';
import { ImageCropper, Table } from '@Components';
import FAIcon from '@Components/ui/fa-icons';
import { ENTITY_ICON } from '@Constants/entity';
import { useGetCityList } from '@Services/city';
import { AUTHENTICITY_RISK_PLATFORM_MAP } from '@Services/meta';
import { useGetEntity } from '@Services/search';
import { WhatsThis } from '@Shared';
import { BrandIcon } from '@Shared/Style';
import { generateCmUrl, getDateFromTimestamp } from '@Utils';
import { CAREER_STAGE_MAPPER, RECENT_MOMENTUM_MAPPER } from '@Utils/constants';
import { COUNTRY_OPTIONS, GENDER_OPTIONS, PRONOUN_OPTIONS } from '@Utils/options';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React from 'react';
import { EditEntityProps } from '..';
import { CAREER_STAGE_CHANGE_OPTIONS } from './constants';
import { useArtistInfo } from './useArtistInfo';

function Info({ q }: EditEntityProps) {
  const { data } = useGetEntity({
    data: {
      q,
    },
  });

  const {
    onChangeState,
    onChangeInput,
    onChangeCheckBox,
    onChangeSelector,
    onChangeGPTSummary,
    onSubmit,
    onChangeImage,
    isFetching,
    props,
    states,
  } = useArtistInfo(q);

  const careerStageChangeOption =
    props.artist_stage.career_stage !== states.override_stage
      ? CAREER_STAGE_CHANGE_OPTIONS[props.artist_stage.career_stage]
      : props.artist_stage.career_stage;

  const renderCitySelector = () => {
    const { data, isLoading } = useGetCityList(
      {
        data: {
          code2s: [states.code2],
        },
      },
      {
        disable: !states.code2,
      },
    );

    return !isLoading && !data?.length ? null : (
      <Select
        onChange={onChangeSelector('current_city_id')}
        placeholder="Select City"
        options={[...(data?.map(({ id, name }) => ({ label: name, value: id })) ?? [])]}
        value={states.current_city_id}
        loading={isLoading}
      />
    );
  };

  // TODO: Apply Form
  return (
    <>
      <Panel
        grid
        header="Artist Information"
        icon={ENTITY_ICON.ARTIST}
        aside={
          <Button loading={isFetching} onClick={onSubmit} icon={<FAIcon name="farPenNib" />}>
            Update All
          </Button>
        }
      >
        <Flex vertical gap={4}>
          <Table
            options={[
              {
                label: 'Name',
                component: (
                  <Flex align="center" gap={2}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={generateCmUrl('artist', { id: data.id })}
                    >
                      {data.name}
                    </a>{' '}
                    {data?.verified && (
                      <Tooltip content="Verified">
                        <FAIcon name="fasCheckCircle" color="blue" />
                      </Tooltip>
                    )}
                  </Flex>
                ),
                labelIcon: 'farTag',
              },
              {
                label: 'Country',
                component: (
                  <Select
                    onChange={onChangeSelector('code2')}
                    options={COUNTRY_OPTIONS}
                    value={states.code2 ?? ''}
                  />
                ),
                labelIcon: 'fasFlag',
              },
              {
                label: 'City',
                component: renderCitySelector(),
                labelIcon: 'fasFlag',
              },
              {
                label: 'Image',
                description:
                  'Recommended to use the image uploader above rather than editing the image_url directly. Generally we should not be pointing this to urls hosted on third-party domains, as if the domain goes down it impacts our application. Deleting this image_url deletes it from cm_artist.image_url but will now trigger the fallback logic for artist images to then use Tivo, Spotify, and other options.',
                component: (
                  <Flex vertical>
                    <Flex justify="between" align="center">
                      <Muted>Upload a new image or put a URL</Muted>
                      <HideButton
                        checked={states.hide_cover_image}
                        onChange={onChangeCheckBox}
                        name="hide_cover_image"
                        label={'bio modal'}
                      />
                    </Flex>
                    <ImageCropper defaultImageUrl={states.image_url} onChangeFile={onChangeImage} />
                    <Input
                      className="my-2"
                      name="image_url"
                      value={states.image_url ?? ''}
                      onChange={onChangeInput}
                      disabled={Boolean(states.image_file)}
                    />
                  </Flex>
                ),
                labelIcon: 'farImage',
              },
              {
                label: 'Gender',
                component: (
                  <Flex gap={2}>
                    {!data.hide_gender && (
                      <Select
                        onChange={onChangeSelector('gender')}
                        options={GENDER_OPTIONS}
                        value={states.gender ?? ''}
                      />
                    )}
                    <HideButton
                      checked={states.hide_gender}
                      onChange={onChangeCheckBox}
                      name="hide_gender"
                      label={'profile'}
                    />
                  </Flex>
                ),
                labelIcon: 'farTransgender',
              },
              {
                label: 'Pronoun',
                component: (
                  <Flex gap={2}>
                    {!data.hide_pronoun && (
                      <Select
                        onChange={onChangeSelector('pronoun_title')}
                        options={PRONOUN_OPTIONS}
                        value={states.pronoun_title ?? ''}
                      />
                    )}
                    <HideButton
                      checked={states.hide_pronoun}
                      onChange={onChangeCheckBox}
                      name="hide_pronoun"
                      label={'profile'}
                    />
                  </Flex>
                ),
                labelIcon: 'farTransgender',
              },
              {
                label: 'Birthday',
                component: (
                  <Flex gap={2}>
                    {!data.hide_birthday && (
                      <DatePicker
                        timeZone="utc"
                        month={
                          states.date_of_birth
                            ? getDateFromTimestamp(states.date_of_birth)
                            : new Date()
                        }
                        value={getDateFromTimestamp(states.date_of_birth)}
                        onChange={date =>
                          onChangeSelector('date_of_birth')(dayjs(date).format('YYYY-MM-DD'))
                        }
                        disabled={{
                          after: new Date(),
                        }}
                      />
                    )}
                    <HideButton
                      checked={states.hide_birthday}
                      onChange={onChangeCheckBox}
                      name="hide_birthday"
                      label={'profile'}
                    />
                  </Flex>
                ),
                labelIcon: 'farCalendar',
              },
              {
                label: 'Band',
                component: (
                  <Checkbox checked={states.band} onCheckedChange={() => onChangeCheckBox('band')}>
                    Band
                  </Checkbox>
                ),
                labelIcon: 'farPeopleGroup',
              },
              {
                label: 'Description',
                description: "If this field is blank, Platform's will be displayed.",
                component: (
                  <>
                    <Textarea
                      name="cm_artist_description"
                      value={states.cm_artist_description ?? ''}
                      onChange={onChangeInput}
                      style={{ height: '400px' }}
                    />
                  </>
                ),
                labelIcon: 'farDatabase',
              },
            ]}
          />
          {states?.gpt_summary && (
            <Table
              title="Bio Summary"
              options={Object.entries(states.gpt_summary).map(([key, value]) => ({
                label: capitalize(key.replace('_', ' ')),
                component: (
                  <Input value={value as string} onChange={onChangeGPTSummary} name={key} />
                ),
              }))}
            />
          )}
        </Flex>
        <Flex vertical gap={4}>
          <Table
            title="Career Stage & Growth"
            options={[
              {
                label: 'Career Growth',
                component: (
                  <Flex gap={2} vertical>
                    {CAREER_STAGE_MAPPER[data.artist_stage.career_stage]}
                    {['legendary', 'superstar'].includes(data.artist_stage.career_stage) && (
                      <Checkbox
                        checked={states[`override_for_artist_stage_${careerStageChangeOption}`]}
                        onCheckedChange={() =>
                          onChangeCheckBox(`override_for_artist_stage_${careerStageChangeOption}`)
                        }
                      >
                        <Flex align="center">
                          Override to {capitalize(careerStageChangeOption)}
                          <WhatsThis title="Override Career Stage">
                            Override the career stage to the opposite of the current stage. This is
                            used to manually set the career stage of an artist once every two weeks.
                          </WhatsThis>
                        </Flex>
                      </Checkbox>
                    )}
                  </Flex>
                ),
              },
              {
                label: 'Recent Momentum',
                component: RECENT_MOMENTUM_MAPPER[data.artist_stage.career_growth],
              },
            ]}
          />
          <Table
            title="Authenticity Risk"
            options={Object.entries(AUTHENTICITY_RISK_PLATFORM_MAP).map(([key, platform]) => {
              const overridden = states.authenticity_risk_overrides?.[key] ?? 0;
              const original = props.authenticity_risk?.[key] ?? 0;

              const value = overridden ? overridden : original;

              return {
                label: (
                  <Flex gap={4}>
                    <BrandIcon platform={platform.toLowerCase() as any} />{' '}
                    {AUTHENTICITY_RISK_PLATFORM_MAP[key]} {capitalize(key.split('_')[1])}
                  </Flex>
                ),
                component: (
                  <Flex align="start" gap={4} vertical>
                    <Select
                      value={value}
                      options={Array.from({ length: 6 }).map((_, i) => ({
                        label: i.toString(),
                        value: i,
                      }))}
                      onChange={onChangeState(`authenticity_risk_overrides.${key}` as any)}
                    />
                    {overridden ? <Muted>{`Overridden. Origin: ${original}`}</Muted> : null}
                  </Flex>
                ),
              };
            })}
          />
          <Table
            title="Contact Information"
            options={[
              {
                label: 'Booking Agent',
                component: (
                  <Textarea
                    style={{ height: '125px' }}
                    name="booking_agent"
                    value={states.booking_agent ?? ''}
                    onChange={onChangeInput}
                  />
                ),
              },
              {
                label: 'Record Label',
                component: (
                  <Textarea
                    style={{ height: '125px' }}
                    name="record_label"
                    value={states.record_label ?? ''}
                    onChange={onChangeInput}
                  />
                ),
              },
              {
                label: 'Press Contact',
                component: (
                  <Textarea
                    style={{ height: '125px' }}
                    name="press_contact"
                    value={states.press_contact ?? ''}
                    onChange={onChangeInput}
                  />
                ),
              },
              {
                label: 'General Manager',
                component: (
                  <Textarea
                    name="general_manager"
                    value={states.general_manager ?? ''}
                    onChange={onChangeInput}
                  />
                ),
              },
            ]}
          />
          <Table
            title="Flags"
            options={[
              {
                label: 'Inactive',
                component: (
                  <Checkbox
                    onCheckedChange={() => onChangeCheckBox('inactive')}
                    name="inactive"
                    checked={states.inactive}
                  />
                ),
              },
              {
                label: 'Duplicate artist',
                component: (
                  <Checkbox
                    onCheckedChange={() => onChangeCheckBox('is_duplicate')}
                    name="is_duplicate"
                    checked={states.is_duplicate}
                  />
                ),
              },
              {
                label: 'Hide / Non artist?',
                component: (
                  <Checkbox
                    onCheckedChange={() => onChangeCheckBox('is_non_artist')}
                    name="is_non_artist"
                    checked={states.is_non_artist}
                  />
                ),
              },
            ]}
          />
        </Flex>
      </Panel>
    </>
  );
}


const HideButton = ({ onChange, name, label, checked }) => {
  return (
    <Tooltip content={!checked ? `Hidden from ${label}` : `Show on ${label}`}>
      <Button
        variant="ghost"
        size="icon"
        icon={<FAIcon name={!checked ? 'farEye' : 'farEyeSlash'} />}
        className={!checked ? 'text-foreground' : 'text-gray-400'}
        onClick={() => onChange(name)}
      />
    </Tooltip>
  );
};

export default React.memo(Info);
