'use client';

import { BUTTON_VARIANTS } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { Button } from './button';
import { Select } from './select';
export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
  // @ts-ignore
  const [selectedMonth, setSelectedMonth] = React.useState(props.selected ?? new Date());

  const handleMonthChange = value => {
    const newMonth = new Date(selectedMonth);
    newMonth.setMonth(value);
    setSelectedMonth(newMonth);
  };

  const handleYearChange = value => {
    const newYear = new Date(selectedMonth);
    newYear.setFullYear(value);
    setSelectedMonth(newYear);
  };

  return (
    <div className="calendar-container">
      <DayPicker
        {...props}
        showOutsideDays={showOutsideDays}
        month={selectedMonth}
        className={cn('p-3', className)}
        classNames={{
          today: 'bg-accent text-accent-foreground',
          months: 'flex flex-col sm:flex-row space-y-4 sm:space-y-0',
          month: 'space-y-4',
          month_caption: 'flex justify-center pt-1 relative items-center',
          caption_label: 'text-sm font-normal',
          nav: 'space-x-1 flex items-center',
          button_previous: 'hidden',
          button_next: 'hidden',
          month_grid: 'w-full border-collapse space-y-1',
          weekdays: 'flex',
          weekday: 'text-muted-foreground rounded-md w-8 font-normal text-[0.8rem]',
          week: 'flex w-full mt-2',
          day: cn(
            BUTTON_VARIANTS({ variant: 'ghost' }),
            'h-8 w-8 p-0 font-normal aria-selected:opacity-100',
            props.mode === 'range'
              ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
              : '[&:has([aria-selected])]:rounded-md'
          ),
          range_start: 'day-range-start',
          range_end: 'day-range-end',
          selected:
            'bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground',
          outside:
            'day-outside text-muted-foreground opacity-50  aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
          disabled: 'text-muted-foreground opacity-50',
          range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
          hidden: 'invisible',
          ...classNames,
        }}
        components={{
          MonthCaption: () => (
            <div className="flex items-center justify-center gap-2 mb-4 w-[200px]">
              <Select
                value={selectedMonth.getMonth()}
                onChange={handleMonthChange}
                className="border p-2 rounded w-auto"
                options={Array.from({ length: 12 }).map((_, index) => ({
                  value: index,
                  label: new Date(0, index).toLocaleString('en-US', { month: 'short' }),
                }))}
              />
              <Select
                value={selectedMonth.getFullYear()}
                onChange={handleYearChange}
                className="border p-2 rounded w-auto"
                options={Array.from({ length: new Date().getFullYear() + 10 - 1900 + 1 })
                  .map((_, index) => {
                    const year = 1900 + index;
                    return {
                      value: year,
                      label: year.toString(),
                    };
                  })
                  .filter(({ value }) =>
                    // @ts-expect-error
                    props.disabled?.before
                      ? // @ts-expect-error
                        value >= props.disabled.before.getFullYear()
                      : // @ts-expect-error
                        props.disabled?.after
                        ? // @ts-expect-error
                          value <= props.disabled.after.getFullYear()
                        : true
                  )}
              ></Select>
            </div>
          ),
          DayButton: ({ children, ...props }) => (
            <Button
              size="icon"
              variant="text"
              {...props}
              onClick={props.disabled ? undefined : props.onClick}
            >
              {children}
            </Button>
          ),
        }}
      />
    </div>
  );
}

Calendar.displayName = 'Calendar';

export { Calendar };
