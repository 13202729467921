import { ENTITY_ICON } from '@Constants/entity';
import { KeywordDetailTrackItem, useGetKeywordTrackDetails } from '@Services/keywords';
import { AntdTable } from '@Shared';
import React from 'react';
import { ENTITY_COLUMNS } from './columns';
import { Panel } from '@/ui';

interface Props {
  q: string;
}

const Tracks = ({ q }: Props) => {
  const { data: track, isLoading: isLoadingTrack } = useGetKeywordTrackDetails(
    {
      data: {
        q: q && q.replaceAll(' ', '').toLowerCase(),
      },
    },
    {
      disable: !q,
    }
  );

  return (
    <Panel icon={ENTITY_ICON.TRACK} header="Tracks">
      <AntdTable<KeywordDetailTrackItem & { position: number }>
        columns={ENTITY_COLUMNS('track') as any}
        data={track?.map((e, index) => ({ ...e, position: index + 1 })) ?? []}
        showSizeChanger={false}
        pageSize={5}
        loading={isLoadingTrack}
      />
    </Panel>
  );
};

export default Tracks;
