import * as React from 'react';

import { cn } from '@/lib/utils';
import { cva, VariantProps } from 'class-variance-authority';

const inputVariants = cva(
  `h-9 w-full rounded-md border border-input bg-transparent py-1 text-sm shadow-sm transition-colors file:border-0 
  file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1
  disabled:cursor-not-allowed disabled:opacity-50 pr-4`,
  {
    variants: {
      variant: {
        default: 'bg-white dark:bg-black',
        filled:
          'bg-input-background text-input-foreground placeholder-input-foreground border-input-background',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
);

const prefixVariants = cva('absolute left-4 flex', {
  variants: {
    variant: {
      default: '',
      filled: 'text-input-foreground',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'>,
    VariantProps<typeof inputVariants> {
  prefix?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, prefix, ...props }, ref) => {
    return (
      <div className={cn('relative flex items-center', className)}>
        {prefix && <div className={prefixVariants({ ...props })}>{prefix}</div>}
        <input
          type={type}
          className={cn(inputVariants({ ...props }), prefix ? 'pl-10' : 'pl-4', className)}
          ref={ref}
          {...props}
        />
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
