import { useCreateArtist } from '@Services/artist';
import { Table } from '@Shared/Style';
import { generateCmUrl } from '@Utils';
import { Divider, Flex, Input, Typography } from 'antd';
import React from 'react';

interface Response {
  artistId: number;
  artistLink: string;
}

const CreateArtist = () => {
  const { execute, isLoading } = useCreateArtist();
  const [response, setResponse] = React.useState<Response>();

  return (
    <Table
      icon="farCirclePlus"
      title="Create New Artist"
      panelStyle
      layout="horizontal"
      form={{
        submitPosition: 'bottom',
        onSubmit: values => {
          execute({
            data: values,
          }).then(response => {
            setResponse(response.data.data);
          });
        },
        initialValues: {
          name: '',
          image_url: undefined,
        },
        loading: isLoading,
      }}
      options={[
        {
          labelIcon: 'farTag',
          label: 'Name',
          value: 'name',
          rules: [
            {
              required: true,
              message: 'Please input artist name!',
            },
          ],
          component: <Input placeholder="Artist's Name." />,
          span: 12,
        },
        {
          labelIcon: 'farImage',
          label: 'Image URL',
          value: 'image_url',
          rules: [
            {
              required: true,
              type: 'url',
              message: 'Please input image URL!',
            },
          ],
          component: <Input placeholder="Image URL." />,
        },
      ]}
    >
      {response && (
        <Flex vertical className="mt-4">
          <Divider />
          <Typography.Title level={5}>Created Artist</Typography.Title>
          <Typography.Link href={response.artistLink} target="_blank">
            {response.artistLink}
          </Typography.Link>
        </Flex>
      )}
    </Table>
  );
};

export default CreateArtist;
