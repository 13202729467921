import React, { useState } from 'react';
import { capitalize } from 'lodash';
import { Select, Divider, Space, Input, Button, Radio, Flex, Checkbox } from 'antd';
import { REASON_TEMPLATES } from './constants';
import { AntdIcon } from '../../../../shared/Style';
import {
  generateCmUrl,
  generateInternalLink,
  getFormattedLocalDate,
  showInfoMessage,
} from '../../../../utilities';
import { useResendClaimEmail } from '../../../../services/request';
import { TARGET_MAPPER } from '../../../../utilities/constants';
import { Column } from '@Shared/Table/AntdTable';
import { Link } from 'react-router-dom';
import UserClaimsModal from './UserClaimsModal';

const getOptions = reasonTemplates =>
  reasonTemplates.reduce((all, one, index) => [...all, { value: one, key: one, label: one }], []);

function DropdownRender({ menu, handleAddReasonOption }) {
  const [name, onNameChange] = useState('');
  return (
    <>
      {menu}
      <Divider style={{ margin: '8px 0' }} />
      <Space style={{ padding: '0 8px 4px' }}>
        <Input
          placeholder="Please enter item"
          value={name}
          onChange={({ target: { value } }) => onNameChange(value)}
          onKeyDown={e => e.stopPropagation()}
        />
        <Button
          type="text"
          icon={<AntdIcon name="plus" />}
          onClick={() => handleAddReasonOption(name)}
        >
          Add item
        </Button>
      </Space>
    </>
  );
}

export const CLAIM_SUBMIT_MODAL_COLUMNS = ({
  declines,
  handleAddReasonOption,
  onChangeReason,
  reasons,
  reasonTemplates,
}: {
  declines: number[];
  onChangeReason: (id: number, reason: string) => void;
  reasons: Record<number, string>;
  handleAddReasonOption: (reason: string) => void;
  reasonTemplates: string[];
}) => [
  {
    Header: 'Type',
    accessor: 'target',
    Cell: ({ value }) => <span>{capitalize(TARGET_MAPPER[value])}</span>,
  },
  {
    Header: 'Name',
    Cell: ({ original }) => (
      <a
        href={generateCmUrl(TARGET_MAPPER[original.target], {
          id: original.target_id,
        })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {original.target_name}
      </a>
    ),
  },
  {
    Header: 'User Email',
    accessor: 'user_email',
  },
  {
    Header: 'Verification Code',
    accessor: 'verification_code',
  },
  {
    Header: 'Reason',
    accessor: 'id',
    Cell: ({ value }) =>
      declines.includes(value) ? (
        <Select
          style={{ width: 300 }}
          onChange={inputValue => onChangeReason(value, inputValue ?? ' ')}
          placeholder="Reason"
          options={getOptions(reasonTemplates)}
          value={reasons[value]}
          // eslint-disable-next-line react/no-unstable-nested-components
          dropdownRender={menu => (
            <DropdownRender menu={menu} handleAddReasonOption={handleAddReasonOption} />
          )}
        />
      ) : (
        ''
      ),
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({ value }) => <span>{value?.split('T')?.[0]}</span>,
  },
];

export const CLAIM_COLUMNS = ({
  onClickApprove,
  onClickDecline,
  approves,
  declines,
  onClickSelectApproveAll,
  onClickSelectDeclineAll,
  data,
}): Column<any>[] => [
  {
    Header: 'Claim Id',
    accessor: 'id',
    width: 50,
  },
  {
    Header: 'Type',
    accessor: 'target',
    Cell: props => <span>{capitalize(TARGET_MAPPER[props.value])}</span>,
  },
  {
    Header: 'Name',
    accessor: 'target_name',
    Cell: props => (
      <a
        href={generateCmUrl(TARGET_MAPPER[props.original.target], { id: props.original.target_id })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.value}
      </a>
    ),
  },
  {
    Header: 'User Email',
    accessor: 'user_email',
    Cell: props => (
      <Link
        to={generateInternalLink('user/user-profile', {
          qs: props.value,
        })}
      >
        {props.value}
      </Link>
    ),
  },
  {
    Header: 'User Claims',
    accessor: 'user_info',
    Cell: props => (
      <UserClaimsModal data={props.original.other_user_requests} id={props.original.user_email} />
    ),
  },
  {
    Header: 'Resend Email',
    Cell: ({ original: { user_email, user_name, verification_code, target_name, id } }) => {
      const { execute, isLoading } = useResendClaimEmail();
      return (
        <Button
          className="blue"
          icon={<AntdIcon name="mail" />}
          loading={isLoading}
          onClick={() =>
            execute({
              data: {
                email: user_email,
                name: user_name,
                code: verification_code,
                targetId: String(id),
                targetName: target_name,
              },
            }).then(() => {
              showInfoMessage(`Email has been sent to ${user_email}`);
            })
          }
        />
      );
    },
  },
  {
    Header: 'Verification Code',
    accessor: 'verification_code',
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: ({ value }) => <span>{getFormattedLocalDate(value)}</span>,
  },
  {
    accessor: 'id',
    Cell: ({ value }) => (
      <Flex justify="center">
        <Radio
          name={`approve_${value}`}
          checked={approves.includes(value) ?? false}
          onClick={() => onClickApprove(value)}
        />
      </Flex>
    ),
    Header: (
      <Flex justify="center" align="center" gap="small">
        Approve
        <Checkbox
          checked={approves.length === data?.length && data?.length > 0}
          onClick={onClickSelectApproveAll}
        />
      </Flex>
    ),
  },
  {
    accessor: 'id',
    Cell: ({ value }) => (
      <Flex justify="center">
        <Radio
          name={`decline_${value}`}
          checked={declines.includes(value) ?? false}
          onClick={() => onClickDecline(value)}
        />
      </Flex>
    ),
    Header: (
      <Flex justify="center" align="center" gap="small">
        Decline
        <Checkbox
          checked={declines.length === data?.length && data?.length > 0}
          onClick={onClickSelectDeclineAll}
        />
      </Flex>
    ),
  },
];
