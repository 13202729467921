import { Button, Panel } from '@/ui';
import { Table } from '@Components';
import FAIcon from '@Components/ui/fa-icons';
import { useDeletePlaylist } from '@Services/playlist';
import { useGetEntity } from '@Services/search';

const DeletePlaylist = ({ q, setQ }: { q: string; setQ: (q: string) => void }) => {
  const { data } = useGetEntity({ data: { q } });
  const { execute, isLoading } = useDeletePlaylist();

  return (
    <Panel
      header={data?.name}
      classNames={{
        footer: 'flex justify-end mt-4',
      }}
      footer={
        <Button
          icon={<FAIcon name="farTrash" />}
          variant="destructive"
          onClick={() => {
            execute({ data: { id: data?.id, streamingType: data?.streamingType } });
            setQ('');
          }}
          loading={isLoading}>
          Delete
        </Button>
      }>
      <Table
        borderless
        options={[
          {
            label: 'Name',
            component: data?.name,
          },
          {
            label: 'Description',
            component: <div dangerouslySetInnerHTML={{ __html: data?.description }} />,
          },
          {
            label: 'Tags',
            component: data?.tags.map((tag: any) => tag.name).join(', '),
          },
          {
            label: 'Streaming Type',
            component: data?.streamingType,
          },
        ]}
      />
    </Panel>
  );
};

export default DeletePlaylist;
