import ArtistIdentityUpdateRequest from '@/pages/request-manager/artist-identity-change';
import Claim from '@/pages/request-manager/claim';
import TrackArtistRequest from '@/pages/request-manager/track-artist-change';
import PushNotificationManager from '@/pages/user-manager/send-push-notification';
import React from 'react';
import LabelGroup from 'screens/Label/LabelGroup';
import TwoFactAuthLog from 'screens/Logs/TwoFactAuthLog';
import TeamManager from 'screens/Onesheet/TeamManager';
import OnesheetUserProfile from 'screens/Onesheet/UserProfile';
import ManageCoupon from 'screens/Purchase/ManageCoupon';
import BlockedDomains from 'screens/Restrictions/BlockedDomains';
import DeleteCMdataPanel from '../pages/entity/delete-entity';
import AddMetadata from '../screens/Artist/AddMetadata';
import AddNewArtist from '../screens/Artist/CreateNewArtist';
import MergeArtist from '../screens/Artist/MergeArtist';
import EditCMdataPanel from '../screens/EditData/EditEntity';
import MergeSocialStat from '../screens/EditData/MergeSocialStat';
import LabelAssociation from '../screens/Label/LabelAssociation';
import CampaignForUser from '../screens/Marketing/Campaign/ForUser';

import GenreRequest from '../pages/request-manager/custom-genre';
import DSPLinkingRequest from '../pages/request-manager/dsp-linking';
import NewsLinkRequest from '../pages/request-manager/news-link';
import URLUpdateRequest from '../pages/request-manager/url-change';
import ChurnedUsers from '../screens/Purchase/ChurnedUsers';
import ManageTeam from '../screens/Purchase/TeamManager';
import TrialSubscription from '../pages/purchase/free-trial-subscription';

import SignUpHistory from '../pages/linking/sign-ups-for-artist-plan';
import AdminUsers from '../pages/user-manager/admin-users';
import EmailReport from '../pages/user-manager/send-email-report';
import TransferFavorites from '../pages/user-manager/transfer-favorites';
import TrustedUsers from '../pages/user-manager/trusted-users';
import UserProfile from '../pages/user-manager/user-profile';
import APILimitOverrides from '../screens/Restrictions/APILimitOverrides';
import StrictDomains from '../screens/Restrictions/StrictDomains';
import AddNewTrack from '../screens/Track/AddNewTrack';

import EditGenre from '@/pages/genre&mood/edit-entity-genre-and-mood';
import KeywordSearch from '@/pages/keywords/keyword-search';
import TrendingKeywords from '@/pages/keywords/trending-keywords';
import LabelCorrection from '@/pages/label/label-correction';
import ActiveUsers from '@/pages/logging/ActiveUsers';
import NewSignups from '@/pages/logging/NewSignups';
import PageHistoryStatistics from '@/pages/logging/PageHistoryStatistics';
import DeleteStripeInvoices from '@/pages/utility/delete-stripe-invoices';
import { FAIcons } from '@Components/ui/fa-icons';
import { ENTITY_ICON } from '@Constants/entity';
import Campaign from '@Screens/Marketing/Campaign/ForDomain';
import EditArtistGenreBatch from 'screens/GenreAndMood/EditArtistGenreBatch';
import EditGenreHierarchy from 'screens/GenreAndMood/EditGenreHierarchy';
import BulkLinking from 'screens/Linking/BulkLinking';
import LinkCompleteness from 'screens/Logs/LinkCompleteness';
import CampaignHistory from 'screens/Marketing/Campaign/History';
import CompanyUnivAccess from 'screens/Marketing/CompanyUnivAccess';
import RequestBadge from '../pages/home/RequestBadge';
import ClearCache from '../pages/utility/clear-cache';

export interface MenuItem {
  isNew?: boolean;
  name: string;
  labelSuffix?: React.JSX.Element;
  component: React.JSX.Element;
  icon?: FAIcons;
  keywords?: string[];
}

const ITEMS = {
  KEYWORDS: [
    {
      name: 'Trending Keywords',
      component: <TrendingKeywords />,
      icon: 'fadChartLineUp',
      isNew: true,
    },
    {
      name: 'Keyword Search',
      component: <KeywordSearch />,
      icon: 'fadSearch',
      isNew: true,
    },
  ],
  UTILITY: [
    {
      name: 'Clear Cache',
      component: <ClearCache />,
    },
    {
      name: 'Delete Stripe Invoices',
      component: <DeleteStripeInvoices />,
    },
  ],
  USER: [
    {
      name: 'User Profile',
      component: <UserProfile />,
      icon: 'farUser',
      keywords: ['subscription', 'block', 'refresh token', '2fa', 'password', 'session', 'claim', 'viewable'],
    },
    {
      name: 'Trusted User',
      icon: 'farUserTie',
      component: <TrustedUsers />,
    },
    {
      name: 'Admin User',
      icon: 'farUserGroupCrown',
      component: <AdminUsers />,
    },

    {
      name: 'Send Email Report',
      icon: 'farEnvelope',
      component: <EmailReport />,
    },
    {
      name: 'Transfer Favorites',
      icon: 'farArrowRightArrowLeft',
      component: <TransferFavorites />,
    },
    {
      name: 'Send Push Notification',
      icon: 'farMobile',
      component: <PushNotificationManager />,
      keywords: ['mobile'],
    },
  ],
  TRACK: [
    {
      name: 'Create Track',
      icon: 'farCirclePlus',
      component: <AddNewTrack />,
    },
  ],
  RESTRICTION: [
    {
      name: 'Block Domains',
      component: <BlockedDomains />,
      icon: 'farBan',
    },
    {
      name: 'Strict Domains',
      component: <StrictDomains />,
      icon: 'farBan',
    },
    {
      name: 'Public API Limit Overrides',
      component: <APILimitOverrides />,
      icon: 'farUserSlash',
      keywords: ['plan'],
    },
  ],
  REQUESTS: [
    {
      name: 'DSP Linking',
      labelSuffix: <RequestBadge type="dspLink" />,
      component: <DSPLinkingRequest />,
      icon: 'farLink',
    },
    {
      name: 'URL Change',
      labelSuffix: <RequestBadge type="urlChange" />,
      component: <URLUpdateRequest />,
      icon: 'farLink',
    },
    {
      name: 'Artist Identity Change',
      labelSuffix: <RequestBadge type="artistIdentity" />,
      component: <ArtistIdentityUpdateRequest />,
      icon: 'farVenusMars',
    },
    {
      name: 'News Link',
      labelSuffix: <RequestBadge type="newsLink" />,
      component: <NewsLinkRequest />,
      icon: 'farNewspaper',
    },
    {
      name: 'Custom Genre',
      labelSuffix: <RequestBadge type="customGenre" />,
      component: <GenreRequest />,
      icon: ENTITY_ICON.GENRE,
    },
    {
      name: 'Track Artist Change',
      labelSuffix: <RequestBadge type="trackArtistChange" />,
      component: <TrackArtistRequest />,
      icon: 'farCompactDisc',
    },
    {
      name: 'Claim',
      component: <Claim />,
      icon: 'farHand',
    },
  ],
  PURCHASE: [
    {
      name: 'Churned Users',
      component: <ChurnedUsers />,
      icon: 'farUserTie',
      keywords: ['plan', 'stripe'],
    },
    {
      name: 'Free Trial Subscription',
      component: <TrialSubscription />,
      icon: 'farGifts',
      keywords: ['free', 'trial'],
    },
    {
      name: 'Coupon Manager',
      component: <ManageCoupon />,
      icon: 'farTicket',
    },
    {
      name: 'Team Manager',
      icon: 'farPeople',
      component: <ManageTeam />,
      keywords: ['members'],
    },
  ],
  ONESHEET_USER_MANAGER: [
    {
      name: 'User Profile',
      component: <OnesheetUserProfile />,
      icon: 'farUser',
    },
  ],
  ONESHEET_TEAM_MANAGER: [
    {
      name: 'Team Manager',
      component: <TeamManager />,
      icon: 'farPeople',
    },
  ],
  MARKETING: [
    {
      name: 'Company-wide and University Organizations',
      icon: 'farBuildingUser',
      component: <CompanyUnivAccess />,
      keywords: ['user pool', 'pool'],
    },
    {
      name: 'Campaign For Domain',
      component: <Campaign />,
    },
    {
      name: 'Campaign For User',
      component: <CampaignForUser />,
    },
    {
      name: 'Campaign History',
      component: <CampaignHistory />,
    },
  ],
  ARTIST: [
    {
      name: 'Create Artist',
      icon: 'farCirclePlus',
      component: <AddNewArtist />,
    },
    {
      name: 'Merge Duplicated Artist',
      icon: 'farMerge',
      component: <MergeArtist />,
    },
    {
      name: 'Add Artist Metadata',
      icon: 'farPlus',
      component: <AddMetadata />,
      keywords: ['social'],
    },
  ],
  CM_DATA: [
    {
      name: 'Edit Entity',
      component: <EditCMdataPanel />,
      keywords: [
        'track',
        'album',
        'artist',
        'playlist',
        'curator',
        'dsp',
        'linking',
        'genre',
        'country',
        'city',
        'gender',
        'birthday',
        'image',
        'career',
        'stage',
        'authenticity',
        'contact',
        'information',
        'bio',
        'summary',
        'non artist',
        'hide',
        'inactive',
        'duplication',
      ],
    },
    {
      name: 'Delete Entity',
      component: <DeleteCMdataPanel />,
    },
    {
      name: 'Merge Social Data',
      component: <MergeSocialStat />,
    },
  ],
  TAG: [
    {
      name: 'Edit Entity Genre & Mood',
      component: <EditGenre />,
      icon: 'farEdit',
      keywords: ['genre', 'mood'],
    },
    {
      name: 'Edit Artist Genre Batch',
      component: <EditArtistGenreBatch />,
      icon: 'farEdit',
    },
    {
      name: 'Genre Hierarchy',
      component: <EditGenreHierarchy />,
      icon: 'farSitemap',
    },
  ],
  LABEL: [
    {
      name: 'Label Group',
      icon: 'farLayerGroup',
      component: <LabelGroup />,
    },
    {
      name: 'Label Association',
      icon: 'farLayerGroup',
      component: <LabelAssociation />,
    },
    {
      name: 'Label Correction',
      component: <LabelCorrection />,
    },
  ],
  LINKING: [
    {
      name: 'Link Completeness',
      component: <LinkCompleteness />,
      icon: 'farBallotCheck',
    },
    {
      icon: 'farHistory',
      name: 'Sign Ups For Artist Plan',
      component: <SignUpHistory />,
    },
    {
      name: 'Bulk URL Update',
      component: <BulkLinking />,
      icon: 'farLink',
    },
  ],
  LOGGING: [
    {
      name: 'Two Factor Authentication Log',
      component: <TwoFactAuthLog />,
      keywords: ['2fa'],
    },
    {
      name: 'Page History Statistics',
      component: <PageHistoryStatistics />,
    },
    {
      name: 'New Signups',
      component: <NewSignups />,
    },
    {
      name: 'Active Users',
      component: <ActiveUsers />,
    },
  ],
} satisfies Record<string, MenuItem[]>;

export default ITEMS;
