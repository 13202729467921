import { AntdIcon, Segments } from '@Shared/Style';
import React, { useState } from 'react';
import { API_BASED_URL } from '@Services/helpers';
import { generateCmUrl, generateInternalLink, showErrorMessage, showInfoMessage } from '@Utils';
import { AntdTable, SearchInput } from '@Shared';
import { Link } from 'react-router-dom';
import FAIcon from '@Components/ui/fa-icons';
import { Badge, Button, FileUpload, Flex, Panel, SectionTitle } from '@/ui';

interface Item {
  isrc: string;
  message: string;
  status: string;
  url: string;
  cm_track?: number;
  track_name: string;
}
interface Response {
  succeeded: Item[];
  failed: Item[];
}

const BulkLinking = () => {
  const [response, setResponse] = useState<Record<string, Response>>();
  const [filteredData, setFilteredData] = useState<Item[] | undefined>();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<string>('');

  return (
    <Panel
      icon="farLink"
      header="Bulk Linking (Track)"
      description={
        <>
          Only support <code>.xlsx</code> file format having <code>ISRC</code> and <code>URL</code>{' '}
          columns. Please check the template (on the top right) for more details.
        </>
      }
      aside={
        <Link
          to={
            'https://storage.googleapis.com/cm-app-assets/request/chartmetric-bulk-url-update-example.xlsx'
          }
        >
          <Button className="green" icon={<FAIcon name="farFileCsv" />}>
            Download Template
          </Button>
        </Link>
      }
    >
      <FileUpload
        type="single"
        accept=".xlsx"
        uploadUrl={API_BASED_URL + '/admin/track/bulk-excel'}
        onChange={(status, file, response) => {
          console.log(status, file, response);
          if (status === 'uploading') {
            setLoading(true);
          }
          if (status === 'done') {
            setLoading(false);
            showInfoMessage(`${file.name} file uploaded successfully.`);
            setSelected(file.name);
            setResponse(old => {
              return {
                ...old,
                [file.name]: response.obj,
              };
            });
          } else if (status === 'error') {
            showErrorMessage(`${file.name} file upload failed.`);
          }
        }}
        onRemove={file => {
          setResponse(old =>
            Object.fromEntries(Object.entries(old ?? {}).filter(([key]) => key !== file.name))
          );
          setFilteredData(undefined);
        }}
      />
      {Object.keys(response ?? {}).length > 0 && (
        <Segments
          options={Object.keys(response ?? {}).map(key => ({
            title: key,
            content: (
              <Flex vertical className="pt-2">
                <SectionTitle className="pb-2">
                  Result ({response?.[selected].succeeded.length} succeeded,{' '}
                  {response?.[selected].failed.length} failed )
                </SectionTitle>
                <SearchInput
                  onChange={setFilteredData}
                  originalData={
                    [
                      ...(response?.[selected].failed ?? []).map(e => ({
                        ...e,
                        status: 'error',
                      })),
                      ...(response?.[selected].succeeded ?? []).map(e => ({
                        ...e,
                        status: 'success',
                      })),
                    ] as Item[]
                  }
                />
                <br />
                <AntdTable
                  loading={loading}
                  data={filteredData}
                  columns={[
                    {
                      Header: 'Status',
                      accessor: 'status',
                      Cell: ({ value, original }) => (
                        <Flex
                          align="center"
                          className={
                            value === 'success'
                              ? 'text-green-500'
                              : original.message ===
                                  'Track is now updating and will be processed shortly.'
                                ? 'text-yellow-500'
                                : 'text-red-500'
                          }
                        >
                          <FAIcon
                            name={value === 'success' ? 'fasCheckCircle' : 'fasCircleXmark'}
                          />
                          <p>{original.message}</p>
                        </Flex>
                      ),
                    },
                    {
                      Header: 'URL',
                      accessor: 'url',
                      Cell: ({ value }) => (
                        <Link to={value} target="_blank" rel="noreferrer">
                          {value}
                        </Link>
                      ),
                    },
                    {
                      Header: 'Track',
                      accessor: 'track_name',
                      Cell: ({ value, original }) =>
                        value ? (
                          <Flex align="center">
                            <Link
                              target="_blank"
                              to={generateCmUrl('track', { id: original.cm_track })}
                            >
                              {value}
                            </Link>
                            <Link
                              to={generateInternalLink('cmdata/edit-entity', {
                                q: generateCmUrl('track', { id: original.cm_track }),
                              })}
                            >
                              <Button
                                variant="ghost"
                                icon={<FAIcon name="farEdit" color="black" />}
                              ></Button>
                            </Link>
                          </Flex>
                        ) : null,
                    },
                    {
                      Header: 'ISRC',
                      accessor: 'isrc',
                    },
                  ]}
                />
              </Flex>
            ),
          }))}
        />
      )}
    </Panel>
  );
};

export default BulkLinking;
