import { Badge, Flex } from '@/ui';
import React from 'react';

function LinksCell({ currentUrl, newUrl, targetId }) {
  return (
    <Flex vertical justify="start" gap={2}>
      <Flex>
        <Badge variant="outline" className="w-[70px]">
          Current
        </Badge>
        <a href={currentUrl} target="_blank" rel="noopener noreferrer">
          {currentUrl}
        </a>
      </Flex>
      <Flex>
        <Badge variant="success" className="w-[70px]">
          New
        </Badge>
        <a href={newUrl} target="_blank" rel="noopener noreferrer" className="text-left">
          {newUrl}
        </a>
      </Flex>
    </Flex>
  );
}

export default LinksCell;
