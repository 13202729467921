import {
  useAddCompanyUnivUser,
  useAddCompanyUnivDomain,
  useGetCompanyUnivPools,
} from '@Services/userPool';
import FAIcon from '@Components/ui/fa-icons';
import { Button, Code, FileUpload, Flex, Input, Modal, ModalButton, Muted, useModal } from '@/ui';
import { FormTable, Table } from '@Components';
import { z } from 'zod';
import { Segments } from '@Shared/Style';
import { API_BASED_URL } from '@Services/helpers';
import { CSVLink } from 'react-csv';
import { showErrorMessage, showInfoMessage } from '@Utils';

const form = z.object({
  name: z.string().min(1, { message: 'Organization Name is required' }),
  domain: z
    .string()
    .min(1, { message: 'Email domain is required' })
    .regex(/@/, { message: '@ should be included.' }),
  stripeCustomerId: z.string().optional(),
});

const CSV_FORMAT = [
  { label: 'Domain', key: 'domain' },
  { label: 'Company', key: 'company' },
  { label: 'Subsidiary', key: 'subsidiary' },
];

const DUMMY_DATA = [{ domain: 'spotify.com', company: 'Spotify', subsidiary: 'Spotify India' }];

const CreateUserPoolModal = () => {
  const { execute: addOrgUser, isLoading: isLoadingAddOrgUser } = useAddCompanyUnivUser();
  const { execute: addOrgDomain, isLoading: isLoadingAddOrgDomain } = useAddCompanyUnivDomain();

  const { refetch } = useGetCompanyUnivPools();

  const { closeModal, showModal, modalProps } = useModal();

  const handleAddOrgUser = ({ name, domain, stripeCustomerId }) => {
    addOrgUser({
      data: {
        name,
        stripeCustomerId,
      },
      query: {
        createCustomer: !stripeCustomerId,
      },
    }).then(e => {
      const {
        data,
      }: {
        data: {
          data: {
            poolId: number;
          };
        };
      } = e;

      const { poolId } = data.data;

      addOrgDomain({
        path: {
          id: poolId,
        },
        data: {
          domain,
        },
      }).then(() => {
        refetch();
        closeModal();
      });
    });
  };
  return (
    <>
      <Button variant="submit" icon={<FAIcon name="fasPlus" />} onClick={showModal}>
        Create New Organization
      </Button>
      <Modal
        {...modalProps}
        open={modalProps.show}
        classNames={{
          content: 'w-[500px]',
        }}
        header="Add Company-wide/University Users Access (User Pools)"
      >
        <Segments
          options={[
            {
              title: 'Single',
              content: (
                <FormTable
                  schema={form}
                  loading={isLoadingAddOrgDomain || isLoadingAddOrgDomain}
                  onSubmit={handleAddOrgUser}
                  options={[
                    {
                      label: 'Organization Name *',
                      name: 'name',
                      component: (
                        <Input
                          name="name"
                          prefix={<FAIcon name="farUser" />}
                          placeholder="Organization Name"
                        />
                      ),
                    },
                    {
                      label: 'Email domain *',
                      description:
                        'Only one domain is allowed when you create a new user pool. and, you can add more domains via add button of the table below.',
                      name: 'domain',
                      component: (
                        <Input prefix={<FAIcon name="farEnvelope" />} placeholder="Email domain" />
                      ),
                    },
                    {
                      label: 'Stripe ID',
                      name: 'stripeCustomerId',
                      description:
                        'If you want to use the same stripe customer id, you can input it. If you do not have it, it will be created automatically.',
                      component: (
                        <Input
                          name="stripeCustomerId"
                          prefix={<FAIcon name="fadTag" />}
                          placeholder="Stripe ID (Optional)"
                        />
                      ),
                    },
                  ]}
                />
              ),
            },
            {
              title: 'Bulk',
              content: (
                <Flex vertical gap={4}>
                  <Flex justify="end">
                    <CSVLink
                      data={DUMMY_DATA}
                      filename="company-univ-access-template.csv"
                      headers={CSV_FORMAT}
                    >
                      <Button icon={<FAIcon name="farFileCsv" />}>Download Template</Button>
                    </CSVLink>
                  </Flex>
                  <Muted>
                    Only support <Code>.csv</Code> file format having{' '}
                    <Code>Domain (e.g. "spotify.com" or "@spotify.com")</Code>,{' '}
                    <Code>company (e.g. "Spotify")</Code>,
                    <Code>subsidiary (e.g. "Spotify India")</Code>, if the domain is a subsidiary of
                    the company, columns.
                  </Muted>
                  <FileUpload
                    accept=".csv"
                    onChange={status => {
                      if (status === 'done') {
                        refetch();
                        closeModal();
                        showInfoMessage('Successfully added user pools.');
                      } else if (status === 'error') {
                        showErrorMessage('Failed to add user pools.');
                      }
                    }}
                    uploadUrl={`${API_BASED_URL}/admin/user/pool/batch`}
                    type="single"
                  />
                </Flex>
              ),
              icon: 'farFileCsv',
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default CreateUserPoolModal;
