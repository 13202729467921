import { useGetEntity } from '@Services/search';
import React, { useEffect, useState } from 'react';
import { EditEntityProps } from '..';
import { isEmpty } from 'lodash';
import { generateCmUrl } from '@Utils';
import { useUpdateAlbum } from '@Services/meta';
import { Table } from '@Components';
import { Button, Flex, Input } from '@/ui';
import EntityCell from '@Shared/Table/EntityCell';

const Album = ({ q }: EditEntityProps) => {
  const {
    data: trackData,
    isFetching,
    refetch,
  } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  const data = trackData.albums[0];

  const [label, setLabel] = useState(data.label);
  const { execute, isLoading } = useUpdateAlbum();

  useEffect(() => {
    setLabel(data.label);
  }, [data]);

  return (
    <Table
      title="Album"
      options={[
        {
          label: 'Name',
          component: <EntityCell entity="album" value={data} />,
        },
        {
          label: 'Label',
          component: (
            <Flex gap={2} justify="between">
              <Input
                style={{ maxWidth: '300px' }}
                value={label}
                onChange={e => {
                  setLabel(e.target.value);
                }}
              />
              <Button
                loading={isLoading}
                className="green"
                onClick={() => {
                  execute({ query: { id: data.id }, data: { label } }).then(() => refetch());
                }}
              >
                Update
              </Button>
            </Flex>
          ),
        },
        {
          label: 'UPC',
          component: data.upc,
        },
      ]}
    />
  );
};

export default Album;
