import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import {
  getFormattedLocalDate,
  getDateFormatFromEpoch,
  showErrorMessage,
  showInfoMessage,
} from '../../../../utilities';
import SearchInput from '../../../../shared/Table/SearchInput';
import CampaignContentModal from '../Components/CampaignContentModal';
import useModal from '../../../../hooks/useModal';
import {
  campaignStripePortal,
  useAddCampaign,
  useDeleteCampaign,
  useGetCampaign,
  useGetCampaignContentById,
  useGetStripeCoupons,
} from '../../../../services/campaign';
import styles from '../Campaign.module.scss';
import StripeCouponModal from '../Components/StripeCouponModal';
import { STRIPE_STANDARD_PLANS } from '../../../../utilities/constants';
import FAIcon from '../../../../components/ui/fa-icons';
import { Button, Card, Flex, Input, ModalButton, Muted, Panel, Skeleton } from '@/ui';
import useCampaign from '../useCampaign';
import AddCampaignSection from '../Components/AddCampaignSection';
import { Table } from '@Components';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import { useGetCoupons } from '@Services/purchase';
import ContentCard from '../Components/ContentCard';
import { useUser } from '@Services/auth';
// TODO: coupon panel design

const Campaign = () => {
  const campaignProps = useCampaign();
  const [domain, setDomain] = useState('');
  const { setAllStates, priceId, coupon, content, enableCoupon } = campaignProps;

  const { execute } = useAddCampaign();
  const { refetch } = useGetCampaign();

  const handleSubmit = () => {
    execute({
      data: {
        domain,
        contentId: content?.id,
        couponCode: coupon?.id,
        priceId: priceId!,
      },
    }).then(() => {
      refetch();
      setDomain('');
      setAllStates({
        priceId: undefined,
        coupon: undefined,
        content: undefined,
        enableCoupon: false,
      });
    });
  };

  const disabled = isEmpty(domain) || isEmpty(priceId) || (enableCoupon && !coupon);

  return (
    <>
      <Panel
        classNames={{
          footer: 'flex justify-end',
        }}
        header="Campaign For Domain"
        description="When a user from a specific domain enters the Campaign page, the event plan is automatically applied."
        footer={
          <Flex vertical align="end">
            <Muted>
              The Stripe plans provided in the campaign will automatically renew once the plan's
              duration has ended.
              <br /> A plan priced at $0 means that once a user subscribes, they can use it for free
              indefinitely. <br /> Avoid using the $0 plan and use a coupon with paid plan instead.
            </Muted>
            <Button className="w-[150px]" onClick={handleSubmit} disabled={disabled}>
              Add New Campaign
            </Button>
          </Flex>
        }
      >
        <Card>
          <Table
            borderless
            options={[
              {
                label: 'Email Domain *',
                component: (
                  <Input
                    value={domain}
                    onChange={e => setDomain(e.target.value)}
                    placeholder="ex) chartmetric.com"
                  />
                ),
              },
            ]}
          />
          <AddCampaignSection {...campaignProps} />
        </Card>
      </Panel>
      <br />
      <CampaignTable />
    </>
  );
};

const CampaignTable = () => {
  const { data, isLoading, refetch } = useGetCampaign();
  const { stripeProducts } = useCampaign();
  const { data: coupons } = useGetStripeCoupons();
  const { authData } = useUser();

  const { execute: deleteCampaign, isLoading: isLoadingDelete } = useDeleteCampaign();

  const getCouponName = couponId => coupons?.find(coupon => coupon.id === couponId)?.name ?? '';

  const getProductItem = productId => {
    return stripeProducts?.products?.find(e => e.id === productId)!;
  };

  //   getPriceItem(productId, priceId) {
  //     return this.props.optionData.prices?.[productId]?.find(e => e.id === priceId);
  //   }

  //   checkFormFilled = () => {
  //     const { domain, enableCoupon, couponCode, productId, priceId } = this.state;
  //     return isEmpty(domain) || isEmpty(priceId) || (enableCoupon && !couponCode);
  //   };

  const getPriceItemName = priceId => {
    const item = Object.values(stripeProducts?.prices ?? {})
      .flat()
      .find(e => e.id === priceId);
    if (!item) return { nickname: '', prodNickname: '' }; //for local test
    const { name: prodNickname } = getProductItem(item.product);
    return { nickname: item.nickname, prodNickname };
  };

  //   deleteCampaign = id => this.props.deleteCampaign({ id }).then(() => this.props.getCampaignList());

  const redirectStripePortal = item => {
    const { coupon_code, price_id, domain } = item;

    campaignStripePortal({
      priceId: price_id,
      email: authData?.email,
      artistIds: undefined,
      couponCode: isEmpty(coupon_code) ? undefined : coupon_code,
    });
  };

  const columns: Column<any>[] = [
    {
      Header: 'ID',
      accessor: 'id',
      width: 100,
    },
    {
      Header: 'Domain',
      accessor: 'domain',
      width: 150,
    },
    {
      Header: 'Price Plan',
      accessor: 'price_id',
      Cell: ({ value }) => {
        const item = getPriceItemName(value);
        return (
          <Flex vertical>
            <span>
              {item?.prodNickname} - {item?.nickname}
            </span>
            <Muted>{value}</Muted>
          </Flex>
        );
      },
    },
    {
      Header: 'Coupon Code',
      accessor: 'coupon_code',
      Cell: props => {
        const name = getCouponName(props.value);
        return (
          <Flex vertical>
            {name}
            <Muted>{props.value}</Muted>
          </Flex>
        );
      },
    },
    {
      Header: 'Content',
      accessor: 'content',
      width: 100,
      Cell: ({ value }) => {
        if (value)
          return (
            <ModalButton trigger={<Button size="sm" icon={<FAIcon name="farEye" />} />}>
              <ContentModalContent id={value} />
            </ModalButton>
          );
        return null;
      },
    },
    {
      Header: 'Date',
      accessor: 'timestp',
      formatter: value => getFormattedLocalDate(value),
      width: 100,
    },
    {
      Header: 'Delete',
      accessor: 'id',
      Cell: ({ value }) => (
        <Button
          loading={isLoadingDelete}
          variant="outlineDestructive"
          onClick={() =>
            deleteCampaign({
              path: {
                id: value,
              },
            }).then(() => refetch())
          }
          icon={<FAIcon name="farTrash" />}
        />
      ),
      width: 60,
    },
    {
      Header: 'Test Stripe URL',
      accessor: 'id',
      Cell: props => (
        <Button onClick={() => redirectStripePortal(props.original)}>Test Stripe URL</Button>
      ),
      width: 150,
    },
  ];

  return (
    <Panel
      header="Campaigns"
      description="After adding the email domain to the Campaign feature, users should log in to the Chartmetric website and access https://app.chartmetric.com/campaign."
    >
      <AntdTable loading={isLoading} data={data} columns={columns} />
    </Panel>
  );
};

export default Campaign;

const ContentModalContent = ({ id }) => {
  const { data } = useGetCampaignContentById({ path: { id } });

  if (data) {
    return <ContentCard content={data} />;
  }
  return <Skeleton className="w-[300px] h-[300px]" />;
};
