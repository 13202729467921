import React, { useCallback, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { Divider, Typography } from 'antd';
import useURLParams from '@Hooks/useURLParams';
import { Segments, ResponsiveSection } from '@Shared/Style';
import { useGetUser } from '../../../services/user';
import GlobalSearch from '../../../components/global-search/GlobalSearch';
import Info from './UserInfo';
import SessionCounts from './UserProfileSessionCounts';
import { FollowedArtist, ViewableArtist } from './UserProfileArtists';
import { LinksByUserDetails } from './UserProfileLinkingHistory';
import Claims from './UserProfileClaim';
import UserProfileContext from './useUserProfileContext';
import { H4 } from '@/ui';

function UserProfile() {
  const { setParams, params } = useURLParams();

  const {
    data,
    isLoading,
    isFetching,
    refetch: getUser,
  } = useGetUser(
    {
      data: { q: params.qs?.toLowerCase() },
    },
    {
      disable: isEmpty(params.qs),
    }
  );

  const refetch = (email?: string) => {
    if (email) setParams({ qs: email });
    else getUser();
  };

  return (
    <UserProfileContext.Provider value={{ refetch }}>
      <GlobalSearch
        defaultValue={params.qs}
        loading={isLoading || isFetching}
        icon="farUser"
        types={['user']}
        onSubmit={(value: string) => {
          setParams({ qs: value });
        }}
        name="userSearch"
        className="top-search-box"
      />

      {data &&
        data?.email?.toLowerCase()?.trim() !== params.qs?.toLowerCase()?.trim() &&
        !isLoading &&
        !data?.error && (
          <>
            <Typography.Text type="danger">
              This user has changed their email address. You searched previous email.
            </Typography.Text>
            <br />
            <br />
          </>
        )}
      {data && (
        <Segments
          className="mt-6"
          options={[
            {
              title: 'Information',
              icon: 'farInfoCircle',
              content: <Info {...data} />,
            },
            {
              title: 'Sessions',
              icon: 'fadChartLineUp',
              content: <SessionCounts q={data?.email} userId={data?.id} />,
            },
            {
              title: 'Followed Entity',
              icon: 'farHeart',
              content: <FollowedArtist userId={data?.id} />,
            },
            {
              title: 'Viewable Artist',
              icon: 'farEye',
              content: <ViewableArtist userId={data?.id} />,
            },
            {
              title: 'Links By User',
              icon: 'farLink',
              content: <LinksByUserDetails q={data?.email} />,
            },
            {
              title: 'Claims',
              icon: 'farHand',
              content: <Claims q={data?.email} />,
            },
          ]}
        />
      )}
    </UserProfileContext.Provider>
  );
}

export default UserProfile;
