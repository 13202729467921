import { persistGetToUrl, mutateToUrl } from './helpers';

const ENDPOINT = {
  COMPANY_UNIV_POOL: '/admin/user/pool',

  UPDATE_COMPANY_UNIV_POOL: '/admin/user/pool/:id',
  COMPANY_UNIV_POOL_MEMBERS: '/admin/user/pool/:id',
  COMPANY_UNIV_POOL_DOMAIN: '/admin/user/pool/:id/domain',
  COMPANY_UNIV_POOL_IP: '/admin/user/pool/:id/ip-range',
  RELATIVES: '/admin/user/pool/:id/relatives',
  UPDATE_RELATIVE: '/admin/user/pool/relatives',
};

type UserPool = {
  domains: string;
  id: number;
  ip_ranges: string[];
  name: string;
  stripe_customer_id: string;
};

export const useGetCompanyUnivPools = persistGetToUrl<{}, UserPool[]>(ENDPOINT.COMPANY_UNIV_POOL);
export const useGetCompanyUnivPoolMembers = persistGetToUrl<{
  path: { id: number };
}>(ENDPOINT.COMPANY_UNIV_POOL_MEMBERS, {
  manual: true,
});
export const useAddCompanyUnivUser = mutateToUrl<{
  data: {
    name: string;
    stripeCustomerId?: string;
  };
  query: {
    createCustomer?: boolean;
  };
}>('POST', ENDPOINT.COMPANY_UNIV_POOL);
export const useAddCompanyUnivDomain = mutateToUrl<{
  path: {
    id: number;
  };
  data: {
    domain: string;
  };
}>('POST', ENDPOINT.COMPANY_UNIV_POOL_DOMAIN);
export const useDeleteCompanyUnivDomain = mutateToUrl<{
  path: {
    id: number;
  };
  data: {
    domain: string;
  };
}>('DELETE', ENDPOINT.COMPANY_UNIV_POOL_DOMAIN);
export const useAddCompanyUnivIp = mutateToUrl<{
  path: {
    id: number;
  };
  data: {
    ipRange: string;
  };
}>('POST', ENDPOINT.COMPANY_UNIV_POOL_IP);
export const useDeleteCompanyUnivIp = mutateToUrl<{
  path: {
    id: number;
  };
  data: {
    ipRange: string;
  };
}>('DELETE', ENDPOINT.COMPANY_UNIV_POOL_IP);

export const useUpdateCompanyUnivUser = mutateToUrl<{
  data: {
    name: string;
  };
  path: {
    id: number;
  };
}>('PATCH', ENDPOINT.UPDATE_COMPANY_UNIV_POOL);
export const useDeleteCompanyUnivUser = mutateToUrl<{
  path: {
    id: number;
  };
}>('DELETE', ENDPOINT.UPDATE_COMPANY_UNIV_POOL);

export const useGetCompanyUnivRelatives = persistGetToUrl<
  {
    path: {
      id: number;
    };
  },
  {
    children: UserPool[];
    parents: UserPool[];
  }
>(ENDPOINT.RELATIVES);

export const useAddCompanyUnivRelative = mutateToUrl<{
  data: {
    parent: number;
    child: number;
  };
}>('POST', ENDPOINT.UPDATE_RELATIVE);

export const useDeleteCompanyUnivRelative = mutateToUrl<{
  data: {
    parent: number;
    child: number;
  };
}>('DELETE', ENDPOINT.UPDATE_RELATIVE);

