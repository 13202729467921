import React from 'react';
import { SelectOption, Flex } from '@/ui';
import { useStates } from '@Hooks';
import { useGetStripeProducts } from '@Services/campaign';
import { STRIPE_STANDARD_PLANS } from '@Utils/constants';
import type { Content, Coupon } from './types';
import FAIcon from '@Components/ui/fa-icons';
import { isEmpty } from 'lodash';

interface State {
  productId?: string;
  priceId?: string;
  coupon?: Coupon;
  enableCoupon: boolean;
  artistIds: string[];
  content?: Content;
  url?: string;
  expiredDate?: string;
}

const useCampaign = () => {
  const { data: stripeProducts, isLoading } = useGetStripeProducts();

  const {
    setAllStates,
    states: { productId, priceId, coupon, enableCoupon, artistIds, content, expiredDate },
  } = useStates<State>({
    enableCoupon: false,
    artistIds: [],
  });
  const getPlanProductOptions = () => {
    return (
      stripeProducts?.products
        ?.reduce<SelectOption[]>((all, one) => {
          all.push({
            label: (
              <Flex align="center">
                {one.name}
                {STRIPE_STANDARD_PLANS!.includes(one.id) && (
                  <FAIcon name="farCircleCheck" color="green" />
                )}
              </Flex>
            ),
            value: one.id,
          });
          return all;
        }, [])
        ?.sort((a, b) => (STRIPE_STANDARD_PLANS!.includes(a.value) ? -1 : 1)) ?? []
    );
  };

  const getPlanPriceOptions = () => {
    return (
      stripeProducts?.prices?.[productId as string]
        ?.reduce<SelectOption[]>((all, one) => {
          all.push({
            label: (
              <Flex align="center">
                {one.unit_amount / 100} / {one.recurring?.interval_count} {one.recurring?.interval}{' '}
                ({one.nickname})
                {STRIPE_STANDARD_PLANS!.includes(one.id) && (
                  <FAIcon name="farCircleCheck" color="green" />
                )}
              </Flex>
            ),
            value: one.id,
          });
          return all;
        }, [])
        ?.sort((a, b) => (STRIPE_STANDARD_PLANS!.includes(a.value) ? -1 : 1)) ?? []
    );
  };

  return {
    stripeProducts,
    setAllStates,
    getPlanProductOptions,
    getPlanPriceOptions,
    productId,
    priceId,
    coupon,
    enableCoupon,
    artistIds,
    content,
    expiredDate,
    isLoading,
  };
};

export default useCampaign;
