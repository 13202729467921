import React, { useState } from 'react';
import { Button, Flex } from 'antd';
import { Panel, ActionInput } from '../../../shared/Style';

import {
  useAddBlockedDomain,
  useDeleteBlockedDomain,
  useGetBlockedDomains,
} from '../../../services/restriction';
import DataTableSearch from '../../../shared/Table/SearchInput';
import FAIcon from '@Components/ui/fa-icons';
import { AntdTable } from '@Shared';
import SearchInput from '../../../shared/Table/SearchInput';
import { showErrorMessage } from '@Utils';

function DeleteButton({ domain, refetch }) {
  const { execute, isLoading } = useDeleteBlockedDomain();

  const deleteDomain = domain => {
    execute({ query: { domain } }).then(() => refetch({}));
  };

  return (
    <Button
      loading={isLoading}
      icon={<FAIcon name="farTrash" />}
      className="red"
      onClick={() => deleteDomain(domain)}
    />
  );
}

const getColumns = refetch => [
  {
    Header: 'Domain',
    accessor: 'domain',
  },
  {
    Header: 'Actions',
    accessor: 'domain',
    width: 100,
    Cell: props => (
      <Flex justify="center">
        <DeleteButton domain={props.value} refetch={refetch} />
      </Flex>
    ),
  },
];

function BlockedDomains() {
  const { data, refetch, isLoading } = useGetBlockedDomains();
  const { execute: fetchAddDomain, isLoading: isAddingDomain, isError } = useAddBlockedDomain();
  const [filteredData, setFilteredData] = useState(data);
  const [domain, setDomain] = useState<string | undefined>();

  const dataObjArr = data?.map(domain => ({ domain })).reverse(); // sort by most recent

  const onSearch = filteredData => {
    setFilteredData(filteredData);
  };

  const addDomain = domain => {
    fetchAddDomain({ data: { domain } })
      .then(() => refetch({}))
      .catch(() => {
        showErrorMessage('Domain already exists');
      });
  };

  return (
    <>
      <Panel title="Add Blocked Domain">
        <ActionInput
          placeholder="Domain URI"
          action={{
            icon: 'farPlus',
            content: 'Add',
            onClick: () => addDomain(domain),
            loading: isAddingDomain,
          }}
          icon="farPlus"
          value={domain}
          onChange={e => setDomain(e.target.value)}
        />
      </Panel>
      <Panel title="Blocked Domains">
        <DataTableSearch originalData={dataObjArr} onChange={onSearch} />
        <br />
        <AntdTable
          loading={isLoading}
          key="table"
          data={filteredData}
          columns={getColumns(refetch)}
        />
      </Panel>
    </>
  );
}

export default BlockedDomains;
