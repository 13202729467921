import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  TWO_FACT_AUTH_LOG: `/admin/two-factor-auth-log`,
  INSERT_NOTE: '/note/:target/:targetId',
  UPDATE_NOTE: '/note/:noteId',
  PAGE_HISTORY_STATISTICS: '/admin/page-history-statistics',
  USER_ACTIVES: 'admin/user-activities/:type',
};

export const useGetTwoFactAuthLog = persistGetToUrl<{
  data: {
    since: string;
    until?: string;
  };
}>(ENDPOINT.TWO_FACT_AUTH_LOG);

export const useInsertNote = mutateToUrl<{
  data: { body: string; date: string };
  path: { target: 'cm_artist' | 'cm_track'; targetId: number };
}>('POST', ENDPOINT.INSERT_NOTE);

export const useUpdateNote = mutateToUrl<{
  data: { body: string; date: string };
  path: { noteId: number };
}>('PUT', ENDPOINT.UPDATE_NOTE);
export const useDeleteNote = mutateToUrl<{
  path: { noteId: number };
}>('DELETE', ENDPOINT.UPDATE_NOTE);

export const useGetPageHistoryStatistics = persistGetToUrl<{
  data: {
    daysAgo: number;
  };
}>(ENDPOINT.PAGE_HISTORY_STATISTICS);

export const useGetUserActivities = persistGetToUrl<{
  path: {
    type: 'signups' | 'active';
  };
  data: {
    limit: number;
    offset: number;
  };
}>(ENDPOINT.USER_ACTIVES);
