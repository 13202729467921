import useStates from './useStates';

const useRequestTable = (getHook, fetchHook) => {
  const { states, setAllStates } = useStates<{
    q: string;
    approves: number[];
    declines: number[];
    filteredData: Record<string, any>[];
  }>({
    q: '',
    approves: [],
    declines: [],
    filteredData: [],
  });

  const { data, isLoading, refetch, isFetching } = getHook();
  const { execute, isLoading: isSubmitting } =
    fetchHook !== null
      ? //@ts-ignore
        fetchHook()
      : { execute: () => {}, isLoading: false };

  const onSearch = filteredData => {
    setAllStates({
      filteredData,
    });
  };

  const onClickApprove = id => {
    if (!states.approves.includes(id))
      setAllStates({
        approves: [...states.approves, id],
        declines: states.declines.filter(c => c !== id),
      });
    else
      setAllStates({
        approves: states.approves.filter(c => c !== id),
      });
  };

  const onClickDecline = id => {
    if (!states.declines.includes(id))
      setAllStates({
        approves: states.approves.filter(c => c !== id),
        declines: [...states.declines, id],
      });
    else
      setAllStates({
        declines: states.declines.filter(c => c !== id),
      });
  };

  const onClickSelectApproveAll = e => {
    e.preventDefault();
    e.stopPropagation();
    if (states.approves.length === (states.filteredData ?? data).length)
      return setAllStates({ approves: [], declines: [] });
    const allIds: number[] = (states.filteredData ?? data).map(request => request.id) ?? [];
    setAllStates({ approves: allIds, declines: [] });
  };

  const onClickSelectDeclineAll = e => {
    e.preventDefault();
    e.stopPropagation();
    if (states.declines.length === (states.filteredData ?? data).length)
      return setAllStates({ approves: [], declines: [] });
    const allIds: number[] = (states.filteredData ?? data).map(request => request.id) ?? [];
    setAllStates({ approves: [], declines: allIds });
  };

  const submit = (
    submitType: (id: number, status: boolean) => any,
    requestType: (approves: number[], declines: number[]) => any
  ) => {
    if (!fetchHook) return;
    const approves = states.approves.map(id => submitType(id, true));
    const declines = states.declines.map(id => submitType(id, false));
    return execute(requestType(approves, declines)).then(() => {
      refetch();
      clear();
    });
  };

  const clear = () => {
    setAllStates({ approves: [], declines: [], filteredData: undefined });
  };

  return {
    state: states,
    isLoading: isLoading || isFetching,
    isSubmitting,
    data,
    onSearch,
    onClickApprove,
    onClickDecline,
    submit,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    refetch,
    selected: states.approves.length + states.declines.length,
    clear,
  };
};

export default useRequestTable;
