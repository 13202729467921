import { Button, Panel } from '@/ui';
import FAIcon from '@Components/ui/fa-icons';
import { useCleanUpStripe } from '@Services/system';

const DeleteStripeInvoices = () => {
  const { execute, isLoading } = useCleanUpStripe();

  return (
    <Panel
      header="Delete Stripe Invocies"
      description="Delete Stripe overdue invoices for cancelled subscriptions. Please use with care. Can take up to 45 minutes">
      <Button
        variant="destructive"
        icon={<FAIcon name="fasTriangleExclamation" />}
        loading={isLoading}
        onClick={() => execute()}>
        Submit
      </Button>
    </Panel>
  );
};

export default DeleteStripeInvoices;
