import { findMatchingString } from '@Utils/string';
import { capitalize } from 'lodash';
import { PLATFORMS } from '.';

export function getPlatformFromString(str: string, capitalizeStr = false) {
  if (!str) return null;
  const lowerStr = str.toLowerCase();
  const target = findMatchingString(lowerStr, [...PLATFORMS, 'cm', 'airplay', 'apple_music']);

  switch (target) {
    case 'cm':
      return capitalizeStr ? 'Chartmetric' : 'chartmetric';
    case 'youtube':
      return capitalizeStr ? 'YouTube' : 'youtube';
    case 'soundcloud':
      return capitalizeStr ? 'SoundCloud' : 'soundcloud';
    case 'airplay':
      return capitalizeStr ? 'Airplay' : 'airplay';
    case 'applemusic':
    case 'apple_music':
      return capitalizeStr ? 'Apple Music' : 'applemusic';
    case 'tiktok':
      return capitalizeStr ? 'TikTok' : 'tiktok';
    case 'itunes':
      return capitalizeStr ? 'iTunes' : 'itunes';
    default:
      return capitalizeStr ? capitalize(target) : target;
  }
}

export function sortPlatforms(platforms: string[]) {
  platforms = platforms.map(e => (e === 'cm' ? 'chartmetric' : e));

  return PLATFORMS.filter(e => platforms.includes(e));
}

export function sortPlatformsByAB(a: string, b: string) {
  return PLATFORMS.indexOf(a as any) - PLATFORMS.indexOf(b as any);
}
