import { AntdIcon } from '@Shared/Style';
import { Button, Flex, Typography } from 'antd';
import React from 'react';
import { useEditGenreHierarchy } from '../Context';
import { useHiddenTagFromAllArtists } from '@Services/tag';
import { WhatsThis } from '@Shared';

const ActionButtons = () => {
  const { selectedGenre } = useEditGenreHierarchy();
  const { execute, isLoading } = useHiddenTagFromAllArtists();

  const handleShow = () => {
    execute({
      data: {
        tagId: selectedGenre!,
        hidden: false,
      },
    });
  };

  const handleHide = () => {
    return execute({
      data: {
        tagId: selectedGenre!,
        hidden: true,
      },
    });
  };

  return (
    <Flex vertical justify="center">
      <Typography.Text type="secondary">
        From Artists
        <WhatsThis title="Hide / Show Genre">
          It will be hide / show from all artists belonging to this genre.
        </WhatsThis>
      </Typography.Text>

      <Flex gap="middle" justify="space-between">
        <Button
          className="blue"
          icon={<AntdIcon name={'eyeEnable'} />}
          onClick={handleShow}
          loading={isLoading}
        >
          Show This Genre
        </Button>
        <Button
          className="red"
          icon={<AntdIcon name={'eyeDisable'} />}
          onClick={handleHide}
          loading={isLoading}
        >
          Hide This Genre
        </Button>
      </Flex>
    </Flex>
  );
};

export default ActionButtons;
