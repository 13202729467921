import { persistGetToUrl, mutateToUrl, axiosInstance } from './helpers';

const ENDPOINT = {
  ADD_CAMPAIGN: '/admin/campaign',
  UPDATE_CAMPAIGN: '/admin/campaign/:id',
  CAMPAIGN_CONTENT: '/admin/campaign/content',
  CAMPAIGN_HISTORY: '/admin/campaign/log',
  UPDATE_CAMPAIGN_CONTENT: '/admin/campaign/content/:id',
  STRIPE_PRODUCTS: '/admin/stripe/products',
  STRIPE_COUPONS: '/admin/stripe/coupon',
  STRIPE_PORTAL: '/stripe/session/campaign',
};

export const useGetCampaignContent = persistGetToUrl(ENDPOINT.CAMPAIGN_CONTENT, {
  manual: true,
});
export const useAddCampaignContent = mutateToUrl<{
  data: {
    title: string;
    sentence: string;
    imageUrl: string;
    buttonLabel: string;
  };
}>('POST', ENDPOINT.CAMPAIGN_CONTENT);
export const useEditCampaignContent = mutateToUrl<{
  path: {
    id: number;
  };
  data: {
    title: string;
    sentence: string;
    imageUrl: string;
    buttonLabel: string;
  };
}>('PATCH', ENDPOINT.UPDATE_CAMPAIGN_CONTENT);
export const useDeleteCampaignContent = mutateToUrl<{
  path: {
    id: number;
  };
}>('DELETE', ENDPOINT.UPDATE_CAMPAIGN_CONTENT);
export const useGetCampaignContentById = persistGetToUrl<{
  path: { id: number };
}>(ENDPOINT.UPDATE_CAMPAIGN_CONTENT);
export const useGetCampaignHistory = persistGetToUrl(ENDPOINT.CAMPAIGN_HISTORY);

export const useGetStripeProducts = persistGetToUrl<
  undefined,
  {
    products: { id: string; name: string }[];
    prices: Record<
      string,
      {
        id: string;
        unit_amount: number;
        recurring: any;
        nickname: string;
        prodNickname: string;
        product: string;
      }[]
    >;
  }
>(ENDPOINT.STRIPE_PRODUCTS);
export const useGetStripeCoupons = persistGetToUrl(ENDPOINT.STRIPE_COUPONS, {
  manual: true,
});

export const useAddCampaign = mutateToUrl<{
  data: {
    priceId: string;
    domain: string;
    couponCode?: string;
    contentId?: number;
  };
}>('POST', ENDPOINT.ADD_CAMPAIGN);

export const useGetCampaign = persistGetToUrl(ENDPOINT.ADD_CAMPAIGN);

export const campaignStripePortal = ({ priceId, email, artistIds, couponCode }) =>
  axiosInstance
    .post(ENDPOINT.STRIPE_PORTAL, {
      priceId,
      email,
      artistIds,
      couponCode,
    })
    .then(res => {
      if (res.data) {
        return res.data;
      }
      // @ts-ignore
      return res.statusCode;
    })
    .catch(error => error);

export const useDeleteCampaign = mutateToUrl<{
  path: {
    id: number;
  };
}>('DELETE', ENDPOINT.UPDATE_CAMPAIGN);
