import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Flex, Skeleton, Tag } from 'antd';
import {
  useGetArtistIdentityChangeRequest,
  useGetDspLinkRequest,
  useGetGenreRequest,
  useGetNewsLinkRequest,
  useGetTrackArtistRequest,
  useGetUrlChangeRequest,
} from '@Services/request';
import { Panel } from '@Shared/Style';
import { isNumber } from 'lodash';

const REQEUST_KEYS = [
  'newsLink',
  'dspLink',
  'urlChange',
  'artistIdentity',
  'customGenre',
  'trackArtistChange',
] as const;

type RequestType = (typeof REQEUST_KEYS)[number];

const useRequestCounts = () => {
  const { data: dspLinkRequest } = useGetDspLinkRequest();
  const { data: newsLinkRequest } = useGetNewsLinkRequest();
  const { data: urlChangeRequest } = useGetUrlChangeRequest();
  const { data: artistIdentityRequest } = useGetArtistIdentityChangeRequest();
  const { data: customGenreRequest } = useGetGenreRequest();
  const { data: trackArtistChangeRequest } = useGetTrackArtistRequest();

  return {
    newsLink: {
      count: newsLinkRequest?.length,
      route: 'requests/news-Link',
    },
    dspLink: {
      count: dspLinkRequest?.length,
      route: 'requests/dsp-linking',
    },
    urlChange: {
      count: urlChangeRequest?.length,
      route: 'requests/url-change',
    },
    artistIdentity: {
      count: artistIdentityRequest?.length,
      route: 'requests/artist-identity-change',
    },
    customGenre: {
      count: customGenreRequest?.length,
      route: 'requests/custom-genre',
    },
    trackArtistChange: {
      count: trackArtistChangeRequest?.length,
      route: 'requests/track-artist-change',
    },
  };
};
function RequestBadge({ type }: { type: RequestType }) {
  const data = useRequestCounts();
  const count = data[type].count;
  return isNumber(count) ? (
    <Badge
      color={count !== 0 ? 'blue' : 'grey'}
      style={{ marginLeft: '4px' }}
      count={count}
      overflowCount={99999}
    />
  ) : (
    <Skeleton.Avatar
      active
      size="small"
      style={{
        marginLeft: '4px',
        width: '20px',
        height: '20px',
      }}
    />
  );
}

export default RequestBadge;
