import { MenuGroup } from '@Routes/menus';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

interface useSidebarToggleStore {
  isOpen: boolean;
  setIsOpen: () => void;
  group: MenuGroup;
  setGroup: (group: MenuGroup) => void;
}

export const useSidebarToggle = create(
  persist<useSidebarToggleStore>(
    (set, get) => ({
      isOpen: true,
      setIsOpen: () => {
        set({ isOpen: !get().isOpen });
      },
      group: 'Chartmetric',
      setGroup: group => {
        set({ group });
      },
    }),
    {
      name: 'sidebarOpen',
      storage: createJSONStorage(() => localStorage),
    }
  )
);
