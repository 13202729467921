import React, { Component, useState } from 'react';
import { capitalize } from 'lodash';
import { useGetChurnedUsers } from '@Services/purchase';
import { getFormattedLocalDate } from '../../../utilities';
import DataTableSearch from '../../../shared/Table/SearchInput';
import CommentBox from './CommentBox';
import { Panel } from '../../../shared/Style';
import { AntdTable } from '@Shared';

const COLUMNS = [
  {
    Header: 'Id',
    accessor: 'id',
    width: 50,
  },
  {
    Header: 'Email',
    accessor: 'customer_email',
    width: 200,
  },
  {
    Header: 'Stripe Id',
    accessor: 'customer_id',
    width: 100,
  },
  {
    Header: 'Status',
    accessor: 'status',
    formatter: value =>
      value
        .split('_')
        .map(e => capitalize(e))
        .join(' '),
    width: 150,
  },
  {
    Header: 'Comment',
    accessor: 'comments',
    width: 250,
    Cell: props => <CommentBox {...props} />,
  },
  {
    Header: 'Latest Plan',
    accessor: 'plan',
    width: 250,
  },
  {
    Header: 'All Time Revenue ($)',
    accessor: 'total_spend',
  },
  {
    Header: 'MRR',
    accessor: 'mrr',
    width: 70,
  },
  {
    Header: 'Activated On',
    accessor: 'activated_on',
    formatter: value => (value ? getFormattedLocalDate(value) : ''),
  },
  {
    Header: 'Churneded On',
    accessor: 'churneded_on',
    formatter: value => (value ? getFormattedLocalDate(value) : ''),
  },
  {
    Header: 'Contacted',
    accessor: 'contacted',
    width: 100,
  },
];

function ChurnedUsers() {
  const [filteredData, setFilteredData] = useState(undefined);
  const { data, isLoading } = useGetChurnedUsers();
  const onSearch = filteredData => {
    setFilteredData(filteredData);
  };

  return (
    <Panel title="Churned Users">
      <DataTableSearch originalData={data} onChange={onSearch} />
      <br />
      <AntdTable
        loading={isLoading}
        key="churnedUsersTable"
        data={filteredData ?? data}
        columns={COLUMNS}
      />
    </Panel>
  );
}

export default ChurnedUsers;
