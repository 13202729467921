import React from 'react';

const UserProfileContext = React.createContext<{
  refetch: (email?: string) => void;
}>({
  refetch: () => {},
});

export const useUserProfileContext = () => {
  const context = React.useContext(UserProfileContext);
  if (context === undefined) {
    throw new Error('useUserProfile must be used within a UserProfileContext');
  }
  return context;
};

export default UserProfileContext;
