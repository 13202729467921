import React from 'react';
import { EditEntityProps } from '.';
import { useGetEntity } from '@Services/search';
import { isEmpty } from 'lodash';
import Info from './Album/Info';
import { ExternalLinksPanel } from '@Components';

const EditAlbum = ({ q }: EditEntityProps) => {
  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  if (data)
    return (
      <>
        <Info q={q} />
        <ExternalLinksPanel urls={data.urls} type="album" callback={() => refetch} id={data.id} />
      </>
    );
  return null;
};

export default EditAlbum;
