import React from 'react';
import { useGenrePanel } from '../context/GenrePanelContext';
import { TAG_TYPES } from '../context/constants';
import { addSpaceBeforeCapitalLetter } from '@Utils/string';
import { InternalTagType } from '@Services/tag';
import AddTagModal from './AddGenreModalButton';
import TagLabel from './GenreLabel';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Flex,
  Panel,
  SectionTitle,
} from '@/ui';
import { ResponsiveSection } from '@Shared/Style';
import FAIcon from '@Components/ui/fa-icons';

const TagGroups = () => {
  const { callback, entireTagMap, groupedTags, id, entity, isLoading } = useGenrePanel();
  return (
    <Panel
      header="Genre & Tags"
      description="It will reflect to production by data side scheduling"
    >
      <ResponsiveSection>
        {Object.keys(groupedTags)
          .filter(type => TAG_TYPES[entity].includes(type as any))
          .map((type: InternalTagType) => (
            <Card>
              <CardHeader className="bg-decorative py-0 flex flex-row items-center text-decorative-foreground h-[55px]">
                <Flex justify="between" align="center" wFull>
                  <CardTitle>{addSpaceBeforeCapitalLetter(type)}</CardTitle>
                  <AddTagModal
                    entity={entity}
                    list={entireTagMap[type]!}
                    internalTagType={type}
                    entityId={id}
                    callback={callback}
                  />
                </Flex>
              </CardHeader>
              <CardContent className="py-4">
                <Flex wrap gap={4}>
                  {isLoading ? <FAIcon name="farSpinner" loading /> : null}
                  {Object.keys(groupedTags[type])
                    .sort((a, b) => a.localeCompare(b))
                    .map((linkType, index) =>
                      linkType === 'undefined' ? (
                        groupedTags[type][linkType].map(tag => (
                          <TagLabel entity={entity} artistId={id} tag={tag} refetch={callback} />
                        ))
                      ) : (
                        <Card>
                          <CardHeader className="p-3">
                            <CardTitle>{linkType}</CardTitle>
                          </CardHeader>
                          <CardContent className="p-3">
                            <Flex wrap gap={2}>
                              {groupedTags[type][linkType].map(tag => (
                                <TagLabel
                                  entity={entity}
                                  artistId={id}
                                  tag={tag}
                                  refetch={callback}
                                />
                              ))}
                            </Flex>
                          </CardContent>
                        </Card>
                      )
                    )}
                </Flex>
              </CardContent>
            </Card>
          ))}
      </ResponsiveSection>
    </Panel>
  );
};

export default TagGroups;
