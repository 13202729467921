import { UserSuggestionItem } from '@Services/system';
import React from 'react';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Flex,
  H4,
  Muted,
  Separator,
  Skeleton,
} from '@/ui';
import FAIcon from '@Components/ui/fa-icons';
import Country from '@Components/Country';

interface Props {
  list: UserSuggestionItem[];
  loading: boolean;
  onClick: (email: string) => (e) => void;
}

function UserItemList({ list, loading, onClick }: Props) {
  return (
    <Flex vertical gap={0}>
      {(loading ? Array.from({ length: 5 }).map((_, i) => ({ key: `user${i}` })) : list).map(
        (item, index) =>
          loading ? (
            <div className="flex items-center space-x-4 h-[76px] px-4" key={index}>
              <Skeleton className="h-10 w-10 rounded-full" />
              <div className="space-y-2">
                <Skeleton className="h-4 w-[250px]" />
                <Skeleton className="h-4 w-[200px]" />
              </div>
            </div>
          ) : (
            <>
              <Button
                variant="ghost"
                className="h-[76px] justify-start"
                onClick={onClick(item?.email)}
                key={item?.id}
              >
                <Flex align="center" gap={4}>
                  <Avatar>
                    <AvatarImage src={item.imageUrl} />
                    <AvatarFallback>
                      <FAIcon name="fadUser" />
                    </AvatarFallback>
                  </Avatar>
                  <Flex vertical gap={3}>
                    <Flex
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      <p className="text-base font-normal">{item?.email}</p>
                    </Flex>
                    <Flex
                      align="center"
                      gap={4}
                      style={{
                        textAlign: 'left',
                      }}
                    >
                      <Muted>ID:{item?.id}</Muted>
                    </Flex>
                  </Flex>
                </Flex>
              </Button>
              {index !== list.length - 1 && <Separator />}
            </>
          )
      )}
    </Flex>
  );
}

export default UserItemList;
