import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { useState } from 'react';
import { AWS_ACCESS_CONFIG } from 'aws-exports';
import { capitalizeEveryWord, showErrorMessage, showInfoMessage } from '../../utilities';
import useStates from '../../hooks/useStates';
import { checkIsUUID4 } from './utils';

const DEFAULT_SHEET_LIMIT = 5;

export type CognitoUser = CognitoIdentityServiceProvider.UserType;
export interface CognitoBasicUser {
  name: string;
  email: string;
  id: string;
  sheetLimit?: number;
}

export const refineCognitoUser = (
  user: CognitoIdentityServiceProvider.UserType
): CognitoBasicUser => {
  const id = user.Attributes?.find(({ Name }) => Name === 'sub')?.Value;
  const email = user.Attributes?.find(({ Name }) => Name === 'email')?.Value;
  const name = user.Attributes?.find(({ Name }) => Name === 'name')?.Value;
  const sheetLimit = user.Attributes?.find(({ Name }) => Name === 'custom:sheetLimit')?.Value;

  return {
    id: id!,
    email: email!,
    name: name!,
    sheetLimit: sheetLimit ? Number(sheetLimit) : DEFAULT_SHEET_LIMIT,
  };
};

export const useUserCognito = () => {
  const [data, setData] = useState<CognitoIdentityServiceProvider.UserType>();
  const [error, setError] = useState<string>();
  const [fetching, setFetching] = useState<boolean>(false);
  const { states: requesting, setAllStates: setRequesting } = useStates({
    sheetLimit: false,
  });

  const UserPoolId = AWS_ACCESS_CONFIG.USER_POOL_ID;
  const cognitoIdentityServiceProvider = new CognitoIdentityServiceProvider({
    accessKeyId: AWS_ACCESS_CONFIG.ACCESS_KEY_ID,
    secretAccessKey: AWS_ACCESS_CONFIG.SECRET_ACCESS_KEY,
    apiVersion: '2016-04-18',
    region: AWS_ACCESS_CONFIG.REGION,
  });

  const fetch = async (q: string) => {
    setFetching(true);

    const type = checkIsUUID4(q) ? 'sub' : 'email';

    const response = await cognitoIdentityServiceProvider
      .listUsers({
        UserPoolId: UserPoolId!,
        Filter: `${type} = "${q}"`,
      })
      .promise();

    const user = response.Users ? response.Users[0] : undefined;
    if (response.Users && response.Users.length > 1) {
      console.log('Unexpectedly found more than 1 user with same email', response);
    }

    setFetching(false);
    if (!user) {
      const error = `Unable to find user with the email: ${q}`;
      setError(error);
      showErrorMessage(error);
      return Promise.reject(error);
    }

    setData(user);
    return Promise.resolve(user);
  };

  const update = async (attributes: Record<string, any>) => {
    const { sheetLimit } = attributes;

    if (!data) {
      const error = `Unable to update user. You should run get() first`;
      setError(error);
      return Promise.reject(error);
    }

    setRequesting({
      ...Object.keys(attributes).reduce((acc, key) => ({ ...acc, [key]: true }), {}),
    });

    const sheetTotal: number =
      (data?.Attributes?.find(a => a.Name === 'custom:sheetTotal')?.Value as unknown as number) ??
      DEFAULT_SHEET_LIMIT;

    if (Number(sheetTotal) > Number(sheetLimit)) {
      const error = `User has reached their sheet limit`;
      showErrorMessage(error);
      setError(error);
      return Promise.reject(error);
    }
    return cognitoIdentityServiceProvider
      .adminUpdateUserAttributes({
        UserAttributes: [
          ...(sheetLimit && [
            {
              Name: 'custom:sheetLimit',
              Value: `${sheetLimit}`,
            },
          ]),
        ],
        UserPoolId: UserPoolId!,
        Username: data.Username!,
      })
      .promise()
      .then(() => {
        showInfoMessage(
          `User ${Object.keys(attributes).map(capitalizeEveryWord).join(', ')} successfully`
        );
      })
      .catch(err => {
        setError(err.message);
        showErrorMessage(err.message);
        setRequesting({
          ...Object.keys(attributes).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
        });
        return Promise.reject(err.message);
      })
      .finally(() =>
        setRequesting({
          ...Object.keys(attributes).reduce((acc, key) => ({ ...acc, [key]: false }), {}),
        })
      );
  };

  return { fetch, update, data, error, fetching, requesting };
};
