import { Button, Checkbox, Flex, Panel, Select } from '@/ui';
import { Table } from '@Components';
import FAIcon from '@Components/ui/fa-icons';
import { useUpdatePlaylist } from '@Services/playlist';
import { useGetEntity } from '@Services/search';
import { BrandIcon } from '@Shared/Style';
import { showErrorMessage } from '@Utils';
import { COUNTRY_OPTIONS } from '@Utils/options';
import { useEffect, useState } from 'react';

const EditPlaylist = ({ q }: { q: string }) => {
  const { data } = useGetEntity({
    data: {
      q,
    },
  });
  const { execute: updatePlaylist, isLoading } = useUpdatePlaylist();
  const [metadata, setMetadata] = useState({
    code2: data?.code,
    personalized: data?.personalized,
  });

  const handleUpdate = () => {
    updatePlaylist({
      data: {
        id: data?.id,
        streamingType: data?.streamingType,
        storefront: data?.code,
        metadata,
      },
    });
  };

  useEffect(() => {
    setMetadata({
      code2: data?.code,
      personalized: data?.personalized,
    });
    if (data?.streamingType !== 'spotify') {
      showErrorMessage('Spotify playlists are only supported for update.');
    }
  }, [data]);

  return data?.streamingType === 'spotify' ? (
    <Panel
      classNames={{
        footer: 'flex justify-end',
      }}
      footer={
        <Button loading={isLoading} variant="submit" onClick={handleUpdate} icon={<FAIcon name="farEdit" />}>
          Update
        </Button>
      }>
      <Table
        borderless
        options={[
          {
            label: 'Name',
            labelIcon: 'farTag',
            component: data?.name,
          },
          {
            label: 'Image',
            labelIcon: 'farImage',
            component: <img src={data?.image_url} className="w-36 h-36" />,
          },
          {
            label: 'Streaming Type',
            labelIcon: 'faSSignal',
            component: (
              <Flex className="capitalize">
                <BrandIcon platform={data?.streamingType as any} style={{ width: 16, height: 16 }} />
                {data?.streamingType}
              </Flex>
            ),
          },
          {
            label: 'Description',
            labelIcon: 'farFileAlt',
            component: data?.description,
          },
          {
            label: 'Tags',
            labelIcon: 'farTag',
            component: data?.tags?.map((tag: any) => tag.name).join(', '),
          },
          {
            label: 'Code2',
            labelIcon: 'fasFlag',
            component: (
              <Select
                options={COUNTRY_OPTIONS}
                value={metadata.code2}
                onChange={value => setMetadata({ ...metadata, code2: value })}
              />
            ),
          },
          {
            label: 'Personalized',
            labelIcon: 'farCircleCheck',
            component: (
              <Checkbox
                checked={metadata.personalized}
                onCheckedChange={value => setMetadata({ ...metadata, personalized: value })}
              />
            ),
          },
        ]}
      />
    </Panel>
  ) : null;
};

export default EditPlaylist;
