import { Checkbox, DatePicker, Input, Panel, Select } from '@/ui';
import { FormTable } from '@Components';
import { useLabelCorrection } from '@Services/label';
import { getFormattedDateString } from '@Utils';
import { isEmpty } from 'lodash';
import { z } from 'zod';

const TYPE_OPTIONS = [
  { value: '-', key: '-', label: 'Any type' },
  { value: 'indie', key: 'indie', label: 'Indie' },
  { value: 'major', key: 'major', label: 'Major' },
];

const formSchema = z.object({
  originalLabel: z.string(),
  revisedLabel: z.string(),
  flagAudiobooks: z.boolean(),
  ignoreBefore: z.number(),
  subLabel: z.string(),
  type: z.enum(['prior', 'current', '-']),
});

const LabelCorrection = () => {
  const { execute, isLoading } = useLabelCorrection();

  const handleSubmit = ({
    flagAudiobooks,
    ignoreBefore,
    originalLabel,
    revisedLabel,
    subLabel,
    type,
  }: z.infer<typeof formSchema>) => {
    execute({
      data: {
        flagAudiobooks,
        ignoreBefore: isEmpty(ignoreBefore) ? undefined : getFormattedDateString(ignoreBefore),
        ignoreOriginalLabel: isEmpty(ignoreBefore),
        originalLabel: originalLabel.trim(),
        revisedLabel: revisedLabel.trim(),
        subLabel: subLabel.trim(),
        type: type === '-' ? undefined : type,
      },
    });
  };

  return (
    <Panel header="Create new data in label clean-up">
      <FormTable
        loading={isLoading}
        onSubmit={handleSubmit}
        schema={formSchema}
        options={[
          {
            name: 'originalLabel',
            label: 'Original Label *',
            component: <Input name="originalLabel" />,
          },
          {
            name: 'revisedLabel',
            label: 'Revised Label *',
            component: <Input name="revisedLabel" />,
          },
          {
            name: 'subLabel',
            label: 'Sub Label',
            component: <Input title="Sub Label" name="subLabel" />,
          },
          {
            name: 'type',
            label: 'Type *',
            component: <Select options={TYPE_OPTIONS} />,
          },
          {
            name: 'ignoreBefore',
            label: 'Ignore Before',
            component: <DatePicker />,
          },
          {
            name: 'flagAudiobooks',
            label: 'Flag for audiobooks',
            component: ({ onChange, value }) => (
              <Checkbox onCheckedChange={onChange} checked={Boolean(value)} />
            ),
          },
        ]}
      />
    </Panel>
  );
};

export default LabelCorrection;
