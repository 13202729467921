import { BrandIcon, AntdIcon } from '@Shared/Style';
import { generateCmUrl, showInfoMessage } from '@Utils';
import { addComma } from '@Utils/number';
import { Flex, Avatar, Typography, Checkbox, Input, Image, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { Column as TableColumn } from '@Shared/Table/AntdTable';
import { useCustomQuery } from '@Services/helpers';
import { capitalize } from 'lodash';
import { SUPPORT_PLATFORMS, TABS } from '../../constants';
import LinkingModal from '../Linking';
import { LinkCompletenessItem, useCheckLinkCompleteness } from '@Services/linkCompleteness';
import NoteCell from '../Statistics/NoteCell';
import { useComplete } from '../../hooks';
import EntityCell from '@Shared/Table/EntityCell';

const COLUMNS = (entity: (typeof TABS)[number]): TableColumn<LinkCompletenessItem>[] => [
  {
    accessor: 'name',
    Cell: ({ value, original }) => <EntityCell value={original as any} entity={entity} />,
    Header: 'Name',
    width: 200,
  },
  entity === 'artist'
    ? {
        accessor: 'cm_artist_rank',
        Cell: ({ value }) => <div>{addComma(value)}</div>,
        Header: (
          <Flex align="center" gap="small" justify="center">
            <BrandIcon platform="chartmetric" />
            Rank
          </Flex>
        ),
        width: 90,
        align: 'center',
      }
    : {
        accessor: 'artists',
        Header: 'Artists',
        Cell: ({ value }) =>
          value.map((artist, i) => (
            <Typography.Link href={generateCmUrl('artist', { id: artist.id })}>
              {artist.name}
              {i !== value.length - 1 && ', '}
            </Typography.Link>
          )),
        width: 90,
      },
  {
    accessor: 'tier',
    Header: 'Tear',
    width: 50,
  },
  ...SUPPORT_PLATFORMS[entity].map(key => ({
    width: 30,
    Header: <BrandIcon platform={key as any} key={key} />,
    Cell: ({ original }) =>
      original?.links?.[key]?.is_verified ? (
        <AntdIcon name="checkCircle" color="blue" />
      ) : original?.links?.[key] ? (
        <AntdIcon name="checkCircle" color="green" />
      ) : (
        <span></span>
      ),
  })),
  {
    accessor: 'notes',
    Header: 'Notes',
    Cell: NoteCell,
    width: 200,
  },
  {
    accessor: 'complete',
    Header: 'Linked',
    Cell: CheckCell,
    width: 75,
  },
  {
    accessor: 'links',
    Header: 'Links',
    width: 50,
    Cell: LinkingModal,
  },
];

const CheckCell = ({ value, original, extraData }) => {
  const { handleCheck, status } = useComplete(original.id, extraData.entity, extraData.cacheKey);

  return <Checkbox onClick={handleCheck} checked={status} />;
};

export default COLUMNS;
