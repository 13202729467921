import React from 'react';
import { Segments } from '../../../shared/Style';
import ClaimRequest from './claim-request';
import ClaimList from './claimed-entities';
import { ENTITY_ICON } from '@Constants/entity';

function Claim() {
  return (
    <Segments
      options={[
        {
          title: 'Claim Request',
          icon: 'farHand',
          content: <ClaimRequest />,
        },
        {
          icon: ENTITY_ICON.ARTIST,
          title: 'Claimed Entities',
          content: <ClaimList />,
        },
      ]}
    />
  );
}

export default Claim;
