import { useEffect } from 'react';

const useOnClickOut = (onClick, ref) => {
  useEffect(() => {
    try {
      // Close dropdown when clicking outside
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        onClick(event);
      };

      // Do not use window event listener.
      // This conflicts with the Popup/DayPicker library on the artist playlist page
      document.body.removeEventListener('click', listener);
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    } catch (e) {
      console.log(e, 'error');
    }
  }, [onClick, ref]);

  return null;
};

export default useOnClickOut;
