import React from 'react';
import { Button, Checkbox, Flex, ModalButton, Panel, Select } from '@/ui';
import { FormTable, GlobalSearch } from '@Components';
import FAIcon from '@Components/ui/fa-icons';
import {
  useAddLabelAssociation,
  useChangeHiddenLabel,
  useGetLabelAssociation,
  useSearchLabel,
} from '@Services/label';
import { AntdTable } from '@Shared';
import { ActionInput } from '@Shared/Style';
import { Column } from '@Shared/Table/AntdTable';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { z } from 'zod';

const HiddenCell = ({ value: rawValue, original }) => {
  const { execute, isLoading } = useChangeHiddenLabel();
  const [value, setValue] = useState(rawValue);

  useEffect(() => {
    setValue(rawValue);
  }, [rawValue]);

  return (
    <Flex justify="center">
      <Checkbox
        checked={value}
        onCheckedChange={e => {
          setValue(e);
          execute({
            data: {
              cm_artist: original.cm_artist,
              label: original.cm_label_id,
              subLabel: original.cm_sublabel_id,
              type: original.type,
              hidden: Boolean(e),
              manual: original.manual,
            },
          });
        }}
      />
    </Flex>
  );
};

const COLUMNS: Column<any>[] = [
  {
    Header: 'Label',
    accessor: 'cm_label',
  },
  {
    Header: 'Label Id',
    accessor: 'cm_label_id',
    width: 100,
  },
  {
    Header: 'SubLabel',
    accessor: 'cm_sublabel',
  },
  {
    Header: 'SubLabel Id',
    accessor: 'cm_sublabel_id',
    width: 100,
  },
  {
    Header: 'Type',
    accessor: 'type',
    width: 80,
  },
  {
    Header: 'Hidden',
    accessor: 'hidden',
    width: 80,
    Cell: HiddenCell,
  },
  {
    Header: 'Manual',
    accessor: 'manual',
    width: 80,
    Cell: props =>
      Boolean(props.value) ? <FAIcon color={'green'} name={'fasCheckCircle'} /> : <div />,
  },
];

const formSchema = z.object({
  label: z.string().min(1, { message: 'Label is required' }),
  subLabel: z.string().min(1, { message: 'SubLabel is required' }),
  type: z.enum(['current', 'prior'], { required_error: 'Type is required' }),
  hidden: z.boolean().optional(),
});

const LabelAssociation = () => {
  const [q, setQ] = useState('');
  const { data, isLoading, refetch } = useGetLabelAssociation(
    {
      data: { q },
    },
    {
      disable: !q,
    }
  );
  const [label, setLabel] = useState<string | number>();
  const [subLabel, setSubLabel] = useState<string | number>();

  const { data: labelSuggestions, isLoading: isLoadingLabelSuggestion } = useSearchLabel(
    {
      data: { label: label! },
    },
    {
      disable: !label,
    }
  );
  const { data: subLabelSuggestions, isLoading: isLoadingSubLabelSuggestion } = useSearchLabel(
    {
      data: { label: subLabel! },
    },
    {
      disable: !subLabel,
    }
  );

  const { execute, isLoading: isLoadingAdd } = useAddLabelAssociation();
  const findLabelIdByName = (name: string) => {
    const id = Object.entries({ ...labelSuggestions, ...subLabelSuggestions }).find(
      ([key, value]) => value.name === name
    )?.[0];
    if (id) return Number(id);
  };

  const labelId = typeof label === 'number' ? label : findLabelIdByName(label!)!;
  const subLabelId = typeof subLabel === 'number' ? subLabel : findLabelIdByName(subLabel!)!;

  const getSuggestionsMap = (suggestions: typeof labelSuggestions) => {
    if (isEmpty(suggestions) || Object.entries(suggestions).length === 0) return [];

    return Object.entries(suggestions).reduce<{ label: string; value: number }[]>(
      (acc, [key, value]) => {
        acc.push({ label: value.name as string, value: Number(key) });
        return acc;
      },
      []
    );
  };

  const labelInputProps = useMemo(
    () => ({
      suggestions: {
        data: getSuggestionsMap(labelSuggestions) ?? [],
        loading: isLoadingLabelSuggestion,
      },
      placeholder: 'Label Name or ID',
    }),
    [labelSuggestions, isLoadingLabelSuggestion]
  );

  const subLabelInputProps = useMemo(
    () => ({
      suggestions: {
        data: getSuggestionsMap(subLabelSuggestions) ?? [],
        loading: isLoadingSubLabelSuggestion,
      },
      placeholder: 'Sub Label Name or ID',
    }),
    [subLabelSuggestions, isLoadingSubLabelSuggestion]
  );

  return (
    <Flex gap={6} vertical>
      <GlobalSearch
        loading={isLoading}
        types={['artist']}
        onSubmit={value => setQ(value as string)}
      />

      {data && (
        <>
          <Panel
            icon="farLayerGroup"
            header="Associated Labels"
            aside={
              <ModalButton
                closeModal={() => {
                  setLabel(undefined);
                  setSubLabel(undefined);
                }}
                header="Add Label Association"
                trigger={<Button icon={<FAIcon name="fasPlus" />}>Add Label Association</Button>}
              >
                <FormTable
                  schema={formSchema}
                  loading={isLoadingAdd}
                  onSubmit={values =>
                    execute({
                      data: {
                        ...values,
                        hidden: values.hidden ?? false,
                        manual: true,
                        cm_artist: data.cm_artist,
                        label: labelId,
                        subLabel: subLabelId,
                        type: values.type as 'current' | 'prior',
                      },
                    }).then(() => refetch())
                  }
                  options={[
                    {
                      name: 'label',
                      label: 'Label Name',
                      component: ({ onChange, value }) => (
                        <ActionInput
                          value={value as string}
                          key="label"
                          onChange={value => {
                            setLabel(value.target.value);
                            onChange(value.target.value);
                          }}
                          {...labelInputProps}
                        />
                      ),
                    },
                    {
                      name: 'subLabel',
                      label: 'Parent Label',
                      component: ({ onChange, value }) => (
                        <ActionInput
                          value={value as string}
                          key="subLabel"
                          onChange={value => {
                            setSubLabel(value.target.value);
                            onChange(value.target.value);
                          }}
                          {...subLabelInputProps}
                        />
                      ),
                    },
                    {
                      name: 'type',
                      label: 'Type',
                      component: (
                        <Select
                          className="w-full"
                          options={[
                            {
                              label: 'Current Association',
                              value: 'current',
                            },
                            {
                              label: 'Prior Association',
                              value: 'prior',
                            },
                          ]}
                        />
                      ),
                    },
                    {
                      label: 'Hidden',
                      component: ({ onChange, value }) => (
                        <Checkbox onCheckedChange={onChange} checked={Boolean(value)} />
                      ),
                      name: 'hidden',
                    },
                  ]}
                />
              </ModalButton>
            }
            title="Modifying Associated Label Of Artist"
            description="Manually set current and prior labels for an artist"
          >
            <AntdTable data={data.labels} columns={COLUMNS} />
          </Panel>
        </>
      )}
    </Flex>
  );
};

export default LabelAssociation;
