import { cn } from '@/lib/utils';
import FAIcon, { FAIcons } from '@Components/ui/fa-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { WhatsThis } from '@Shared';
import React from 'react';
import { ControllerRenderProps, Path, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  Button,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from './ui';

interface FormProps<T extends z.ZodObject<any, any>> {
  schema: T;
  options: {
    name: Path<z.TypeOf<T>>;
    label: string;
    component:
      | React.ReactNode
      | ((field: ControllerRenderProps<z.TypeOf<T>, Path<z.TypeOf<T>>>) => React.ReactNode);
    description?: string;
  }[];
  onSubmit: SubmitHandler<z.infer<T>>;
  loading?: boolean;
}

const FormTable = <T extends z.ZodObject<any, any>>({
  schema,
  options,
  onSubmit,
  loading,
}: FormProps<T>) => {
  const form = useForm<z.infer<T>>({
    resolver: zodResolver(schema),
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className=" rounded-md mb-2 bg-white dark:bg-black">
          {options.map(option => (
            <FormField
              key={String(option.name)}
              control={form.control}
              name={option.name}
              render={({ field }) => {
                console.log(field);
                return (
                  <FormItem className="grid grid-cols-3 items-center gap-4">
                    <FormLabel className="text-left font-normal text-decorative-foreground bg-decorative py-5 px-2 h-full">
                      {option.label}
                    </FormLabel>
                    <div className="flex flex-col col-span-2 !mt-0 text-sm mr-2">
                      <FormControl>
                        {typeof option.component === 'function'
                          ? option.component(field)
                          : React.cloneElement(option.component as React.ReactElement, {
                              ...field,
                            })}
                      </FormControl>
                      {option.description && (
                        <FormDescription>{option.description}</FormDescription>
                      )}
                      <FormMessage />
                    </div>
                  </FormItem>
                );
              }}
            />
          ))}
        </div>
        <Button
          loading={loading}
          variant="submit"
          icon={<FAIcon name="farCircleCheck" />}
          type="submit"
          className="float-right"
          disabled={!form.formState.isValid}
        >
          Submit
        </Button>
      </form>
    </Form>
  );
};

interface TableProps {
  options: {
    label: React.ReactNode;
    component: React.ReactNode;
    labelIcon?: FAIcons;
    description?: string;
  }[];
  description?: string;
  title?: string;
  aside?: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
  borderless?: boolean;
}

const Table = ({
  options,
  aside,
  title,
  description,
  className,
  borderless,
  footer,
}: TableProps) => {
  const renderContent = () => {
    return options.map((option, index) => (
      <div className="grid grid-cols-3 items-center border border-decorative">
        <div
          className={cn(
            'flex items-center space-x-2 bg-decorative px-4 py-3 text-decorative-foreground text-sm col-span-1 h-full',
            index === 0 ? 'rounded-tl-md' : index === options.length - 1 ? 'rounded-bl-md' : ''
          )}
        >
          {option.labelIcon && <FAIcon name={option.labelIcon} />}
          <p className="whitespace-normal break-words">{option.label}</p>
          {option.description && <WhatsThis>{option.description}</WhatsThis>}
        </div>
        <p className="text-sm col-span-2 px-2 py-2 whitespace-normal break-words">
          {option.component}
        </p>
      </div>
    ));
  };

  if (borderless) return <div>{renderContent()}</div>;

  return (
    <Card className={className}>
      {title || aside ? (
        <CardHeader className="justify-between flex flex-row items-center">
          <CardTitle>{title}</CardTitle>
          {aside}
        </CardHeader>
      ) : (
        <div className="h-6" />
      )}
      <CardContent className="pt-0">{renderContent()}</CardContent>
      {footer && <CardFooter>{footer}</CardFooter>}
    </Card>
  );
};

export { FormTable, Table };
