import React, { useEffect } from 'react';
import { generateCmUrl } from '@Utils';
import { Divider, Flex, Tooltip } from 'antd';
import classNames from 'classnames';
import { capitalize } from 'lodash';

interface EntityData {
  id: number;
  name: string;
  image_url: string;
  description?: string;
  platform?: string;
}

const EntityOverlapCell = ({
  value,
  entity,
}: {
  value: EntityData[];
  entity: 'artist' | 'track' | 'playlist' | 'album';
}) => {
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 300);
  }, []);

  if (!show) return null;

  return (
    <Flex className="relative" justify="center">
      {value.map((data, index) => (
        <a
          key={data.id}
          target="_blank"
          href={generateCmUrl(entity, {
            id: data.id,
            service: data.platform,
          })}
        >
          <Tooltip
            key={data.id}
            title={
              <div>
                <p>{capitalize(data.name)}</p>
                <Divider className="my-1 text-white" />
                {data.description && <p>{data.description}</p>}
              </div>
            }
            color={
              {
                artist: 'black',
                track: 'blue',
                playlist: 'green',
                album: 'yellow',
              }[entity]
            }
            placement="top"
          >
            <img
              rel="preload"
              loading="lazy"
              style={{
                left:
                  (value.length - 1) / 2 !== index
                    ? index < (value.length - 1) / 2
                      ? `${(index + 1) * 10}px`
                      : `-${(index + 1) * 2.5}px`
                    : '0',
              }}
              src={data.image_url}
              alt={data.name}
              className={classNames(
                `
                max-w-11 max-h-11
                hover:z-10 cursor-pointer
                hover:scale-[1.25]
                transition-all duration-300 ease-in-out
                relative
               `,
                {
                  'rounded-full': entity === 'artist',
                  'rounded-md': entity === 'playlist',
                }
              )}
            />
          </Tooltip>
        </a>
      ))}
    </Flex>
  );
};

export default React.memo(EntityOverlapCell);
