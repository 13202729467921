import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  COUPON: '/admin/coupon',
  CHURNED_USER: '/admin/churned-users',
  UPDATE_CHURNED_USER_COMMENT: `/admin/churned-users/:id/comment`,
  ADD_TRIAL_SUBSCRIPTION: `/admin/user/stripe/free-trial`,
  APPLY_COUPON: '/admin/free-trial',
  STRIPE_HISTORY: '/admin/user/stripe/subscriptions',
};

export const useGetCoupons = persistGetToUrl<{
  data: {
    expired?: boolean;
  };
}>(ENDPOINT.COUPON);
export const useCreateCoupon = mutateToUrl<{
  data: {
    code: string;
    expirationDate: string;
    numOfDays: number;
  };
}>('POST', ENDPOINT.COUPON);

export const useUpdateCoupon = mutateToUrl<{
  query: {
    id: number;
  };
  data: {
    code: string;
    expirationDate: string;
    numOfDays: number;
  };
}>('PUT', ENDPOINT.COUPON);
export const useDeleteCoupon = mutateToUrl<{
  query: {
    id: string;
  };
}>('DELETE', ENDPOINT.COUPON);

export const useGetChurnedUsers = persistGetToUrl(ENDPOINT.CHURNED_USER);

export const useUpdateChurnedUserComment = mutateToUrl<{
  data: {
    comment: string;
  };
  path: {
    id: string;
  };
}>('PATCH', ENDPOINT.UPDATE_CHURNED_USER_COMMENT);

export const useAddTrialSubscription = mutateToUrl<{
  data: {
    email: string;
    days: number;
    type: 'premium' | 'api';
  };
}>('POST', ENDPOINT.ADD_TRIAL_SUBSCRIPTION);

export const useApplyCoupon = mutateToUrl<{
  data: {
    code: string;
    q: string;
  };
}>('POST', ENDPOINT.APPLY_COUPON);

export const useGetStripeHistory = persistGetToUrl<
  {
    data: {
      email: string;
    };
  },
  Array<{
    id: string;
    object: 'subscription';
    application: null;
    application_fee_percent: null;
    automatic_tax: {
      enabled: boolean;
      liability: {
        type: string;
      };
    };
    billing_cycle_anchor: number;
    billing_cycle_anchor_config: null;
    billing_thresholds: null;
    cancel_at: null;
    cancel_at_period_end: boolean;
    canceled_at: number;
    cancellation_details: {
      comment: null;
      feedback: null;
      reason: string;
    };
    collection_method: string;
    created: number;
    currency: string;
    current_period_end: number;
    current_period_start: number;
    customer: string;
    days_until_due: null;
    default_payment_method: string;
    default_source: null;
    default_tax_rates: Array<any>;
    description: null;
    discount: null;
    discounts: Array<any>;
    ended_at: number;
    invoice_settings: {
      account_tax_ids: null;
      issuer: {
        type: string;
      };
    };
    items: {
      object: string;
      data: Array<{
        id: string;
        object: string;
        billing_thresholds: null;
        created: number;
        discounts: Array<any>;
        metadata: Record<string, any>;
        plan: {
          id: string;
          object: string;
          active: boolean;
          aggregate_usage: null;
          amount: number;
          amount_decimal: string;
          billing_scheme: string;
          created: number;
          currency: string;
          interval: string;
          interval_count: number;
          livemode: boolean;
          metadata: {
            product_name: string;
            webhook_target: string;
          };
          meter: null;
          nickname: null;
          product: string;
          tiers_mode: null;
          transform_usage: null;
          trial_period_days: null;
          usage_type: string;
        };
        price: {
          id: string;
          object: string;
          active: boolean;
          billing_scheme: string;
          created: number;
          currency: string;
          custom_unit_amount: null;
          livemode: boolean;
          lookup_key: null;
          metadata: {
            product_name: string;
            webhook_target: string;
          };
          nickname: null;
          product: string;
          recurring: {
            aggregate_usage: null;
            interval: string;
            interval_count: number;
            meter: null;
            trial_period_days: null;
            usage_type: string;
          };
          tax_behavior: string;
          tiers_mode: null;
          transform_quantity: null;
          type: string;
          unit_amount: number;
          unit_amount_decimal: string;
        };
        quantity: number;
        subscription: string;
        tax_rates: Array<any>;
      }>;
      has_more: boolean;
      total_count: number;
      url: string;
    };
    latest_invoice: string;
    livemode: boolean;
    metadata: Record<string, any>;
    next_pending_invoice_item_invoice: null;
    on_behalf_of: null;
    pause_collection: null;
    payment_settings: {
      payment_method_options: null;
      payment_method_types: null;
      save_default_payment_method: string;
    };
    pending_invoice_item_interval: null;
    pending_setup_intent: null;
    pending_update: null;
    plan: {
      id: string;
      object: string;
      active: boolean;
      aggregate_usage: null;
      amount: number;
      amount_decimal: string;
      billing_scheme: string;
      created: number;
      currency: string;
      interval: string;
      interval_count: number;
      livemode: boolean;
      metadata: {
        product_name: string;
        webhook_target: string;
      };
      meter: null;
      nickname: null;
      product: string;
      tiers_mode: null;
      transform_usage: null;
      trial_period_days: null;
      usage_type: string;
    };
    quantity: number;
    schedule: null;
    start_date: number;
    status: string;
    test_clock: null;
    transfer_data: null;
    trial_end: null;
    trial_settings: {
      end_behavior: {
        missing_payment_method: string;
      };
    };
    trial_start: null;
  }>
>(ENDPOINT.STRIPE_HISTORY, {
  manual: true,
});