import { useCustomQuery } from '@Services/helpers';
import { useCheckLinkCompleteness } from '@Services/linkCompleteness';
import { showInfoMessage } from '@Utils';
import { capitalize } from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import { TABS } from './constants';

export const useComplete = (id: number, entity: (typeof TABS)[number], cacheKey: any) => {
  const { execute, isLoading } = useCheckLinkCompleteness();
  const { data, update } = useCustomQuery(cacheKey);
  const value = useMemo(() => data?.data?.find(item => item.id === id)?.complete, [data, id]);
  const [status, setStatus] = useState(value);

  useEffect(() => {
    setStatus(value);
  }, [id]);

  const handleCheck = async e => {
    if (isLoading) return;
    const newStatus = !status;
    setStatus(newStatus);
    await execute({
      data: {
        status: newStatus,
      },
      path: {
        target: entity,
        targetId: id,
      },
    });
    update({
      ...data,
      data: data.data.map(item => {
        if (item.id === id) {
          return {
            ...item,
            complete: newStatus,
          };
        }
        return item;
      }),
    });
    showInfoMessage(`${newStatus ? 'Completed' : 'Incomplete'} ${capitalize(entity)} ${name}`);
  };

  return {
    status,
    handleCheck,
  };
};
