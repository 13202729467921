import { Segments } from '@Shared/Style';
import React from 'react';
import CompanyUnivAccessTable from './CompanyUnivAccessTable';
import CompanyUnivAccessHierarchy from './CompanyUnivAccessHierarchy';

const CompanyUnivAccess = () => {
  return (
    <Segments
      options={[
        {
          icon: 'faListUl',
          title: 'List',
          content: <CompanyUnivAccessTable />,
        },
        {
          icon: 'farSitemap',
          title: 'Hierarchy',
          content: <CompanyUnivAccessHierarchy />,
        },
      ]}
    />
  );
};

export default CompanyUnivAccess;
