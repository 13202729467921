import FAIcon from '@Components/ui/fa-icons';
import { useGetTwoFactAuthLog } from '@Services/log';
import { useDeleteCacheKey } from '@Services/system';
import { useVerify2FA } from '@Services/user';
import { Button, Tag } from 'antd';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import useDateSelect from '../../../hooks/useDateSelect';
import { Panel } from '../../../shared/Style';
import WhatsThis from '../../../components/WhatsThis';
import DateSelector from '../../../shared/Table/DateSelector';
import DataTableSearch from '../../../shared/Table/SearchInput';
import { generateInternalLink, getFormattedLocalDate } from '../../../utilities';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';

type Event = 'verification-shown' | 'succeeded' | 'rejected' | 'complained' | 'blocked' | 'unblocked';

const getCacheKey = (since: string, until?: string) =>
  `Admin: getTwoFactorAuthLog: [{"since":"${since}"${until ? `,"until":"${until}"` : ''}}]`;

function ActionButton(props: { original: any; value: Event }) {
  const { original, value } = props;
  const { execute, isLoading } = useVerify2FA();

  if (original.verified) {
    return <FAIcon name="fasCheckCircle" color="green" />;
  }

  if (props.value !== 'complained' && !original.verified && original.tried_verify) {
    return (
      <Button loading={isLoading} onClick={() => execute({ path: { id: props.original.user_id as number } })}>
        Verify
      </Button>
    );
  }

  return null;
}

const COLUMNS: Column<Record<string, any>>[] = [
  {
    Header: 'UserID',
    accessor: 'user_id',
  },
  {
    Header: 'User',
    accessor: 'user_email',
    Cell: (props: any) => (
      <a
        target="_blank"
        href={generateInternalLink('user/user-profile', { qs: props.value })}
        rel="noreferrer"
      >
        {props.value}
      </a>
    ),
    width: 150,
  },
  {
    Header: 'IP Address',
    accessor: 'ip_address',
  },
  {
    Header: 'User Agent',
    accessor: 'user_agent',
  },
  {
    Header: (
      <div style={{ textAlign: 'center' }}>
        Event
        <WhatsThis title="Events">
          Verification-shown: user was asked to verify
          <br />
          <br />
          Succeeded: user succeeded verification
          <br />
          <br />
          Rejected: user failed verification
          <br />
          <br />
          Complained: may be harder to capture as that comes in email
          <br />
          <br />
          Blocked: user was blocked from logging in / by admin (Manage in block list section)
          <br />
          <br />
          Unblocked: we unblocked the user to have them login
        </WhatsThis>
      </div>
    ),
    accessor: 'event',
    Cell: (props: any) => (
      <Tag
        color={
          {
            rejected: 'red',
            blocked: 'red',
            succeeded: 'green',
            complained: 'purple',
            unblocked: 'blue',
            'verification-shown': 'blue',
          }[props.value] || 'default'
        }
      >
        {capitalize(props.value)}
      </Tag>
    ),
    width: 150,
  },
  {
    Header: 'Reason',
    accessor: 'reason',
    Cell: (props: any) => props.value?.toLowerCase() ?? '',
  },
  {
    Header: 'By Admin',
    accessor: 'by_admin_email',
    Cell: (props: any) => props.value?.split('@')?.[0] ?? '',
    width: 150,
  },
  {
    Header: 'Timestamp',
    accessor: 'created_at',
    Cell: (props: any) => getFormattedLocalDate(props.value, true),
    width: 170,
  },
  {
    Header: 'Verified',
    accessor: 'event',
    Cell: (props: any) => <ActionButton {...props} />,
  },
];

const SEARCH_FIELDS = [
  {
    label: 'User',
    value: 'user_email',
  },
  {
    label: 'IP Address',
    value: 'ip_address',
  },
  {
    label: 'User Agent',
    value: 'user_agent',
  },
  {
    label: 'Event',
    value: 'event',
  },
  {
    label: 'Reason',
    value: 'reason',
  },
  {
    label: 'By Admin',
    value: 'by_admin_email',
  },
];

function TwoFactAuthLog() {
  const { handleDateChange, handleSelectPeriod, period, since, until } = useDateSelect({
    defaultPeriod: 1,
  });
  const [filteredData, setFilteredData] = useState<Record<string, any>[] | undefined>(undefined);
  const { data, isLoading, refetch } = useGetTwoFactAuthLog({
    data: {
      since,
      until,
    },
  });
  const { execute: deleteCacheKey, isLoading: isRemovingCache } = useDeleteCacheKey();

  useEffect(() => {
    refetch();
  }, [since, until]);

  return (
    <Panel
      title="Two Factor Authentication Log"
      extra={
        <Button
          icon={
            <FAIcon
              name="farRotate"
              loading={isRemovingCache}
              onClick={() =>
                deleteCacheKey({ query: { key: getCacheKey(since, until) } }).then(() => refetch())
              }
            />
          }
        >
          Refetch
        </Button>
      }
    >
      <DateSelector
        handleDateChange={handleDateChange}
        handleSelectPeriod={handleSelectPeriod}
        period={period}
        since={since}
        until={until}
        options={[1, 3, 7]}
      />
      <br />
      <DataTableSearch filters={SEARCH_FIELDS} originalData={data} onChange={setFilteredData} />
      <br />
      <AntdTable data={filteredData ?? data} columns={COLUMNS} loading={isLoading} />
    </Panel>
  );
}

export default TwoFactAuthLog;
