import React from 'react';
import { Pagination as AntdPagination } from 'antd';

interface PaginationProps {
  page: number;
  pageSize: number;
  onPageChange: (page: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
  total: number;
  excludeSizeChanger?: boolean;
}

function Pagination(props: PaginationProps) {
  const { page, pageSize, onPageChange, onPageSizeChange, total, excludeSizeChanger } = props;

  return (
    <AntdPagination
      style={{
        marginTop: '16px',
      }}
      showSizeChanger={!excludeSizeChanger}
      showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
      showQuickJumper
      current={page + 1}
      pageSize={pageSize}
      total={total}
      onChange={(page, pageSize) => {
        onPageChange(page - 1);
        onPageSizeChange?.(pageSize);
      }}
    />
  );
}

export default Pagination;
