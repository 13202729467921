export const G_CLOUD_STORAGE = {
  CHARTMETRIC_LOGO: 'https://storage.googleapis.com/cm-app-assets/images/main/CM_Gradient_Logo.svg',
  CHARTMETRIC_TEXT: 'https://app.chartmetric.com/images/icons/CM-Text-white.svg',
  CHARTMETRIC_FULL_LOGO:
    'https://cdn.sanity.io/images/zdrkqyxr/production-alt/ecff7013f37cbf79115cca3d17b47b79de688c0b-161x32.svg',
  NEW_ICON: 'https://storage.googleapis.com/cm-app-assets/icons/new-pill-navbar.svg',
  COVER_IMG_FALLBACK: 'https://storage.googleapis.com/cm-app-assets/images/main/av2.png',
  ONESHEET:
    'https://cdn.prod.website-files.com/632189deb4ae204cd483ebe2/64d2f144f3927f249eb79222_Blue-OnesheetLogo-p-500.png',
};
