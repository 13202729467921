import { H4, Muted } from '@/ui';
import { Flex } from '@/ui';
import React from 'react';

interface Props {
  title: string;
  description?: string;
  extra?: React.ReactNode;
}

const Title = ({ title, description, extra }: Props) => {
  return (
    <Flex justify="between" gap={0} align="center" className="mb-4 mt-2">
      <Flex vertical>
        <H4>{title}</H4>
        {description && <Muted>{description}</Muted>}
      </Flex>
      <div>{extra}</div>
    </Flex>
  );
};

export default Title;
