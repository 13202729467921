import {
  useUpdateArtistLink,
  useUpdateTrackLink,
  useUpdateAlbumLink,
  useUpdateCuratorLink,
} from '@Services/linking';
import { Callback, UpdateLinkingMetaData } from './types';

export const useArtistLinking = (id: number, callback: Callback) => {
  const { execute, isLoading } = useUpdateArtistLink();

  const onAdd = (url: string) =>
    execute({
      query: { id },
      data: {
        q: url.trim(),
      },
    }).then(() => callback(url));

  const onDelete = (url: string) =>
    execute({
      query: { id },
      data: {
        q: url.trim(),
        isDelete: true,
      },
    }).then(() => callback(url));

  return {
    onAdd,
    onDelete,
    isLoading,
  };
};

export const useTrackLinking = (
  id: number,
  callback: Callback,
  { artistName, isrc, trackName }: UpdateLinkingMetaData
) => {
  const { execute, isLoading } = useUpdateTrackLink();

  const onAdd = (url: string) =>
    execute({
      query: { id },
      data: {
        q: url.trim(),
        artists: artistName,
        isrc,
        name: trackName,
      },
    }).then(() => callback(url));

  const onDelete = (url: string) =>
    execute({
      data: {
        q: url.trim(),
      },
    }).then(() => callback(url));

  return {
    onAdd,
    onDelete,
    isLoading,
  };
};

export const useAlbumLinking = (id: number, callback: Callback) => {
  const { execute, isLoading } = useUpdateAlbumLink();

  const onAdd = (url: string) =>
    execute({
      query: { id },
      data: {
        q: url.trim(),
      },
    }).then(() => callback(url));

  const onDelete = async (url: string) =>
    execute({
      query: {},
      data: {
        q: url.trim(),
      },
    }).then(() => callback(url));

  return {
    onAdd,
    onDelete,
    isLoading,
  };
};

export const useCuratorLinking = (
  id: number,
  callback: Callback,
  { streamingType }: UpdateLinkingMetaData
) => {
  const { execute, isLoading } = useUpdateCuratorLink();

  const onAdd = (url: string) =>
    execute({
      query: {
        id,
        streamingType: streamingType!,
      },
      data: {
        q: url.trim(),
      },
    }).then(() => callback(url));

  const onDelete = (url: string) =>
    execute({
      query: {
        id,
        streamingType: streamingType!,
      },
      data: {
        q: url.trim(),
        isDelete: true,
      },
    }).then(() => callback(url));

  return {
    onAdd,
    onDelete,
    isLoading,
  };
};
