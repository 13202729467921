import React, { useEffect, useMemo } from 'react';
import { usePagination } from '@Hooks';
import { AntdTable } from '@Shared';
import COLUMNS from './columns';
import { TABS } from '../../constants';
import { getLinkCompletenessResponse } from '@Services/linkCompleteness';

interface Props extends ReturnType<typeof usePagination> {
  data: getLinkCompletenessResponse;
  isLoading: boolean;
  entity: (typeof TABS)[number];
  cacheKey: any;
}

const TableView = ({
  handleChangePage,
  handleChangePageSize,
  limit,
  offset,
  cacheKey,
  isLoading,
  data,
  entity,
}: Props) => {
  const columns = useMemo(() => COLUMNS(entity), [entity]);

  return (
    <AntdTable
      key={entity}
      extraData={{
        cacheKey,
        entity,
      }}
      loading={isLoading}
      columns={columns}
      data={data?.data ?? []}
      lazyPaginationOptions={{
        limit,
        offset,
        total: data?.total!,
        handleChangePage,
        handleChangePageSize,
      }}
    />
  );
};

export default React.memo(TableView);
