import React from 'react';
import { capitalize, isEmpty } from 'lodash';
import { generateCmUrl } from '../../../../utilities';
import { useGetEntity } from '@Services/search';
import { EditEntityProps } from '..';
import { Table } from '@Components';
import { Avatar, Flex } from '@/ui';
import EntityCell from '@Shared/Table/EntityCell';

function Info({ q }: EditEntityProps) {
  const { data, isFetching, refetch } = useGetEntity(
    {
      data: {
        q,
      },
    },
    {
      disable: isEmpty(q),
    }
  );

  return (
    <Table
      title="Basic Information"
      options={[
        {
          label: 'Name',
          component: <EntityCell entity="track" value={data} />,
        },
        {
          label: 'Artists',
          component: data.artists?.map(({ name, id }, index) => (
            <a
              key={id}
              target="_blank"
              rel="noopener noreferrer"
              href={generateCmUrl('artist', { id })}
              style={{ marginRight: 6 }}
            >
              {capitalize(name)}
              {index !== (data.artists?.length ?? 0) - 1 && ', '}
            </a>
          )),
          labelIcon: 'fadMicrophoneStand',
        },
        {
          label: 'ISRC',
          component: data.isrc,
        },
      ]}
    />
  );
}

export default Info;
