import React from 'react';
import { useCustomQuery } from '@Services/helpers';
import { useGetSocialUrlsManual } from '@Services/social';
import { AntdIcon } from '@Shared/Style';
import { Button, Flex, Tag, Typography } from 'antd';
import { SUPPORT_PLATFORMS, TABS } from '../constants';
import { generateCmUrl } from '@Utils';
import ModalButton from '@Shared/Style/ModalButton';
import { LinkCompletenessItem } from '@Services/linkCompleteness';
import { ExternalLinksPanel } from '@Components/external-links-panel';
import { useGetEntity } from '@Services/search';
import EntityCell from '@Shared/Table/EntityCell';
import { ENTITY } from '@Constants/entity';
import { capitalize, isObject } from 'lodash';
import { Skeleton } from '@/ui';

export const LinkingPanel = ({
  entity,
  item,
  cacheKey,
}: {
  entity: (typeof TABS)[number];
  item: LinkCompletenessItem;
  cacheKey: any;
}) => {
  const { id, links, isrc, name, artists } = item;

  const { data, update } = useCustomQuery(cacheKey);
  const { refetch: getSocialUrls } = useGetSocialUrlsManual({
    path: {
      id,
      entity: {
        artist: 'cm_artist',
        track: 'cm_track',
      }[entity] as any,
    },
  });

  return (
    <ExternalLinksPanel
      panelStyle={false}
      type={entity as any}
      urls={
        links
          ? Object.entries(links).reduce((acc, [key, value]) => {
              if (SUPPORT_PLATFORMS[entity].includes(key)) {
                acc[key] = value;
              }
              return acc;
            }, {})
          : {}
      }
      id={id}
      meta={
        entity === 'artist'
          ? {
              artistName: name,
            }
          : {
              artistName: artists?.[0]?.name,
              trackName: name,
              isrc: isrc,
            }
      }
      callback={async () => {
        const socialUrls = Object.entries(await getSocialUrls().then(res => res.data)).reduce(
          (acc, [key, value]) => {
            if (SUPPORT_PLATFORMS[entity].includes(key)) {
              acc[key] = value;
            }
            return acc;
          },
          {}
        );

        return update({
          ...data,
          data: data.data.map(item => {
            if (item.id === id) {
              return {
                ...item,
                links: socialUrls,
              };
            }
            return item;
          }),
        });
      }}
    />
  );
};

const LinkingModalTitle = ({ entity, id }: { entity: ENTITY; id: number }) => {
  const { data, isLoading } = useGetEntity({
    data: {
      q: generateCmUrl(entity, {
        id,
      }),
    },
  });

  return data ? (
    <Flex align="center" gap="large" wrap className="w-full">
      <EntityCell entity={entity} value={data} className="!text-base" />
      {data.genres && (
        <Flex vertical className="pl-2">
          <Typography.Text type="secondary">Genres</Typography.Text>
          <Flex>
            {isObject(data.genres)
              ? Object.values(data.genres)
                  .flat(2)
                  .slice(0, 3)
                  .map(({ name, id }) => (
                    <Tag color="blue" key={id}>
                      {capitalize(name)}
                    </Tag>
                  ))
              : null}
          </Flex>
        </Flex>
      )}
      {data.artists && data.artists.length > 0 && (
        <Typography.Text type="secondary" style={{ fontSize: 16 }}>
          {` by ${data.artists.map(artist => artist.name).join(', ')}`}
        </Typography.Text>
      )}
    </Flex>
  ) : isLoading ? (
    <Skeleton className="w-full h-12" />
  ) : null;
};

const LinkingModal = React.memo(
  ({ original, extraData }: { original: LinkCompletenessItem; value: any; extraData: any }) => {
    return (
      <ModalButton
        cancelButtonProps={{ style: { display: 'none' } }}
        style={{
          minWidth: '50vw',
        }}
        title={'DSP & Social Links'}
        Button={<Button icon={<AntdIcon name="list" />} type="primary" />}
      >
        <LinkingModalTitle entity={extraData.entity} id={original.id} />
        <br />
        <LinkingPanel entity={extraData.entity} item={original} cacheKey={extraData.cacheKey} />
      </ModalButton>
    );
  }
);

export default LinkingModal;
