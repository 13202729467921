'use client';

import React from 'react';
import { SheetMenu } from './sheet-menu';
import { UserNav } from './user-nav';
import { ModeToggle } from './mode-toggle';
import {
  Button,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenu,
  Flex,
  Typography,
} from '..';
import useRoute from '@Routes/useRoute';
import { useUser } from '@Services/auth';
import FAIcon, { FAIcons } from '@Components/ui/fa-icons';
import { cn } from '@/lib/utils';
import { SearchCommand } from '@Components/SearchCommand';
import {
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbSeparator,
  BreadcrumbEllipsis,
  Breadcrumb,
  BreadcrumbPage,
} from '../breadcrumb';
import { ChevronDownIcon } from 'lucide-react';

const NavTab = ({ className }: { className?: string }) => {
  const { currentItem, currentMenu, onChangeItem } = useRoute();

  return (
    <Flex gap={4} align="center" wrap className={cn(className)}>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <FAIcon name={currentMenu?.icon as FAIcons} />
            <BreadcrumbPage>{currentMenu?.name}</BreadcrumbPage>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <DropdownMenu>
              <DropdownMenuTrigger className="flex items-center gap-1 text-primary">
                <FAIcon name={currentItem?.icon as FAIcons} />
                <span className="text-primary">{currentItem?.name}</span>
                <ChevronDownIcon className="w-4 h-4" />
              </DropdownMenuTrigger>
              <DropdownMenuContent align="start">
                {currentMenu?.items?.map((item, i) => (
                  <DropdownMenuItem onClick={() => onChangeItem(item.name)} key={i}>
                    {item.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
    </Flex>
  );
};

export function Navbar() {
  const { currentRoute, currentMenu } = useRoute();
  const { userData } = useUser();

  return (
    <header className="top-0 z-10 w-full dark:shadow-secondary pt-3">
      <div className="mx-4 sm:mx-8 flex h-14 items-center">
        <div className="flex items-center space-x-4 lg:space-x-0">
          <SheetMenu />
          {currentRoute === '/' ? (
            <Flex>
              <p className="text-muted-foreground text-xl font-medium">
                {new Date().getDay() === 5
                  ? '🎉 Happy Friday, '
                  : new Date().getHours() < 6
                    ? '😴 Good night, '
                    : new Date().getHours() < 12
                      ? '👋🏻 Good morning, '
                      : new Date().getHours() < 14
                        ? '🍔 Happy lunch, '
                        : new Date().getHours() < 18
                          ? '🌞 Good afternoon, '
                          : '🌟 Good evening, '}
                {userData?.name}!
              </p>
            </Flex>
          ) : (
            <NavTab />
          )}
        </div>

        <div className="flex flex-1 items-center justify-end gap-4">
          <SearchCommand />
          {/* <ModeToggle /> */}
          <UserNav />
        </div>
      </div>
    </header>
  );
}
