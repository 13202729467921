import { message } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import React from 'react';

const ToastContext = React.createContext<MessageInstance | null>(null);

export const ToastProvider = ({ children }) => {
  const [api, holder] = message.useMessage();

  return (
    <ToastContext.Provider value={api}>
      {holder}
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = React.useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
