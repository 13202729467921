import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  NEWS_LINK: '/admin/news-link/request',
  NEWS_LINK_DOMAINS: '/admin/news-link/domains',
  DSP_LINK: '/admin/links/requests',
  DSP_LINK_UPDATE: '/admin/links/requests/bulkUpdate',
  URL_CHANGE: '/admin/cm-url/requests',
  URL_CHANGE_UPDATE: '/admin/cm-url/requests/bulkUpdate',
  ARTIST_IDENTITY_CHANGE: '/admin/artistIdentity/requests',
  ARTIST_IDENTITY_CHANGE_UPDATE: '/admin/artistIdentity/requests/bulkUpdate',
  GENRE_REQUEST: `/admin/artist/genre-request`,
  GENRE_REQUEST_UPDATE: `/admin/artist/genre-request/:id`,
  GET_CLAIM_REQUEST: '/admin/claim-request',
  GET_CLAIM_REQUEST_STATUS: `/admin/claim-request/status/:code`,
  APPROVE_CLAIM_REQUEST: `/admin/claim-request/accept/:id`,
  DECLINE_CLAIM_REQUEST: `/admin/claim-request/deny/:id`,
  RESEND_CLAIM_EMAIL: `/admin/email/claim-request-created`,
  TRACK_ARTIST: '/admin/track/artist-change-requests',
  TRACK_ARTIST_UPDATE: '/admin/track/artist-change-requests/update',
};

export const useGetTrackArtistRequest = persistGetToUrl(ENDPOINT.TRACK_ARTIST);
export const useSubmitTrackArtistRequest = mutateToUrl<{
  data: { requests: { process: boolean; id: number }[] };
}>('PATCH', ENDPOINT.TRACK_ARTIST_UPDATE);


export const useGetNewsLinkRequest = persistGetToUrl(ENDPOINT.NEWS_LINK, {
  parse: data =>
    data?.data?.map(item => ({
      ...item,
      url:
        item.url.startsWith('http://') || item.url.startsWith('https://')
          ? item.url
          : `http://${item.url}`,
    })),
});

export const useUpdateNewsLinkRequest = mutateToUrl<{
  data: { requests: { process: boolean; id: number; cm_artist: number }[] };
}>('PATCH', ENDPOINT.NEWS_LINK);
export const useGetNewsLinkDomains = persistGetToUrl(ENDPOINT.NEWS_LINK_DOMAINS);

export const useGetDspLinkRequest = persistGetToUrl(ENDPOINT.DSP_LINK);
export const useSubmitDspLinkRequest = mutateToUrl<{
  data: { requests: { process: boolean; id: number }[] };
}>('POST', ENDPOINT.DSP_LINK_UPDATE);

export const useGetUrlChangeRequest = persistGetToUrl(ENDPOINT.URL_CHANGE);
export const useSubmitUrlChangeRequest = mutateToUrl<{
  data: { requests: { process: boolean; id: number }[] };
}>('POST', ENDPOINT.URL_CHANGE_UPDATE);

export const useGetArtistIdentityChangeRequest = persistGetToUrl(ENDPOINT.ARTIST_IDENTITY_CHANGE);
export const useSubmitArtistIdentityChangeRequest = mutateToUrl<{
  data: { requests: { process: boolean; id: number }[] };
}>('POST', ENDPOINT.ARTIST_IDENTITY_CHANGE_UPDATE);

export const useGetGenreRequest = persistGetToUrl(ENDPOINT.GENRE_REQUEST);
export const useApproveGenreRequest = mutateToUrl<{
  path: { id: number };
}>('PATCH', ENDPOINT.GENRE_REQUEST_UPDATE);
export const useDeclineGenreRequest = mutateToUrl<{
  path: { id: number };
}>('DELETE', ENDPOINT.GENRE_REQUEST_UPDATE);

export const useGetClaimRequest = persistGetToUrl(ENDPOINT.GET_CLAIM_REQUEST);
export const useGetClaimRequestStatus = persistGetToUrl<{
  path: { code: string };
}>(ENDPOINT.GET_CLAIM_REQUEST_STATUS, {
  manual: true,
});
export const useApproveClaimRequest = mutateToUrl<{
  path: { id: number };
}>('POST', ENDPOINT.APPROVE_CLAIM_REQUEST);
export const useDeclineClaimRequest = mutateToUrl<{
  path: { id: number };
  data: { reason: string };
}>('POST', ENDPOINT.DECLINE_CLAIM_REQUEST);
export const useResendClaimEmail = mutateToUrl<{
  data: {
    email: string;
    name: string;
    code: string;
    targetName: string;
    targetId: string;
  };
}>('POST', ENDPOINT.RESEND_CLAIM_EMAIL);
