import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import DataTableSearch from '../../../shared/Table/SearchInput';

import { useAddTrustedUser, useDeleteTrustedUser, useGetTrustedUser } from '../../../services/user';
import GlobalSearch from '../../../components/global-search/GlobalSearch';
import { generateInternalLink, getFormattedLocalDate } from '@Utils';
import { Link } from 'react-router-dom';
import FAIcon from '@Components/ui/fa-icons';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import { Panel, Button, Popconfirm, Modal } from '@/ui';

const columns = (deleteTrustedUser): Column<any>[] => [
  {
    Header: 'User Id',
    accessor: 'user_info', // String-based value accessors!
  },
  {
    Header: 'First Name',
    accessor: 'first_name',
    width: 200,
  },
  {
    Header: 'Last Name',
    accessor: 'last_name',
  },
  {
    Header: 'Email',
    accessor: 'email',
    Cell: props => (
      <Link
        to={generateInternalLink('user/user-profile', {
          qs: props.value,
        })}
      >
        {props.value}
      </Link>
    ),
  },
  {
    Header: 'Company',
    accessor: 'company',
    filter: true,
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    formatter: value => getFormattedLocalDate(value),
    sorter: true,
  },
  {
    Header: 'Delete',
    width: 50,
    Cell: props => (
      <Popconfirm
        message={
          <span>
            Are you sure delete <strong>{props.original.email}</strong> from trusted user?
          </span>
        }
        onConfirm={() => deleteTrustedUser(props.original.user_info)}
      >
        <Button icon={<FAIcon name="farTrash" />} className="red" />
      </Popconfirm>
    ),
  },
];

function AddTrustedUserModal({ open, refetch, setOpen }) {
  const { execute: addTrustedUser, isLoading: isLoadingAdd } = useAddTrustedUser();

  const [inputValue, setInputValue] = useState('');

  const handleAddTrustedUser = () => {
    addTrustedUser({ data: { q: inputValue } }).then(() => {
      refetch();
      setOpen(false);
    });
  };

  return (
    <Modal
      header="Add Trusted User"
      open={open}
      footer={
        <Button
          variant="submit"
          disabled={!inputValue || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)}
          loading={isLoadingAdd}
          onClick={handleAddTrustedUser}
          icon={<FAIcon name="farPlus" />}
        >
          Add
        </Button>
      }
      closeModal={() => setOpen(false)}
    >
      <GlobalSearch
        placeholder="Search for user"
        onChange={setInputValue}
        loading={isLoadingAdd}
        types={['user']}
        icon="farUser"
        size="middle"
      />
    </Modal>
  );
}

function TrustedUsers() {
  const { data, isLoading, refetch } = useGetTrustedUser();
  const { execute: deleteTrustedUser, isLoading: isLoadingDelete } = useDeleteTrustedUser();

  const [open, setOpen] = useState(false);
  const [filteredData, setFilteredData] = useState();

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const onSearch = filteredData => {
    setFilteredData(filteredData);
  };

  const handleDeleteTrustedUser = q => {
    deleteTrustedUser({ data: { q } }).then(() => refetch());
  };

  return (
    <>
      <Panel
        header="Trusted Users"
        aside={
          <Button
            variant="submit"
            disabled={isLoading}
            icon={<FAIcon name="farPlus" />}
            onClick={() => setOpen(true)}
          >
            Add Trusted User
          </Button>
        }
        icon="farUserTie"
      >
        <DataTableSearch originalData={data} onChange={onSearch} size="large" />
        <AntdTable
          loading={isLoading || isLoadingDelete}
          data={filteredData ?? []}
          columns={columns(handleDeleteTrustedUser)}
          pageSize={15}
        />
      </Panel>
      <AddTrustedUserModal open={open} refetch={refetch} setOpen={setOpen} />
    </>
  );
}

export default TrustedUsers;
