import { persistGetToUrl } from './helpers';

const ENDPOINT = {
  GET: '/sns/socialUrls/:entity/:id',
};

export const useGetSocialUrlsManual = persistGetToUrl<{
  path: {
    entity: 'cm_artist' | 'cm_track';
    id: number;
  };
}>(ENDPOINT.GET, {
  manual: true,
});
