import React from 'react';
import { Empty, Typography } from 'antd';
import { BrandIcon } from '../../../../shared/Style';
import { generateInternalLink, getFormattedLocalDate } from '@Utils';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import { Panel } from '@/ui';
import FAIcon from '@Components/ui/fa-icons';

function ArtistLinksHistory({ artist_links_history }) {
  const columns: Column<any>[] = [
    {
      Header: 'Social Type',
      accessor: 'type',
      Cell: ({ value }) => (
        <BrandIcon
          platform={value}
          style={{
            width: '20px',
            height: '20px',
          }}
        />
      ),
    },
    {
      Header: 'Account ID',
      accessor: 'account_id',
    },
    {
      Header: 'URL',
      accessor: 'url',
      Cell: ({ value }) => <a href={value}>{value}</a>,
    },
    {
      Header: 'User ID',
      accessor: 'user_id',
    },
    {
      Header: 'User Email',
      accessor: 'email',
      Cell: ({ value }) => (
        <Link to={generateInternalLink('user/user-profile', { qs: value })}>{value}</Link>
      ),
    },
    {
      Header: 'Created At',
      accessor: 'created_at',
      formatter: value => getFormattedLocalDate(value),
    },
    {
      Header: 'Active',
      accessor: 'active',
      Cell: ({ value }) => (value === 'true' ? <FAIcon name="farCheck" color="green" /> : null),
    },
  ];
  return (
    <Panel header="Artist Links History">
      {!artist_links_history || !artist_links_history.length ? (
        <Empty />
      ) : (
        <AntdTable data={artist_links_history} columns={columns} />
      )}
    </Panel>
  );
}

export default ArtistLinksHistory;
