import { Card, Flex } from 'antd';
import React from 'react';
import { useEditArtistGenreBatch } from '../../Context';
import ArtistList from './ArtistList';

const ArtistSelection = () => {
  const { numberOfArtists } = useEditArtistGenreBatch();

  return (
    <Card
      type="inner"
      title={numberOfArtists ? `${numberOfArtists} selected` : 'Select Artists'}
      style={{ width: '100%', height: '100%' }}
      styles={{
        body: {
          maxHeight: '64vh',
          height: '100%',
          display: 'inline-block',
          width: '100%',
        },
      }}
    >
      <ArtistList />
    </Card>
  );
};

export default ArtistSelection;
