import React from 'react';
import { Panel } from '@/ui';
import { useGetUserActivities } from '@Services/log';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import { getFormattedDateString } from '@Utils';

const COLUMNS: Column<any>[] = [
  {
    Header: 'ID',
    accessor: 'id',
    width: 80,
  },
  {
    Header: 'Domain',
    accessor: 'domain_name',
  },
  {
    Header: 'Register Date',
    accessor: 'register_date',
    formatter: getFormattedDateString,
  },
  {
    Header: 'Most Recent Activity',
    accessor: 'most_recent_activity',
    formatter: getFormattedDateString,
  },
  {
    Header: 'Total Sessions',
    accessor: 'total_sessions',
  },
  {
    Header: 'Usage Duration',
    accessor: 'usage_duration',
    Cell: ({ value }) =>
      `${value.days ? `${value.days}D ` : ''}${value.hours ? `${value.hours}H ` : ''}${
        value.minutes ? `${value.minutes}M ` : ''
      }${value.seconds ? `${value.seconds}S` : ''}`,
  },
  {
    Header: 'Artist',
    accessor: 'artist_following',
  },
  {
    Header: 'Playlist',
    accessor: 'playlist_following',
  },
  {
    Header: 'Curator',
    accessor: 'curator_following',
  },
];

const ActiveUsers = () => {
  const { data, isLoading } = useGetUserActivities({
    data: {
      offset: 0,
      limit: 500,
    },
    path: {
      type: 'active',
    },
  });
  return (
    <Panel header="Active Users" description="List of active users">
      <AntdTable columns={COLUMNS} data={data} loading={isLoading} />
    </Panel>
  );
};

export default ActiveUsers;
