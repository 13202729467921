import { useTagList } from '@Services/tag';
import { GetProp, Select } from 'antd';
import React from 'react';
import { capitalize } from 'lodash';

type Props =
  | {
      multiple?: false | undefined;
      onChange: (value: number) => void;
      tagType: keyof GetProp<ReturnType<typeof useTagList>, 'tagMap'>;
      value?: number;
    }
  | {
      multiple: true;
      onChange: (value: number[]) => void;
      tagType: keyof GetProp<ReturnType<typeof useTagList>, 'tagMap'>;
      value?: number[];
    };

const TagFilter = ({ value, multiple, onChange, tagType = 'mainV2' }: Props) => {
  const { tagMap, getTagById, isLoading } = useTagList();

  return (
    <Select
      loading={isLoading}
      showSearch
      filterOption={(input, option) =>
        JSON.stringify(option).toLowerCase().includes(input.toLowerCase())
      }
      value={value}
      mode={multiple ? 'multiple' : undefined}
      onChange={onChange}
      placeholder="Genre (v2)"
      style={{ width: 200 }}
      options={tagMap[tagType].map(tag => ({
        label: capitalize(tag.name).slice(0, 50),
        value: tag.id,
      }))}
    />
  );
};

export default React.memo(TagFilter);
