import { Button, Input, Panel, Select } from '@/ui';
import { Table } from '@Components';
import FAIcon from '@Components/ui/fa-icons';
import { useMergeSocialStat } from '@Services/meta';
import { useState } from 'react';
const mediaOptions = [{ value: 'instagram', label: 'Instagram', icon: 'faInstagram' }];

const MergeSocialStat = () => {
  const { execute: mergeSocialStat, isLoading: isFetchingMergeSocialStat } = useMergeSocialStat();
  const [mediaType, setMediaType] = useState('');
  const [primaryId, setPrimaryId] = useState('');
  const [secondaryId, setSecondaryId] = useState('');

  const handleMediaChange = (value: string) => setMediaType(value);
  const handlePrimaryIdChange = ({ target: { value } }) => setPrimaryId(value);
  const handleSecondaryIdChange = ({ target: { value } }) => setSecondaryId(value);

  const handleSubmit = () => {
    mergeSocialStat({ data: { mediaType, primaryId, secondaryId } });
  };

  return (
    <Panel
      header="Merge old social media data to current one"
      classNames={{
        footer: 'flex justify-end',
      }}
      footer={
        <Button
          onClick={handleSubmit}
          loading={isFetchingMergeSocialStat}
          icon={<FAIcon name="fasCheckCircle" />}>
          Submit
        </Button>
      }>
      <Table
        borderless
        options={[
          {
            label: 'Social Media',
            component: (
              <Select
                placeholder="Social media"
                options={mediaOptions}
                onChange={handleMediaChange}
                value={mediaType}
              />
            ),
          },
          {
            label: 'Primary Id',
            component: (
              <Input
                value={primaryId}
                onChange={handlePrimaryIdChange}
                placeholder="Current social id. ex: billieeilish"
              />
            ),
          },
          {
            label: 'Secondary Id',
            component: (
              <Input
                value={secondaryId}
                name="secondaryId"
                placeholder="Old social id. ex: whereareavocados"
                onChange={handleSecondaryIdChange}
              />
            ),
          },
        ]}
      />
    </Panel>
  );
};

export default MergeSocialStat;
