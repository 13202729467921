import React, { useState } from 'react';
import { useStates } from '@Hooks';
import { useAddTrialSubscription, useGetStripeHistory } from '@Services/purchase';
import { capitalize, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { generateInternalLink, getDateFormatFromEpoch } from '@Utils';
import { Table } from '@Components';
import { Button, Flex, Input, Panel, Muted, Modal, useModal } from '@/ui';
import { AntdTable } from '@Shared';
import { STRIPE_TRIAL_PLANS } from '@Utils/constants';
import FAIcon from '@Components/ui/fa-icons';
import dayjs from 'dayjs';

type SubscriptionType = 'api' | 'premium';


function TrialSubscription() {
  const { states, setAllStates } = useStates({
    email: '',
    duration: 14,
  });

  const { closeModal, modalProps, showModal } = useModal<{ type: SubscriptionType; data: any }>();
  const [lastUserEmail, setLastUserEmail] = useState<string>();
  const { execute, isLoading: isLoadingAddTrialSubscription, data } = useAddTrialSubscription();
  const {
    data: stripeHistory,
    isLoading: isLoadingStripeHistory,
    isFetching: isFetchingStripeHistory,
    refetch: getStripeHistory,
  } = useGetStripeHistory(
    {
      data: { email: states.email },
    },
    {
      disable: !states.email,
    }
  );

  const handleChangeInput = e => {
    const { name, value } = e.target;
    setAllStates({ [name]: value });
  };

  const handleSubmit = async (type: SubscriptionType) => {
    const allSubscriptions = await getStripeHistory().then(e => e.data);

    const pastTrial = allSubscriptions?.find(e =>
      e.items.data.find(item => item.plan.id === STRIPE_TRIAL_PLANS?.[type.toUpperCase()])
    );

    if (pastTrial) {
      showModal({
        type,
        data: pastTrial,
      });
    } else {
      handleGrantTrial(type);
    }
  };

  const handleGrantTrial = (type: SubscriptionType) => {
    const { email, duration } = states;

    execute({
      data: { email, days: Number(duration), type },
    }).then(() => {
      setLastUserEmail(email);
      setAllStates({ email: '', duration: 14 });
    });
  };

  const disabled = isEmpty(states.email) || states.duration <= 0;
  const isLoading =
    isLoadingAddTrialSubscription || isFetchingStripeHistory || isLoadingStripeHistory;

  return (
    <>
      <Modal
        closeModal={closeModal}
        open={modalProps.show}
        footer={
          modalProps.data && (
            <Flex gap={2}>
              <Button variant="ghost" onClick={closeModal}>
                Cancel
              </Button>
              {dayjs(modalProps.data.trial_end * 1000).isBefore() ? (
                <Button variant="submit" onClick={() => handleGrantTrial(modalProps.type)}>
                  Proceed
                </Button>
              ) : null}
            </Flex>
          )
        }
        header={
          modalProps.data && (
            <Flex>
              <FAIcon name="fasExclamationTriangle" className="text-yellow-500" />
              {dayjs(modalProps.data.trial_end * 1000).isBefore()
                ? 'Trial ended in the past'
                : 'Trial is currently active'}
            </Flex>
          )
        }
      >
        {modalProps.data && (
          <span>
            This user has already a trial plan{' '}
            <code>{modalProps.data.items.data[0].plan.nickname}</code> <br />
            {dayjs(modalProps.data.trial_end * 1000).isBefore()
              ? `in the past, trial ended at Do you want to proceed?`
              : `currently active${modalProps.data.trial_end ? ' , Trial will be ended at ' : ''}`}
            {modalProps.data.trial_end ? getDateFormatFromEpoch(modalProps.data.trial_end) : ''}.
          </span>
        )}
      </Modal>
      <Panel
        header="Free Trial Subscription"
        description={
          <>
            If the user already has a paid plan same type, it won't be processed.
            <br />
            API trial: Please send the refresh token through
            <Link to={generateInternalLink('user/user-profile')}> Search User </Link> to user after
            this.
          </>
        }
        footer={
          <Flex align="center" gap={2} vertical justify="center" wFull>
            <Flex gap={2}>
              <Button
                loading={isLoading}
                disabled={disabled}
                variant="submit"
                onClick={() => handleSubmit('api')}
              >
                Grant API Trial
              </Button>
              <Button
                loading={isLoading}
                disabled={disabled}
                onClick={() => handleSubmit('premium')}
              >
                Grant Premium Trial
              </Button>
            </Flex>
          </Flex>
        }
      >
        <Table
          borderless
          options={[
            {
              label: 'Email',
              component: (
                <Input
                  type="email"
                  name="email"
                  value={states.email}
                  onChange={handleChangeInput}
                />
              ),
              labelIcon: 'farPaperPlane',
            },
            {
              label: 'Duration',
              component: (
                <Input
                  type="number"
                  name="duration"
                  value={states.duration}
                  onChange={handleChangeInput}
                />
              ),
              labelIcon: 'farCalendar',
            },
          ]}
        />
      </Panel>
      {data?.obj?.subscriptions && (
        <Panel title={`User Subscriptions (${lastUserEmail})`}>
          <AntdTable
            columns={[
              {
                Header: 'Plan',
                accessor: 'items.data[0].plan.nickname',
              },
              {
                Header: 'Start Date',
                accessor: 'start_date',
                formatter: value => getDateFormatFromEpoch(value),
              },
              {
                Header: 'End Date',
                accessor: 'cancel_at',
                formatter: value => (value ? getDateFormatFromEpoch(value) : 'N/A'),
              },
              {
                Header: 'Trial Days',
                accessor: 'trial_start',
                formatter: (_, original) => {
                  return original['trial_start']
                    ? Math.ceil((original['trial_end'] - original['trial_start']) / 86400)
                    : 'N/A';
                },
              },
              {
                Header: 'Metadata',
                accessor: 'items.data[0].plan.metadata',
                formatter: value => JSON.stringify(value),
              },
            ]}
            data={data?.obj?.subscriptions ?? []}
          />
        </Panel>
      )}
    </>
  );
}

export default TrialSubscription;
