import { useGetTeamMembers } from '@Services/team';
import { GlobalSearch, Table } from '@Components';
import { AntdIcon } from '@Shared/Style';
import { generateInternalLink, getFormattedLocalDate, getStripeCustomerUrl } from '@Utils';
import { Button, Divider, Typography } from 'antd';
import { isEmpty } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import AddMemberModal from './Components/AddMemberModal';
import Members from './Members';
import { CSVLink } from 'react-csv';
import useURLParams from '@Hooks/useURLParams';
import { useGetUser } from '@Services/user';
import Subscription, { SubscriptionPlan } from '@Components/UserSubscription';
import { Flex, Panel } from '@/ui';

const CSV_FORMAT = [
  { label: 'Status', key: 'status' },
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'email' },
  { label: 'Verified Email', key: 'verified' },
  { label: 'Registered At', key: 'register_date' },
  { label: 'Added At', key: 'last_added_at' },
  { label: 'Removed At', key: 'last_removed_at' },
  { label: 'is admin', key: 'admin' },
  { label: 'Joined team', key: 'joined' },
  { label: 'Stripe ID', key: 'stripeId' },
];

const ManageTeam = () => {
  const {
    params: { q },
    setParams,
  } = useURLParams();

  const {
    data,
    isLoading,
    refetch: refetchTeam,
  } = useGetTeamMembers(
    {
      data: { q },
    },
    {
      disable: isEmpty(q),
    }
  );

  const { data: user, refetch: refetchUser } = useGetUser(
    {
      data: { q },
    },
    {
      disable: isEmpty(q),
    }
  );

  const managerInfo = {
    ...user?.teamInfo,
    ...data?.manager,
  };

  const refetch = () => {
    refetchTeam();
    refetchUser();
  };

  const getCSVData = () => {
    if (!data) return [];
    const format = (status: 'current' | 'past', data: any) => {
      return data.map(e => ({
        ...e,
        verified: e.verified ? 'Yes' : 'No',
        admin: e?.teamInfo?.admin ? 'Yes' : 'No',
        joined: e?.teamInfo?.verified ? 'Yes' : 'No',
        register_date: getFormattedLocalDate(e.register_date),
        last_added_at: getFormattedLocalDate(e.last_added_at),
        last_removed_at: getFormattedLocalDate(e.last_removed_at),
        stripeId: e.customerInfo.id,
        status,
      }));
    };

    return [...format('current', data.members), ...format('past', data.pastMembers)];
  };

  return (
    <Flex gap={6} vertical>
      <GlobalSearch
        types={['user']}
        buttonLabel="Search"
        icon="farUser"
        loading={isLoading}
        className="mt-4"
        defaultValue={q}
        onSubmit={value => setParams({ q: value as string })}
      />
      {data && (
        <>
          <Panel
            header="Team"
            aside={
              <Flex gap={2}>
                <CSVLink
                  data={getCSVData()}
                  headers={CSV_FORMAT}
                  filename={`Team Members - ${data.manager.company}.csv`}
                >
                  <Button className="blue" icon={<AntdIcon name="csv" />}>
                    Download CSV (Members)
                  </Button>
                </CSVLink>

                <AddMemberModal
                  managerId={data.manager.id}
                  managerEmail={data.manager.email}
                  refetch={refetch}
                />
              </Flex>
            }
          >
            <Table
              title="Manage"
              options={[
                {
                  label: 'Company',
                  component: data.manager.company,
                },
                {
                  label: 'Manager',
                  component: (
                    <>
                      {data.manager.name}
                      <Link
                        target="_blank"
                        to={generateInternalLink('user/user-profile', {
                          qs: data.manager.email,
                        })}
                      >
                        ({data.manager.email})
                      </Link>
                    </>
                  ),
                },
                {
                  label: 'Stripe ID',
                  component: (
                    <Typography.Link href={getStripeCustomerUrl(data.manager.customer_id)}>
                      {data.manager.customer_id}
                    </Typography.Link>
                  ),
                },
              ]}
            />
            <SubscriptionPlan {...user} />
          </Panel>
          <Members data={data.members} type="current" manager={managerInfo} refetch={refetch} />
          <Members data={data.pastMembers} type="past" manager={managerInfo} refetch={refetch} />
        </>
      )}
    </Flex>
  );
};

export default ManageTeam;
