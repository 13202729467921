import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

dayjs.extend(utc);

/**
 *
 * @param date
 * @returns YYYY-MM-DD
 */
export const getUTCDateString = (date: Date) => {
  console.log(date);
  return dayjs(date).utc().format('YYYY-MM-DD');
};

/**
 *
 * @param string
 * @returns Date
 */
export const getUTCDate = (string: string) => {
  const date = dayjs.utc(string).toDate();
  const adjustedDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  console.log(string, adjustedDate);
  return adjustedDate;
};
