import { useStates } from '@Hooks';
import { useUpdateCoupon } from '@Services/purchase';
import { Flex, Input, Modal, Typography } from 'antd';
import React, { useEffect } from 'react';

interface Props {
  open: boolean;
  setClose: () => void;
  code: string;
  expiration_date: string;
  num_of_days: number;
  id: number;
  refetch: () => void;
}

function UpdateModal({ open, setClose, code, expiration_date, num_of_days, id, refetch }: Props) {
  const { execute, isLoading } = useUpdateCoupon();

  const { states, setAllStates } = useStates({
    code: '',
    expiration_date: '',
    num_of_days: 0,
  });

  useEffect(() => {
    if (open)
      setAllStates({
        code,
        expiration_date: expiration_date?.split('T')[0],
        num_of_days,
      });
  }, [code, expiration_date, num_of_days]);

  const handleChange = e => {
    const { name, value } = e.target;
    setAllStates({ [name]: value });
  };

  const handleSubmit = () =>
    execute({
      query: { id },
      data: {
        code: states.code,
        expirationDate: states.expiration_date,
        numOfDays: states.num_of_days,
      },
    });

  return (
    <Modal
      title="Update Coupon"
      open={open}
      onCancel={setClose}
      confirmLoading={isLoading}
      onOk={() => {
        handleSubmit().then(setClose).then(refetch);
      }}
      okText="Update"
      cancelText="Cancel"
    >
      <Flex gap="small" vertical>
        <Flex vertical>
          <Typography.Text type="secondary">Code</Typography.Text>
          <Input placeholder="Code" value={states.code} onChange={handleChange} name="code" />
        </Flex>
        <Flex vertical>
          <Typography.Text type="secondary">Expired date</Typography.Text>
          <Input
            type="date"
            placeholder="Expiration Date"
            value={states.expiration_date}
            onChange={handleChange}
            name="expiration_date"
          />
        </Flex>
        <Flex vertical>
          <Typography.Text type="secondary">Days for free trial</Typography.Text>
          <Input
            type="number"
            placeholder="Days For Free Trial"
            value={states.num_of_days}
            onChange={handleChange}
            name="num_of_days"
          />
        </Flex>
      </Flex>
    </Modal>
  );
}

export default UpdateModal;
