import {
  useUpdateTeamInvitation,
  TeamMemberInvitationTable,
  useUpdateTeam,
} from '@Services/Onesheet/team';
import { Form, Modal, Input, Checkbox } from 'antd';
import React, { useEffect, useState } from 'react';

interface Form {
  email: string;
  permissions: string;
  isAdmin: boolean;
}

function AddMemberModal({
  open,
  setOpen,
  teamId,
  teamName,
  callback,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  teamId: string;
  teamName: string;
  callback?: () => void;
}) {
  const { addMember, fetching } = useUpdateTeam();
  const [form] = Form.useForm<Form>();
  const [disabled, setDisabled] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setDisabled(false))
      .catch(() => setDisabled(true));
  }, [form, values]);

  const handleSubmit = () => {
    const { email, permissions, isAdmin } = form.getFieldsValue();

    addMember(email, teamId, {
      isAdmin,
      permissions,
    }).then(() => {
      form.resetFields();
      setOpen(false);
      callback?.();
    });
  };

  // TODO: change to GridTable
  return (
    <Modal
      title={`Add Member : ${teamName}`}
      open={open}
      cancelText="Close"
      okText="Add"
      onCancel={() => setOpen(false)}
      okButtonProps={{
        disabled,
        loading: fetching,
      }}
      onOk={() => {
        handleSubmit();
      }}
    >
      <Form
        form={form}
        name="basic"
        initialValues={
          {
            email: '',
            permissions: '',
            isAdmin: false,
          } as Form
        }
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <Form.Item
          label="Email"
          name="email"
          labelCol={{ span: 5 }}
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Please input user email',
            },
          ]}
        >
          <Input name="email" variant="filled" placeholder="Enter email address" type="email" />
        </Form.Item>
        <Form.Item
          label="Folder IDs"
          name="permissions"
          labelCol={{ span: 5 }}
          rules={[{ type: 'string', message: 'Please input permissions' }]}
        >
          <Input
            name="permissions"
            variant="filled"
            placeholder="Enter folder IDs to allow reading. Separate by comma."
            type="array"
          />
        </Form.Item>

        <Form.Item
          label="Is Admin"
          name="isAdmin"
          labelCol={{ span: 5 }}
          valuePropName="checked"
          initialValue={false}
        >
          <Checkbox name="isAdmin" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default AddMemberModal;
