import { Button, DatePicker, Input, Modal, ModalButton, useModal } from '@/ui';
import FAIcon from '@Components/ui/fa-icons';
import React from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormTable } from '@Components';
import { useCreateCoupon, useGetCoupons } from '@Services/purchase';
import dayjs from 'dayjs';

const formSchema = z.object({
  code: z.string().min(2, {
    message: 'Code must be at string ',
  }),
  expiration_date: z.date({
    required_error: 'A date of birth is required.',
  }),
  num_of_days: z.string().min(1, {
    message: 'Number of days must be at least 1.',
  }),
});

const AddCouponModal = ({ refetch }) => {
  const { isLoading, execute } = useCreateCoupon();
  const { showModal, closeModal, modalProps } = useModal();

  const onSubmit = (data: z.infer<typeof formSchema>) => {
    execute({
      data: {
        code: data.code,
        expirationDate: dayjs(data.expiration_date).format('YYYY-MM-DD'),
        numOfDays: Number(data.num_of_days),
      },
    })
      .then(closeModal)
      .then(refetch);
  };

  return (
    <>
      <Button onClick={showModal} icon={<FAIcon name="farPlus" />}>
        Add Coupon
      </Button>
      <Modal header="Add Coupon" closeModal={closeModal} open={modalProps.show}>
        <FormTable
          loading={isLoading}
          schema={formSchema}
          onSubmit={onSubmit}
          options={[
            {
              name: 'code',
              label: 'Code',
              component: <Input />,
            },
            {
              name: 'expiration_date',
              label: 'Expiration Date',
              component: (
                <DatePicker
                  disabled={{
                    before: new Date(),
                  }}
                />
              ),
            },
            {
              name: 'num_of_days',
              label: 'Number of Days',
              component: <Input type="number" />,
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default AddCouponModal;
