import { Button, Card, CardContent, CardHeader, CardTitle, Panel, Textarea } from '@/ui';
import FAIcon from '@Components/ui/fa-icons';
import { useDeleteCacheKey, useLookUpCache } from '@Services/system';
import { ActionInput } from '@Shared/Style';
import { showErrorMessage } from '@Utils';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

const ClearCache = () => {
  const [key, setKey] = useState('');

  const { data, isLoading, refetch } = useLookUpCache({ data: { key } });
  const { execute: deleteCacheKey, isLoading: isDeleting } = useDeleteCacheKey();

  useEffect(() => {
    if (!isEmpty(key)) {
      refetch().then(e => {
        e.data?.data === null && showErrorMessage('Cached Value Is Null Or Cache Key Was Not Found.');
      });
    }
  }, [key]);

  return (
    <Panel
      header="Clear Cache"
      classNames={{
        footer: 'flex justify-end',
      }}
      footer={
        data?.data && (
          <Button
            variant="destructive"
            icon={<FAIcon name="farTrash" />}
            loading={isDeleting}
            onClick={() => deleteCacheKey({ query: { key } }).then(() => setKey(''))}>
            Delete
          </Button>
        )
      }>
      <ActionInput
        placeholder="Enter a Redis cache key e.g. Track: GetBasicInfoV6{'0':'cm','1':'81299652'}"
        action={{
          icon: 'farSearch',
          content: 'Lookup',
          onClick: e => {
            setKey(e.target.value);
          },
          loading: isLoading,
        }}
      />
      {data?.data && (
        <Card>
          <CardHeader>
            <CardTitle>Cached Value</CardTitle>
          </CardHeader>
          <CardContent>
            <Textarea value={JSON.stringify(data, null, 2)} />
          </CardContent>
        </Card>
      )}
    </Panel>
  );
};

export default ClearCache;
