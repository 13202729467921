import { Badge, Checkbox, Flex, Panel, Radio } from '@/ui';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import React from 'react';
import { Link } from 'react-router-dom';
import useRequestTable from '../../../hooks/useRequstTable';
import { useGetUrlChangeRequest, useSubmitUrlChangeRequest } from '../../../services/request';
import LinksCell from '../../../shared/Table/LinksCell';
import DataTableSearch from '../../../shared/Table/SearchInput';
import RequestSubmitButton from '../../../shared/Table/SubmitModal';
import { generateCmUrl, generateInternalLink } from '../../../utilities';

const SUBMIT_MODAL_COLUMNS = [
  {
    Header: 'Links',
    Cell: ({ original }) => (
      <LinksCell currentUrl={original.old_url} newUrl={original.new_url} targetId={original.target_id} />
    ),
  },
  {
    Header: 'Requester',
    accessor: 'user_email',
  },
];

const COLUMNS = ({
  onClickApprove,
  onClickDecline,
  approves,
  declines,
  data,
  onClickSelectApproveAll,
  onClickSelectDeclineAll,
}): Column<any>[] => [
  {
    Header: 'Target Id',
    accessor: 'target_id',
    Cell: props => (
      <a href={generateCmUrl('artist', { id: props.value })} target="_blank" rel="noopener noreferrer">
        {props.value}
      </a>
    ),
  },
  {
    Header: 'Links',
    Cell: props => (
      <LinksCell
        currentUrl={props.original.old_url}
        newUrl={props.original.new_url}
        targetId={props.original.target_id}
      />
    ),
    width: 500,
  },
  {
    Header: 'Requester',
    accessor: 'user_email',
    Cell: ({ value }) => <Link to={generateInternalLink('user/user-profile', { qs: value })}>{value}</Link>,
  },

  {
    accessor: 'id',
    Header: (
      <Flex justify="center" align="center" gap={2}>
        Approve
        <Checkbox
          checked={approves.length === data?.length && data.length > 0}
          onClick={onClickSelectApproveAll}
        />
      </Flex>
    ),
    Cell: ({ value }) => (
      <Flex justify="center">
        <Radio
          name={`approve_${value}`}
          checked={approves.includes(value)}
          onClick={() => onClickApprove(value)}
        />
      </Flex>
    ),
  },
  {
    accessor: 'id',
    Header: (
      <Flex justify="center" align="center" gap={2}>
        Decline
        <Checkbox
          checked={declines.length === data?.length && data.length > 0}
          onClick={onClickSelectDeclineAll}
        />
      </Flex>
    ),
    Cell: ({ value }) => (
      <Flex justify="center">
        <Radio
          name={`decline_${value}`}
          checked={declines.includes(value)}
          onClick={() => onClickDecline(value)}
        />
      </Flex>
    ),
  },
];

function URLUpdateRequestPanel() {
  const {
    data,
    isLoading,
    state,
    onSearch,
    onClickApprove,
    onClickDecline,
    submit,
    isSubmitting,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    selected,
  } = useRequestTable(useGetUrlChangeRequest, useSubmitUrlChangeRequest);

  const onClickSubmit = () =>
    submit(
      (id, status) => ({ id, process: status }),
      (approves, declines) => ({ data: { requests: [...approves, ...declines] } }),
    );

  return (
    <Panel
      icon="fadLink"
      aside={<DataTableSearch originalData={data} onChange={onSearch} />}
      header={
        <Flex align="center" gap={2}>
          URL Update Request
          <Badge variant="default">{data?.length ?? '0'}</Badge>
        </Flex>
      }
      classNames={{
        footer: 'justify-center',
      }}
      footer={
        <RequestSubmitButton
          columns={SUBMIT_MODAL_COLUMNS}
          onClickSubmit={onClickSubmit}
          approveRequest={state.approves}
          declineRequest={state.declines}
          data={data ?? []}
          loading={isSubmitting}
          selected={selected}
        />
      }
    >
      <AntdTable
        loading={isLoading}
        key="table"
        data={!state.filteredData ? data : state.filteredData}
        columns={COLUMNS({
          onClickApprove,
          onClickDecline,
          approves: state.approves,
          declines: state.declines,
          data,
          onClickSelectApproveAll,
          onClickSelectDeclineAll,
        })}
      />
    </Panel>
  );
}

export default React.memo(URLUpdateRequestPanel);
