import { useMergeArtists } from '@Services/artist';
import { GlobalSearch } from '@Components';
import { Panel, Table } from '@Shared/Style';
import React from 'react';

const MergeArtist = () => {
  const { execute, isLoading } = useMergeArtists();

  return (
    <Table
      icon="farMerge"
      title="Merge Duplicated Artist"
      panelStyle
      layout="horizontal"
      form={{
        loading: isLoading,
        initialValues: {
          primaryArtistUrl: '',
          secondaryArtistUrl: '',
        },
        submitPosition: 'bottom',
        onSubmit: values => {
          execute({
            query: values,
          });
        },
      }}
      options={[
        {
          rules: [
            {
              required: true,
              type: 'url',
              message: 'Please input primary artist!',
            },
          ],
          label: 'Primary',
          value: 'primaryArtistUrl',
          component: <GlobalSearch types={['artist']} size="middle" />,
          span: 12,
        },
        {
          rules: [
            {
              required: true,
              type: 'url',
              message: 'Please input secondary artist!',
            },
          ],
          label: 'Secondary',
          value: 'secondaryArtistUrl',
          component: <GlobalSearch types={['artist']} size="middle" />,
        },
      ]}
    />
  );
};

export default MergeArtist;
