import { AuthenticityRiskKeys } from '@Services/meta';
import { CAREER_STAGE_MAPPER, RECENT_MOMENTUM_MAPPER } from '@Utils/constants';

export interface EditableData {
  image_url: string;
  code2: string;
  gender: number;
  cm_artist_description: string;
  booking_agent: string;
  record_label: string;
  press_contact: string;
  is_duplicate: boolean;
  general_manager: string;
  pronoun_title: string;
  is_non_artist: boolean;
  gpt_summary: any;
  date_of_birth: string;
  hide_gender: boolean;
  hide_birthday: boolean;
  hide_pronoun: boolean;
  hide_cover_image: boolean;
  band: boolean;
  override_stage: string;
  current_city_id: number;
  authenticity_risk_overrides?: { [key in AuthenticityRiskKeys]: number };
  inactive: boolean;
}

export interface StaticData {
  artist_stage: {
    career_growth: keyof typeof RECENT_MOMENTUM_MAPPER;
    career_growth_score: number;
    career_stage: keyof typeof CAREER_STAGE_MAPPER;
    career_stage_score: number;
  };
  associatedLabels: {
    current: { cm_label: string; cm_sublabel: string[] };
    prior: { cm_label: string; cm_sublabel: string[]; label_type: string };
  };
  authenticity_risk: {
    [key in AuthenticityRiskKeys]: number;
  };
  topSongwriterCollaborators: string[];
  rank_eg: number;
  rank_fb: number;
  current_city: string;
}

export interface States extends EditableData {
  override_for_artist_stage_legendary: boolean;
  override_for_artist_stage_superstar: boolean;
  image_file: File | undefined;
}

export type TAGS =
  | 'hide_birthday'
  | 'hide_gender'
  | 'hide_pronoun'
  | 'hide_cover_image'
  | 'override_for_artist_stage_legendary'
  | 'override_for_artist_stage_superstar';

export const CAREER_STAGE_CHANGE_OPTIONS = {
  legendary: 'superstar',
  superstar: 'legendary',
};
