import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';

import { cn } from '@/lib/utils';

const Variants = cva(`flex`, {
  variants: {
    justify: {
      center: 'justify-center',
      start: 'justify-start',
      end: 'justify-end',
      between: 'justify-between',
      around: 'justify-around',
      evenly: 'justify-evenly',
    },
    align: {
      center: 'items-center',
      start: 'items-start',
      end: 'items-end',
      baseline: 'items-baseline',
      stretch: 'items-stretch',
    },
    wrap: {
      true: 'flex-wrap ',
      false: 'flex-nowrap',
    },
    shrink: {
      true: 'flex-shrink',
      false: 'flex-grow',
    },
  },
  defaultVariants: {
    justify: 'start',
    wrap: false,
    align: 'stretch', //if start, full carousel is not working
  },
});

interface Props extends VariantProps<typeof Variants>, React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
  vertical?: boolean;
  flex?: boolean | number;
  gap?: number;
  separator?: boolean;
  shrink?: boolean;
  wFull?: boolean;
  hFull?: boolean;
}

const Flex = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      justify,
      align,
      wrap,
      flex,
      children,
      vertical,
      style,
      separator,
      wFull,
      hFull,
      shrink = true,
      gap = 1,
      className,
      ...rest
    },
    ref
  ) => (
    <div
      {...rest}
      ref={ref}
      style={{
        ...style,
        gap: gap * 4,
        flex: typeof flex === 'number' ? flex : flex ? 1 : undefined,
      }}
      className={cn(
        Variants({
          justify,
          align,
          wrap,
          shrink,
        }),
        vertical ? 'flex-col' : 'flex-row',
        separator && 'pb-2',
        wFull && 'w-full',
        hFull && 'h-full',
        className
      )}
    >
      {separator
        ? React.Children.map(children, (child, index) => (
            <React.Fragment key={index}>
              <div className="border-b w-full border-gray-300" />
              {child}
              {index === React.Children.count(children) - 1 && <div className="pb-[1px]" />}
            </React.Fragment>
          ))
        : children}
    </div>
  )
);

export { Flex };
