import React from 'react';
import { Check, ChevronsUpDown, MenuIcon, PanelsTopLeft } from 'lucide-react';
import { Menu } from './menu';
import { Button } from '../button';
import { Sheet, SheetContent, SheetHeader, SheetTrigger } from '../sheet';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../dropdown-menu';
import { useStore } from 'zustand';
import { useSidebarToggle } from '@Hooks/layout/useSidebar';
import { Typography } from '..';
import { MENU_GROUP } from '@Routes/menus';

const GroupDropdown = () => {
  const sidebar = useStore(useSidebarToggle, state => state);

const groupImage = MENU_GROUP.find(menu => menu.name === sidebar.group)!;

return (
  <DropdownMenu>
    <DropdownMenuTrigger asChild>
      <Button
        variant="ghost"
        size="lg"
        className="data-[state=open]:-accent data-[state=open]:text-sidebar-accent-foreground px-2"
      >
        <img
          src={groupImage.image}
          width={groupImage?.size.width}
          height={groupImage.size.height}
        />
        <ChevronsUpDown className="ml-auto" size={16} />
      </Button>
    </DropdownMenuTrigger>
    <DropdownMenuContent className="w-[--radix-dropdown-menu-trigger-width]" align="start">
      {MENU_GROUP.map(menu => (
        <DropdownMenuItem key={menu.name} onSelect={() => sidebar.setGroup(menu.name)}>
          {menu.name} {menu.name === sidebar.group && <Check className="ml-auto" size={16} />}
        </DropdownMenuItem>
      ))}
    </DropdownMenuContent>
  </DropdownMenu>
);
};

export default GroupDropdown;
