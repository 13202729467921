import React, { useState } from 'react';
import {
  Button,
  Divider,
  Flex,
  Select,
  Tag as AntdTag,
  Popconfirm,
  Typography,
  Card,
  Tooltip,
} from 'antd';
import { capitalize, isEmpty } from 'lodash';
import { PLATFORMS_COLOR } from '@Utils/constants';
import { AntdIcon, Panel } from '../../../shared/Style';
import { capitalizeEveryWord } from '../../../utilities';
import ModalButton from '@Shared/Style/ModalButton';
import {
  InternalTagType,
  LinkType,
  SUPPORTED_GENRE_ORDERING_ENTITIES,
  Tag,
  useTagList,
  useUpdateTag,
} from '@Services/tag';
import { addSpaceBeforeCapitalLetter } from '@Utils/string';
import { EntityTag } from '../context/types';
import { getTagColor, TAG_TYPES } from '../context/constants';
import { useGenrePanel } from '../context/GenrePanelContext';
import FAIcon from '@Components/ui/fa-icons';

function TagLabel({
  entity,
  tag,
  refetch,
  artistId,
}: {
  entity: 'artist' | 'track';
  tag: EntityTag;
  refetch: () => void;
  artistId: number;
}) {
  const { orders, onEditOrderGenre: onChangeOrder } = useGenrePanel();

  const { id, name, source, hidden, internalTagType, link_type } = tag;
  const { handleHiddenArtistGenreTag, isAdding, isHiding } = useUpdateTag(
    entity,
    artistId,
    refetch
  );

  return (
    <AntdTag
      style={{
        width: 'min-content',
      }}
      color={getTagColor(internalTagType, hidden, source)}
    >
      <Flex gap="middle">
        {capitalizeEveryWord(name.replaceAll('_', ' '))} {source ? `(${capitalize(source)})` : ''}
        <Flex gap="small" align="center">
          {SUPPORTED_GENRE_ORDERING_ENTITIES.includes(entity) &&
            !orders.includes(id) &&
            !hidden &&
            (internalTagType === 'mainV2' || internalTagType === 'sub') && (
              <Tooltip title="Add to ordering">
                <FAIcon name={'fasCirclePlus'} onClick={() => onChangeOrder(id)} />
              </Tooltip>
            )}

          {entity === 'artist' &&
            (internalTagType === 'mainV2' ||
              internalTagType === 'sub' ||
              internalTagType === 'main') && (
              <Tooltip title={hidden ? 'Show' : 'Hide'}>
                <FAIcon
                  loading={isHiding || isAdding}
                  onClick={event => {
                    handleHiddenArtistGenreTag(id, link_type);
                  }}
                  name={hidden ? 'farEyeSlash' : 'farEye'}
                />
              </Tooltip>
            )}
        </Flex>
      </Flex>
    </AntdTag>
  );
}

export default TagLabel;
