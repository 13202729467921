import { AntdIcon } from '@Shared/Style';
import ModalButton from '@Shared/Style/ModalButton';
import { Button } from 'antd';
import React, { useState } from 'react';
import { useInsertTagRelation, useTagList } from '@Services/tag';
import { useEditGenreHierarchy } from '../Context';
import AddHierarchyModal, { Item } from '../../../../shared/Hierarchy/AddHierarchyModalButton';
import { capitalize } from 'lodash';

const AddGenreHierarchyModalButton = () => {
  const { useGenreRelatives: useGenreGroup, useGenre } = useEditGenreHierarchy();
  const { data } = useGenre();
  const { execute, isLoading } = useInsertTagRelation();
  const { tagMap, getTagById } = useTagList();
  const { refetch } = useGenreGroup();

  if (!data) return null;
  const { name, id } = data;


  const handleSubmit = async (selectedTags: Item[]) => {
    await Promise.all(
      selectedTags.map(tag =>
        execute({
          data:
            tag.position === 'parent'
              ? {
                  parent: tag.id,
                  child: id,
                }
              : {
                  parent: id,
                  child: tag.id,
                },
        })
      )
    ).then(() => refetch());
  };

  return (
    <AddHierarchyModal
      rootId={id}
      title={`Add Genre Relationship to ${capitalize(name)}`}
      onSubmit={handleSubmit}
      options={tagMap['mainV2'].map(tag => ({
        label: capitalize(tag.name).slice(0, 50),
        value: tag.id,
      }))}
    />
  );
};

export default React.memo(AddGenreHierarchyModalButton);
