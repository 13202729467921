export const ROUTE_PATH = {
  HOME: '/',
  ONESHEET: '/onesheet',
  USER: '/user',
  REQUESTS: '/requests',
  CMDATA: '/cmdata',
  ARTIST: '/artist',
  TRACK: '/track',
  LABEL: '/label',
  CAMPAIGN: '/campaign',
  PURCHASE: '/purchase',
  RESTRICTION: '/restriction',
  UTILITY: '/utility',
  LOGGING: '/logging',
  LOGIN: '/login',
  TAG: '/tag',
  LINKING: '/linking',
  KEYWORDS: '/keyword',
};


export const ONESHEET_PATH = {
  USER_MANAGER: '/onesheet/user-manager',
  TEAM_MANAGER: '/onesheet/team-manager',
};


export const generateRoute = (route: string, name: string) =>
  `${route}/${name.toLowerCase().replace('/', '-').replaceAll(' ', '-')}`;