import React, { useEffect } from 'react';
import { useInsertNote, useUpdateNote, useDeleteNote } from '@Services/log';
import { LinkCompletenessItem, NoteItem } from '@Services/linkCompleteness';
import { AntdIcon, ActionInput } from '@Shared/Style';
import ModalButton from '@Shared/Style/ModalButton';
import { showInfoMessage } from '@Utils';
import { Button, Flex, Typography, Input, Divider } from 'antd';
import { random } from 'lodash';
import { useState } from 'react';
import { useCustomQuery } from '@Services/helpers';

const NoteCell = ({
  original,
  value,
  extraData,
}: {
  original: LinkCompletenessItem;
  value: NoteItem[];
  extraData: { cacheKey: any };
}) => {
  const [notesState, setNotesState] = useState<NoteItem[]>(original.notes ?? []);
  const { execute: insertNote, isLoading: isLoadingInsert } = useInsertNote();
  const { execute: updateNote, isLoading: isLoadingUpdate } = useUpdateNote();
  const { execute: deleteNote, isLoading: isLoadingDelete } = useDeleteNote();
  const { data, update } = useCustomQuery(extraData.cacheKey);

  useEffect(() => {
    setNotesState(original.notes ?? []);
  }, [value]);

  const isChanged =
    (notesState ?? [])?.some(
      n => n.user_email === 'New' || original.notes?.find(note => note.id === n.id)?.body !== n.body
    ) || (original.notes ?? []).some(n => !notesState.some(note => note.id === n.id));

  const onSubmit = async () =>
    await Promise.all([
      ...notesState
        .filter(n => n.user_email === 'New')
        .map(n =>
          insertNote({
            path: { target: 'cm_artist', targetId: original.id },
            data: { body: n.body, date: n.note_date },
          })
        ),
      ...notesState
        .filter(
          n =>
            n.user_email !== 'New' &&
            original.notes?.find(note => note.id === n.id)?.body !== n.body
        )
        .map(n =>
          updateNote({
            path: {
              noteId: n.id,
            },
            data: {
              body: n.body,
              date: n.note_date,
            },
          })
        ),
      ...(original.notes ?? [])
        .filter(n => !notesState.some(note => note.id === n.id))
        .map(n =>
          deleteNote({
            path: {
              noteId: n.id,
            },
          })
        ),
    ]).then(() => {
      showInfoMessage('Notes updated');
      update({
        ...data,
        data: data.data.map(item => {
          if (item.id === original.id) {
            return {
              ...item,
              notes: notesState,
            };
          }
          return item;
        }),
      });
    });

  const isLoading = isLoadingInsert || isLoadingUpdate || isLoadingDelete;
  return (
    <ModalButton
      style={{ minWidth: '50vw' }}
      Button={
        value?.length > 0 ? (
          <Flex gap="small" justify="space-between" align="center">
            <Typography.Text>{value?.map?.(note => note?.body)?.join(' / ')}</Typography.Text>
          </Flex>
        ) : (
          <Button icon={<AntdIcon color="gray" name="plus" />} type="text" />
        )
      }
      title="Edit Notes"
      onOk={onSubmit}
      okText="Save"
      okButtonProps={{
        disabled: !isChanged,
        loading: isLoading,
      }}
    >
      <Flex vertical gap="small">
        {notesState?.map(note => (
          <Flex gap="small" align="center">
            <Typography.Text type="secondary" style={{ width: 250 }}>
              {note.user_email}
            </Typography.Text>
            <Input.TextArea
              autoSize
              allowClear
              maxLength={500}
              key={note.id}
              value={note.body}
              onChange={e => {
                setNotesState(
                  notesState.map(n => (n.id === note.id ? { ...n, body: e.target.value } : n))
                );
              }}
            />
            <Button
              onClick={() => {
                setNotesState(notesState.filter(n => n.id !== note.id));
              }}
              icon={<AntdIcon name="minus" />}
              className="red"
              style={{ width: 42, height: 32 }}
            />
          </Flex>
        ))}
      </Flex>
      <Divider />
      <Typography.Text type="secondary">New Note</Typography.Text>
      <ActionInput
        maxLength={500}
        clearAfterAction
        action={{
          icon: 'farPlus',
          onClick: e =>
            setNotesState(old => [
              ...old,
              {
                body: e.target.value,
                id: random(0, 1000),
                note_date: new Date().toISOString(),
                target: 'cm_artist',
                target_id: original.id,
                timestp: '',
                user_info: 0,
                user_email: 'New',
              },
            ]),
        }}
      />
      <br />
    </ModalButton>
  );
};

export default NoteCell;
