import { SearchInput } from '@Shared';
import { Card, Checkbox, Divider, Flex, Select, Space, Tag, Typography } from 'antd';
import React, { useState } from 'react';
import { useEditArtistGenreBatch } from '../../Context';
import { AntdIcon } from '@Shared/Style';
import GenreList from './GenreList';
import { PLATFORMS_COLOR } from '@Utils/constants';
import { useTagList } from '@Services/tag';
import { capitalize } from 'lodash';

const GenreSelection = () => {
  const { tags, onChangeTags: onChangeGenres, mode } = useEditArtistGenreBatch();
  const { tagMap, getTagById } = useTagList();

  const handleChangeGenre = (value: number) => {
    onChangeGenres(old => {
      if (old.includes(value)) {
        return old.filter(item => item !== value);
      }
      return [...old, value];
    });
  };

  return (
    <Card
      type="inner"
      title={`Select Tag(s) to ${capitalize(mode)}`}
      style={{ width: '100%' }}
      styles={{
        body: {
          height: '50vh',
          display: 'inline-block',
          width: '100%',
        },
      }}
    >
      <Typography.Paragraph strong>Selected</Typography.Paragraph>
      <div style={{ height: 100 }}>
        <Flex gap="small" wrap style={{ overflowY: 'auto', maxHeight: 100 }}>
          {tags.map(id => (
            <Tag
              key={id}
              onClick={() => handleChangeGenre(id)}
              color={
                {
                  main: PLATFORMS_COLOR.chartmetric,
                  sub: PLATFORMS_COLOR.spotify,
                  mainV2: PLATFORMS_COLOR.youtube,
                  others: PLATFORMS_COLOR.acr,
                }[getTagById(id)?.internalTagType ?? 'others']
              }
            >
              <Flex gap="small">
                {capitalize(getTagById(id)?.name)}
                <AntdIcon name="close" />
              </Flex>
            </Tag>
          ))}
        </Flex>
      </div>
      <Divider />
      <GenreList onChange={handleChangeGenre} values={tags} />
    </Card>
  );
};

export default GenreSelection;
