import React from 'react';
import { capitalize } from 'lodash';
import { Link } from 'react-router-dom';
import DataTableSearch from '../../../shared/Table/SearchInput';
import { generateCmUrl, generateInternalLink, getFormattedLocalDate } from '../../../utilities';
import { useGetDspLinkRequest, useSubmitDspLinkRequest } from '../../../services/request';
import useRequestTable from '../../../hooks/useRequstTable';
import RequestSubmitButton from '../../../shared/Table/SubmitModal';
import LinksCell from '../../../shared/Table/LinksCell';
import { AntdTable } from '@Shared';
import { Column } from '@Shared/Table/AntdTable';
import { Badge, Checkbox, Flex, Panel, Radio, Separator, Tooltip, TooltipRoot } from '@/ui';

const SEARCH_FILTERS = [
  {
    label: 'Source From',
    value: 'source_from',
  },
  {
    label: 'Requester',
    value: 'user_email',
  },
  {
    label: 'Created At',
    value: 'created_at',
  },
  {
    label: 'New Url',
    value: 'new_url',
  },
  {
    label: 'Current Url',
    value: 'current_url',
  },
  {
    label: 'Target Id',
    value: 'target_id',
  },
  {
    label: 'Id',
    value: 'id',
  },
];

const SUBMIT_MODAL_COLUMNS = [
  {
    Header: 'Links',
    Cell: ({ original }) => (
      <LinksCell
        currentUrl={original.current_url}
        newUrl={original.new_url}
        targetId={original.target_id}
      />
    ),
  },
  {
    Header: 'Requester',
    Cell: ({ original }) =>
      capitalize(original.user_email ?? original.source_from?.split(':')[0] ?? ''),
  },
  {
    Header: 'Created At',
    accessor: 'created_at',
    Cell: ({ value }) => getFormattedLocalDate(value),
  },
];

const COLUMNS = ({
  onClickApprove,
  onClickDecline,
  approves,
  declines,
  onClickSelectApproveAll,
  onClickSelectDeclineAll,
  data,
}): Column<any, any>[] => [
  {
    Header: 'Id',
    accessor: 'id',
  },
  {
    Header: 'From',
    accessor: 'source_from',
    formatter: value => (value ? capitalize(value.split(':')[0]) : 'Cm'),
    filter: true,
  },
  {
    Header: 'Target Id',
    accessor: 'target_id',
    Cell: props => (
      <a
        href={generateCmUrl('artist', { id: props.value })}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.value}
      </a>
    ),
  },
  {
    Header: 'Links',
    accessor: 'new_url',
    Cell: props => (
      <LinksCell
        currentUrl={props.original.current_url}
        newUrl={props.value}
        targetId={props.original.target_id}
      />
    ),
    width: 900,
  },
  {
    Header: 'Requester',
    accessor: 'user_email',
    Cell: props =>
      props.original.source_from?.split(':')[0] === 'cm' ? (
        <Link
          to={generateInternalLink('user/user-profile', {
            qs: props.value ?? props.original.source_from?.split(':')[1] ?? '',
          })}
        >
          {props.value ?? props.original.source_from?.split(':')[1] ?? ''}
        </Link>
      ) : (
        <Tooltip content={props.value ?? props.original.source_from?.split(':')[1] ?? ''}>
          {(props.value ?? props.original.source_from?.split(':')[1] ?? '').slice(-4).toUpperCase()}
        </Tooltip>
      ),
  },
  {
    Header: 'Created',
    accessor: 'created_at',
    Cell: props => <>{getFormattedLocalDate(props.value)}</>,
    sorter: true,
    width: 200,
  },
  {
    accessor: 'id',
    Cell: ({ value }) => (
      <Flex justify="center">
        <Radio
          name={`approve_${value}`}
          checked={approves.includes(value) ?? false}
          onClick={() => {
            onClickApprove(value);
          }}
        />
      </Flex>
    ),
    Header: (
      <Flex justify="center" align="center" gap={2}>
        Approve
        <Checkbox
          checked={approves.length === data?.length && data?.length > 0}
          onClick={onClickSelectApproveAll}
        />
      </Flex>
    ),
    width: 100,
  },
  {
    accessor: 'id',
    Cell: ({ value }) => (
      <Flex justify="center">
        <Radio
          name={`decline_${value}`}
          checked={declines.includes(value) ?? false}
          onClick={() => onClickDecline(value)}
        />
      </Flex>
    ),
    Header: (
      <Flex justify="center" align="center" gap={2}>
        Decline
        <Checkbox
          checked={declines.length === data?.length && data?.length > 0}
          onClick={onClickSelectDeclineAll}
        />
      </Flex>
    ),
    width: 100,
  },
];

function DSPLinkingRequest() {
  const {
    data,
    isLoading,
    state,
    onSearch,
    onClickApprove,
    onClickDecline,
    submit,
    isSubmitting,
    onClickSelectApproveAll,
    onClickSelectDeclineAll,
    selected,
  } = useRequestTable(useGetDspLinkRequest, useSubmitDspLinkRequest);

  const onClickSubmit = () =>
    submit(
      (id, status) => ({ id, process: status }),
      (approves, declines) => ({ data: { requests: [...approves, ...declines] } })
    );

  return (
    <Panel
      icon="fadLink"
      aside={<DataTableSearch filters={SEARCH_FILTERS} originalData={data} onChange={onSearch} />}
      header={
        <Flex align="center" gap={2}>
          DSP Link Request
          <Badge>{data?.length ?? '0'}</Badge>
        </Flex>
      }
      classNames={{
        footer: 'justify-center',
      }}
      footer={
        <RequestSubmitButton
          columns={SUBMIT_MODAL_COLUMNS}
          onClickSubmit={onClickSubmit}
          approveRequest={state.approves}
          declineRequest={state.declines}
          data={data ?? []}
          loading={isSubmitting}
          selected={selected}
        />
      }
    >
      <AntdTable
        key="table"
        loading={isLoading}
        data={!state.filteredData ? data : state.filteredData}
        columns={COLUMNS({
          onClickApprove,
          onClickDecline,
          approves: state.approves,
          declines: state.declines,
          onClickSelectApproveAll,
          onClickSelectDeclineAll,
          data,
        })}
      />
    </Panel>
  );
}

export default React.memo(DSPLinkingRequest);
