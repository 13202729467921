import { ENTITY_ICON } from '@Constants/entity';
import { KeywordDetailArtistItem, useGetKeywordArtistDetails } from '@Services/keywords';
import { AntdTable } from '@Shared';
import FAIcon from '@Components/ui/fa-icons';
import { Column } from '@Shared/Table/AntdTable';
import EntityCell from '@Shared/Table/EntityCell';
import { ordinalSuffixFormatter } from '@Utils/number';
import React from 'react';
import { ENTITY_COLUMNS } from './columns';
import { Panel } from '@/ui';

interface Props {
  q: string;
}

const Artists = ({ q }: Props) => {
  const { data: artist, isLoading: isLoadingArtist } = useGetKeywordArtistDetails(
    {
      data: {
        q: q && q.replaceAll(' ', '').toLowerCase(),
      },
    },
    {
      disable: !q,
    }
  );

  return (
    <>
      <Panel icon={ENTITY_ICON.ARTIST} header="Artists">
        <AntdTable<KeywordDetailArtistItem & { position: number }>
          columns={ENTITY_COLUMNS('artist') as any}
          data={artist?.map((e, index) => ({ ...e, position: index + 1 })) ?? []}
          showSizeChanger={false}
          pageSize={5}
          loading={isLoadingArtist}
        />
      </Panel>
    </>
  );
};

export default Artists;
