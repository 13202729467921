import { mutateToUrl, persistGetToUrl } from './helpers';

const ENDPOINT = {
  SIMILAR_TRACK: '/admin/similarTracks',
  CREATE: '/admin/track/new',
};

export const useAddSimilarTrack = mutateToUrl<{
  query: {
    trackId1: number;
    trackId2: number;
  };
}>('POST', ENDPOINT.SIMILAR_TRACK);

export const useUpdateSimilarTrack = mutateToUrl<{
  query: {
    trackId1: number;
    trackId2: number;
    hidden: boolean;
  };
}>('PUT', ENDPOINT.SIMILAR_TRACK);

export const useCreateTrack = mutateToUrl<{
  data: {
    name: string;
    image_url: string;
    isrc: string;
    album_url: string;
    artist_url: string;
  };
}>('POST', ENDPOINT.CREATE);