import Subscription from '@Components/UserSubscription';
import { Popconfirm } from 'antd';
import { useState } from 'react';
import {
  useAddTrustedUser,
  useBlockUser,
  useDeleteUser,
  useGrantUserAPIAccess,
  useRemoveSSOLogin,
  useRemoveUserAPIAccess,
  useResetUserPassword,
  useSendVerifyEmail,
  useUnblockLast2FA,
  useUnblockUser,
  useUpdateUserEmail,
  useUpdateUserName,
  useVerifyUserEmail,
} from '../../../services/user';
import { ResponsiveSection, WhatsThis } from '../../../shared/Style';

import {
  generateInternalLink,
  getDateFormatFromDatePicker,
  showInfoMessage,
} from '../../../utilities';

import {
  Avatar,
  AvatarFallback,
  AvatarImage,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  Flex,
  Input,
  ModalButton,
  ModalClose,
  Panel,
} from '@/ui';
import { Table } from '@Components';
import FAIcon from '@Components/ui/fa-icons';
import { Muted } from '@Components/ui/typography';
import { capitalize, isEmpty } from 'lodash';
import { useUserProfileContext } from './useUserProfileContext';

const BlockUserButton = ({ q, blocked }: { q: string; blocked: boolean }) => {
  const [blockedUser, setBlockedUser] = useState(blocked);
  const { refetch } = useUserProfileContext();

  const { execute: blockUser, isLoading: isFetchingBlockUser } = useBlockUser();
  const { execute: unblockUser, isLoading: isFetchingUnblockUser } = useUnblockUser();

  const handleClick = async () => {
    if (blockedUser) {
      await unblockUser({ query: { q } });
    } else {
      await blockUser({ query: { q } });
    }
    setBlockedUser(old => !old);
    refetch();
  };

  return (
    <Button
      loading={isFetchingBlockUser || isFetchingUnblockUser}
      variant={!blockedUser ? 'outlineDestructive' : 'destructive'}
      icon={<FAIcon name="farXmark" />}
      className={blockedUser ? 'green' : ''}
      onClick={handleClick}
    >
      {blockedUser ? 'Click to unblock' : 'Click to block'} User
    </Button>
  );
};

const DeleteUserButton = ({ q }: { q: string }) => {
  const { execute: deleteUser, isLoading: isFetchingDeleteUser } = useDeleteUser();
  const { refetch } = useUserProfileContext();
  const handleClick = async () => {
    await deleteUser({ query: { q } });
    refetch();
  };
  return (
    <Popconfirm title="Are you sure you want to delete this user?" onConfirm={handleClick}>
      <Button
        variant="outlineDestructive"
        icon={<FAIcon name="farTrash" />}
        loading={isFetchingDeleteUser}
      >
        Delete User
      </Button>
    </Popconfirm>
  );
};

const VerifyUserEmailButton = ({ q }: { q: string }) => {
  const { refetch } = useUserProfileContext();
  const { execute, isLoading } = useVerifyUserEmail();
  const handleClick = () => execute({ query: { q } }).then(() => refetch());
  return (
    <Button
      loading={isLoading}
      icon={<FAIcon name="farCheck" />}
      onClick={handleClick}
      title="Verify User Email"
      variant="outlineSubmit"
    >
      Verify User
    </Button>
  );
};

function UnblockLast2FAButton({ id }: { id: number }) {
  const { execute, isLoading } = useUnblockLast2FA();

  const handleClick = () => {
    execute({ path: { id } });
  };
  return (
    <Button
      variant="outlineSubmit"
      icon={<FAIcon name="fasCheckCircle" />}
      onClick={handleClick}
      loading={isLoading}
    >
      Verify Last 2FA Device And Unblock
      <WhatsThis>
        When user fails to login with 2FA, the user is blocked. This will unblock the user
      </WhatsThis>
    </Button>
  );
}

const SendVerifyEmailButton = ({ q }: { q: string }) => {
  const { execute, isLoading } = useSendVerifyEmail();
  return (
    <Button
      loading={isLoading}
      icon={<FAIcon name="farPaperPlane" />}
      variant="outlinePrimary"
      onClick={() => execute({ data: { email: q } })}
    >
      Send Verify Email
    </Button>
  );
};

const GrantUserAPIAccessButton = ({ q, token }: { q: string; token: string }) => {
  const { refetch } = useUserProfileContext();
  const { execute, isLoading } = useGrantUserAPIAccess();

  const handleClick = () => execute({ query: { q } }).then(() => refetch());
  return (
    <Button
      loading={isLoading}
      variant="outlineSubmit"
      icon={token ? <FAIcon name="farPaperPlane" /> : <FAIcon name="farPlus" />}
      onClick={handleClick}
    >
      {token ? 'Send To User' : 'Generate'}
    </Button>
  );
};

const RemoveUserAPIAccessButton = ({ q }: { q: string }) => {
  const { refetch } = useUserProfileContext();
  const { execute, isLoading } = useRemoveUserAPIAccess();
  const handleClick = () => execute({ query: { q } }).then(() => refetch());

  return (
    <Popconfirm title="Are you sure you want to remove user API access?" onConfirm={handleClick}>
      <Button
        variant="outlineDestructive"
        icon={<FAIcon name="farTrash" />}
        loading={isLoading}
        title="Remove User API Access"
      />
    </Popconfirm>
  );
};

const RemoveSSOLoginButton = ({ q }: { q: string }) => {
  const { refetch } = useUserProfileContext();
  const { execute, isLoading } = useRemoveSSOLogin();
  const handleClick = () => execute({ query: { q } }).then(() => refetch());
  return (
    <Button
      variant="outlineDestructive"
      icon={<FAIcon name="farTrash" />}
      onClick={handleClick}
      loading={isLoading}
    >
      Remove 3th party
    </Button>
  );
};

const AddTrustedUserButton = ({ q }: { q: string }) => {
  const { refetch } = useUserProfileContext();
  const { execute, isLoading } = useAddTrustedUser();
  const handleClick = () => execute({ data: { q } }).then(() => refetch());
  return (
    <Button
      variant="outlineSubmit"
      icon={<FAIcon name="fasCheckCircle" />}
      onClick={handleClick}
      loading={isLoading}
    >
      Add To Trusted User
    </Button>
  );
};

const ResetPasswordPasswordButton = ({ q }: { q: string }) => {
  const { execute, isLoading } = useResetUserPassword();
  const handleClick = () => execute({ query: { q } });
  return (
    <Button
      variant="outlineDestructive"
      icon={<FAIcon name="farRotateLeft" />}
      onClick={handleClick}
      loading={isLoading}
    >
      Reset User Password (chartmetric!)
    </Button>
  );
};

const ChangeUserEmailButton = ({ id, email }: { id: number; email: string }) => {
  const { refetch } = useUserProfileContext();
  const { execute, isLoading } = useUpdateUserEmail();

  const handleSubmit = () => {
    if (email !== newEmail) {
      execute({
        data: {
          id,
          email: newEmail,
        },
      }).then(() => refetch(newEmail));
    }
  };
  const [newEmail, setNewEmail] = useState(email);
  return (
    <ModalButton
      header="Change User Email"
      trigger={<Button loading={isLoading} icon={<FAIcon name="farEdit" />} variant="ghost" />}
      footer={
        <ModalClose asChild>
          <Button
            onClick={handleSubmit}
            variant="submit"
            icon={<FAIcon name="farCircleCheck" />}
            loading={isLoading}
            disabled={!newEmail || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(newEmail)}
          >
            Change Email
          </Button>
        </ModalClose>
      }
    >
      <Input
        placeholder="New Email"
        value={newEmail}
        onChange={e => setNewEmail(e.target.value)}
        prefix={<FAIcon name="farEnvelope" />}
      />
    </ModalButton>
  );
};

const ChangeUserNameButton = ({ id, name }: { id: number; name: string }) => {
  const { refetch } = useUserProfileContext();
  const { execute, isLoading } = useUpdateUserName();
  const [newName, setNewName] = useState(name);

  const handleSubmit = () => {
    if (name !== newName) {
      execute({
        data: {
          id,
          name: newName,
        },
      })
        .then(() => showInfoMessage('Successfully changed user name.'))
        .then(() => refetch());
    }
  };
  return (
    <Flex align="center" justify="between" gap={2}>
      {name}
      <ModalButton
        footer={
          <ModalClose asChild>
            <Button
              onClick={handleSubmit}
              variant="submit"
              icon={<FAIcon name="farCircleCheck" />}
              loading={isLoading}
              disabled={isEmpty(newName)}
            >
              Change User Name
            </Button>
          </ModalClose>
        }
        header="Change User Name"
        trigger={<Button variant="ghost" loading={isLoading} icon={<FAIcon name="farEdit" />} />}
      >
        <Input
          placeholder="New Name"
          value={newName}
          onChange={e => setNewName(e.target.value)}
          prefix={<FAIcon name="farUser" />}
        />
      </ModalButton>
    </Flex>
  );
};

function Info({
  id,
  name,
  email,
  blocked,
  reason = 'Unspecified',
  customerInfo,
  register_date,
  image_url,
  verified,
  trusted,
  inTeam,
  teamInfo,
  refreshToken,
  source,
  ip_addresses,
  churnInfo,
}) {
  return (
    <ResponsiveSection>
      <Panel
        classNames={{
          content: 'gap-8 flex flex-col',
        }}
        header={
          <Flex align="center" gap={4}>
            <Avatar className="h-7 w-7">
              <AvatarImage src={image_url} alt={name} />
              <AvatarFallback>
                <Muted>{name?.slice(0, 2)}</Muted>
              </AvatarFallback>
            </Avatar>
            <h2>{name}</h2>
          </Flex>
        }
      >
        <Table
          title="User Info"
          footer={
            <Flex wrap gap={2}>
              <UnblockLast2FAButton id={id} />

              <ResetPasswordPasswordButton q={email} />

              <DeleteUserButton q={id} />
            </Flex>
          }
          options={[
            {
              label: 'User Name',
              component: <ChangeUserNameButton name={name} id={id} />,
              labelIcon: 'farTag',
            },
            {
              label: 'User Email',
              labelIcon: 'farEnvelope',
              component: (
                <Flex justify="between" align="center" gap={2}>
                  {email}
                  <ChangeUserEmailButton email={email} id={id} />
                </Flex>
              ),
            },
            {
              label: 'User ID',
              component: (
                <Flex align="center" justify="between" gap={2}>
                  {id}
                  <Button
                    variant="ghost"
                    icon={<FAIcon name="farCopy" />}
                    onClick={() => {
                      navigator.clipboard.writeText(id);
                      showInfoMessage('Copied!');
                    }}
                  />
                </Flex>
              ),
              labelIcon: 'farIdCard',
            },
            {
              label: 'Joined at',
              component: getDateFormatFromDatePicker(register_date),
              labelIcon: 'farCalendar',
            },
            {
              label: 'Verified Email',
              component: (
                <Flex justify="between" gap={12} align="center">
                  <FAIcon color="green" name={verified && 'fasCheckCircle'} />
                  {!verified && <VerifyUserEmailButton q={id} />}
                  {!verified && <SendVerifyEmailButton q={email} />}
                </Flex>
              ),
              labelIcon: 'fasCheckCircle',
            },
            {
              labelIcon: 'farUserTie',
              label: 'Trusted',
              component: !trusted ? (
                <AddTrustedUserButton q={id} />
              ) : (
                <FAIcon color="green" name="fasCheckCircle" />
              ),
            },
            {
              label: 'Signed up with',
              component: (
                <Flex wrap gap={8} align="center" justify="between">
                  {capitalize(source) || 'EMAIL'}
                  {/* @ts-ignore */}
                  {source && <RemoveSSOLoginButton q={id} />}
                </Flex>
              ),

              labelIcon: 'farEnvelope',
            },
            {
              label: 'Refresh Token',
              description: 'User should have API plan to use this token',
              component: (
                <Flex gap={8} wrap align="center" justify="between">
                  {!refreshToken.refresh_token && 'No refresh token'}
                  {refreshToken.refresh_token && (
                    <Button
                      variant="ghost"
                      icon={<FAIcon name="farCopy" />}
                      onClick={() => {
                        navigator.clipboard.writeText(refreshToken.refresh_token);
                        showInfoMessage('Copied!');
                      }}
                    />
                  )}
                  <Flex gap={4}>
                    <GrantUserAPIAccessButton q={id} token={refreshToken.refresh_token} />
                    <RemoveUserAPIAccessButton q={id} />
                  </Flex>
                </Flex>
              ),
              labelIcon: 'fadLockKeyhole',
            },
            {
              label: 'Blocked',
              component: (
                <Flex gap={4} justify="between" align="center" wrap>
                  {blocked ? 'Blocked' : 'Not Blocked'}
                  {blocked && `This user is currently blocked (Reason: ${reason})`}
                  {/* @ts-ignore */}

                  <BlockUserButton blocked={blocked} reason={reason} q={id} />
                </Flex>
              ),
              labelIcon: 'farXmark',
            },
          ]}
        />
        {teamInfo?.managerEmail && (
          <Table
            title="Team Info"
            aside={
              <Button variant="link" asChild size="auto">
                <a
                  target="_blank"
                  href={generateInternalLink(`purchase/team-manager`, {
                    q: email,
                  })}
                >
                  See details
                </a>
              </Button>
            }
            options={[
              {
                label: 'Team Role',
                component:
                  teamInfo &&
                  (teamInfo.managerEmail === email
                    ? 'Account Manager'
                    : teamInfo.admin
                      ? 'ADMIN'
                      : 'MEMBER'),
                labelIcon: 'farTag',
              },
              {
                label: 'Manager Email',
                component: teamInfo && teamInfo.managerEmail,
                labelIcon: 'farEnvelope',
              },
              {
                label: 'Max Members',
                component: teamInfo && teamInfo.maxAccounts,
                labelIcon: 'farUsers',
              },
              {
                label: 'Max Members (Artist Plan)',
                component: teamInfo && teamInfo.artistsPerAccount,
                labelIcon: 'farUsers',
              },
            ]}
          />
        )}
      </Panel>
      <Subscription email={email} customerInfo={customerInfo} churnInfo={churnInfo} userId={id} />
    </ResponsiveSection>
  );
}

export default Info;
