import { Alert, AlertDescription, AlertTitle } from '@/ui';
import { isEmpty } from 'lodash';
import { Terminal } from 'lucide-react';

const Intercom = () => {
  const notice = process.env.REACT_APP_NOTICE;
  if (isEmpty(notice)) return null;

  return (
    <Alert>
      <Terminal className="h-4 w-4" />
      <AlertTitle>Notice</AlertTitle>
      {/* @ts-ignore */}
      <AlertDescription dangerouslySetInnerHTML={{ __html: notice }} />
    </Alert>
  );
};

export default Intercom;
