import { cn } from '@/lib/utils';
import { Button } from '@/ui';
import FAIcon from '@Components/ui/fa-icons';
import { ENTITY } from '@Constants/entity';
import Country from '@Components/Country';
import { generateCmUrl, generateInternalLink } from '@Utils';
import { Typography, Flex, Tooltip } from 'antd';
import classNames from 'classnames';
import { capitalize } from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  value: {
    id: string | number;
    name: string;
    image_url: string;
    platform?: string;
    code2?: string;
    description?: string;
  };
  entity: ENTITY;
  className?: string;
}

const EntityCell = ({ value, entity, className }: Props) => (
  <Typography.Link
    className={cn(className, 'font-medium line-clamp-1')}
    target="_blank"
    href={generateCmUrl(entity, {
      id: value.id,
      service: value.platform,
    })}
  >
    <Flex align="center" gap="small" className="flex-col md:flex-row">
      <img
        width={44}
        height={44}
        src={value.image_url}
        className={classNames('max-w-11 max-h-11', {
          'rounded-full': entity === 'artist',
          'rounded-md': entity === 'playlist',
        })}
      />
      <Flex gap={4} align="center">
        {capitalize(value.name)}
        {value.code2 && <Country showName={false} value={value.code2} />}
        <Link
          target="_blank"
          to={generateInternalLink('cmdata/edit-entity', {
            q: generateCmUrl(entity, {
              id: value.id,
              service: value.platform,
            }),
          })}
        >
          <Button icon={<FAIcon name="farEdit" color="gray" />} variant="ghost" size="icon" />
        </Link>
      </Flex>
    </Flex>
  </Typography.Link>
);

export default EntityCell;
