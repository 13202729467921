import { persistGetToUrl } from './helpers';

const ENDPOINT = {
  CITY_LIST: `/city/list`,
};

export const useGetCityList = persistGetToUrl<{
  data: {
    offset?: number;
    limit?: number;
    q?: string;
    sortColumn?: string;
    sortOrderDesc?: boolean;
    code2s?: string[];
    continents?: string[];
    genres?: string[];
    population?: { min: number; max: number }[];
    bounding_box?: { top_left: any; bottom_right: any };
  };
}>(ENDPOINT.CITY_LIST, {
  parse: data => data?.obj,
});
