import { ARTIST_TAG_MAPPER } from '@Utils/constants';
import { mutateToUrl, persistGetToUrl } from './helpers';
import { useTagList } from './tag';

const ENDPOINT = {
  VERIFY: '/sns/verify-single',
  LINKING: (entity: string) => `/admin/${entity}/linking`,
  SUGGESTIONS: '/admin/:target/link-suggestions',
};

export const useUpdateArtistLink = mutateToUrl<{
  query: {
    id?: number;
  };
  data: {
    isDelete?: boolean;
    q: string;
  };
}>('PUT', ENDPOINT.LINKING('artist'));

export const useUpdateTrackLink = mutateToUrl<{
  query?: {
    id?: number;
  };
  data: {
    q: string;
    isrc?: string;
    name?: string;
    artists?: string;
  };
}>('PUT', ENDPOINT.LINKING('track'));

export const useUpdateAlbumLink = mutateToUrl<{
  query: {
    id?: number; //if id undefined, it will be deleted
  };
  data: {
    q: string;
  };
}>('PUT', ENDPOINT.LINKING('album'));

export const useVerifyDSP = mutateToUrl<{
  data: {
    urlTarget: 'cm_artist';
    urlSource: string;
    targetId: number;
  };
}>('PUT', ENDPOINT.VERIFY);

export const LINK_SUGGESTION_SUPPORTED_PLATFORMS = {
  artist: ['spotify', 'youtube', 'tiktok', 'shazam', 'pandora', 'instagram'],
  track: ['spotify', 'youtube', 'tiktok', 'shazam', 'pandora'],
};

export const getLinkSuggestionsCacheKey = (params: any) => [
  'LINK_SUGGESTIONS',
  params.data,
  params.path.target,
];

export const useLGetLinkSuggestions = persistGetToUrl<
  {
    path: {
      target: 'artist' | 'track';
    };
    data: {
      artistName: string;
      trackName?: string;
      platforms: (typeof LINK_SUGGESTION_SUPPORTED_PLATFORMS)['artist'][number][];
    };
  },
  {
    [key: string]: { url: string; ordering: number; title: string }[];
  }
>(ENDPOINT.SUGGESTIONS, {
  key: getLinkSuggestionsCacheKey,
  manual: true,
});


export const useUpdateCuratorLink = mutateToUrl<{
  query: {
    id?: number;
    streamingType: string;
  };
  data: {
    isDelete?: boolean;
    q: string;
  };
}>('PUT', ENDPOINT.LINKING('curator'));