import React, { useMemo } from 'react';
import { Tag as AntdTag, Button, Empty, Flex, Tooltip } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { capitalizeEveryWord } from '@Utils';
import { SUPPORTED_GENRE_ORDERING_ENTITIES, useUpdateGenreOrder } from '@Services/tag';
import { getTagColor } from '../context/constants';
import { EntityTag } from '../context/types';
import { useGenrePanel } from '../context/GenrePanelContext';
import { Panel } from '@/ui';
import FAIcon from '@Components/ui/fa-icons';

const GenreOrder = () => {
  const { entity, isLoading, orders, groupedTags, onEditOrderGenre, onChangeOrder, id } =
    useGenrePanel();
  const { execute, isLoading: isLoadingUpdate } = useUpdateGenreOrder({
    loadingMessage: 'Updating order...',
  });

  if (!SUPPORTED_GENRE_ORDERING_ENTITIES.includes(entity)) {
    return null;
  }

  const genres = useMemo(
    () =>
      orders
        .map(id =>
          Object.values(groupedTags)
            .flatMap(e => Object.values(e))
            .flat()
            .filter(e => !e.hidden)
            .find(e => e.id === id)
        )
        .filter(Boolean) as EntityTag[],
    [orders, groupedTags]
  );

  const handleOnDragEnd = result => {
    console.log('result', result);
    if (!result.destination) {
      return;
    }

    const newOrders = Array.from(orders);
    const [reorderedItem] = newOrders.splice(result.source.index, 1);
    newOrders.splice(result.destination.index, 0, reorderedItem);

    onChangeOrder(newOrders);
  };

  return (
    <Panel
      header="Ordering"
      aside={
        <Button
          type="primary"
          className="green"
          icon={<FAIcon name={'farEdit'} />}
          onClick={() =>
            execute({
              path: { entity, id },
              data: { order: orders },
            })
          }
          loading={isLoadingUpdate}
        >
          Save
        </Button>
      }
      description={
        <>
          The order is for displaying in productions. You can change the order by dragging the tags
          and add or delete. It will reflect to production by data-side scheduling.
        </>
      }
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="genres" direction="horizontal">
          {provided => (
            <Flex
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="px-2 overflow-auto"
            >
              {genres.length > 0 ? (
                genres.map((genre, index) => (
                  <Draggable key={genre.id} draggableId={genre.id.toString()} index={index}>
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <AntdTag
                          color={getTagColor(genre.internalTagType, genre.hidden, genre.source)}
                          className="mb-1"
                        >
                          <Flex align="center" gap="middle">
                            {capitalizeEveryWord(genre.name.replaceAll('_', ' '))}
                            <Tooltip title="Remove from ordering">
                              <FAIcon
                                name={'fasCircleXmark'}
                                onClick={() => onEditOrderGenre(genre.id)}
                              />
                            </Tooltip>
                          </Flex>
                        </AntdTag>
                      </div>
                    )}
                  </Draggable>
                ))
              ) : (
                <Empty description="No genres to order" />
              )}
              {provided.placeholder}
            </Flex>
          )}
        </Droppable>
      </DragDropContext>
    </Panel>
  );
};

export default GenreOrder;
