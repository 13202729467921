import { MENUS } from './menus';
import { FAIcons } from '@Components/ui/fa-icons';
import { getRouteName } from './useRoute';

export type Submenu = {
  href: string;
  label: string;
  icon?: FAIcons;
};

export type Menu = {
  href: string;
  label: string;
  icon: FAIcons;
  submenus: Submenu[];
};

export type Group = {
  group: string;
  menus: Menu[];
};

export const checkActiveSubmenu = (rootRoute: string, subMenus: Submenu[]) => {
  if (rootRoute === window.location.pathname) {
    return true;
  }

  return subMenus.some(
    subMenu => getRouteName(rootRoute, subMenu.label) === window.location.pathname
  );
};

export const checkActiveMenu = (route: string) => {
  return window.location.pathname === route;
};

export const getSidebarMenus = (menu: any): Group[] =>
  menu.reduce((all: Group[], menu) => {
    if (menu.group) {
      const group = all.find(group => group.group === menu.group);
      if (group) {
        group.menus.push({
          href: menu.route,
          label: menu.name,
          icon: menu.icon,
          submenus: menu.items.map(item => ({
            icon: item.icon,
            href: getRouteName(menu.route, item.name),
            label: item.name,
            active: false,
          })),
        });
      } else {
        all.push({
          group: menu.group,
          menus: [
            {
              href: menu.route,
              label: menu.name,
              icon: menu.icon,
              submenus: menu.items.map(item => ({
                icon: item.icon,
                href: getRouteName(menu.route, item.name),
                label: item.name,
                active: false,
              })),
            },
          ],
        });
      }
    } else {
      all.push({
        group: '',
        menus: [
          {
            href: menu.route,
            label: menu.name,
            icon: menu.icon,
            submenus: menu.items.map(item => ({
              href: getRouteName(menu.route, item.name),
              label: item.name,
              active: false,
            })),
          },
        ],
      });
    }

    return all;
  }, []);
