import { Button, Form, FormField, H3, Input, Muted } from '@/ui';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUser } from '@Services/auth';
import { useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { z } from 'zod';
import { G_CLOUD_STORAGE } from '../constants/resources';
import { ROUTE_PATH } from '../routes/path';

function Login() {
  const { login, isAuthed, isLoggingIn, isAutoLoggingIn } = useUser();

  const handleLogin = async (data: z.infer<typeof formSchema>) => {
    login({ username: data.email, password: data.password });
  };

  const formSchema = z.object({
    email: z.string().email(),
    password: z.string().min(1),
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  if (isAuthed)
    return (
      <Navigate
        to={
          window.location.search
            ? window.location.search.replace('?redirect=', '') + window.location.hash
            : ROUTE_PATH.HOME
        }
      />
    );

  return (
    <main className="flex flex-row h-[100vh]">
      <section className="flex-col flex-1 h-full hidden md:flex">
        <div
          id="chartmetric"
          className="bg-[#111928] flex-1 flex flex-row items-center justify-center gap-4"
        >
          <img src={G_CLOUD_STORAGE.CHARTMETRIC_LOGO} alt="Chartmetric" width={100} height={32} />
          <img
            src={G_CLOUD_STORAGE.CHARTMETRIC_TEXT}
            alt="Chartmetric-text"
            width={300}
            height={300}
          />
        </div>
        <div id="onesheet" className="bg-[#0368ff32] flex-1 flex items-center justify-center">
          <img src={G_CLOUD_STORAGE.ONESHEET} alt="One Sheet Club" width={300} height={300} />
        </div>
      </section>
      <section className="flex-1 h-full flex flex-col items-center justify-center gap-4 bg-white dark:bg-black">
        <H3>Login to the Admin tool</H3>
        <Muted>Enter your chartmetric admin account to access</Muted>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(handleLogin)}
            className="gap-4 flex flex-col mt-4 w-[350px]"
          >
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => <Input {...field} placeholder="Email" />}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => <Input {...field} placeholder="Password" type="password" />}
            />
            <Button
              type="submit"
              loading={isLoggingIn}
              disabled={isLoggingIn || isAutoLoggingIn}
              variant="black"
            >
              Login
            </Button>
          </form>
        </Form>
        <Muted className="w-[350px] text-center mt-2">
          If you don't have access, please contact Yusang Park or request to anyone can access admin
          tool. They can grant you access in Admin users feature in this tool.
        </Muted>
      </section>
    </main>
  );
}

export default Login;
