'use client';

import FAIcon, { FAIcons } from '@Components/ui/fa-icons';
import { ChevronDown, Dot } from 'lucide-react';
import { useState } from 'react';

import { cn } from '@/lib/utils';
import {
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  TooltipContent,
  TooltipProvider,
  TooltipRoot,
  TooltipTrigger,
} from '@/ui';
import { DropdownMenuArrow } from '@radix-ui/react-dropdown-menu';
import { checkActiveMenu, checkActiveSubmenu, Submenu } from '@Routes/sidebar';
import { Link } from 'react-router-dom';

interface CollapseMenuButtonProps {
  route: string;
  icon: FAIcons;
  label: string;
  active: boolean;
  submenus: Submenu[];
  isOpen: boolean | undefined;
}

export function CollapseMenuButton({
  icon: Icon,
  label,
  active,
  submenus,
  isOpen = true,
  route,
}: CollapseMenuButtonProps) {
  const isSubmenuActive = checkActiveSubmenu(route, submenus);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isSubmenuActive);

  return isOpen ? (
    <Collapsible open={isCollapsed} onOpenChange={setIsCollapsed} className="w-full">
      <CollapsibleTrigger className="[&[data-state=open]>div>div>svg]:rotate-180 mb-1" asChild>
        <Button
          variant={active ? 'ghost' : 'ghost'}
          className={cn(
            'w-full justify-start h-10 rounded-xl hover:bg-transparent',
            active ? 'font-semibold text-primary' : 'text-gray-500'
          )}
        >
          <div className="w-full items-center flex justify-between">
            <div className="flex items-center">
              <span className="flex mr-4">
                <FAIcon className="w-[18px] h-[18px]" name={Icon} />
              </span>
              <p
                className={cn(
                  'max-w-[150px] truncate ',
                  isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0'
                )}
              >
                {label}
              </p>
            </div>
            <div
              className={cn(
                'whitespace-nowrap',
                isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0'
              )}
            >
              <ChevronDown size={18} className="transition-transform duration-200 text-gray-500" />
            </div>
          </div>
        </Button>
      </CollapsibleTrigger>
      <CollapsibleContent className="overflow-hidden data-[state=closed]:animate-collapsible-up data-[state=open]:animate-collapsible-down">
        {submenus.map(({ href, label, icon }, index) => {
          return (
            <Button
              key={index}
              variant={checkActiveMenu(href) ? 'default' : 'ghost'}
              className={cn(
                'w-full justify-start h-10 mb-1 rounded-xl hover:bg-primary hover:bg-opacity-10 hover:text-primary-foreground',
                checkActiveMenu(href) ? 'font-semibold' : 'text-gray-500'
              )}
              asChild
            >
              <Link to={href} className="text-foreground">
                <span className="mr-4 ml-2 flex flex-row items-center">
                  {icon ? (
                    <FAIcon name={icon} className={cn('w-[16px] h-[16px]')} />
                  ) : (
                    <Dot size={18} />
                  )}
                </span>
                <p
                  className={cn(
                    'max-w-[170px] truncate',
                    isOpen ? 'translate-x-0 opacity-100' : '-translate-x-96 opacity-0'
                  )}
                >
                  {label}
                </p>
              </Link>
            </Button>
          );
        })}
      </CollapsibleContent>
    </Collapsible>
  ) : (
    <DropdownMenu>
      <TooltipProvider disableHoverableContent>
        <TooltipRoot delayDuration={100}>
          <TooltipTrigger asChild>
            <DropdownMenuTrigger asChild>
              <Button
                variant={active ? 'default' : 'ghost'}
                className={cn(
                  'w-full justify-start h-10 mb-1 rounded-xl',
                  active ? 'font-semibold' : 'text-gray-400'
                )}
              >
                <div className="w-full items-center flex justify-between">
                  <div className="flex items-center justify-center">
                    <FAIcon
                      name={Icon}
                      className={cn('w-[18px] h-[18px]', isOpen === false ? '' : 'mr-4')}
                    />
                    <p
                      className={cn(
                        'max-w-[200px] truncate ',
                        isOpen === false ? 'opacity-0' : 'opacity-100'
                      )}
                    >
                      {label}
                    </p>
                  </div>
                </div>
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          <TooltipContent side="right" align="start" alignOffset={2}>
            {label}
          </TooltipContent>
        </TooltipRoot>
      </TooltipProvider>
      <DropdownMenuContent side="right" sideOffset={25} align="start">
        <DropdownMenuLabel className="max-w-[190px] truncate font-normal">
          {label}
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        {submenus.map(({ href, label }, index) => (
          <DropdownMenuItem key={index} asChild>
            <Link className="cursor-pointer text-foreground" to={href}>
              <p className="max-w-[180px] truncate font-normal">{label}</p>
            </Link>
          </DropdownMenuItem>
        ))}
        <DropdownMenuArrow className="fill-border" />
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
