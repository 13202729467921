import { get, set } from 'lodash';
import { useEffect, useRef, useState } from 'react';

export type DeepKeys<T> = {
  [K in keyof T & (string | number)]: T[K] extends object
    ? `${K}` | `${K}.${DeepKeys<T[K]>}`
    : `${K}`;
}[keyof T & (string | number)];

const useStates = <T extends Record<string, any>>(freshState: T, dependencies: any[] = []) => {
  const [states, _setState] = useState<T>(freshState);
  const initialStateRef = useRef(freshState);

  const setState =
    <K extends DeepKeys<T>>(key: K) =>
    (value?: T[K] | ((old: T[K]) => T[K])) => {
      _setState(old => {
        const newState = { ...old };
        const newValue =
          typeof value === 'function' ? (value as (old: T[K]) => T[K])(get(old, key)) : value;
        set(newState, key, newValue);

        return newState;
      });
    };

  const setAllStates = (newState: Partial<T> | ((old: T) => Partial<T>)) => {
    _setState(old => {
      const nextState = typeof newState === 'function' ? newState(old) : newState;
      return { ...old, ...nextState };
    });
  };

  const onChangeState =
    <K extends DeepKeys<T>>(key: K) =>
    (value: T[K]) => {
      _setState(old => ({ ...set(old, key, value) }));
    };

  const onDispatchState =
    <K extends DeepKeys<T>>(key: K, callback: (newValue: T[K], old: T) => T | T) =>
      (value: T[K]) => {
        _setState(old => (callback?.(value, old) ? callback(value, old) : value));
      };;

  const forceUpdateStates = () => {
    _setState(initialStateRef.current);
  };

  useEffect(() => {
    initialStateRef.current = freshState;
  }, [freshState]);

  useEffect(() => {
    if (dependencies.length === 0) return;
    _setState(initialStateRef.current);
  }, [...dependencies]);

  return { states, onChangeState, onDispatchState, setState, setAllStates, forceUpdateStates };
};

export default useStates;
